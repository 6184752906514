import { StyledFilterSelector } from "./styled.filter-selector";
import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { useMemo } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import {
  EFilteredAlarms,
  EFilterSelection,
  IFilterState,
  ILocalState,
} from "../../../../../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import {
  EAppStatusActionType,
  IAppStatusAction,
} from "../../../../../../_lib/store/reducers/appStatus";
import { isEqual } from "lodash";

export interface IFilterOptionsList {
  name: EFilteredAlarms;
}
export interface IFilterItem {
  selected: boolean;
  text: string;
  type: keyof IFilterState;
}
const { Wrapper, List, ListItem, Selection, Text } = StyledFilterSelector;
export const FilterByAlertType = ({ name }: IFilterOptionsList): JSX.Element => {
  const { t } = useTranslation(["tax/common"]);
  const filters = useSelector(({ appStatus: { filters } }: ILocalState) => filters, isEqual);
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const items = useMemo<IFilterItem[]>(() => {
    const options = filters?.options;
    if (options === undefined) return [];

    switch (name) {
      case "PREV_MONTH":
      case "AVERAGE":
      case "CUMULATIVE":
      case "CRITICAL_VALUE":
        return [
          {
            selected: options[name].isCritical === EFilterSelection.SHOW,
            text: t("tax/common:main_content.total_analyse.filter_critical"),
            type: "isCritical",
          },
          {
            selected: options[name].isConsiderable === EFilterSelection.SHOW,
            text: t("tax/common:main_content.total_analyse.filter_considerable"),
            type: "isConsiderable",
          },
        ];
      default:
        return [];
    }
  }, [filters?.options, name, t]);
  const handleToggleSelection = (type: keyof IFilterState): void => {
    const options = filters?.options;
    if (!options || !options[name]) return;

    dispatchAppStatus({
      type: EAppStatusActionType.TOGGLE_APP_ALERT,
      payload: {
        toggle_alerts: [name, type],
      },
    });
  };

  return (
    <Wrapper>
      <List>
        {items.map(({ selected, text, type }, index) => {
          return (
            <ListItem
              key={index}
              onClick={(): void => {
                handleToggleSelection(type);
              }}
            >
              <Selection selected={selected}>
                <Icon name={IconNameEnums.Q_CHECKMARK_ON} size={IconSizeEnums.SMALL} />
              </Selection>
              <Text>{text}</Text>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};
