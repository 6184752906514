export interface IEvaluations {
  month: string;
  all_relevant: boolean;
  grades: IEvaluationGrade[];
}

export interface IEvaluationGrade {
  grade: number;
  grade_id: string;
  trend: EEvaluationTrend;
  weight: number;
}

export enum EEvaluationTrend {
  better = "better",
  unchanged = "unchanged",
  worse = "worse",
}
