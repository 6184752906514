import { FC, useMemo } from "react";
import { EProductEnums } from "../../../../_lib/Layout/types.layout";

import { SetupTaxMenu } from "./SetupTaxMenu";

import { SetupLayout } from "../../../../_lib/Layout";
import { PrepareSettings } from "./PrepareSettings";
import { ClientSelection } from "./ClientSelection";
// import { ClientsAnalyse } from "./ClientsAnalyse";
import { ThresholdSettings } from "./ThresholdSettings";
import { useSelector } from "react-redux";
import { ETaxSetupSyncState, ILocalState } from "../../../../@types/index.d";
import { isEqual } from "lodash";

export const SetupTax: FC = () => {
  const appSync = useSelector(({ appStatus: { appSync } }: ILocalState) => appSync, isEqual);
  const SetupTaxContent = useMemo(() => {
    switch (appSync && appSync.setupSyncState) {
      case ETaxSetupSyncState.INITIAL:
        return PrepareSettings;
      case ETaxSetupSyncState.THRESHOLD:
        return ThresholdSettings;
      case ETaxSetupSyncState.SELECTION:
        return ClientSelection;
      default:
        return PrepareSettings;
    }
  }, [appSync]) as FC;

  return <SetupLayout Menu={SetupTaxMenu} Content={SetupTaxContent} product={EProductEnums.TAX} />;
};
