import { FC } from "react";
import {
  AlignmentEnums,
  InputTypes,
  Form,
  FormRow,
  IFormState,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Input,
  ICardTypeEnums,
  ErrorBoundary,
  FadeIn,
  Card,
  CardBody,
  CardTitle,
} from "@canei/app-components";

import { useTranslation } from "react-i18next";

import { SimpleLayout } from "../../_lib/Layout";

import { LoginError } from "./LoginError";

import { useChangePassword } from "../../_lib/hooks";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { Logo } from "./Logo";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../../_lib/store/reducers";
import { isEqual } from "lodash";

export const ChangePassword: FC = () => {
  const { t } = useTranslation(["quick/common"]);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();
  const [changePassword, loading] = useChangePassword();

  const handleForceChange = (form: IFormState): void => {
    if (currentUser.errors.length > 0) {
      dispatchUser({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          errors: [],
        },
      });
    }
    changePassword({
      email: form?.email || currentUser.email,
      password: form.password,
      repeatPassword: form.repeatPassword,
      temporaryPassword: form?.temporaryPassword || "",
    });
  };

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <FadeIn>
          <Card type={ICardTypeEnums.CENTERED} loading={loading}>
            <CardTitle>
              <Logo />
            </CardTitle>

            <CardBody>
              <Form onSubmitHandler={handleForceChange}>
                {currentUser.email === "" && (
                  <FormRow align={AlignmentEnums.STRETCH}>
                    <Input
                      label={t("quick/common:change.email.label")}
                      name="email"
                      type={InputTypes.EMAIL}
                      value=""
                      required={true}
                      disabled={loading}
                      branded={true}
                    />
                  </FormRow>
                )}
                {currentUser.temporaryPassword === "" && (
                  <FormRow align={AlignmentEnums.STRETCH}>
                    <Input
                      label={t("quick/common:change.temp_password.label")}
                      name="temporaryPassword"
                      type={InputTypes.PASSWORD}
                      value=""
                      required={true}
                      disabled={loading}
                      branded={true}
                    />
                  </FormRow>
                )}
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Input
                    label={t("quick/common:change.password.label")}
                    name="password"
                    type={InputTypes.PASSWORD}
                    value=""
                    required={true}
                    disabled={loading}
                    branded={true}
                  />
                </FormRow>
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Input
                    label={t("quick/common:change.repeat_password.label")}
                    name="repeatPassword"
                    type={InputTypes.PASSWORD}
                    value=""
                    required={true}
                    disabled={loading}
                    branded={true}
                  />
                </FormRow>

                {currentUser.errors.length > 0 && <LoginError />}

                <FormRow align={AlignmentEnums.CENTER}>
                  <ButtonBranded
                    inverted={false}
                    variant={ButtonBrandedVariantEnums.PRIMARY}
                    type="submit"
                    disabled={loading}
                    inline
                  >
                    {t("quick/common:change.submit")}
                  </ButtonBranded>
                </FormRow>
              </Form>
            </CardBody>
          </Card>
        </FadeIn>
      </ErrorBoundary>
    </SimpleLayout>
  );
};
