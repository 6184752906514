import { css } from "styled-components/macro";

import styled from "styled-components/macro";
import { IClientUploadState } from "../../SetupTax/styled.setup-tax";
import { EClientSelection } from "../../../../../_lib/hooks/useDatev";
import { useMemo } from "react";

const Wrapper = styled.div(({ theme }) => {
  return css`
    max-width: 75vw;
    max-height: 75vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: ${theme.rem(1)};
    margin-bottom: ${theme.rem(2)};
    overflow-y: auto;
    overflow-x: hidden;
  `;
});

const ChkListItem = styled.div(({ theme }) => {
  return css`
    flex-basis: calc(25% - ${theme.rem(1)});
  `;
});

const Month = styled.div(({ theme }) => {
  return css`
    max-width: 20%;
    margin-right: ${theme.rem(1)};
    margin-top: ${theme.rem(0.5)};
  `;
});

const SearchBar = styled.div(({ theme }) => {
  return css`
    position: relative;
    width: 50%;
    margin-right: ${theme.rem(1)};
    margin-top: ${theme.rem(1.5)};
    & span {
      position: absolute;
      z-index: 1;
      right: ${theme.rem(1)};
      top: ${theme.rem(0.5)};
    }
  `;
});

const ClientName = styled.span(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(0)};
    position: relative;
    width: 100%;
    &:hover {
      & > div {
        display: block;
      }
    }
  `;
});

const ClientCode = styled.div(({ theme }) => {
  return css`
    display: none;
    position: absolute;
    font-size: ${theme.rem(0.8)};
    top: ${theme.rem(-2)};
    padding: ${theme.rem(0.3)};
    box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
      ${theme.values.colors.global.greys._025};
    border-radius: ${theme.rem(0.5)};
    text-align: center;
  `;
});

const ErrorText = styled.section(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(1)};
    width: 100%;
    text-align: center;
    color: red;
  `;
});

const SelectAll = styled.div(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(2)};
    display: flex;
    width: 95%;
    justify-content: space-between;
  `;
});

const ErrorLegendWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: space-between;
    padding-left: ${theme.rem(1.5)};
    gap: ${theme.rem(1)};
  `;
});

const ErrorLegend = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    gap: ${theme.rem(0.5)};
  `;
});

const ErrorLegendIcon = styled.span<IClientUploadState>(({ theme, state }) => {
  const { success, error, info } = theme.values.colors.global.validation;
  const { _000 } = theme.values.colors.global.greys;

  const color: string = useMemo(() => {
    if (state === EClientSelection.LOADED) return success;
    if (state === EClientSelection.ERRORED) return error;
    if (state === EClientSelection.NO_SUSAS) return info;
    if (state === EClientSelection.TIME_OUT) return info;
    if (state === EClientSelection.INCOMPATIBLE) return info;
    if (state === EClientSelection.DATAPATH_INVALID) return info;
    if (state === EClientSelection.LOADING) return _000;
    return _000;
  }, [state, success, error, info, _000]);

  return css`
    svg,
    svg * {
      fill: ${color};
    }
  `;
});

export const StyledViewSetup = {
  Wrapper,
  ChkListItem,
  Month,
  SearchBar,
  ClientName,
  ClientCode,
  ErrorText,
  SelectAll,
  ErrorLegendWrapper,
  ErrorLegendIcon,
  ErrorLegend,
};
