import { FC } from "react";
import { Cell, CellProps } from "@canei/app-components";
import { Trans } from "react-i18next";
import { StyledFilterable } from "./styled.filterable";

import { Filter, IFilterProps } from "./Filter";
export interface IFilterable extends IFilterProps {
  i18nKey: string;
  active?: boolean;
}

export const Filterable: FC<IFilterable & CellProps> = ({
  level = "title",
  align,
  i18nKey,
  name,
}) => {
  return (
    <Cell level={level} align={align}>
      <StyledFilterable.Wrapper>
        <StyledFilterable.Label>
          <Trans i18nKey={`tax/common:main_content.total_analyse.rows.${i18nKey}`} />
        </StyledFilterable.Label>
        <StyledFilterable.Button>
          <Filter name={name} />
        </StyledFilterable.Button>
      </StyledFilterable.Wrapper>
    </Cell>
  );
};
