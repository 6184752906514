import { FC, lazy, Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { CustomerState, ILocalState, EPrivatePaths } from "../../../@types/index.d";
import { ESubscriptionStatus, LoaderTypeEnums, LoadingIndicator } from "@canei/app-components";
import { Redirect, Route, Switch } from "react-router-dom";
import { DownloadApp } from "./DownloadApp";
import Logout from "../../local/Private/Dashboard/Logout";
import { isEqual } from "lodash";

const PaymentWall = lazy(() => import("./Payment/PaymentWall"));

export const Private: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const customerData = useSelector(
    ({ billing: { customerData } }: ILocalState) => customerData,
    isEqual
  );
  const subscription = useSelector(
    ({ billing: { subscription } }: ILocalState) => subscription,
    isEqual
  );
  const renderPaymentWall = useMemo<boolean>(() => {
    if (!customerData) return false;
    const now = Date.now() / 1000;

    if (customerData.status === "canceled" || customerData.status === "payment_required") {
      if (
        customerData?.status === ESubscriptionStatus.CANCELED &&
        customerData?.canceled_at &&
        customerData?.canceled_at > now
      ) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }, [customerData]);
  const isLoading = useMemo(() => {
    if (currentUser.state === CustomerState.READY) return false;
    return !subscription;
  }, [currentUser.state, subscription]);

  if (isLoading) {
    return <LoadingIndicator type={LoaderTypeEnums.PAGE} />;
  }

  return (
    <Switch>
      <Route path={EPrivatePaths.LOGOUT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Logout />
        </Suspense>
      </Route>
      <Route path={EPrivatePaths.PAYMENT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <PaymentWall />
        </Suspense>
      </Route>
      <Route path={EPrivatePaths.HOME}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          {renderPaymentWall ? <Redirect to={EPrivatePaths.PAYMENT} /> : <DownloadApp />}
        </Suspense>
      </Route>
      <Route path={"*"}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Redirect to={renderPaymentWall ? EPrivatePaths.PAYMENT : EPrivatePaths.HOME} />
        </Suspense>
      </Route>
    </Switch>
  );
};
