import { AccountMappingType, Country, ERevenueCurves } from "@canei/app-components";
import { ClientInput, ClientTax, ILocalState } from "../../../@types";
import moment from "moment";
import {
  IDatevFiscalYearShort,
  IRequiredDataForSusaCall,
} from "@canei/datev-connect-client/@types";
import { useSetDatevMetaData } from "../useMetaData";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

interface IuseDatevHelperRetVal {
  getClientObject: (
    name: string,
    category: string,
    fiscal_year: string,
    mapping: string,
    customer_id: string
  ) => Omit<ClientInput, "client_id">;
  getRequiredData: (
    datevClientId: string,
    selectedDate: string,
    clientInCRM: ClientTax
  ) => Promise<IRequiredDataForSusaCall | undefined>;
}

export const useDatevHelper = (): IuseDatevHelperRetVal => {
  const [setMeta] = useSetDatevMetaData();
  const datev = useSelector(({ appStatus: { datev } }: ILocalState) => datev, isEqual);

  // return a client object with all required fields
  const getClientObject = (
    name: string,
    category: string,
    fiscal_year: string,
    mapping: string,
    customer_id: string
  ): Omit<ClientInput, "client_id"> => {
    return {
      links: [],
      mapping: mapping !== null ? mapping : AccountMappingType.UNK00N,
      customer_id,
      name,
      category,
      address: {
        city: "",
        country: Country.DEU,
        line_1: "",
        line_2: "",
        postal: "00000",
        telephone: "",
      },
      fiscal_year: fiscal_year ?? "Jan",
      customization: {
        dashboard_kpis: [],
        report_color: "#00ff00",
        plan_settings: {
          revenue_curve: ERevenueCurves.OF001,
          revenue_growth: 0,
          revenue_ratios: {
            jan: 10,
            feb: 5,
            mar: 10,
            apr: 5,
            may: 10,
            jun: 10,
            jul: 10,
            aug: 10,
            sep: 10,
            oct: 5,
            dec: 5,
            nov: 10,
          },
        },
      },
    };
  };

  // return all required susas for a given date (should be 5)
  const getRequiredData = async (
    datevClientId: string,
    selectedDate: string,
    clientInCRM: ClientTax
  ): Promise<IRequiredDataForSusaCall | undefined> => {
    // initiate return object
    const requiredData: IRequiredDataForSusaCall = {
      datevClientId,
      requiredSusas: [],
      requiredFiscalYears: [],
    };
    // if somehow there is no selected date in system, return empty array
    if (!selectedDate) return undefined;

    // check if the fiscal year exists for the selected date
    const fiscalYearsOkV1 = await checkIfFiscalYearExists(selectedDate, clientInCRM.fiscal_years);

    // if it does not, try to grab it from the datev api
    const fiscal_years = fiscalYearsOkV1
      ? clientInCRM.fiscal_years
      : await updateFiscalYears(datevClientId, clientInCRM);

    // check if the fiscal year exists for the selected date after the update
    const fiscalYearsOkV2 = await checkIfFiscalYearExists(selectedDate, fiscal_years);

    // if it does not, return an empty array
    if (!fiscalYearsOkV2) return undefined;

    // initiate moment object from selected date
    const syncMonth = moment(selectedDate.replace("-", "/"), "MM/YYYY").startOf("month");

    // save the filtered fiscal years to the return object
    requiredData.requiredFiscalYears = await getRequiredFiscalYears(fiscal_years, syncMonth);

    if (requiredData.requiredFiscalYears.length < 2) return undefined;

    // save the required susas to the return object
    requiredData.requiredSusas = await getRequiredSusas(
      requiredData.requiredFiscalYears,
      syncMonth
    );

    // return the required data object
    return requiredData;
  };

  // check if fiscal year for selected date exists
  const checkIfFiscalYearExists = async (
    selectedDate: string,
    fiscalYears: IDatevFiscalYearShort[]
  ): Promise<boolean> => {
    // initiate moment object for selected date
    const syncMonth = moment(selectedDate.replace("-", "/"), "MM/YYYY").endOf("month").endOf("day");

    // select last fiscal year
    const lastFicalYear = fiscalYears[fiscalYears.length - 1] as IDatevFiscalYearShort;

    // if there is none return false
    if (!lastFicalYear) return false;

    // extract the end date of the last fiscal year
    const fiscalYearEnd = moment(fiscalYears[fiscalYears.length - 1].end)
      .endOf("month")
      .endOf("day");

    // check if the last fiscal year end is same or before the selected date
    return syncMonth.isSameOrBefore(fiscalYearEnd);
  };

  // function to update fiscal years from Datev
  const updateFiscalYears = async (
    datevClientId: string,
    clientInCRM: ClientTax
  ): Promise<IDatevFiscalYearShort[]> => {
    // get fiscal years from datev
    let fiscalYears = await datev.connector?.loadFiscalYearsOfClient(datevClientId);

    // format them to short version
    fiscalYears =
      fiscalYears &&
      fiscalYears.map((fiscalYear: IDatevFiscalYearShort) => {
        const fiscal_year = {
          id: fiscalYear.id,
          begin_month: fiscalYear.begin_month,
          end: fiscalYear.end,
          begin: fiscalYear.begin,
        };
        return fiscal_year;
      });

    // update the fiscal years in the CRM client metadata
    setMeta({
      variables: {
        data: {
          account_id: clientInCRM.client_id,
          fiscal_years: fiscalYears as IDatevFiscalYearShort[],
        },
      },
    });

    // return the fiscal years
    return fiscalYears as IDatevFiscalYearShort[];
  };

  // const filterFiscalYears = (fiscalYears: IDatevFiscalYearShort[]): IDatevFiscalYearShort[] => {
  const getRequiredFiscalYears = async (
    fiscalYears: IDatevFiscalYearShort[],
    selectedDate: moment.Moment
  ): Promise<IDatevFiscalYearShort[]> => {
    // initiate date for last year
    const selectedDatePreviousYear = selectedDate.clone().subtract(1, "years");
    const selectedDatePreviousPreviousYear = selectedDate.clone().subtract(2, "years");

    // filter the fiscal years to only include the ones that are relevant for the selected date
    const requiredFiscalYears = fiscalYears.filter((fiscalYear) => {
      const fiscalYearEnd = moment(fiscalYear.end).endOf("month");
      const fiscalYearStart = moment(fiscalYear.begin).startOf("month");
      return (
        selectedDate.isBetween(fiscalYearStart, fiscalYearEnd) ||
        selectedDate.isSame(fiscalYearStart) ||
        selectedDate.isSame(fiscalYearEnd) ||
        selectedDatePreviousYear.isBetween(fiscalYearStart, fiscalYearEnd) ||
        selectedDatePreviousYear.isSame(fiscalYearStart) ||
        selectedDatePreviousYear.isSame(fiscalYearEnd) ||
        selectedDatePreviousPreviousYear.isBetween(fiscalYearStart, fiscalYearEnd) ||
        selectedDatePreviousPreviousYear.isSame(fiscalYearStart) ||
        selectedDatePreviousPreviousYear.isSame(fiscalYearEnd)
      );
    });

    // return the filtered fiscal years
    return requiredFiscalYears;
  };

  const getRequiredSusas = async (
    fiscalYears: IDatevFiscalYearShort[],
    syncMonth: moment.Moment
  ): Promise<string[]> => {
    // add the required susas dates to the array
    const requiredSusas = [
      syncMonth.format("MM/YYYY"),
      syncMonth.clone().subtract(1, "months").format("MM/YYYY"),
      syncMonth.clone().subtract(1, "years").format("MM/YYYY"),
      syncMonth.clone().subtract(1, "years").subtract(1, "months").format("MM/YYYY"),
    ];

    // add the last month of the last closed fiscal year
    requiredSusas.push(
      moment(fiscalYears[fiscalYears.length - 1].end)
        .subtract(1, "years")
        .format("MM/YYYY")
    );

    return requiredSusas;
  };

  return {
    getClientObject,
    getRequiredData,
  };
};
