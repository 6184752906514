import { FC, useState, useEffect } from "react";

import {
  Alert,
  AlertTypeEnums,
  CardCvc,
  CardExpiry,
  CardNumber,
  Input,
  InputTypes,
  SepaDebitIBAN,
  Select,
  SelectItem,
  SelectionTypes,
  ISelectValue,
  P,
  EInputTriState,
} from "@canei/app-components";
import { useTranslation } from "react-i18next";
import { StyledPaymentForm } from "./styled.payment-form";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { EBillingActionType, IBillingAction } from "../../_lib/store/reducers";
import { ILocalState } from "../../@types";
import { isEqual } from "lodash";

export interface IPaymentFormProps {
  form: IPaymentFormValues;
  valid: Record<keyof IPaymentFormValues, EInputTriState>;
  state?: IPaymentFormState;
}
export interface IPaymentFormState {
  type: AlertTypeEnums;
  message: string;
}
export interface IPaymentFormValues {
  line1: string;
  line2: string;
  email: string;
  postal_code: string;
  city: string;
  name: string;
  card_number: string;
  cvc: string;
  valid_until: number;
}

export const initialPaymentFormData: IPaymentFormProps = {
  form: {
    line1: "",
    line2: "",
    postal_code: "",
    city: "",
    email: "",
    name: "",
    card_number: "",
    cvc: "",
    valid_until: 0,
  },
  valid: {
    line1: EInputTriState.INITIAL,
    line2: EInputTriState.INITIAL,
    postal_code: EInputTriState.INITIAL,
    city: EInputTriState.INITIAL,
    email: EInputTriState.INITIAL,
    name: EInputTriState.INITIAL,
    card_number: EInputTriState.INITIAL,
    cvc: EInputTriState.INITIAL,
    valid_until: EInputTriState.INITIAL,
  },
};
export const PaymentForm: FC<IPaymentFormProps> = ({ form, valid, state }) => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { t } = useTranslation(["quick/common"]);
  const [method, setMethod] = useState("card");
  const dispatchBilling = useDispatch<Dispatch<IBillingAction>>();

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: "DE",
  };

  const handleSelectPaymentType = (e: ISelectValue<string>): void => {
    if (e.value === "") return;
    setMethod(e.value);
  };

  useEffect(() => {
    dispatchBilling({
      type: EBillingActionType.SET_PAYMENT_TYPE,
      payload: { paymentType: method },
    });
  }, [method, dispatchBilling]);

  return (
    <StyledPaymentForm.Wrapper>
      <StyledPaymentForm.Entry area="invoice">
        <StyledPaymentForm.SectionTitle>
          {t("quick/common:payment.invoice.caption")}
        </StyledPaymentForm.SectionTitle>
      </StyledPaymentForm.Entry>
      <StyledPaymentForm.Entry area="company">
        <Input
          name={"line1"}
          label={t("quick/common:payment.invoice.labels.company")}
          valid={valid.line1 !== EInputTriState.FALSE}
          value={""}
          invalidWarning={t("misc.required_field")}
          branded={true}
        />
      </StyledPaymentForm.Entry>

      <StyledPaymentForm.Entry area="street">
        <Input
          name={"line2"}
          label={t("quick/common:payment.invoice.labels.street")}
          valid={valid.line2 !== EInputTriState.FALSE}
          value={form.line2 || ""}
          invalidWarning={t("misc.required_field")}
          branded={true}
        />
      </StyledPaymentForm.Entry>

      <StyledPaymentForm.Entry area="zip">
        <Input
          name={"postal_code"}
          label={t("quick/common:payment.invoice.labels.zip")}
          valid={valid.postal_code !== EInputTriState.FALSE}
          value={form.postal_code || ""}
          invalidWarning={t("misc.required_field")}
          branded={true}
        />
      </StyledPaymentForm.Entry>
      <StyledPaymentForm.Entry area="city">
        <Input
          name={"city"}
          label={t("quick/common:payment.invoice.labels.city")}
          valid={valid.city !== EInputTriState.FALSE}
          value={form.city || ""}
          invalidWarning={t("misc.required_field")}
          branded={true}
        />
      </StyledPaymentForm.Entry>
      <StyledPaymentForm.Entry area="email">
        <Input
          name={"email"}
          type={InputTypes.EMAIL}
          label={"E-mail"}
          valid={valid.email !== EInputTriState.FALSE}
          value={currentUser.appUser.email || ""}
          invalidWarning={t("misc.required_field")}
          branded={true}
        />
      </StyledPaymentForm.Entry>
      <StyledPaymentForm.Entry area="payment">
        <StyledPaymentForm.SectionTitle>
          {t("quick/common:payment.headline")}
        </StyledPaymentForm.SectionTitle>
      </StyledPaymentForm.Entry>

      <StyledPaymentForm.Entry area="name">
        <Input
          name={"name"}
          label={
            method !== "sepa_debit"
              ? t("quick/common:payment.card.labels.owners_name")
              : t("quick/common:payment.sepa_debit.owners_name")
          }
          valid={valid.name !== EInputTriState.FALSE}
          value={form.name || ""}
          invalidWarning={t("misc.required_field")}
          branded={true}
        />
      </StyledPaymentForm.Entry>
      <StyledPaymentForm.Entry area="payment_option">
        <Select
          name="format"
          text={method === "sepa_debit" ? "SEPA-Lastschrift" : "Kreditkarte"}
          label={t("quick/common:payment.card.labels.method")}
          type={SelectionTypes.LIST}
          value={method}
          valid
          invalidWarning={t("misc.required_field")}
          branded={true}
          onChange={handleSelectPaymentType}
        >
          <SelectItem
            value="card"
            text={t("quick/common:payment.payment_options.card")}
            selected={method === "sepa_debit" ? false : true}
          />
          <SelectItem
            value="sepa_debit"
            text={t("quick/common:payment.payment_options.sepa_debit")}
            selected={method === "sepa_debit" ? true : false}
          />
        </Select>
      </StyledPaymentForm.Entry>
      {method === "sepa_debit" && (
        <>
          <StyledPaymentForm.Entry area="card_number">
            <SepaDebitIBAN
              label={t("quick/common:payment.sepa_debit.label")}
              branded
              valid={false}
              options={IBAN_ELEMENT_OPTIONS}
              invalidWarning={t("misc.required_field")}
            />
          </StyledPaymentForm.Entry>
          <StyledPaymentForm.Entry area="iban_text" maxWidth="fit-content">
            <StyledPaymentForm.IbanText>
              <P>{t("quick/common:payment.sepa_debit.explanation_text")}</P>
            </StyledPaymentForm.IbanText>
          </StyledPaymentForm.Entry>
        </>
      )}
      {method === "card" && (
        <>
          <StyledPaymentForm.Entry area="card_number">
            <CardNumber
              label={t("quick/common:payment.card.labels.card_number")}
              branded
              valid={false}
              invalidWarning={t("misc.required_field")}
            />
          </StyledPaymentForm.Entry>
          <StyledPaymentForm.Entry area="cvc">
            <CardCvc
              name="cvc"
              label={t("quick/common:payment.card.labels.cvc")}
              options={{ placeholder: "" }}
              branded
              valid={false}
              invalidWarning={t("misc.required_field")}
            />
          </StyledPaymentForm.Entry>
          <StyledPaymentForm.Entry area="valid_until">
            <CardExpiry
              label={t("quick/common:payment.card.labels.valid_until")}
              valid={false}
              invalidWarning={t("misc.required_field")}
              branded
            />
          </StyledPaymentForm.Entry>
        </>
      )}
      <StyledPaymentForm.Entry area="error">
        <StyledPaymentForm.StateText>
          {state && <Alert type={state.type}>{state.message}</Alert>}
        </StyledPaymentForm.StateText>
      </StyledPaymentForm.Entry>
    </StyledPaymentForm.Wrapper>
  );
};
