import styled, { css } from "styled-components/macro";

const Wrapper = styled.section(({ theme }) => {
  return css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.rem(2)};
    > * {
      flex: 1;
    }
  `;
});
export const StyledSelectSoftware = { Wrapper };
