import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { FC } from "react";
import { StyledContactDialog } from "./styled.ContactDialog";
import { Trans, useTranslation } from "react-i18next";

const ContactDialog: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const renderedItems = [
    {
      name: IconNameEnums.Q_CALL,
      title: "tax/common:main_content.contact.items.first_icon.title",
      text: "tax/common:main_content.contact.items.first_icon.text",
    },
    {
      name: IconNameEnums.Q_MAIL,
      title: "tax/common:main_content.contact.items.second_icon.title",
      text: "tax/common:main_content.contact.items.second_icon.text",
    },
    {
      name: IconNameEnums.Q_CALENDAR,
      title: "tax/common:main_content.contact.items.third_icon.title",
      text: "tax/common:main_content.contact.items.third_icon.text",
    },
  ];
  return (
    <StyledContactDialog.Wrapper>
      <StyledContactDialog.TitleWrapper>
        {t("tax/common:main_content.contact.headline")}
      </StyledContactDialog.TitleWrapper>

      <StyledContactDialog.Content>
        <StyledContactDialog.SuperAllItemsWrapper>
          {renderedItems.map(({ name, title, text }, i) => {
            return (
              <StyledContactDialog.SuperWrapper key={i}>
                <StyledContactDialog.IconWrapper>
                  <Icon name={name} size={IconSizeEnums.LARGE} />
                </StyledContactDialog.IconWrapper>
                <StyledContactDialog.ItemTitleWrapper>
                  {t(`${title}`)}
                </StyledContactDialog.ItemTitleWrapper>
                <StyledContactDialog.ItemTextWrapper>
                  <Trans i18nKey={`${text}`} />
                </StyledContactDialog.ItemTextWrapper>
              </StyledContactDialog.SuperWrapper>
            );
          })}
        </StyledContactDialog.SuperAllItemsWrapper>
      </StyledContactDialog.Content>
    </StyledContactDialog.Wrapper>
  );
};

export default ContactDialog;
