import initialData from "../initialState";
import {
  DataSyncStateActionTypes,
  EDataSyncRefreshRate,
  IDataSync,
  IDataSyncAction,
  IDataSyncActionPayload,
} from "../../../@types/index.d";

const dataSync = (state: IDataSync = initialData.dataSync, action: IDataSyncAction): IDataSync => {
  const setIdleIfTimeOuts = (): EDataSyncRefreshRate | undefined => {
    const now = Date.now();
    const tmpState: IDataSyncActionPayload = {
      ...state[action.payload.level][action.payload.scope],
      ...action.payload,
    };
    return tmpState.retryUntil &&
      tmpState.refreshRate !== EDataSyncRefreshRate.SLOW &&
      now > tmpState.retryUntil
      ? EDataSyncRefreshRate.IDLE
      : tmpState.refreshRate;
  };

  switch (action.type) {
    case DataSyncStateActionTypes.SET_TIMESTAMP:
      return {
        ...state,
        [action.payload.level]: {
          ...state[action.payload.level],
          [action.payload.scope]: {
            ...state[action.payload.level][action.payload.scope],
            refreshRate: setIdleIfTimeOuts(),
            timeStamp: action.payload.state?.timeStamp,
          },
        },
      };
    case DataSyncStateActionTypes.SET_PENDING:
      return {
        ...state,
        [action.payload.level]: {
          ...state[action.payload.level],
          [action.payload.scope]: {
            ...state[action.payload.level][action.payload.scope],
            ...action.payload.state,
            refreshRate: setIdleIfTimeOuts(),
            pending: true,
          },
        },
      };
    case DataSyncStateActionTypes.SET_READY:
      return {
        ...state,
        [action.payload.level]: {
          ...state[action.payload.level],
          [action.payload.scope]: {
            ...state[action.payload.level][action.payload.scope],
            ...action.payload.state,
            refreshRate: setIdleIfTimeOuts(),
            pending: false,
          },
        },
      };
    case DataSyncStateActionTypes.SET_TIMEOUT:
      return {
        ...state,
        [action.payload.level]: {
          ...state[action.payload.level],
          [action.payload.scope]: {
            ...state[action.payload.level][action.payload.scope],
            ...action.payload.state,
            refreshRate: setIdleIfTimeOuts(),
            timeout: action.payload.state?.timeout || false,
          },
        },
      };
    case DataSyncStateActionTypes.SET_REFRESH_RATE:
      return {
        ...state,
        [action.payload.level]: {
          ...state[action.payload.level],
          [action.payload.scope]: {
            ...state[action.payload.level][action.payload.scope],
            ...action.payload.state,
            retryUntil: action.payload.retryUntil,
            refreshRate: action.payload.refreshRate || EDataSyncRefreshRate.SLOW,
          },
        },
      };
    case DataSyncStateActionTypes.RESET:
      return {
        ...state,
        [action.payload.level]: initialData.dataSync[action.payload.level],
      };
    default:
      return state;
  }
};

export default dataSync;
