import styled, { css } from "styled-components/macro";

export const StyledSimplePage = styled.main(({ theme }) => {
  return css`
    display: grid;
    grid-template-rows: 1fr min-content;
    grid-row-gap: ${({ theme }): string => theme.rem(1)};
    width: 100vw;
    height: 100vh;
  `;
});

export const StyledSimpleContent = styled.section(({ theme }) => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
});
export const StyledSimpleFooter = styled.footer(({ theme }) => {
  return css`
    background-color: ${theme.values.colors.global.secondary._085};
    color: ${theme.values.colors.global.secondary._025};
    position: relative;
    text-align: center;
    padding: ${theme.rem(1)};
  `;
});
