import { v4 as uuidv4 } from "uuid";
import { FC, useCallback, useContext, useMemo, useState } from "react";

import {
  ButtonBranded,
  ButtonBrandedVariantEnums,
  ButtonVariantEnums,
  CheckBox,
  ECheckboxState,
  LoaderTypeEnums,
  LoadingIndicator,
} from "@canei/app-components";
import { StyledWizardForm } from "../../../../../StyledComponents/styled.wizard-form";
import { EQRegisterActionType, EProgressStep, RegisterContext, RegisterDispatch } from "../";
import { Trans, useTranslation } from "react-i18next";

import { registrationForm } from "./registrationForm";

import { EPublicPaths } from "../../../../../@types/index.d";
import awsConfig from "../../../../../awsConfig";
import config from "../../../../../config";

export const Confirming: FC = () => {
  const tRegisterContext = useContext(RegisterContext);
  const qRegisterDispatch = useContext(RegisterDispatch);
  const [formPosting, setFormPosting] = useState(false);
  const registrationFormCallback = useCallback(registrationForm, []);
  const { t } = useTranslation(["tax/common"]);
  const canRegister = useMemo(() => {
    return tRegisterContext.terms_accepted && tRegisterContext.privacy_accepted;
  }, [tRegisterContext.terms_accepted, tRegisterContext.privacy_accepted]);
  const handleStepBack = (): void => {
    qRegisterDispatch({
      type: EQRegisterActionType.UPDATE_DATA,
      payload: {
        progress: {
          ...tRegisterContext.progress,
          currentStep: EProgressStep.CUSTOMER_DATA,
        },
      },
    });
  };
  const handleConfirmCheck = (
    chkName: "terms_accepted" | "privacy_accepted" | "data_processing_accepted"
  ) => {
    return (e: ECheckboxState): void => {
      const isChecked = e === ECheckboxState.ON;
      if (tRegisterContext[chkName] === isChecked) return;
      qRegisterDispatch({
        type: EQRegisterActionType.UPDATE_DATA,
        payload: { [chkName]: isChecked },
      });
    };
  };
  const handleRegister = (): void => {
    setFormPosting(true);
    const nextRedirect =
      process.env.NODE_ENV === "development"
        ? `${window.location.protocol}//${window.location.host}`
        : awsConfig.registration.NEXT_PAGE;

    const toReferrer = awsConfig.registration.TO_REFERRER;

    registrationFormCallback({
      post: awsConfig.registration.FORM_POST,
      data: {
        _company: tRegisterContext.company_name,
        _firstname: tRegisterContext.first_name,
        _lastname: tRegisterContext.last_name,
        _street: tRegisterContext.street,
        _postalcode: tRegisterContext.postal_code,
        _city: tRegisterContext.city,
        _email: tRegisterContext.email,
        _telephone: tRegisterContext.phone,
        _coupon: tRegisterContext.coupon || "",
      },
      settings: {
        _product: config.product_id,
        _pricing: config.pricing_id,
        _next:
          document.referrer === "" ? `${nextRedirect}${EPublicPaths.REGISTER_SUCCESS}` : toReferrer,
        _type: "signup",
        _language: "DE",
        _covid: `${uuidv4()}:0:${config.product_id}-app`,
        _utm_campaign: tRegisterContext.utm_campaign || "",
        _utm_source: tRegisterContext.utm_source || "",
        _utm_medium: tRegisterContext.utm_medium || "",
        _utm_content: tRegisterContext.utm_content || "",
        _utm_term: tRegisterContext.utm_term || "",
        _ad_group_id: tRegisterContext.ad_group_id || "",
        _gcl_id: tRegisterContext.gcl_id || "",
        _lpage: tRegisterContext.l_page || "",
      },
    }).submit();
  };
  return (
    <>
      <StyledWizardForm.FormWrapper columnCount={1}>
        <StyledWizardForm.Entry>
          <CheckBox
            inverted={false}
            variant={ButtonVariantEnums.DEFAULT}
            onChange={handleConfirmCheck("data_processing_accepted")}
            checked={
              tRegisterContext.data_processing_accepted ? ECheckboxState.ON : ECheckboxState.OFF
            }
          >
            <Trans i18nKey="tax/common:wizards.registration.confirming.accept_data_processing"></Trans>
          </CheckBox>
        </StyledWizardForm.Entry>
        <StyledWizardForm.Entry>
          <CheckBox
            inverted={false}
            variant={ButtonVariantEnums.DEFAULT}
            onChange={handleConfirmCheck("terms_accepted")}
            checked={tRegisterContext.terms_accepted ? ECheckboxState.ON : ECheckboxState.OFF}
          >
            <Trans i18nKey="tax/common:wizards.registration.confirming.accept_terms">
              <a href={config.shared_links.terms_url} target="_blank" rel="noreferrer">
                accept terms
              </a>
            </Trans>
          </CheckBox>
        </StyledWizardForm.Entry>
        <StyledWizardForm.Entry>
          <CheckBox
            inverted={false}
            variant={ButtonVariantEnums.DEFAULT}
            onChange={handleConfirmCheck("privacy_accepted")}
            checked={tRegisterContext.privacy_accepted ? ECheckboxState.ON : ECheckboxState.OFF}
          >
            <Trans i18nKey="tax/common:wizards.registration.confirming.accept_privacy">
              <a href={config.shared_links.privacy_url} target="_blank" rel="noreferrer">
                accept privacy
              </a>
            </Trans>
          </CheckBox>
        </StyledWizardForm.Entry>
      </StyledWizardForm.FormWrapper>
      <StyledWizardForm.Footer>
        <ButtonBranded
          inverted={true}
          type={"button"}
          inline={true}
          variant={ButtonBrandedVariantEnums.DEFAULT}
          onClick={handleStepBack}
        >
          {t("tax/common:wizards.back")}
        </ButtonBranded>
        <ButtonBranded
          inverted={false}
          type={"button"}
          inline={true}
          variant={ButtonBrandedVariantEnums.PRIMARY}
          disabled={!canRegister}
          onClick={handleRegister}
        >
          {t("tax/common:wizards.registration.register")}
        </ButtonBranded>
      </StyledWizardForm.Footer>
      {formPosting && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}
    </>
  );
};
