import { ISubscription } from "@canei/app-components";
import { IBillingInfo } from "../../../@types";
import { IBillingDetailedAddress, ICustomerData } from "../../Layout/TopBar/Payment/payment.types";
import initialState from "../initialState";

export interface IBillingAction {
  type: EBillingActionType;
  payload: IBillingPayload;
}
export interface IBillingPayload {
  subscription?: ISubscription;
  paymentType?: string;
  customerData?: ICustomerData;
  billingAddress?: IBillingDetailedAddress;
}

export enum EBillingActionType {
  SET_SUBSCRIPTION_DATA = "SET_SUBSCRIPTION_DATA",
  SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE",
  SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA",
  SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS",
}

const billing = (state = initialState.billing, action: IBillingAction): IBillingInfo => {
  switch (action.type) {
    case EBillingActionType.SET_SUBSCRIPTION_DATA:
      if (action.payload.subscription === undefined) return state;
      return {
        ...state,
        subscription: { ...state.subscription, ...action.payload.subscription },
      };
    case EBillingActionType.SET_PAYMENT_TYPE:
      if (action.payload.paymentType === undefined) return state;
      return {
        ...state,
        paymentType: action.payload.paymentType,
      };
    case EBillingActionType.SET_CUSTOMER_DATA:
      if (action.payload.customerData === undefined) return state;
      return {
        ...state,
        customerData: {
          ...action.payload.customerData,
        },
      };
    case EBillingActionType.SET_BILLING_ADDRESS:
      if (action.payload.billingAddress === undefined) return state;
      return {
        ...state,
        billingAddress: action.payload.billingAddress,
      };
    default:
      return state;
  }
};

export default billing;
