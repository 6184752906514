import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  EPrivatePaths,
  EPublicPaths,
  ILocalState,
  UserStateActionTypes,
} from "../../../@types/index.d";
import { FC, Suspense } from "react";
import { LoaderTypeEnums, LoadingIndicator } from "@canei/app-components";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";

import { Reset, Forgot, ChangePassword, Login, ErrorPage } from "../../shared";
import { Register } from "./Register";
import { ICurrentUserAction } from "../../../_lib/store/reducers";
import { isEqual } from "lodash";

export const Public: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  history.listen(() => {
    currentUser.errors.length > 0 &&
      dispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          errors: [],
        },
      });
  });

  return (
    <Switch>
      <Route exact path={EPublicPaths.HOME}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <>{currentUser.forceChange ? <ChangePassword /> : <Login />}</>
        </Suspense>
      </Route>
      <Route exact path={EPrivatePaths.LOGOUT}>
        <Redirect to={EPublicPaths.LOGIN} />
      </Route>
      <Route exact path={EPublicPaths.LOGIN}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <>{currentUser.forceChange ? <ChangePassword /> : <Login />}</>
        </Suspense>
      </Route>
      <Route exact path={EPublicPaths.RESET}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <>{currentUser.authenticated ? <Redirect to={EPrivatePaths.DASHBOARD} /> : <Reset />}</>
        </Suspense>
      </Route>
      <Route exact path={EPublicPaths.FORGOT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Forgot />
        </Suspense>
      </Route>
      <Route exact path={[EPublicPaths.REGISTER, EPublicPaths.REGISTER_SUCCESS]}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Register />
        </Suspense>
      </Route>

      <Route exact path="*">
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <ErrorPage
            title="404 - NOT FOUND!"
            message="The page you are looking for not is not found!"
          />
        </Suspense>
      </Route>
    </Switch>
  );
};
