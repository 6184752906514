import { Dispatch } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { EFilteredAlarms, EFilterSelection, ILocalState } from "../../../@types/index.d";
import { EAppStatusActionType, IAppStatusAction } from "../../store/reducers/appStatus";
import { IClientAlert, IGetAlertsResults } from "./types.alerting";

interface IAlertingHelper {
  getAlertsCompleted: (data: IGetAlertsResults) => void;
  getDescriptionsList: (data: IClientAlert[]) => Record<string, EFilterSelection>;
}

export const useAlertingHelper = (): IAlertingHelper => {
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const filters = useSelector(({ appStatus: { filters } }: ILocalState) => filters, isEqual);

  const alertArr = [
    "AL01",
    "AL02",
    "AL03",
    "AL04",
    "AL05",
    "AL06",
    "AL07",
    "AL08",
    "AL09",
    "AL10",
    "AL11",
    "AL12",
    "AL13",
  ];

  // get filters in to redux and enrich alert objects
  const getAlertsCompleted = (data: IGetAlertsResults): void => {
    const desc = getDescriptionsList(data.results);
    let options = {
      [EFilteredAlarms.PREV_MONTH]: {
        isCritical: EFilterSelection.SHOW,
        isConsiderable: EFilterSelection.SHOW,
      },
      [EFilteredAlarms.AVERAGE]: {
        isCritical: EFilterSelection.SHOW,
        isConsiderable: EFilterSelection.SHOW,
      },
      [EFilteredAlarms.CUMULATIVE]: {
        isCritical: EFilterSelection.SHOW,
        isConsiderable: EFilterSelection.SHOW,
      },
      [EFilteredAlarms.CRITICAL_VALUE]: {
        isCritical: EFilterSelection.SHOW,
        isConsiderable: EFilterSelection.SHOW,
      },
      DESC: desc,
      CATEGORY: {},
    };

    if (filters?.options) {
      options = filters?.options;
      options.DESC = desc;
    }

    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_FILTERS,
      payload: {
        filters: {
          options: options,
        },
      },
    });
  };

  const getDescriptionsList = (data: IClientAlert[]): Record<string, EFilterSelection> => {
    if (data.length === 0) return {};
    const result: string[] = [];
    data.forEach(({ months }) => {
      if (months.length > 0) {
        months[0].compare.forEach(({ alert_id }) => {
          result.includes(alert_id) || result.push(alert_id);
        });
      } else {
        alertArr.forEach((alertItem) => {
          result.includes(alertItem) || result.push(alertItem);
        });
      }
    });
    return result.reduce((prev, cur) => ({ ...prev, [cur]: EFilterSelection.SHOW }), {});
  };

  return { getAlertsCompleted, getDescriptionsList };
};
