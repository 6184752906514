import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

const Wrapper = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      `,
    },
    LARGE: {
      style: css`
        width: ${theme.rem(24)};
      `,
    },
  });
});

const Item = styled.div(({ theme }) => {
  return css`
    flex-basis: 100%;
    text-align: center;
  `;
});

const Headline = styled.h1(({ theme }) => {
  return css`
    flex-basis: 100%;
    text-align: center;
    font-size: ${theme.rem(1.6)};
    font-weight: ${theme.values.fontWeight.bold};
    margin-bottom: ${theme.rem(2)};

    :first-line {
      font-weight: ${theme.values.fontWeight.normal};
    }
  `;
});

const EntryGroup = styled(Wrapper)(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(2)};
    flex-basis: 100%;
  `;
});

const Entry = styled(Item)(({ theme }) => {
  return css`
    border-bottom: 1px solid;
    padding: ${theme.rem(2 / 3)};
    font-size: ${theme.rem(1.2)};
    flex-basis: 100%;
    :last-child {
      border-bottom: none;
    }
  `;
});

const Price = styled(Item)(({ theme }) => {
  return css`
    font-weight: ${theme.values.fontWeight.normal};
    color: ${theme.values.colors.global.secondary._040};
    line-height: ${theme.rem(1.2)};
    margin-bottom: ${theme.rem(2)};
    :first-line {
      color: ${theme.values.colors.global.greys._000};
      font-size: ${theme.rem(1.8)};
      line-height: ${theme.rem(2)};
      font-weight: ${theme.values.fontWeight.bold};
    }
  `;
});
const TaxNote = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(0.85)};
  `;
});

const Info = styled(Item)(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.secondary._040};
    margin-bottom: ${theme.rem(2)};
  `;
});

export const StyledRegisterCTA = {
  Wrapper,
  Headline,
  Item,
  EntryGroup,
  Entry,
  Price,
  TaxNote,
  Info,
};
