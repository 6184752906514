import { FC } from "react";
import { IOverviewSectionProps } from "..";
import { StyledTile } from "../../../../../StyledComponents";

export const Upgrade: FC<IOverviewSectionProps<HTMLDivElement>> = ({ forwardRef }) => {
  return (
    <StyledTile.Wrapper ref={forwardRef} gridArea="upgrade">
      <StyledTile.ContentWrapper>
        <StyledTile.Head> </StyledTile.Head>
      </StyledTile.ContentWrapper>
    </StyledTile.Wrapper>
  );
};
