import { FC, useMemo } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { StyledFilterSelector } from "./styled.filter-selector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EFilterGroup, EFilterSelection, ILocalState } from "../../../../../../@types/index.d";
import {
  EAppStatusActionType,
  IAppStatusAction,
} from "../../../../../../_lib/store/reducers/appStatus";
import { isEqual } from "lodash";

export interface IFilterByGroupNameProps {
  name: EFilterGroup;
}

export interface IFilterByGroupItem {
  selected: boolean;
  text: string;
  type: string;
}
const { Wrapper, List, ListItem, Selection, Text } = StyledFilterSelector;
export const FilterByGroupName: FC<IFilterByGroupNameProps> = ({ name }) => {
  const { t } = useTranslation("tax/common");
  const filters = useSelector(({ appStatus: { filters } }: ILocalState) => filters, isEqual);
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();

  const items = useMemo<IFilterByGroupItem[]>(() => {
    if (filters?.options?.DESC === undefined) return [];
    const alerts = Object.entries(filters?.options?.DESC) || [];
    return alerts.map(([code, selected]) => ({
      type: code,
      selected: selected === EFilterSelection.SHOW,
      text: t(`tax/common:main_content.total_analyse.alerts.${code}`),
    }));
  }, [filters?.options?.DESC, t]);

  const handleToggleSelection = (type: string): void => {
    dispatchAppStatus({
      type: EAppStatusActionType.TOGGLE_APP_GROUP,
      payload: {
        toggle_group_id: [name, type],
      },
    });
  };

  return (
    <Wrapper>
      <List>
        {items.map(({ selected, text, type }, index) => {
          return (
            <ListItem
              key={index}
              onClick={(): void => {
                handleToggleSelection(type);
              }}
            >
              <Selection selected={selected}>
                <Icon name={IconNameEnums.Q_CHECKMARK_ON} size={IconSizeEnums.SMALL} />
              </Selection>
              <Text>{text}</Text>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};
