import styled, { css } from "styled-components/macro";
interface IQInvoicesWrapperProps {
  margin_bottom?: boolean;
}
const Wrapper = styled.div<IQInvoicesWrapperProps>(({ theme, margin_bottom }) => {
  return css`
    width: max(80vh, ${theme.rem(24)});
    margin-bottom: ${margin_bottom ? theme.rem(1) : 0};
    padding: 0 ${theme.rem(1)} ${theme.rem(1)};
    display: flex;
    flex-direction: column;
    & button {
      align-self: flex-end;
    }
    & > h2 {
      text-align: center;
      margin: 0 0 15px 0;
    }
    & > h3 {
      margin: 15px 0 0;
    }
  `;
});

const SelectionWrapper = styled.div<IQInvoicesWrapperProps>(({ theme, margin_bottom }) => {
  return css`
    width: calc(max(80vh, ${theme.rem(24)}) - 30px);
    margin-bottom: ${theme.rem(2)};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 5px;

    & > div,
    & > h3 {
      width: 46%;
    }
    & > h3 {
      text-align: center;
    }
    & > span {
      align-self: center;
    }
  `;
});

const ClientListWrapper = styled.div(({ theme }) => {
  return css`
    box-shadow: 1px 1px 3px #d5d5d5, -1px -1px 3px #d5d5d5;
    border-radius: 10px;

    overflow-y: hidden;
  `;
});

const ClientList = styled.div(({ theme }) => {
  return css`
    max-height: 50vh;
    overflow-y: auto;

    h3 {
      padding: 0 ${theme.rem(1)};
    }
  `;
});

const ClientWrapper = styled.div(({ theme }) => {
  return css`
    width: 100%;
    padding: ${theme.rem(0.5)} ${theme.rem(1)};
    cursor: pointer;
  `;
});

const Footer = styled.div(({ theme }) => {
  return css`
    height: ${theme.rem(2)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.rem(2)};
  `;
});

const TableOverFlow = styled.div(({ theme }) => {
  return css`
    max-height: ${theme.rem(15)};
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: ${theme.rem(-1)};
  `;
});

const CellContent = styled.span(({ theme }) => {
  return css`
    margin-right: ${theme.rem(1)};
  `;
});

const EmailInputWrapper = styled.div(({ theme }) => {
  return css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: ${theme.rem(1)};
    & button {
      position: absolute;
      top: ${theme.rem(0)};
    }
    & input {
      position: absolute;
      right: ${theme.rem(1.46)};
      top: ${theme.rem(-0.15)};
      background-color: none;
      text-align: right;
      border: none;
      border-bottom: ${theme.values.colors.global.greys._025} 1px solid;
      font-size: ${theme.rem(1)};
      color: ${theme.values.colors.global.greys._100};
    }
  `;
});

const ClientsTitleWrapper = styled.div(({ theme }) => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 ${theme.rem(1)};
  `;
});

const ClientsTitles = styled.div(({ theme }) => {
  return css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
});

const SelectAll = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    gap: ${theme.rem(0.5)};
  `;
});

export const StyledCustomerUser = {
  Wrapper,
  CellContent,
  TableOverFlow,
  EmailInputWrapper,
  SelectionWrapper,
  ClientListWrapper,
  ClientList,
  ClientWrapper,
  Footer,
  ClientsTitleWrapper,
  SelectAll,
  ClientsTitles,
};
