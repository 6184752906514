import styled, { css } from "styled-components/macro";

interface IListRow {
  isTitle?: boolean;
}
const ListWrapper = styled.div(({ theme }) => {
  return css`
    display: block;
    width: min(${theme.rem(32)}, 100%);
    margin-bottom: ${theme.rem(2)};
  `;
});

const ListRow = styled.div<IListRow>(({ theme, isTitle = false }) => {
  return css`
    display: flex;
    flex-wrap: nowrap;
    font-weight: ${isTitle ? theme.values.fontWeight.bold : theme.values.fontWeight.normal};
    padding: ${theme.rem(1 / 3)} 0;
  `;
});

const ClientName = styled.div(() => {
  return css`
    flex-grow: 1;
  `;
});

const ClientId = styled.div(() => {
  return css`
    flex-basis: 40%;
  `;
});

const TimeSpan = styled.div(({ theme }) => {
  return css`
    flex-basis: 50%;
    display: flex;
    grid-gap: ${theme.rem(1)};
    > div {
      flex: 1;
      text-align: center;
    }
  `;
});

export const StyledSetupBB = { ListWrapper, ListRow, ClientName, ClientId, TimeSpan };
