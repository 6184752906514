import { FC, useEffect, useMemo, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import {
  AlignmentEnums,
  Form,
  FormRow,
  IDialogKeyEnums,
  useDialogContext,
  useDialogs,
  Button,
  ButtonVariantEnums,
  Input,
  EInputTriState,
  SelectionTypes,
  SelectItem,
  Select,
  CardNumber,
  CardCvc,
  CardExpiry,
  Alert,
  ISelectValue,
  InputTypes,
  SepaDebitIBAN,
  P,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  LoadingIndicator,
  LoaderTypeEnums,
  AlertTypeEnums,
  Country,
  EventErrorsCodes,
} from "@canei/app-components";
import { IPaymentDialogContext } from "./payment.types";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState, EDataSyncLevel, EDataSyncScope } from "../../../../@types/index.d";
import {
  EPaymentMethods,
  useBillingInfo,
  useMakePayment,
  useTransactionEventListener,
} from "../../../hooks";
import { initialPaymentFormData } from "../../../../components/PaymentForm";
import { StyledPaymentPage } from "../../../../views/hosted/Private/Payment/styled.payment-page";
import { StyledPaymentForm } from "../../../../components/PaymentForm/styled.payment-form";
import { EBillingActionType, IBillingAction } from "../../../store/reducers";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_PRICING } from "../../../hooks/billing/payment-queries";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";
import { isEqual } from "lodash";

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  placeholderCountry: "DE",
};
const Content: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const customerData = useSelector(
    ({ billing: { customerData } }: ILocalState) => customerData,
    isEqual
  );
  const paymentType = useSelector(
    ({ billing: { paymentType } }: ILocalState) => paymentType,
    isEqual
  );
  const billingAddress = useSelector(
    ({ billing: { billingAddress } }: ILocalState) => billingAddress,
    isEqual
  );
  const dialogs = useDialogs();
  const dispatchBilling = useDispatch<Dispatch<IBillingAction>>();
  const [method, setMethod] = useState("card");
  const [companyName, setCompanyName] = useState<string>("");
  const [covId, setCovId] = useState<string | undefined>(undefined);
  const { isEmail } = validator;
  const [transactionEvent, errorState, transaction] = useTransactionEventListener();
  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);

  const {
    updateBillingAddress,
    getCustomerStatus,
    customerStatusLoading,
    updateBillingAddressLoading,
    updateBillingAddressCalled,
  } = useBillingInfo();
  const dialogContext = useDialogContext<IPaymentDialogContext | undefined>(
    IDialogKeyEnums.QUICK_MANAGE_PAYMENTS,
    undefined
  );

  const {
    makePayment,
    updateBillingDetails,
    canBeSubmitted,
    billingDetails,
    paymentFormData,
    isReady,
    isLoading,
    callUpdateBillingFn,
    sendUpdateBilling,
  } = useMakePayment();
  const { form, valid, state } = paymentFormData;

  const { data, loading } = useQuery(GET_CUSTOMER_PRICING, {
    variables: {
      pricing_id: customerData?.pricing_id,
    },
  });
  const handleCloseDialog = (): void => {
    dialogs.close(IDialogKeyEnums.QUICK_MANAGE_PAYMENTS);
  };

  const handleSubmit = (): void => {
    setCovId(uuidv4());

    dialogContext &&
      dialogs.setData<IPaymentDialogContext>(IDialogKeyEnums.QUICK_MANAGE_PAYMENTS, {
        ...dialogContext,
        shouldSubmit: true,
      });

    const params = {
      city: billingDetails.address.city,
      email: billingDetails.email,
      line_1: billingDetails.address.line1,
      line_2: billingDetails.address.line2,
      name: companyName,
      postal: billingDetails.address.postal_code,
      telephone: null,
    };
    // to save the data and use for updateBillingAddress fn (only for the cards that require 3DS2 Authentication)
    localStorage.setItem("updateBillingParams", JSON.stringify(params));
  };

  const handleSelectPaymentType = (e: ISelectValue<string>): void => {
    if (e.value === "") return;
    setMethod(e.value);
  };

  const billingStatus = useMemo<boolean>(() => {
    if (!customerData) return false;
    if (customerData.status === "canceled" || customerData.status === "payment_required")
      return true;
    else return false;
  }, [customerData]);

  // to pre-fill input field with the data in redux
  const expiryDate = useMemo(() => {
    if (!customerData || !customerData.payment_method) return;
    const { payment_method } = customerData;
    if (payment_method?.exp_month && payment_method?.exp_year) {
      return `${payment_method?.exp_month} / ${payment_method?.exp_year?.toString().slice(-2)}`;
    }
  }, [customerData]);

  // to validate the email
  const [email, setEmail] = useState<string>(
    billingAddress?.email || currentUser?.appUser?.email || ""
  );
  // to pre-fill input field with the data in redux
  const lastFour = useMemo(() => {
    if (!customerData || !customerData.payment_method) return;
    return customerData.payment_method.last_four;
  }, [customerData]);

  // only to initially fill companyState info from redux
  useEffect(() => {
    if (!billingAddress) return;
    if (billingAddress.name && companyName === "") setCompanyName(billingAddress.name);
  }, [companyName, billingAddress]);

  // to send patch request to billing, to update billing address
  useEffect(() => {
    if (!billingDetails) return;
    if (!billingDetails.address) return;
    if (!currentUser.appUser.customer_id) return;

    if (
      state?.type === AlertTypeEnums.SUCCESS &&
      billingDetails.address.line1 &&
      billingDetails.address.city &&
      billingDetails.address.postal_code &&
      billingDetails.name &&
      billingDetails.email &&
      companyName &&
      callUpdateBillingFn
    ) {
      updateBillingAddress({
        variables: {
          params: {
            line_1: billingDetails.address.line1,
            line_2: billingDetails.address.line2,
            city: billingDetails.address.city,
            postal: billingDetails.address.postal_code,
            country: Country.DEU,
            email: billingDetails.email,
            name: companyName,
          },
          customer_id: currentUser.appUser.customer_id,
        },
      });

      localStorage.removeItem("updateBillingParams");
      sendUpdateBilling(false);
    }
  }, [
    state,
    updateBillingAddress,
    currentUser.appUser.customer_id,
    companyName,
    billingDetails,
    callUpdateBillingFn,
    sendUpdateBilling,
  ]);

  // to send patch request to billing, to update billing address (only for the cards that require 3DS2 Authentication)
  useEffect(() => {
    if (!dialogContext) return;
    if (!billingAddress) return;
    if (!currentUser.appUser.customer_id) return;

    if (dialogContext.stateFor3DS2 === AlertTypeEnums.SUCCESS && !state) {
      const savedBillingAddressToRedux = billingAddress;
      const savedBillingAddressToLS = localStorage.getItem("updateBillingParams");

      if (
        savedBillingAddressToLS &&
        savedBillingAddressToRedux &&
        JSON.stringify(savedBillingAddressToRedux) !== JSON.stringify(savedBillingAddressToLS)
      ) {
        const params = {
          ...JSON.parse(savedBillingAddressToLS),
          country: Country.DEU,
        };

        // telephone is currently null, that is why I remove it here not to have an error
        delete params.telephone;

        params &&
          updateBillingAddress({
            variables: {
              params,
              customer_id: currentUser.appUser.customer_id,
            },
          });
        localStorage.removeItem("updateBillingParams");
      }
    }
  }, [dialogContext, currentUser.appUser.customer_id, updateBillingAddress, billingAddress, state]);

  // to send transaction
  useEffect(() => {
    if (dataUpdated) return;
    if (!covId) return;
    if (!currentUser.appUser.customer_id) return;

    if (
      state?.type === AlertTypeEnums.SUCCESS &&
      covId &&
      !dataUpdated &&
      updateBillingAddressCalled
    ) {
      setTransactionLoading(true);

      transactionEvent({
        transactionId: covId,
        accountId: currentUser.appUser.customer_id,
        level: EDataSyncLevel.CUSTOMER,
        scope: EDataSyncScope.WALLET,
      });

      setDataUpdated(true);
    }
  }, [
    state,
    currentUser.appUser.customer_id,
    covId,
    transactionEvent,
    transactionLoading,
    dataUpdated,
    updateBillingAddressCalled,
  ]);

  useEffect(() => {
    if (!transactionLoading) return;
    if (transaction?.transactionId && errorState) {
      const process = errorState[transaction.transactionId];
      if (process === EventErrorsCodes.TRANSACTION_OK || process === EventErrorsCodes.TIME_OUT) {
        setTransactionLoading(false);

        getCustomerStatus({
          variables: { customer_id: currentUser.appUser.customer_id },
        });
      }
    }
  }, [
    currentUser.appUser.customer_id,
    errorState,
    getCustomerStatus,
    transaction?.transactionId,
    transactionLoading,
    dialogs,
    updateBillingAddressCalled,
  ]);

  // to fill redux with the selected payment type
  useEffect(() => {
    if (!method) return;
    dispatchBilling({
      type: EBillingActionType.SET_PAYMENT_TYPE,
      payload: { paymentType: method },
    });
  }, [method, dispatchBilling]);

  useEffect(() => {
    if (dialogContext?.shouldSubmit) {
      dialogs.setData(IDialogKeyEnums.QUICK_MANAGE_PAYMENTS, {
        ...dialogContext,
        shouldSubmit: false,
      });
      makePayment({
        type: paymentType as EPaymentMethods,
        customer_id: currentUser.appUser.customer_id,
        covId,
      });
    }
  }, [currentUser.appUser.customer_id, dialogContext, dialogs, makePayment, paymentType, covId]);

  // to render loading spinner
  useEffect(() => {
    dialogs.busy(
      IDialogKeyEnums.QUICK_MANAGE_PAYMENTS,
      isLoading || customerStatusLoading || updateBillingAddressLoading || transactionLoading
    );
  }, [dialogs, isLoading, customerStatusLoading, updateBillingAddressLoading, transactionLoading]);

  if (dialogContext === undefined) return null;

  return (
    <StyledPaymentPage.Wrapper>
      {billingStatus && (
        <>
          <StyledPaymentPage.Title>
            {t("main_content.payment.end_of_trial.caption")}
          </StyledPaymentPage.Title>
          <StyledPaymentPage.Text>
            <Trans i18nKey="tax/common:main_content.payment.end_of_trial.message">
              <a rel="noreferrer" href="https://stripe.com/" target="_blank">
                stripe.com
              </a>

              <a
                rel="noreferrer"
                href="https://stripe.com/de/guides/general-data-protection-regulation"
                target="_blank"
              >
                privacy
              </a>
              <a rel="noreferrer" href="https://stripe.com/de/legal" target="_blank">
                terms
              </a>
            </Trans>
          </StyledPaymentPage.Text>
        </>
      )}

      <StyledPaymentPage.Section>
        <Form subscribe={updateBillingDetails} customStyle={{ display: "unset" }}>
          <StyledPaymentForm.Wrapper>
            <StyledPaymentForm.Entry area="invoice">
              <StyledPaymentForm.SectionTitle>
                {t("quick/common:payment.invoice.caption")}
              </StyledPaymentForm.SectionTitle>
            </StyledPaymentForm.Entry>
            <StyledPaymentForm.Entry area="company">
              <Input
                name={"company_name"}
                label={t("quick/common:payment.invoice.labels.company")}
                valid={valid.line1 !== EInputTriState.FALSE}
                value={billingAddress?.name || companyName || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
                onChange={(e): void => setCompanyName(e.target.value)}
              />
            </StyledPaymentForm.Entry>

            <StyledPaymentForm.Entry area="street">
              <Input
                name={"line1"}
                label={t("quick/common:payment.invoice.labels.street")}
                valid={valid.line1 !== EInputTriState.FALSE}
                value={billingAddress?.line_1 || form.line1 || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
              />
            </StyledPaymentForm.Entry>

            <StyledPaymentForm.Entry area="zip">
              <Input
                name={"postal_code"}
                label={t("quick/common:payment.invoice.labels.zip")}
                valid={valid.postal_code !== EInputTriState.FALSE}
                value={billingAddress?.postal || form.postal_code || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
              />
            </StyledPaymentForm.Entry>
            <StyledPaymentForm.Entry area="city">
              <Input
                name={"city"}
                label={t("quick/common:payment.invoice.labels.city")}
                valid={valid.city !== EInputTriState.FALSE}
                value={billingAddress?.city || form.city || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
              />
            </StyledPaymentForm.Entry>
            <StyledPaymentForm.Entry area="email">
              <Input
                name={"email"}
                type={InputTypes.EMAIL}
                label={"E-mail"}
                valid={isEmail(email)}
                value={billingAddress?.email || email}
                invalidWarning={t("misc.required_field")}
                branded={true}
                onChange={(e): void => setEmail(e.target.value)}
              />
            </StyledPaymentForm.Entry>
            <StyledPaymentForm.Entry area="payment">
              <StyledPaymentForm.SectionTitle>
                {t("quick/common:payment.headline")}
              </StyledPaymentForm.SectionTitle>
            </StyledPaymentForm.Entry>

            <StyledPaymentForm.Entry area="name">
              <Input
                name={"name"}
                label={
                  method !== "sepa_debit"
                    ? t("quick/common:payment.card.labels.owners_name")
                    : t("quick/common:payment.sepa_debit.owners_name")
                }
                valid={valid.name !== EInputTriState.FALSE}
                value={form.name || ""}
                invalidWarning={t("misc.required_field")}
                branded={true}
              />
            </StyledPaymentForm.Entry>
            <StyledPaymentForm.Entry area="payment_option">
              <Select
                name="format"
                text={method === "sepa_debit" ? "SEPA-Lastschrift" : "Kreditkarte"}
                label={t("quick/common:payment.card.labels.method")}
                type={SelectionTypes.LIST}
                value={method}
                valid
                invalidWarning={t("misc.required_field")}
                branded={true}
                onChange={handleSelectPaymentType}
              >
                <SelectItem
                  value="card"
                  text={t("quick/common:payment.payment_options.card")}
                  selected={method === "sepa_debit" ? false : true}
                />
                <SelectItem
                  value="sepa_debit"
                  text={t("quick/common:payment.payment_options.sepa_debit")}
                  selected={method === "sepa_debit" ? true : false}
                />
              </Select>
            </StyledPaymentForm.Entry>
            {method === "sepa_debit" && (
              <>
                <StyledPaymentForm.Entry area="card_number">
                  <SepaDebitIBAN
                    label={t("quick/common:payment.sepa_debit.label")}
                    branded
                    valid={false}
                    options={IBAN_ELEMENT_OPTIONS}
                    invalidWarning={t("misc.required_field")}
                  />
                </StyledPaymentForm.Entry>
                <StyledPaymentForm.Entry area="iban_text" maxWidth="fit-content">
                  <StyledPaymentForm.IbanText>
                    <P>{t("quick/common:payment.sepa_debit.explanation_text")}</P>
                  </StyledPaymentForm.IbanText>
                </StyledPaymentForm.Entry>
              </>
            )}
            {method === "card" && (
              <>
                <StyledPaymentForm.Entry area="card_number">
                  <CardNumber
                    label={t("quick/common:payment.card.labels.card_number")}
                    branded
                    valid={false}
                    invalidWarning={t("misc.required_field")}
                    options={{
                      placeholder: lastFour ? `**** **** **** ${lastFour}` : "1234 1234 1234 1234",
                    }}
                  />
                </StyledPaymentForm.Entry>
                <StyledPaymentForm.Entry area="cvc">
                  <CardCvc
                    name="cvc"
                    label={t("quick/common:payment.card.labels.cvc")}
                    options={{ placeholder: "" }}
                    branded
                    valid={false}
                    invalidWarning={t("misc.required_field")}
                  />
                </StyledPaymentForm.Entry>
                <StyledPaymentForm.Entry area="valid_until">
                  <CardExpiry
                    label={t("quick/common:payment.card.labels.valid_until")}
                    valid={false}
                    invalidWarning={t("misc.required_field")}
                    branded
                    options={{ placeholder: expiryDate ? expiryDate : "MM / JJ" }}
                  />
                </StyledPaymentForm.Entry>
              </>
            )}
            <StyledPaymentForm.Entry area="error">
              <StyledPaymentForm.StateText>
                {state && <Alert type={state.type}>{state.message}</Alert>}
                {!state && dialogContext?.stateFor3DS2 && (
                  <Alert type={AlertTypeEnums[dialogContext?.stateFor3DS2]}>
                    {t(`quick/common:payment.${dialogContext?.stateFor3DS2}`)}
                  </Alert>
                )}
              </StyledPaymentForm.StateText>
            </StyledPaymentForm.Entry>
          </StyledPaymentForm.Wrapper>
        </Form>
      </StyledPaymentPage.Section>

      <StyledPaymentPage.Footer>
        {!billingStatus ? (
          <>
            <FormRow align={AlignmentEnums.RIGHT}>
              <Button
                inverted={false}
                type={"button"}
                inline={true}
                variant={ButtonVariantEnums.SECONDARY}
                disabled={false}
                onClick={handleCloseDialog}
              >
                {t("misc.cancel")}
              </Button>
              <Button
                inverted={false}
                type={"button"}
                inline={true}
                variant={ButtonVariantEnums.PRIMARY}
                disabled={!isReady || !canBeSubmitted || !isEmail(email)}
                onClick={handleSubmit}
              >
                {t("misc.save")}
              </Button>
            </FormRow>
          </>
        ) : (
          <>
            <ButtonBranded
              onClick={handleSubmit}
              variant={ButtonBrandedVariantEnums.PRIMARY}
              inverted={false}
              type={"button"}
              inline
              disabled={!isReady || !isEmail(email) || !canBeSubmitted}
            >
              {loading ? (
                <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />
              ) : (
                t("main_content.payment.end_of_trial.submit", {
                  price: data?.getCustomerPricing.price.toFixed(2) || "",
                })
              )}
            </ButtonBranded>
          </>
        )}
      </StyledPaymentPage.Footer>
    </StyledPaymentPage.Wrapper>
  );
};

export type TUseManagePaymentsDialog = () => IUseManagePaymentsDialogRetVal;
export interface IUseManagePaymentsDialogRetVal {
  open: (stateFor3DS2?: AlertTypeEnums) => void;
}

export const useManagePaymentsDialog: TUseManagePaymentsDialog = () => {
  const customerData = useSelector(({ billing: { customerData } }: ILocalState) => customerData);
  const dialogs = useDialogs();
  const body = document.getElementsByTagName("body")[0];

  const renderPaymentWall = useMemo<boolean>(() => {
    if (!customerData) return false;
    if (customerData.status === "canceled" || customerData.status === "payment_required")
      return true;
    else return false;
  }, [customerData]);

  return {
    open: (stateFor3DS2?: AlertTypeEnums): void => {
      dialogs.open({
        dialogKey: IDialogKeyEnums.QUICK_MANAGE_PAYMENTS,
        data: {
          key: 0,
          shouldSubmit: false,
          paymentForm: initialPaymentFormData,
          stateFor3DS2,
        },
        title: renderPaymentWall ? "" : "Zahlung und Abrechnung",
        closable: renderPaymentWall ? false : true,
        content: <Content />,
        flexible: body.clientWidth >= 1024 ? false : true,
      });
    },
  };
};
