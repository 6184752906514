import { gql, QueryTuple, useLazyQuery } from "@apollo/client";
import { IGetAlertsResults, IGetAllAlertsVars } from "./types.alerting";
const GET_ALL_ALERTS = gql`
  query getAllAlerts($customer_id: ID!, $from: String, $to: String) {
    results: getAllAlerts(customer_id: $customer_id, from: $from, to: $to) {
      client_id
      months {
        date
        compare {
          alert_id
          prev_month
          prev_year_avg_month
          prev_year_cumulated
          critical
          details {
            alert_detail_id
            value
            value_cumulated
            prev_month_value
            prev_year_avg_month_value
            prev_year_cumulated_value
          }
        }
      }
    }
  }
`;

export const useGetAllAlerts = (): QueryTuple<IGetAlertsResults, IGetAllAlertsVars> => {
  return useLazyQuery<IGetAlertsResults, IGetAllAlertsVars>(GET_ALL_ALERTS);
};
