import { FC } from "react";

import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { useTranslation } from "react-i18next";
import { StyledSetup } from "../../../../StyledComponents";

export const SetupBBMenu: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  return (
    <StyledSetup.SetupMenu.List>
      <StyledSetup.SetupMenu.ListItem>
        <Icon name={IconNameEnums.Q_GRADE} size={IconSizeEnums.SMALL} />
        <span>{t("initial_setup.better_business.system_settings.caption")}</span>
      </StyledSetup.SetupMenu.ListItem>
      <StyledSetup.SetupMenu.ListItem>
        <Icon name={IconNameEnums.Q_GRADE} size={IconSizeEnums.SMALL} />
        <span>{t("initial_setup.better_business.client_settings.caption")}</span>
      </StyledSetup.SetupMenu.ListItem>
      <StyledSetup.SetupMenu.ListItem>
        <Icon name={IconNameEnums.Q_GRADE} size={IconSizeEnums.SMALL} />
        <span>{t("initial_setup.better_business.sync.caption")}</span>
      </StyledSetup.SetupMenu.ListItem>
    </StyledSetup.SetupMenu.List>
  );
};
