import styled, { css } from "styled-components/macro";
interface IQInvoicesWrapperProps {
  margin_bottom?: boolean;
}
const Wrapper = styled.div<IQInvoicesWrapperProps>(({ theme, margin_bottom }) => {
  return css`
    width: max(75vh, ${theme.rem(24)});
    margin-bottom: ${margin_bottom ? theme.rem(1) : 0};
  `;
});
export const StyledDownloadInvoices = {
  Wrapper,
};
