import { FC, useState } from "react";
import {
  Form,
  FormRow,
  IFormState,
  InputTypes,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Input,
  AlignmentEnums,
  P,
  FadeIn,
  AlertTypeEnums,
  ICardTypeEnums,
  ErrorBoundary,
  Alert,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
} from "@canei/app-components";

import { ILocalState } from "../../@types/index.d";

import { Link } from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";

import { LoginError } from "./LoginError";

import { SimpleLayout } from "../../_lib/Layout";
import { useSelector } from "react-redux";
import { useForgotPassword } from "../../_lib/hooks";
import { Logo } from "./Logo";
import { isEqual } from "lodash";

export const Forgot: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const forgotPassword = useForgotPassword();
  function handleForgot(form: IFormState): void {
    setLoading(true);
    forgotPassword(form.email).finally(() => {
      setLoading(false);
      setIsRequested(true);
    });
  }
  return (
    <SimpleLayout>
      <ErrorBoundary>
        <FadeIn>
          <Card type={ICardTypeEnums.CENTERED} loading={loading}>
            <CardTitle>
              <Logo />
            </CardTitle>
            <CardBody>
              <Form onSubmitHandler={handleForgot}>
                <P>
                  <Trans i18nKey={t("forgot.info_text")} />
                </P>
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Input
                    name="email"
                    label={t("forgot.email.label")}
                    type={InputTypes.EMAIL}
                    value={currentUser.email}
                    branded={true}
                    autoComplete={"on"}
                    required={true}
                  />
                </FormRow>
                {isRequested && currentUser.errors.length > 0 && <LoginError />}

                {isRequested && currentUser.errors.length === 0 && (
                  <FormRow align={AlignmentEnums.STRETCH}>
                    <Alert type={AlertTypeEnums.SUCCESS}>
                      <Trans i18nKey={`forgot.confirm_text`} values={{ email: currentUser.email }}>
                        Wir haben Ihren Bestätigungscode an Ihre E-Mail-Adresse gesendet....
                        <Link to="/reset">hier Ihren Bestätigungscode ein.</Link>
                      </Trans>
                    </Alert>
                  </FormRow>
                )}

                <FormRow align={AlignmentEnums.STRETCH}>
                  <ButtonBranded
                    inverted={false}
                    variant={ButtonBrandedVariantEnums.PRIMARY}
                    type="submit"
                  >
                    {t("forgot.submit")}
                  </ButtonBranded>
                </FormRow>
              </Form>
            </CardBody>
            <CardFooter>
              <div>
                <Link to="/">{t("misc.back")}</Link>
              </div>
            </CardFooter>
          </Card>
        </FadeIn>
      </ErrorBoundary>
    </SimpleLayout>
  );
};
