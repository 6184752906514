import { ActionCreatorWithPayload, createAction, createReducer } from "@reduxjs/toolkit";
import initialData from "../initialState";

export enum EDataStoreActionType {
  SET_TEMPLATES_DATA = "SET_TEMPLATES_DATA",
}

// initialize empty functions object ([EDataStoreActionType]: function())
const actionFunctions = {} as Record<
  EDataStoreActionType,
  ActionCreatorWithPayload<Partial<IDataStorePayload>, EDataStoreActionType>
>;

export interface IDataStoreAction {
  type: EDataStoreActionType;
  payload: IDataStorePayload;
}
export interface IDataStorePayload {
  accFrameTemplates: string[];
}

// add a function for each action type
for (const key of Object.keys(EDataStoreActionType) as Array<keyof typeof EDataStoreActionType>) {
  actionFunctions[EDataStoreActionType[key]] = createAction(EDataStoreActionType[key]);
}

const dataStore = createReducer(initialData.dataStore, (builder) => {
  builder.addCase(actionFunctions[EDataStoreActionType.SET_TEMPLATES_DATA], (state, action) => {
    if (!action.payload?.accFrameTemplates) return state;
    state.accFrameTemplates = action.payload.accFrameTemplates;
  });
});

export default dataStore;
