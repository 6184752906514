import { FC } from "react";
import { StyledSideBar } from "./styled.side-bar";
import { Menu } from "./Menu";
import { Contact } from "./Contact";

export const SideBar: FC = ({ children }) => (
  <StyledSideBar.Wrapper>
    <Menu />
    <Contact />
  </StyledSideBar.Wrapper>
);
