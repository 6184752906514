import { FC, useEffect } from "react";

import { ILocalState, ThemeStateActionTypes } from "../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { IThemeOptionsAction } from "../store/reducers";
import { Dispatch } from "@reduxjs/toolkit";

import {
  SystemMessages,
  LoaderTypeEnums,
  LoadingIndicator,
  SelectClientState,
} from "@canei/app-components";
import { StyledCommonLayout } from "./styled.common";
import { useSystemMessages } from "../hooks/useSystemMessages";
import { LayoutEnums } from "./types.layout";
import { isEqual } from "lodash";

export const CommonLayout: FC = ({ children }) => {
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const systemMessages = useSelector(({ systemMessages }: ILocalState) => systemMessages, isEqual);
  const { removeSystemMessage } = useSystemMessages();
  const dispatch = useDispatch<Dispatch<IThemeOptionsAction>>();

  useEffect(() => {
    if (themeOptions.layout !== LayoutEnums.COMMON) {
      dispatch({
        type: ThemeStateActionTypes.SET_LAYOUT,
        payload: { layout: LayoutEnums.COMMON },
      });
    }
  }, [dispatch, themeOptions.layout]);

  return (
    <StyledCommonLayout>
      {children}
      <SystemMessages messages={systemMessages} removeMessageCallback={removeSystemMessage} />
      {currentUser.selectedClient.state === SelectClientState.PENDING && (
        <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />
      )}
    </StyledCommonLayout>
  );
};
