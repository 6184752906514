import styled, { css } from "styled-components/macro";
import { useMemo } from "react";
import { EClientSelection } from "../../../../_lib/hooks/useDatev";
export interface IClientUploadState {
  state: EClientSelection;
}
const ClientListWrapper = styled.div(({ theme }) => {
  return css`
    max-height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${theme.rem(2)};
  `;
});
const ClientUploadState = styled.span<IClientUploadState>(({ theme, state }) => {
  const { success, error, info } = theme.values.colors.global.validation;
  const { _000 } = theme.values.colors.global.greys;

  const color = useMemo(() => {
    if (state === EClientSelection.LOADED) return success;
    if (state === EClientSelection.ERRORED) return error;
    if (state === EClientSelection.NO_SUSAS) return info;
    if (state === EClientSelection.TIME_OUT) return info;
    if (state === EClientSelection.INCOMPATIBLE) return info;
    if (state === EClientSelection.DATAPATH_INVALID) return info;
    if (state === EClientSelection.LOADING) return _000;
    return _000;
  }, [state, success, error, info, _000]);

  return css`
    svg,
    svg * {
      fill: ${color};
    }
  `;
});

const NoClientsWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    & > span {
      margin-right: ${theme.rem(1.33)};
      & svg path {
        fill: ${theme.values.colors.global.primary._100};
      }
    }
  `;
});

const Month = styled.div(({ theme }) => {
  return css`
    margin-right: ${theme.rem(1)};
    margin-top: ${theme.rem(0.5)};
  `;
});

const ClientName = styled.span(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(0)};
    position: relative;
    width: 100%;
    &:hover {
      & > div {
        display: block;
      }
    }
  `;
});

const ClientCode = styled.div(({ theme }) => {
  return css`
    display: none;
    position: absolute;
    font-size: ${theme.rem(0.8)};
    right: ${theme.rem(-3.5)};
    top: ${theme.rem(-0.2)};
    padding: ${theme.rem(0.3)};
    box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
      ${theme.values.colors.global.greys._025};
    border-radius: ${theme.rem(0.5)};
    text-align: center;
  `;
});

export const StyledSelection = {
  ClientListWrapper,
  NoClientsWrapper,
  ClientUploadState,
  Month,
  ClientName,
  ClientCode,
};
