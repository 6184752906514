import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyledDownloadApp } from "./styled.download-app";

interface IDownloadItems {
  name: IconNameEnums;
  title: string;
  text: string;
}

const DownloadTopic: FC<IDownloadItems> = ({ name, title, text }) => {
  const { t } = useTranslation(["tax/common"]);
  return (
    <div>
      <StyledDownloadApp.IconWrapper>
        <Icon name={name} size={IconSizeEnums.LARGE} />
      </StyledDownloadApp.IconWrapper>
      <StyledDownloadApp.ItemTitleWrapper>{t(`${title}`)}</StyledDownloadApp.ItemTitleWrapper>
      <StyledDownloadApp.ItemTextWrapper>
        <Trans i18nKey={`${text}`} />
      </StyledDownloadApp.ItemTextWrapper>
    </div>
  );
};

export default DownloadTopic;
