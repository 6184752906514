import { FC } from "react";
import { EPublicPaths, ICardTypeEnums } from "../../../@types/index.d";
import { Card, CardBody, CardTitle, FadeIn, P } from "@canei/app-components";

import { Link } from "react-router-dom";

import { SimpleLayout } from "../../../_lib/Layout";

interface IErrorPage {
  title: string;
  message: string;
}
export const ErrorPage: FC<IErrorPage> = ({ title, message }) => {
  return (
    <SimpleLayout>
      <FadeIn>
        <Card type={ICardTypeEnums.CENTERED}>
          <CardTitle>{title}</CardTitle>
          <CardBody>
            <P>{message}</P>
            <P>
              Go to<Link to={EPublicPaths.HOME}> Home Page</Link> and use menu to search your
              content.
            </P>
          </CardBody>
        </Card>
      </FadeIn>
    </SimpleLayout>
  );
};
