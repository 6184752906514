import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { SelectSoftware } from "./SelectSoftware";
import { EPublicPaths } from "../../../@types/index.d";
import { Login } from "../../shared";
import { SetupBB } from "./SetupBB";

export const Public: FC = () => {
  return (
    <Switch>
      <Route exact path={EPublicPaths.LOGIN}>
        <Login />
      </Route>
      <Route exact path={EPublicPaths.BETTER_BUSINESS}>
        <SetupBB />
      </Route>
      <Route exact path={"*"}>
        <SelectSoftware />
      </Route>
    </Switch>
  );
};
