import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  QueryLazyOptions,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { GET_CUSTOMER_STATUS, GET_SUBSCRIPTIONS, UPDATE_BILLING_ADDRESS } from "./payment-queries";
import {
  IGetCustomerStatus,
  ISubscriptionData,
  ISubscriptionVars,
  IUpdateBillingAddressRetVal,
  IUpdateBillingAddressVars,
} from "../../Layout/TopBar/Payment/payment.types";
import { EBillingActionType, IBillingAction } from "../../store/reducers";

export interface IGetCustomerStatusVar {
  customer_id: string;
}
export interface IGetCustomerStatusRetVal {
  getCustomerStatus: (
    options?:
      | QueryLazyOptions<{
          customer_id: string;
        }>
      | undefined
  ) => void;
  customerStatusLoading: boolean;
  updateBillingAddress: (
    options?:
      | MutationFunctionOptions<
          IUpdateBillingAddressRetVal,
          IUpdateBillingAddressVars,
          DefaultContext,
          ApolloCache<IUpdateBillingAddressRetVal>
        >
      | undefined
  ) => Promise<FetchResult>;
  updateBillingAddressLoading: boolean;
  updateBillingAddressCalled: boolean;
  getSubscriptions: (options?: QueryLazyOptions<ISubscriptionVars> | undefined) => void;
}

export const useBillingInfo = (): IGetCustomerStatusRetVal => {
  const dispatchBilling = useDispatch<Dispatch<IBillingAction>>();
  const updateBillingFnOnCompleted = (data: IUpdateBillingAddressRetVal): void => {
    if (!data) return;
    dispatchBilling({
      type: EBillingActionType.SET_BILLING_ADDRESS,
      payload: { billingAddress: data.updateBillingAddress },
    });
  };

  const getCustomerStatusOnComplete = (data: IGetCustomerStatus): void => {
    if (data && data.getCustomerStatus) {
      const {
        customer_id,
        product_id,
        pricing_id,
        status,
        start_date,
        trial_end,
        canceled_at,
        payment_method,
        billing_address,
      } = data.getCustomerStatus;

      dispatchBilling({
        type: EBillingActionType.SET_CUSTOMER_DATA,
        payload: {
          customerData: {
            customer_id,
            product_id,
            pricing_id,
            status,
            start_date,
            trial_end,
            canceled_at,
            payment_method,
            // billing_address,
          },
        },
      });
      dispatchBilling({
        type: EBillingActionType.SET_BILLING_ADDRESS,
        payload: { billingAddress: billing_address },
      });
    }
  };

  const updateSubscriptionOnComplete = (data: ISubscriptionData): void => {
    if (data && data.result) {
      dispatchBilling({
        type: EBillingActionType.SET_SUBSCRIPTION_DATA,
        payload: { subscription: data.result[0] },
      });
    }
  };

  const [getSubscriptions] = useLazyQuery<ISubscriptionData, ISubscriptionVars>(GET_SUBSCRIPTIONS, {
    fetchPolicy: "no-cache",
    onCompleted: updateSubscriptionOnComplete,
  });

  const [getCustomerStatus, { loading: customerStatusLoading }] = useLazyQuery<
    IGetCustomerStatus,
    { customer_id: string }
  >(GET_CUSTOMER_STATUS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      getCustomerStatusOnComplete(data);
    },
  });

  const [
    updateBillingAddress,
    { loading: updateBillingAddressLoading, called: updateBillingAddressCalled },
  ] = useMutation<IUpdateBillingAddressRetVal, IUpdateBillingAddressVars>(UPDATE_BILLING_ADDRESS, {
    fetchPolicy: "no-cache",
    onCompleted: updateBillingFnOnCompleted,
  });

  return {
    getCustomerStatus,
    customerStatusLoading,
    updateBillingAddress,
    updateBillingAddressLoading,
    getSubscriptions,
    updateBillingAddressCalled,
  };
};
