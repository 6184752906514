import { FC, useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Cell,
  Row,
  Table,
  ButtonVariantEnums,
  IconButton,
  Icon,
  IDialogKeyEnums,
  useDialogs,
  useLocaleFromLanguage,
  LoadingIndicator,
  LoaderTypeEnums,
  IconNameEnums,
  IconSizeEnums,
} from "@canei/app-components";
import { useSelector } from "react-redux";
import { StyledDownloadInvoices } from "./styled.download-invoices";
import { ILocalState } from "../../../../@types";
import { isEqual } from "lodash";

interface IInvoices {
  number: string;
  created: string;
  invoice_pdf: string;
}
const GET_INVOICES = gql`
  query getInvoices($customer_id: ID!) {
    getInvoices(customer_id: $customer_id) {
      number
      created
      invoice_pdf
    }
  }
`;
export interface IDownloadInvoice {
  getInvoices: IInvoices[];
}
export interface IDownloadInvoiceVar {
  customer_id: string;
}
export const Content: FC = () => {
  const { t } = useTranslation(["quick/common"]);
  const { localeFromLanguage } = useLocaleFromLanguage();
  const [downloading, setDownloading] = useState(false);
  const [invoices, setInvoices] = useState<IInvoices[] | undefined>([]);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const [getInvoices, { loading }] = useLazyQuery<IDownloadInvoice, IDownloadInvoiceVar>(
    GET_INVOICES,
    {
      onCompleted: (data) => {
        setInvoices(data.getInvoices);
      },
      fetchPolicy: "no-cache",
    }
  );
  const handleDownloadInvoice = (pdfLink: string): void => {
    if (invoices !== undefined) {
      setDownloading(true);
      const a = document.createElement("a");
      a.href = pdfLink;
      a.download = "report";
      a.click();
    }
  };
  useEffect(() => {
    if (downloading) {
      setTimeout(() => setDownloading(false), 4000);
    }
  }, [downloading]);
  useEffect(() => {
    if (currentUser.appUser.customer_id === null) return;
    getInvoices({ variables: { customer_id: currentUser.appUser.customer_id } });
  }, [getInvoices, currentUser.appUser.customer_id]);
  return (
    <>
      {downloading && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}
      <StyledDownloadInvoices.Wrapper margin_bottom={true}>
        <Table width="100%">
          <thead>
            <Row>
              <Cell level="title" highlight width="50%">
                {" "}
                {t("quick/common:main_content.top_bar.user_profile.invoices.invoice_number")}
              </Cell>
              <Cell level="title" highlight>
                {" "}
                {t("quick/common:main_content.top_bar.user_profile.invoices.date")}
              </Cell>
              <Cell level="title" highlight>
                &nbsp;
              </Cell>
            </Row>
          </thead>
          <tbody>
            {invoices && invoices.length === 0 ? (
              <Row>
                <Cell>
                  {loading
                    ? "Wird geladen...."
                    : t("quick/common:main_content.top_bar.user_profile.invoices.no_invoice")}
                </Cell>
                <Cell>&nbsp;</Cell>
                <Cell>&nbsp;</Cell>
              </Row>
            ) : (
              invoices?.map(({ number, created, invoice_pdf }, index) => {
                return (
                  <Row key={index}>
                    <Cell>{number}</Cell>
                    <Cell>
                      {new Date(+created * 1000).toLocaleDateString(localeFromLanguage(), {
                        month: "2-digit",
                        year: "numeric",
                        day: "2-digit",
                      })}
                    </Cell>
                    <Cell align="right">
                      <IconButton
                        icon={<Icon size={IconSizeEnums.SMALL} name={IconNameEnums.Q_DOWNLOAD} />}
                        inverted={false}
                        variant={ButtonVariantEnums.DEFAULT}
                        type={"button"}
                        onClick={(): void => {
                          handleDownloadInvoice(invoice_pdf);
                        }}
                      />
                    </Cell>
                  </Row>
                );
              })
            )}
          </tbody>
        </Table>
      </StyledDownloadInvoices.Wrapper>
    </>
  );
};
interface IUseInvoicesDialogRetVal {
  open: () => void;
}
export const useInvoicesDialog = (): IUseInvoicesDialogRetVal => {
  const dialogs = useDialogs();
  return {
    open: (): void => {
      dialogs.open({
        dialogKey: IDialogKeyEnums.SIMPLE,
        title: "Rechnungen",
        closable: true,
        content: <Content />,
        flexible: false,
      });
    },
  };
};
