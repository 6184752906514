/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
  /**
   * @memberof configuration
   * @property {object}webapp - Webapp defaults.
   */
  webapp: {
    MAX_ATTACHMENT_SIZE: 5000000,
    kpi: "KPIB12",
    revenue_curve: "OF006",
    revenue_growth: 0,
    poster: "/assets/video_preview.png",
  },
  shared_links: {
    imprint: "https://canei.tax/impressum/",
    terms_url: "https://canei.tax/allgemeine-geschaeftsbedingungen/",
    privacy_url: "https://canei.tax/datenschutzerklaerung/",
    data_processing: "https://canei.tax/auftragsdatenverarbeitungsvertrag/",
    plan_meeting: "https://canei.ag/#contact",
  },
  product_key: "tax",
  product_id: "canei_tax",
  pricing_id: "canei_tax_01",
  datev_url: "http://localhost:58454/datev/api",
  considerable: "10",
  critical: "25",
  limiter: 10,
  alertsLimiter: 100,
  warningLimiter: 100,
};

export default config;
