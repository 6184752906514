import { FC, useContext, useEffect, useState } from "react";
import { IconNameEnums, IMenuItemProps } from "@canei/app-components";

import { StyledMenu } from "./styled.menu";

import { useTranslation } from "react-i18next";
import { MenuItem } from "./MenuItem";

import { useHistory } from "react-router-dom";

import { EPrivatePaths } from "../../../../@types/index.d";
import { MainContext, EMenuItem } from "../../../../views/local/Private/PrivateMainContext";

export interface IMenuProps<T> extends Omit<IMenuItemProps<T>, "level"> {
  icon: IconNameEnums;
}
export const Menu: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const { appContext } = useContext(MainContext);
  const history = useHistory();
  const [currentLocation, setCurrentLocation] = useState(history.location.pathname);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== currentLocation)
        setCurrentLocation(`${location.pathname}${location.hash}`);
    });
  }, [history, currentLocation]);

  const menu: Record<EMenuItem, IMenuProps<EPrivatePaths>> = {
    [EMenuItem.TOTAL_ANALYSE]: {
      ...t(`tax/common:main_menu.total_analyse`, { returnObjects: true }),
      selected: currentLocation === EPrivatePaths.TOTAL,
      icon: IconNameEnums.TOTAL_ANALYZE,
      href: EPrivatePaths.TOTAL,
    },
    [EMenuItem.VIEW_SETUP]: {
      ...t(`tax/common:main_menu.view_setup`, { returnObjects: true }),
      selected: currentLocation === EPrivatePaths.EDIT_VIEW,
      icon: IconNameEnums.EDIT_VIEW,
      href: EPrivatePaths.EDIT_VIEW,
    },
  };

  return (
    <StyledMenu>
      {appContext.menu.map(({ value }) => (
        <MenuItem {...menu[value]} key={value} />
      ))}
    </StyledMenu>
  );
};
