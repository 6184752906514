import { useApolloClient } from "@apollo/client";
import { useCallback, useRef } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../@types";
import { EAppStatusActionType, IAppStatusAction } from "../../store/reducers/appStatus";
import { GET_CACHED_EVENTS } from "./eventListener-queries";
import { EventListenerCodes, IStartEventListenerInput } from "./types.event-listener";
import { isEqual } from "lodash";

interface IUseEventListenerRetVal {
  startBulkEventSync: (params: IStartEventListenerInput) => void;
}

export const useBulkEventListener = (): IUseEventListenerRetVal => {
  const apolloClient = useApolloClient();
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const eventsState = useSelector(
    ({ appStatus: { eventsState } }: ILocalState) => eventsState,
    isEqual
  );
  const alertsInProgress = useSelector(
    ({ appStatus: { alertsInProgress } }: ILocalState) => alertsInProgress,
    isEqual
  );
  const eventsStateRef = useRef(eventsState);
  eventsStateRef.current = eventsState;
  const alertsInProgressRef = useRef(alertsInProgress);
  alertsInProgressRef.current = alertsInProgress;
  // initialize the event sync interval using the interval and the getEventState function
  const eventSync = useRef<NodeJS.Timer | null>(null);

  // event bulk listener function
  const startBulkEventSync = useCallback(
    (params: IStartEventListenerInput) => {
      // if there are no params in the array stop
      if (!params) return;

      // if there is already an event listener running stop
      if (eventsState && eventsState[params.transactionId]) return;

      // dispatch event listener to eventsState redux state
      dispatchAppStatus({
        type: EAppStatusActionType.UPDATE_EVENTS_STATE,
        payload: {
          [params.transactionId]: {
            transactionId: params.transactionId,
            accountId: params.accountId,
            status: EventListenerCodes.UNKNOWN,
            timeOutTimer: 90,
          },
        },
      });

      // initialize The interval for event sync in seconds
      const interval = 5;

      // if there is no event state yet create one
      if (!eventsState || Object.keys(eventsState).length === 0) {
        eventSync.current = setInterval(getBulkEventState, interval * 1000);
      }

      // try getting the event state from cached events in backend
      function getBulkEventState(): void {
        const inputArray = Object.keys(eventsStateRef.current)
          .filter((event) => eventsStateRef.current[event].status === EventListenerCodes.UNKNOWN)
          .map((event) => eventsStateRef.current[event]);

        const allDone =
          Object.keys(eventsStateRef.current).every(
            (event) => eventsStateRef.current[event].status !== EventListenerCodes.UNKNOWN
          ) &&
          Object.keys(eventsStateRef.current).length ===
            Object.keys(alertsInProgressRef.current).length;

        if (allDone) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          clearInterval(eventSync.current!);
          return;
        }

        apolloClient
          .query({
            query: GET_CACHED_EVENTS,
            variables: {
              eventsState: inputArray,
            },
            fetchPolicy: "no-cache",
          })
          .then(({ data }) => {
            if (!data) return;
            if (!data.eventsStateArray) return;
            if (data.eventsStateArray.length === 0) return;

            // parse the results into eventsState redux state
            for (const event of data.eventsStateArray) {
              if (!event) continue;

              const { transactionId, accountId, status, timeOutTimer } = event;
              if (status === EventListenerCodes.TRANSACTION_OK) {
                dispatchAppStatus({
                  type: EAppStatusActionType.UPDATE_EVENTS_STATE,
                  payload: {
                    [transactionId]: {
                      transactionId: transactionId,
                      accountId: accountId,
                      status: EventListenerCodes.TRANSACTION_OK,
                      timeOutTimer: timeOutTimer,
                    },
                  },
                });
              } else if (
                status !== EventListenerCodes.TRANSACTION_OK &&
                status !== EventListenerCodes.UNKNOWN
              ) {
                dispatchAppStatus({
                  type: EAppStatusActionType.UPDATE_EVENTS_STATE,
                  payload: {
                    [transactionId]: {
                      transactionId: transactionId,
                      accountId: accountId,
                      status: EventListenerCodes.ERROR,
                      timeOutTimer: timeOutTimer,
                    },
                  },
                });
              } else {
                if (timeOutTimer <= 0) {
                  dispatchAppStatus({
                    type: EAppStatusActionType.UPDATE_EVENTS_STATE,
                    payload: {
                      [transactionId]: {
                        ...eventsStateRef.current[transactionId],
                        status: EventListenerCodes.TIME_OUT,
                      },
                    },
                  });
                } else {
                  // update the timeOutTimer for next call
                  dispatchAppStatus({
                    type: EAppStatusActionType.UPDATE_EVENTS_STATE,
                    payload: {
                      [transactionId]: {
                        ...eventsStateRef.current[transactionId],
                        timeOutTimer: timeOutTimer - interval,
                      },
                    },
                  });
                }
              }
            }
          });
      }
    },
    [apolloClient, dispatchAppStatus, eventsState]
  );

  return {
    startBulkEventSync,
  };
};
