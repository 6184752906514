import { FC } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";
import { useTranslation } from "react-i18next";
import { StyledSetup } from "../../../../StyledComponents";
import { useDispatch } from "react-redux";
import { ICurrentUserAction } from "../../../../_lib/store/reducers";
import { UserStateActionTypes } from "../../../../@types/amplify.d";
import { Auth } from "aws-amplify";
import { EAppStatusActionType, IAppStatusAction } from "../../../../_lib/store/reducers/appStatus";

export const SetupTaxMenu: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  const dispatchAppStore = useDispatch<Dispatch<IAppStatusAction>>();

  const handleLogout = (): void => {
    Auth.signOut().then(() => {
      dispatch({ type: UserStateActionTypes.CLEAR_STORE, payload: {} });
      dispatchAppStore({ type: EAppStatusActionType.CLEAR_STORE, payload: {} });
      localStorage.clear();
      window.location.reload();
    });
  };

  return (
    <StyledSetup.SetupMenu.List>
      <StyledSetup.SetupMenu.ListItem onClick={handleLogout}>
        <Icon name={IconNameEnums.Q_PROFILE} size={IconSizeEnums.SMALL} />
        <span>{t("main_content.user_menu.logout")}</span>
      </StyledSetup.SetupMenu.ListItem>
    </StyledSetup.SetupMenu.List>
  );
};
