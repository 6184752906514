import { gql, QueryResult, useQuery } from "@apollo/client";
import { AccountMappingType } from "@canei/app-components";

const GET_FRAME_TEMPLATES = gql`
  {
    result: getTemplates
  }
`;
export interface IQueryFrameTemplateData {
  result: AccountMappingType[];
}

export const useAccountFrameTemplates = (): QueryResult => {
  return useQuery<IQueryFrameTemplateData>(GET_FRAME_TEMPLATES);
};
