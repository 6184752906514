import { FC, useContext } from "react";

import { EProgressStep, RegisterContext } from "../";

import { CustomerData } from "./CustomerData";
import { Confirming } from "./Confirming";
import { StyledWizardForm } from "../../../../../StyledComponents/styled.wizard-form";
import { Success } from "./Success";
import { Trans, useTranslation } from "react-i18next";

export const RegisterSteps: FC = () => {
  const registerContext = useContext(RegisterContext);
  const { t } = useTranslation(["tax/common"]);
  const captions = {
    [EProgressStep.CUSTOMER_DATA]: t("tax/common:wizards.registration.customer_data.caption"),
    [EProgressStep.CONFIRM]: t("tax/common:wizards.registration.confirming.caption"),
    [EProgressStep.SUCCESS]: <Trans i18nKey="tax/common:wizards.registration.success.caption" />,
  };

  return (
    <>
      <StyledWizardForm.FormCaption>
        {captions[registerContext.progress.currentStep]}
      </StyledWizardForm.FormCaption>

      {registerContext.progress.currentStep === EProgressStep.CUSTOMER_DATA && <CustomerData />}
      {registerContext.progress.currentStep === EProgressStep.CONFIRM && <Confirming />}
      {registerContext.progress.currentStep === EProgressStep.SUCCESS && <Success />}
    </>
  );
};
