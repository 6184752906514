/**
 * @file
 */

import { IThemeOptions } from "../../@types/index.d";
import { IThemeValues, IBrandedValues, IDevice } from "@canei/app-components";
import { EProductEnums, LayoutEnums } from "../Layout/types.layout";
/**
 * Creates Compiled theme which will be used to calculate style and theme related variables.
 */
export default class ThemeCompiler {
  values: IThemeValues<
    EProductEnums.TAX | EProductEnums.BETTER_BUSINESS | EProductEnums.HAUFE_BETTER_BUSINESS
  >;
  device: IDevice;
  branded: IBrandedValues<
    EProductEnums.TAX | EProductEnums.BETTER_BUSINESS | EProductEnums.HAUFE_BETTER_BUSINESS
  >;
  layout: LayoutEnums;
  sideNavExpanded: boolean;

  /**
   * @constructor
   * @param themeValues
   * @param device
   * @param options
   */
  constructor(themeValues: IThemeValues<EProductEnums>, device: IDevice, options: IThemeOptions) {
    this.values = themeValues;
    this.device = device;
    this.layout = options.layout;
    this.branded = options.branded;
    this.sideNavExpanded = options.sideNavExpanded;
  }

  /**
   * Calculates related size in px string from given `val`
   *
   * `baseFontSize` retrieved from given theme
   * @param val floating number
   */
  rem(val: number): string {
    return `${this.values.baseFontSize * val}px`;
  }
}
