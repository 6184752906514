import styled, { css } from "styled-components/macro";

const QCardWrapper = styled.div(({ theme }) => {
  return css`
    margin-top: ${theme.rem(3)};
  `;
});
const Wrapper = styled.div(({ theme }) => {
  return css`
    position: relative;
    padding: 0 ${theme.rem(2)};
  `;
});
const Section = styled.div(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(2)};
  `;
});

const Title = styled(Section)(({ theme }) => {
  return css`
    font-size: ${theme.rem(1.5)};
    font-weight: ${theme.values.fontWeight.bold};
    text-align: center;
  `;
});
const Text = styled(Section)(({ theme }) => {
  return css`
    display: flex;
    max-width: fit-content;
    text-align: justify;
  `;
});
const Footer = styled(Section)(({ theme }) => {
  return css`
    display: flex;
    justify-content: center;
  `;
});

export const StyledPaymentPage = { Wrapper, Section, Title, Text, Footer, QCardWrapper };
