import { FC, forwardRef, useEffect, useRef, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import {
  ButtonVariantEnums,
  Icon,
  IconButton,
  IconButtonProps,
  IconNameEnums,
  IconSizeEnums,
} from "@canei/app-components";

import SvgCaretUpSolid from "@canei/app-components/lib/components/Icon/IconLib/CaretUpSolid";
import styled, { css } from "styled-components/macro";
import { FilterByAlertType } from "./FilterByAlertType";

import { FilterByGroupName } from "./FilterByGroupName";
import { TFilterOptions } from "../../../../../../@types";

const Arrow = styled.div(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.greys._010};
    height: ${theme.rem(2)};
    width: ${theme.rem(2)};
    top: ${theme.rem(-2)};
    z-index: 1;
  `;
});

const FilterButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
  <span ref={ref}>
    <IconButton {...props} />
  </span>
));
export interface IFilterProps {
  name: keyof TFilterOptions;
}
export const Filter: FC<IFilterProps> = ({ name }) => {
  const [visible, setVisible] = useState(false);
  const openerRef = useRef<HTMLButtonElement | null>(null);

  const toggle = (): void => {
    setVisible(!visible);
  };

  const handleOnClickOutSide = (/*instance: any, event: any*/): void => {
    setVisible(false);
  };

  useEffect(() => {
    if (openerRef.current === null) return;
    const contentWrapper = document.querySelector("[data-content-wrapper]");
    if (contentWrapper === null) return;
    const hideOutOfView = (): void => {
      const openerY = openerRef.current?.getBoundingClientRect().top || 121;

      if (openerY < 120 && visible) {
        setVisible(false);
      }
    };

    contentWrapper.addEventListener("scroll", hideOutOfView);
    return (): void => {
      contentWrapper.removeEventListener("scroll", hideOutOfView);
    };
  }, [visible]);

  return (
    <Tippy
      visible={visible}
      interactive={true}
      onClickOutside={handleOnClickOutSide}
      appendTo={document.body}
      render={(props): JSX.Element => {
        return (
          <>
            <Arrow data-popper-arrow="" {...props}>
              <SvgCaretUpSolid />
            </Arrow>
            {name === "CATEGORY" || name === "DESC" ? (
              <FilterByGroupName name={name} />
            ) : (
              <FilterByAlertType name={name} />
            )}
          </>
        );
      }}
      placement={"bottom"}
    >
      <FilterButton
        icon={<Icon name={IconNameEnums.FILTER_SORT_ON} size={IconSizeEnums.SMALL} />}
        inverted={false}
        variant={ButtonVariantEnums.DEFAULT}
        onClick={toggle}
        ref={openerRef}
      />
    </Tippy>
  );
};
