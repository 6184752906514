import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";
import { RefObject } from "react";

interface IImageWrapperProps {
  imageUrl: string;
}
interface ContentWrapperProps<T> {
  headline?: string;
  caption?: string;
  image?: string;
  inverted?: boolean;
  forwardRef?: RefObject<T>;
  gridArea?: string;
}
const TextIconWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    gap: ${theme.rem(0.33)};
  `;
});
const ContentWrapper = styled.section<Pick<ContentWrapperProps<HTMLElement>, "inverted">>(
  ({ theme, inverted }) => {
    const { _000: backgroundColor } = theme.values.colors.global.greys;
    const { _100: textColor } = theme.values.colors.global.secondary;
    return css`
      box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
        ${theme.values.colors.global.greys._025};
      border-radius: ${theme.rem(1.75)};
      background-color: ${inverted ? textColor : backgroundColor};
      color: ${inverted ? backgroundColor : textColor};
      overflow: hidden;
      flex-grow: 1;
      position: relative;
    `;
  }
);
const Wrapper = styled(ContentWrapper)(({ theme, inverted = false }) => {
  const { _000: backgroundColor } = theme.values.colors.global.greys;
  const { _100: textColor } = theme.values.colors.global.secondary;
  return useResponsive({
    MINI: {
      style: css`
        display: flex;
        grid-column-gap: ${theme.rem(1)};
        background-color: ${inverted ? textColor : backgroundColor};
        color: ${inverted ? backgroundColor : textColor};
        flex-wrap: wrap;
      `,
    },
    XLARGE: {
      style: css`
        flex-wrap: nowrap;
      `,
    },
  });
});

const Image = styled.div<IImageWrapperProps>(({ theme, imageUrl }) => {
  return useResponsive({
    MINI: {
      style: css`
        flex-basis: 100%;
        height: ${theme.rem(24)};
        background: url(${imageUrl}) no-repeat center center;
        background-size: cover;
      `,
    },
    XLARGE: {
      style: css`
        flex-basis: 50%;
      `,
    },
  });
});

const Content = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        align-self: center;
        flex-basis: 100%;
        justify-content: center;
        align-items: center;
        padding: ${theme.rem(2)};
        > :not(:last-child) {
          margin-bottom: ${theme.rem(2)};
        }
      `,
    },
    XLARGE: {
      style: css`
        flex-basis: 50%;
      `,
    },
  });
});

const ContentTitle = styled.h2(({ theme }) => {
  return css`
    font-size: ${theme.rem(1.4)};
    line-height: ${theme.rem(1.8)};
    font-weight: ${theme.values.fontWeight.bold};
  `;
});

const LegendWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      margin-right: ${theme.rem(0.5)};
    }
  `;
});

const AlertWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
});

export const StyledTotalAnalyze = {
  TextIconWrapper,
  Wrapper,
  Image,
  Content,
  ContentTitle,
  LegendWrapper,
  AlertWrapper,
};
