import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

const Wrapper = styled.div(() => {
  return css`
    grid-area: content;
    overflow-y: scroll;
    position: relative;
  `;
});

const Inner = styled.section(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: grid;
        grid-template-areas:
          "welcome"
          "total-analyze"
          "view-setup"
          "offer"
          "data-sync"
          "upgrade";
        background-color: ${theme.values.colors.global.secondary._010};
        padding: ${theme.rem(3.33)};
        grid-template-columns: auto;
        grid-column-gap: ${theme.rem(2)};
        grid-row-gap: ${theme.rem(6)};
        position: absolute;
        z-index: 0;
      `,
    },
    XLARGE: {
      style: css`
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "welcome welcome"
          "total-analyze total-analyze"
          "view-setup view-setup"
          "offer offer"
          "data-sync data-sync"
          "upgrade upgrade";
      `,
    },
  });
});

export const StyledContent = { Wrapper, Inner };
