import { FetchResult, useMutation } from "@apollo/client";
import { FileUploadFormat } from "@canei/app-components";
import {
  IDatevJsonFile,
  IDatevJsonInput,
  IDatevJsonMultipleInput,
  IDatevJsonResult,
  IUploadSingleVar,
  UPLOAD_DATEV_JSON,
  UPLOAD_MULTIPLE_DATEV_JSON,
} from "./types.useDatev";

export interface IUseUploadDatevJsonRetVal {
  uploadSingle: (options: IUploadSingleVar) => Promise<FetchResult<IDatevJsonResult>>;
  uploadMultiple: (options: IUploadSingleVar[]) => Promise<FetchResult<IDatevJsonResult[]>>;
}

export const useUploadDatevJson = (): IUseUploadDatevJsonRetVal => {
  const [singleUpload] = useMutation<IDatevJsonResult, IDatevJsonInput>(UPLOAD_DATEV_JSON);
  const [multipleUpload] = useMutation<IDatevJsonResult[], IDatevJsonMultipleInput>(
    UPLOAD_MULTIPLE_DATEV_JSON
  );

  const uploadSingle = (options: IUploadSingleVar): Promise<FetchResult<IDatevJsonResult>> => {
    const vars: IDatevJsonFile = {
      ...options,
      lastModified: Date.now().toString(),
      name: "",
      size: options.content.length,
      type: "JSON",
      format: FileUploadFormat.JSON_DATEV_CONNECT,
    };

    const inputVars: IDatevJsonInput = {
      file: vars,
      covid: options.covid,
    };

    return singleUpload({
      variables: inputVars,
    });
  };

  const uploadMultiple = (
    options: IUploadSingleVar[]
  ): Promise<FetchResult<IDatevJsonResult[]>> => {
    const vars: IDatevJsonInput[] = options.map((item) => {
      const varItem: IDatevJsonFile = {
        ...item,
        lastModified: Date.now().toString(),
        name: "",
        size: item.content.length,
        type: "JSON",
        format: FileUploadFormat.JSON_DATEV_CONNECT,
      };
      const inputVars: IDatevJsonInput = {
        file: varItem,
        covid: item.covid,
      };

      return inputVars;
    });

    const inputVars: IDatevJsonMultipleInput = {
      data: vars,
    };

    return multipleUpload({
      variables: inputVars,
      fetchPolicy: "no-cache",
    });
  };

  return {
    uploadSingle,
    uploadMultiple,
  };
};
