import styled, { css } from "styled-components/macro";

export interface IMonthPickerItem {
  selected: boolean;
  disabled: boolean;
}

export const StyledMonthPicker = styled.div(({ theme }) => {
  return css`
    display: inline-flex;
    flex-basis: 100%;
    position: relative;
  `;
});

export const StyledMSelectorWrapper = styled.div(({ theme }) => {
  const borderColor = theme.values.colors.global.greys._025;
  return css`
    position: absolute;
    bottom: 100%;
    right: 0;
    background-color: ${theme.values.colors.global.greys._000};
    display: grid;
    grid-template-columns: 1fr auto;
    padding: ${theme.rem(0.5)};
    border: 1px solid ${borderColor};
    font-size: ${theme.rem(1)};
    z-index: 3;
    border-radius: ${theme.rem(2 / 3)};
    box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
      ${theme.values.colors.global.greys._025};
  `;
});

export const StyledPickItem = styled.div<IMonthPickerItem>(({ theme, selected, disabled }) => {
  const selectedCss = css`
    color: ${theme.values.buttons.default.color.base.background};
    border: 1px solid ${theme.values.buttons.primary.color.base.background};
  `;
  const disabledCss = css`
    opacity: 0.5;
    cursor: not-allowed;
  `;
  const hover = css`
    color: ${theme.values.buttons.primary.color.base.color};
    background-color: ${theme.values.buttons.primary.color.base.background};
  `;

  return css`
    padding: ${theme.rem(1 / 3)} ${theme.rem(2 / 3)};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-weight: ${theme.values.fontWeight.normal};
    user-select: none;
    border: 1px solid transparent;
    ${selected && selectedCss}
    ${disabled && disabledCss}
    &:hover {
      ${!disabled && hover}
    }
  `;
});

export const StyledYearsList = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-row-gap: ${theme.rem(2 / 3)};
  `;
});

export const StyledYear = styled(StyledPickItem)(({ theme }) => {
  return css``;
});

export const StyledMonthsList = styled.div(({ theme }) => {
  return css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${theme.rem(1 / 3)};
    grid-row-gap: ${theme.rem(1 / 3)};
  `;
});

export const StyledMonth = styled(StyledPickItem)(({ theme }) => {
  return css``;
});
