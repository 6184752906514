import styled, { css } from "styled-components/macro";

const Wrapper = styled.section(({ theme }) => {
  const { _100 } = theme.values.colors.global.secondary;
  return css`
    grid-area: sidebar;
    width: ${theme.rem(18)};
    background-color: ${_100};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;
});
export const StyledSideBar = { Wrapper };
