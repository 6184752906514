import rgba from "polished/lib/color/rgba";
import styled, { css } from "styled-components/macro";

const Wrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 0 ${theme.rem(3.33)};
    grid-column-gap: ${theme.rem(1)};
    & > * {
      max-width: max-content;
    }
  `;
});
const User = styled.div(({ theme }) => {
  return css`
    position: relative;
  `;
});
const ClientName = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(1.4)};
    line-height: ${theme.rem(5 / 3)};
    font-weight: ${theme.values.fontWeight.bold};
    display: flex;
    grid-column-gap: ${theme.rem(1)};
    cursor: pointer;
  `;
});
const ListWrapper = styled.div(({ theme }) => {
  return css`
    background-color: ${theme.values.colors.global.greys._000};
    border-radius: ${theme.rem(2 / 3)};
    position: fixed;
    top: 0;
    right: 0;
    transform: translate(${theme.rem(-2)}, ${theme.rem(8)});
    padding: ${theme.rem(1)} ${theme.rem(2)};
    box-shadow: ${theme.rem(1 / 3)} ${theme.rem(2 / 3)} ${theme.rem(4 / 3)}
      ${rgba(theme.values.colors.global.greys._100, 0.29)};
  `;
});
const List = styled.ul(({ theme }) => {
  return css`
    list-style: none;
    padding: 0;
    margin: 0;
    width: ${theme.rem(22)};
  `;
});
const ListItem = styled.li(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    list-style: none;
    padding: ${theme.rem(1)} 0;
    margin: 0;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: ${theme.values.colors.global.greys._025} 1px solid;
    }
  `;
});
const CustomerName = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(1)};
    line-height: ${theme.rem(1.2)};
    color: ${theme.values.colors.global.secondary._055};
  `;
});

export const StyledUserMenu = {
  Wrapper,
  User,
  ClientName,
  CustomerName,
  ListWrapper,
  List,
  ListItem,
};
