import { combineReducers } from "redux";
import { default as appStatus } from "./appStatus";
import { default as currentUser } from "./currentUser";
import { default as billing } from "./billing";
import { default as draftClient } from "./draftClient";
import { default as themeOptions } from "./themeOptions";
import { default as dataSync } from "./dataSync";
import { default as warmUp } from "./warmUp";
import { default as downloads } from "./downloads";
import { default as uploads } from "./uploads";
import { default as systemMessages } from "./systemMessages";
import { default as dataStore } from "./dataStore";
export * from "./currentUser";
export * from "./billing";
export * from "./themeOptions";
export * from "./draftClient";
export * from "./dataSync";
export * from "./warmUp";
export * from "./downloads";
export * from "./uploads";
export * from "./systemMessages";
export * from "./dataStore";

export default combineReducers({
  appStatus,
  currentUser,
  billing,
  draftClient,
  themeOptions,
  dataSync,
  warmUp,
  downloads,
  uploads,
  systemMessages,
  dataStore,
});
