import { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  Icon,
  IconNameEnums,
  IconSizeEnums,
  LoaderTypeEnums,
  LoadingIndicator,
  FadeIn,
  IconButton,
  ButtonVariantEnums,
} from "@canei/app-components";
import { useHistory } from "react-router-dom";
import { EPrivatePaths } from "../../../../@types/index.d";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../../@types";
import { StyledUserMenu } from "../UserMenu/styled.user-menu";
import { gql, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useManagePaymentsDialog } from "../Payment";
import { useUserManagementDialog } from "../CustomerUserManagement/useUserManagementDialog";
import { isEqual } from "lodash";

// THIS COMPONENT EXTENDED ORIGINALLY FROM <UserMenu /> COMPONENT, WE DONT NEED THE SAME FUNCTIONALITY IN WEBAPP, SO I MADE A NEW COMPONENT NOT TO EFFECT THE EXISTING ONE IN FUTURE.
// STYLING IS SAME, SO I USED THE EXISTING STYLING OF <UserMenu />

const GET_AVV_TAX = gql`
  query getAvv($customerId: ID!) {
    getAvv(customerId: $customerId)
  }
`;
export const UserMenuWebApp: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { open: handleCustomerUserDialog } = useUserManagementDialog();
  const { open: openManagePaymentsDialog } = useManagePaymentsDialog();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const customerName = useMemo(() => {
    if (!currentUser.appUser.customer.contact) return undefined;
    return `${currentUser.appUser.customer.contact.first_name} ${currentUser.appUser.customer.contact.last_name}`;
  }, [currentUser.appUser.customer.contact]);
  const { t } = useTranslation(["tax/common"]);

  const [getAvvTax, { loading }] = useLazyQuery(GET_AVV_TAX, {
    onCompleted: (data) => {
      const a = document.createElement("a");
      a.href = data.getAvv;
      a.download = "avv";
      a.click();
    },
    fetchPolicy: "no-cache",
  });

  useLayoutEffect(() => {
    const closeOnOuterClick = (e: MouseEvent): void => {
      if (userMenuRef === null) return;
      if (userMenuRef.current === null) return;
      if (e === null) return;
      if (e.target === null) return;
      const isOuter = !(
        userMenuRef.current === e.target || userMenuRef.current.contains(e.target as Node)
      );

      isOuter && isMenuVisible && setIsMenuVisible(false);
    };

    if (isMenuVisible) {
      window.document.querySelector("body")?.addEventListener("mouseup", closeOnOuterClick);
      return (): void => {
        window.document.querySelector("body")?.removeEventListener("mouseup", closeOnOuterClick);
      };
    }
  }, [isMenuVisible]);
  const handleToggleMenu = (): void => {
    setIsMenuVisible(!isMenuVisible);
  };
  const handleGotoToPayments = (): void => {
    setIsMenuVisible(false);
    openManagePaymentsDialog();
  };
  const handleLogOut = (): void => {
    history.push(EPrivatePaths.LOGOUT);
  };
  const handleAvvDownload = (): void => {
    getAvvTax({ variables: { customerId: currentUser.appUser.customer_id } });
  };
  const handleCustomerUserManagement = (): void => {
    setIsMenuVisible(false);
    handleCustomerUserDialog();
  };

  return (
    <>
      <StyledUserMenu.Wrapper>
        <StyledUserMenu.User ref={userMenuRef}>
          <StyledUserMenu.ClientName onClick={handleToggleMenu}>
            <span>
              {customerName ? (
                customerName
              ) : (
                <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
              )}
            </span>
            <Icon
              size={IconSizeEnums.SMALL}
              name={isMenuVisible ? IconNameEnums.Q_ARROW_DOWN : IconNameEnums.Q_ARROW_DOWN}
            />
          </StyledUserMenu.ClientName>
          <StyledUserMenu.CustomerName>
            {currentUser.appUser.customer?.name ? (
              currentUser.appUser.customer?.name
            ) : (
              <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
            )}
          </StyledUserMenu.CustomerName>

          {isMenuVisible && (
            <FadeIn>
              <StyledUserMenu.ListWrapper>
                <StyledUserMenu.List>
                  <StyledUserMenu.ListItem onClick={handleGotoToPayments}>
                    {t("tax/common:main_content.user_menu.payment")}
                  </StyledUserMenu.ListItem>
                  <StyledUserMenu.ListItem onClick={handleAvvDownload}>
                    {t("tax/common:main_content.user_menu.download_avv")}
                    <IconButton
                      icon={<Icon size={IconSizeEnums.SMALL} name={IconNameEnums.Q_DOWNLOAD} />}
                      inverted={false}
                      variant={ButtonVariantEnums.DEFAULT}
                      type={"button"}
                    />
                  </StyledUserMenu.ListItem>
                  {currentUser.appUser.root === true && (
                    <StyledUserMenu.ListItem onClick={handleCustomerUserManagement}>
                      {t("tax/common:main_content.user_menu.users")}
                    </StyledUserMenu.ListItem>
                  )}
                  <StyledUserMenu.ListItem onClick={handleLogOut}>Abmelden</StyledUserMenu.ListItem>
                </StyledUserMenu.List>
              </StyledUserMenu.ListWrapper>
            </FadeIn>
          )}
        </StyledUserMenu.User>
      </StyledUserMenu.Wrapper>
      {loading && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}
    </>
  );
};
