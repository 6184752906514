import { FC } from "react";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  ErrorBoundary,
  FormRow,
  StyledQWizardForm,
  windowOpen,
  EQCardSectionTypes,
  QCard,
  QCardSection,
} from "@canei/app-components";

import { Trans, useTranslation } from "react-i18next";
import { StyledDownloadApp } from "./styled.download-app";
import { SimpleLayout } from "../../../../_lib/Layout";
import { Logo } from "../../../shared/Logo";
import awsConfig from "../../../../awsConfig";

export const PaymentWarning: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  return (
    <SimpleLayout>
      <ErrorBoundary>
        <StyledDownloadApp.Wrapper>
          <StyledDownloadApp.TopHeaderWrapper>
            <StyledDownloadApp.LogoWrapper>
              <Logo />
            </StyledDownloadApp.LogoWrapper>
          </StyledDownloadApp.TopHeaderWrapper>

          <QCard>
            <QCardSection type={EQCardSectionTypes.DEFAULT}>
              <StyledQWizardForm.Wrapper>
                <StyledQWizardForm.Content>
                  <StyledDownloadApp.DownloadItemsWrapper>
                    <Trans i18nKey="tax/common:main_content.payment.end_of_trial.message">
                      <a rel="noreferrer" href="https://stripe.com/" target="_blank">
                        stripe.com
                      </a>

                      <a
                        rel="noreferrer"
                        href="https://stripe.com/de/guides/general-data-protection-regulation"
                        target="_blank"
                      >
                        privacy
                      </a>
                      <a rel="noreferrer" href="https://stripe.com/de/legal" target="_blank">
                        terms
                      </a>
                    </Trans>
                  </StyledDownloadApp.DownloadItemsWrapper>
                </StyledQWizardForm.Content>
                <StyledQWizardForm.Footer>
                  {/* <FormRow align={AlignmentEnums.CENTER}>
                    <ButtonBranded
                      inverted={false}
                      type={"button"}
                      inline={true}
                      variant={ButtonBrandedVariantEnums.PRIMARY}
                      disabled={false}
                      onClick={(): void => window.location.reload()}
                    >
                      {t("misc.back")}
                    </ButtonBranded>
                  </FormRow> */}
                  <FormRow align={AlignmentEnums.CENTER}>
                    <ButtonBranded
                      inverted={false}
                      type={"button"}
                      inline={true}
                      variant={ButtonBrandedVariantEnums.PRIMARY}
                      disabled={false}
                      onClick={(): void =>
                        windowOpen({ url: awsConfig.registration.NEXT_PAGE, target: "_blank" })
                      }
                    >
                      {t("main_content.payment.payment_warning")}
                    </ButtonBranded>
                  </FormRow>
                </StyledQWizardForm.Footer>
              </StyledQWizardForm.Wrapper>
            </QCardSection>
          </QCard>
        </StyledDownloadApp.Wrapper>
      </ErrorBoundary>
    </SimpleLayout>
  );
};

export default PaymentWarning;
