import { ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { Auth } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../store/reducers/currentUser";
import { useLogin } from "./useLogin";
import { AuthEnums, AuthError } from "@canei/app-components";
import { isEqual } from "lodash";

export interface IChangePasswordParams {
  email: string;
  password: string;
  repeatPassword: string;
  temporaryPassword: string;
}
type IUseChangePassword = () => [
  ({ email, password, repeatPassword, temporaryPassword }: IChangePasswordParams) => void,
  boolean
];
const initialPasswordState = {
  email: "__INITIAL__",
  password: "__INITIAL__",
  repeatPassword: "__INITIAL__",
  temporaryPassword: "__INITIAL__",
  loading: false,
};
export const useChangePassword: IUseChangePassword = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const [login] = useLogin();
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();

  const [params, setParams] = useState(initialPasswordState);
  const changePassCallback = useCallback(
    (isMounted: boolean) => {
      const { email, password, loading, temporaryPassword } = params;
      if (loading || email === "__INITIAL__") return;
      setParams({ ...initialPasswordState, loading: true });
      Auth.signIn(email.toLowerCase(), temporaryPassword)
        .then((cognitoUser) => {
          return Auth.completeNewPassword(cognitoUser, password, null);
        })
        .then(() => {
          isMounted && login({ email, password });
        })
        .catch((e) => {
          const err: AuthError = {
            // __typename: TypeNameEnums.AUTH_ERROR,
            code: e.code,
            name: e.code,
          };

          switch (e.code) {
            case AuthEnums.USER_NOT_AUTHORIZED: {
              // In Change Password process, USER_NOT_AUTHORIZED has only one meaning
              // that given password is not valid. SO convert it to more meaningful error code
              err.code = AuthEnums.CODE_MISMATCH;
              err.name = AuthEnums.CODE_MISMATCH;
            }
          }
          isMounted && setParams({ ...params, loading: false });
          dispatch({ type: UserStateActionTypes.CURRENT_USER, payload: { errors: [err] } });
        });
    },
    [dispatch, params, login]
  );
  useEffect(() => {
    let mounted = true;
    if (params.email === "__INITIAL__" || params.loading) return;
    if (currentUser.errors.length > 0) return;
    changePassCallback(mounted);
    setParams({ ...params, loading: true });
    return (): void => {
      mounted = false;
    };
  }, [changePassCallback, currentUser.errors.length, params]);

  /*** [useChangePassword hook returns a callback and a isReady state tuple] **/
  return [
    ({ email, password, repeatPassword, temporaryPassword }: IChangePasswordParams): void => {
      if (password !== repeatPassword) {
        const err: AuthError = {
          // __typename: TypeNameEnums.AUTH_ERROR,
          code: AuthEnums.PASSWORD_CONFIRM_MISMATCH,
          name: "invalid_password_repeat",
        };

        dispatch({
          type: UserStateActionTypes.CURRENT_USER,
          payload: { email: email, errors: [err] },
        });
        setParams({ ...params, loading: false });
        return;
      }
      setParams({
        loading: false,
        email,
        password,
        repeatPassword,
        temporaryPassword:
          temporaryPassword === "" ? currentUser.temporaryPassword : temporaryPassword,
      });
    },
    params.loading,
  ];
};
