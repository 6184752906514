import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  ButtonVariantEnums,
  CheckBox,
  ECheckboxState,
  Form,
  FormRow,
  Icon,
  IconNameEnums,
  IconSizeEnums,
  IFormState,
  IInputVariantEnums,
  Input,
  InputTypes,
  SlideIn,
  SlideInDirections,
  MonthPicker,
  getMontPickerData,
  toSuSaDate,
  P,
} from "@canei/app-components";

import { StyledSetup } from "../../../../StyledComponents";
import { StyledSelection } from "./styled.setup-tax";
import { MainContext } from "../PrivateMainContext";
import { gql, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { ETaxAppSyncState, ETaxSetupSyncState, ILocalState } from "../../../../@types/store.d";
import { EAppStatusActionType, IAppStatusAction } from "../../../../_lib/store/reducers/appStatus";
import { EClientSelection, useDatevCreateUpload } from "../../../../_lib/hooks/useDatev";
import { EAppSyncLocal, useAppSync } from "../../../../_lib/hooks/useAppSync";
import { useSetDatevMetaData } from "../../../../_lib/hooks";
import { isEqual } from "lodash";

const DATEV_ERROR = gql`
  mutation appErrors($customer_id: ID!) {
    noDatevErrorEvent(customer_id: $customer_id)
  }
`;

export const ClientSelection: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const { customer } = useContext(MainContext);
  const { processDatevClients } = useDatevCreateUpload();
  const [errored, setErrored] = useState(false);
  const [noDatevErrorEvent] = useMutation(DATEV_ERROR);
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const appStatus = useSelector(({ appStatus }: ILocalState) => appStatus, isEqual);
  const { datev, metaData, appSync } = useSelector(
    (state: ILocalState) => state.appStatus,
    isEqual
  );
  const alertsInProgress = useSelector(
    ({ appStatus: { alertsInProgress } }: ILocalState) => alertsInProgress,
    isEqual
  );
  const [allChecked, setAllChecked] = useState(false);
  const [setMeta] = useSetDatevMetaData();
  const { changeAppSync } = useAppSync();

  // move user to dashboard if at least one alert has loaded
  useEffect(() => {
    // if there are no alerts stop
    if (!Object.keys(alertsInProgress).length) return;

    // // is one of alerts loaded?
    // const alertLoaded = Object.values(alertsInProgress).some(
    //   (alert) => alert.progress === EClientSelection.LOADED
    // );

    const allDone = Object.keys(alertsInProgress).every(
      (key) =>
        alertsInProgress[key].progress === EClientSelection.LOADED ||
        alertsInProgress[key].progress === EClientSelection.NO_SUSAS ||
        alertsInProgress[key].progress === EClientSelection.INCOMPATIBLE ||
        alertsInProgress[key].progress === EClientSelection.DATAPATH_INVALID ||
        alertsInProgress[key].progress === EClientSelection.TIME_OUT ||
        alertsInProgress[key].progress === EClientSelection.ERRORED
    );

    // if at least one alert is loaded from useGetAlertsOfClient() update appSync
    allDone &&
      changeAppSync({
        ready: true,
        appSyncState: ETaxAppSyncState.DASHBOARD,
        setupSyncState: ETaxSetupSyncState.DONE,
        local: EAppSyncLocal.REMOVE,
      });
  }, [alertsInProgress, appSync, changeAppSync, datev, metaData]);

  // defines if contact admin message will be displayed
  const noClientList = useMemo<boolean>(() => {
    if (metaData && metaData.datev_clients && metaData.datev_clients.length) {
      return false;
    }
    return true;
  }, [metaData]);

  // disables selection and buttons if loading, enables on error and loading done
  const disableSelection = useMemo(() => {
    if (!Object.keys(alertsInProgress)) return false;
    const allDone = Object.keys(alertsInProgress).every(
      (key) =>
        alertsInProgress[key].progress === EClientSelection.LOADED ||
        alertsInProgress[key].progress === EClientSelection.NO_SUSAS ||
        alertsInProgress[key].progress === EClientSelection.INCOMPATIBLE ||
        alertsInProgress[key].progress === EClientSelection.DATAPATH_INVALID ||
        alertsInProgress[key].progress === EClientSelection.TIME_OUT
    );
    if (errored) return false;
    if (allDone) return false;
    return true;
  }, [alertsInProgress, errored]);

  const makeErrored = (): void => {
    if (errored) return;
    setErrored(true);
  };

  // dispatches Datev error event
  useEffect(() => {
    if (!errored) return;
    noDatevErrorEvent({ variables: { customer_id: customer.customer_id } });
  }, [customer.customer_id, errored, noDatevErrorEvent]);

  // handle month selection change
  const handleSelectionChange = (form: IFormState): void => {
    const monthPickerData = getMontPickerData("taxSuSaDate", form);
    const { value } = monthPickerData;
    if (
      (!currentUser.appUser.root && !metaData?.datev_clients) ||
      (metaData?.datev_clients && !metaData.datev_clients.length)
    )
      return;

    if (datev.selected_date === undefined || value !== datev.selected_date) {
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: { ...datev, selected_date: value },
        },
      });
    }
  };

  // handle client selection via checkboxes
  const handleSelection = (name: string | undefined): void => {
    // if name is missing stop
    if (!name) return;

    // if select all is checked dispatch all clients to appStatus.selected_clients and change state of allChecked to true
    if (name === "selectAll" && !allChecked) {
      const selectedClients = datev.datev_clients && datev.datev_clients.map((client) => client.id);
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: { ...datev, selected_clients: selectedClients },
        },
      });
      setAllChecked(true);
      return;
    }

    // if select all is checked and allChecked is true, dispatch empty array to appStatus.selected_clients and change state of allChecked to false
    else if (name === "selectAll" && allChecked) {
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: { ...datev, selected_clients: [] },
        },
      });
      setAllChecked(false);
      return;
    }

    // single client selection
    else {
      const alreadySelected = datev.selected_clients.includes(name);
      if (alreadySelected) {
        const selectedClients = datev.selected_clients.filter((client) => client !== name);
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_DATEV,
          payload: {
            datev: { ...datev, selected_clients: [...selectedClients] },
          },
        });
        setAllChecked(false);
      } else {
        const selectedClients = [...datev.selected_clients, name];
        const allChecked = selectedClients.length === datev.datev_clients.length;
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_DATEV,
          payload: {
            datev: { ...datev, selected_clients: [...selectedClients] },
          },
        });
        if (allChecked) setAllChecked(true);
      }
    }
  };

  // starts the proccess of client creation, susa upload and alerts fetching
  const handleStartAnalyse = (): void => {
    if (!currentUser.appUser.customer_id) return;
    if (errored) setErrored(false);

    // dispatch clients in process to redux and reset alerts and clients
    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_STATUS,
      payload: {
        ...appStatus,
        alerts: [],
        metaData: {
          ...metaData,
          date: datev?.selected_date,
          clients: [...datev.selected_clients],
        },
        alertsInProgress: {},
      },
    });

    // reset Evens State
    dispatchAppStatus({
      type: EAppStatusActionType.RESET_EVENTS_STATE,
      payload: {},
    });

    // dispatch clients to account metadata
    setMeta({
      variables: {
        data: {
          account_id: currentUser.appUser.user_id,
          date: datev.selected_date,
          clients: [...datev.selected_clients],
        },
      },
    });

    // start client processing
    processDatevClients(datev.selected_clients);
  };

  // searchbar handler, save filtered clients to appStatus.filtered_clients
  const handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const filteredClients = datev.datev_clients.filter((client) => {
      return client.name.toLowerCase().includes(value.toLowerCase());
    });

    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_DATEV,
      payload: {
        datev: {
          ...datev,
          filtered_clients: filteredClients,
        },
      },
    });
  };

  // reset filtered_clients to appStatus.datev_clients if there is no search value (LOOP!!)
  useEffect(() => {
    if (noClientList) return;

    if (!datev.filtered_clients || datev.filtered_clients.length === 0) {
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: {
            ...datev,
            filtered_clients: datev.datev_clients,
          },
        },
      });
    }
  }, [dispatchAppStatus, datev, noClientList, metaData?.datev_clients, metaData]);

  return (
    <Form onSubmitHandler={handleStartAnalyse} subscribe={handleSelectionChange}>
      <StyledSetup.SetupContent.Wrapper>
        <StyledSetup.SetupContent.Caption>
          {t("initial_setup.tax.clients_selection.caption_two")}
        </StyledSetup.SetupContent.Caption>
        <SlideIn direction={SlideInDirections.TO_LEFT}>
          <StyledSetup.SetupContent.Body>
            <StyledSetup.SetupContent.InformationTextBold>
              <Trans i18nKey={"tax/common:initial_setup.tax.clients_selection.title_text_one"} />
            </StyledSetup.SetupContent.InformationTextBold>
            <StyledSelection.Month>
              <MonthPicker
                data={[]}
                name={"taxSuSaDate"}
                disabled={false}
                initialValue={undefined}
                to={toSuSaDate()}
              />
            </StyledSelection.Month>
            <StyledSetup.SetupContent.InformationTextBold>
              <Trans i18nKey={"tax/common:initial_setup.tax.clients_selection.title_text_two"} />
            </StyledSetup.SetupContent.InformationTextBold>
            <StyledSetup.SetupContent.InformationText>
              <Trans i18nKey={"tax/common:initial_setup.tax.clients_selection.text_one"} />
            </StyledSetup.SetupContent.InformationText>
            <StyledSetup.SetupContent.SearchBar>
              <FormRow align={AlignmentEnums.SPACE_BETWEEN}>
                <CheckBox
                  inverted={false}
                  variant={ButtonVariantEnums.DEFAULT}
                  name={"selectAll"}
                  disabled={disableSelection}
                  checked={allChecked ? ECheckboxState.ON : ECheckboxState.OFF}
                  // setting key allows the component to reset on state change
                  key={Math.random()}
                  onMouseClick={handleSelection}
                ></CheckBox>
                <Icon size={IconSizeEnums.SMALL} name={IconNameEnums.Q_SEARCH} />
                <Input
                  type={InputTypes.TEXT}
                  variant={IInputVariantEnums.DEFAULT}
                  name={"Suche"}
                  value={""}
                  placeholder="Suche"
                  branded
                  onChange={handleSearchBarChange}
                />
              </FormRow>
            </StyledSetup.SetupContent.SearchBar>
            {noClientList && (
              <StyledSelection.NoClientsWrapper>
                <Icon size={IconSizeEnums.LARGE} name={IconNameEnums.ATTENTION_2} />
                <P>{t("initial_setup.tax.clients_selection.no_clients")}</P>
              </StyledSelection.NoClientsWrapper>
            )}
            <StyledSelection.ClientListWrapper>
              {datev.filtered_clients &&
                datev.filtered_clients.map((client) => {
                  if (!client) return null;
                  const state =
                    alertsInProgress &&
                    alertsInProgress[client.id] &&
                    alertsInProgress[client.id].progress;

                  if (state === "ERRORED") makeErrored();

                  // get icon from state if there is any
                  const icon = alertsInProgress[client.id]?.icon;

                  // if client is in appStatus.selected_clients we show it as selected
                  const checked = datev.selected_clients.some((item) => {
                    return item === client?.id;
                  });

                  return (
                    <FormRow align={AlignmentEnums.SPACE_BETWEEN} key={Math.random()}>
                      <CheckBox
                        inverted={false}
                        variant={ButtonVariantEnums.DEFAULT}
                        name={client.id}
                        disabled={disableSelection}
                        checked={checked ? ECheckboxState.ON : ECheckboxState.OFF}
                        onMouseClick={handleSelection}
                      >
                        <StyledSelection.ClientName>
                          {client?.name}
                          <StyledSelection.ClientCode>{client?.number}</StyledSelection.ClientCode>
                        </StyledSelection.ClientName>
                      </CheckBox>
                      {icon && (
                        <StyledSelection.ClientUploadState state={state}>
                          <Icon name={icon} size={IconSizeEnums.SMALL} />
                        </StyledSelection.ClientUploadState>
                      )}
                    </FormRow>
                  );
                })}
            </StyledSelection.ClientListWrapper>

            <FormRow align={AlignmentEnums.CENTER}>
              <ButtonBranded
                type={"submit"}
                variant={ButtonBrandedVariantEnums.PRIMARY}
                inverted={false}
                inline
                disabled={
                  datev.selected_clients.length === 0 ||
                  datev.selected_date === "" ||
                  disableSelection
                }
              >
                {t("initial_setup.tax.clients_selection.button_text")}
              </ButtonBranded>
            </FormRow>
            {errored && (
              <StyledSetup.SetupContent.ErrorText>
                <Trans
                  i18nKey={"tax/common:initial_setup.tax.clients_selection.internet_problems"}
                />
              </StyledSetup.SetupContent.ErrorText>
            )}
          </StyledSetup.SetupContent.Body>
        </SlideIn>
      </StyledSetup.SetupContent.Wrapper>
    </Form>
  );
};
