import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import stripUrlAuth from "strip-url-auth";
import { useTranslation } from "react-i18next";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Form,
  FormRow,
  IDialogKeyEnums,
  IDialogOptions,
  Input,
  InputTypes,
  StyledQWizardForm,
  useDialogs,
} from "@canei/app-components";
import { useSelector } from "react-redux";
import { ETaxSetupSyncState, ETaxAppSyncState, ILocalState } from "../../../../@types/store.d";
import { useSetDatevMetaData } from "../../../hooks/useMetaData";
import { DatevConnectClient } from "@canei/datev-connect-client";
import { StyledSettings } from "./styled.settings";
import { EAppSyncLocal, useAppSync } from "../../../hooks/useAppSync";
import { isEqual } from "lodash";

const Content: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dialogs = useDialogs();
  const { t } = useTranslation(["quick/common"]);
  const [setMeta] = useSetDatevMetaData();
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const { changeAppSync } = useAppSync();

  const [datevUrlInput, setDatevUrlInput] = useState(metaData?.datev_url || "");

  const changeDatev = (): void => {
    const datevUrl = new URL(datevUrlInput);
    let strippedUrl = stripUrlAuth(datevUrl.href);
    if (strippedUrl.slice(-1) === "/") strippedUrl = strippedUrl.slice(0, -1);
    const datevConnectClient = new DatevConnectClient(
      strippedUrl,
      datevUrl.username === "" ? null : datevUrl.username.replace(/\+/g, " ").trim(),
      datevUrl.password === "" ? null : datevUrl.password
    );
    datevConnectClient
      ?.loadClients()
      .then((data) => {
        return Promise.all([
          data,
          setMeta({
            variables: {
              data: {
                account_id: currentUser.attributes.customer_id as string,
                datev_url: datevUrlInput,
              },
            },
          }),
        ]);
      })
      .then(() => {
        dialogs.close(IDialogKeyEnums.QUICK_EDIT_PROFILE);

        window.location.reload();
      })
      .catch((err: string) => {
        return;
      });
  };
  const handleDatevUrlChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setDatevUrlInput(e.target.value);
  };

  const changeThresholds = (): void => {
    changeAppSync({
      ready: false,
      appSyncState: ETaxAppSyncState.DASHBOARD,
      setupSyncState: ETaxSetupSyncState.THRESHOLD,
      local: EAppSyncLocal.UPDATE,
    });
    dialogs.close(IDialogKeyEnums.QUICK_EDIT_PROFILE);
  };
  useEffect(() => {
    if (metaData?.datev_url) {
      setDatevUrlInput(metaData?.datev_url);
    }
  }, [metaData?.datev_url]);

  return (
    <StyledSettings.Wrapper>
      <Form onSubmitHandler={changeDatev} customStyle={{ display: "unset" }}>
        <FormRow align={AlignmentEnums.CENTER}>
          <Input
            type={InputTypes.TEXT}
            name={"datev_url"}
            value={datevUrlInput}
            branded={true}
            label={t("tax/common:main_content.user_menu.settings.change_url")}
            onChange={handleDatevUrlChange}
          />
          <ButtonBranded
            inverted={false}
            type={"submit"}
            inline
            variant={ButtonBrandedVariantEnums.PRIMARY}
            disabled={false}
          >
            {t("misc.save")}
          </ButtonBranded>
        </FormRow>
      </Form>
      <Form onSubmitHandler={changeThresholds} customStyle={{ display: "unset" }}>
        <FormRow align={AlignmentEnums.CENTER}>
          <StyledQWizardForm.FormWrapper columnCount={2}>
            <Input
              type={InputTypes.TEXT}
              name={"datev_url"}
              value={""}
              placeholder={metaData?.thresholds?.considerable}
              branded={true}
              label={t("tax/common:initial_setup.tax.threshold.considerable")}
              disabled={true}
            />
            <Input
              type={InputTypes.TEXT}
              name={"datev_url"}
              value={""}
              placeholder={metaData?.thresholds?.critical}
              branded={true}
              label={t("tax/common:initial_setup.tax.threshold.critical")}
              disabled={true}
            />
          </StyledQWizardForm.FormWrapper>

          <ButtonBranded
            inverted={false}
            type={"submit"}
            inline
            variant={ButtonBrandedVariantEnums.PRIMARY}
            disabled={false}
          >
            {t("tax/common:main_content.user_menu.settings.threshold_button")}
          </ButtonBranded>
        </FormRow>
      </Form>
    </StyledSettings.Wrapper>
  );
};

export type TuseEditSettingsDialog = () => IuseEditSettingsDialogRetVal;
export interface IuseEditSettingsDialogRetVal {
  open: () => void;
}

export const useEditSettingsDialog: TuseEditSettingsDialog = () => {
  const { t } = useTranslation(["quick/common"]);
  const dialogs = useDialogs();
  const options = useMemo((): IDialogOptions<Partial<TuseEditSettingsDialog>> => {
    return {
      dialogKey: IDialogKeyEnums.QUICK_EDIT_PROFILE,
      title: `${t("tax/common:main_content.user_menu.settings.user_settings")}`,
      closable: true,
      content: <Content />,
      flexible: false,
    };
  }, [t]);
  return {
    open: (): void => {
      dialogs.open(options);
    },
  };
};
