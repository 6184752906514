import { DatevConnectClient, IDatevClient } from "@canei/datev-connect-client";
import { Client, Customer } from "@canei/app-components";

export interface ISetupContext {
  pending: ISetupPending | undefined;
}

export interface ISetupPending {
  clients: string[];
  tries: number;
  interval: number;
}

export interface IDashboardMenuItem {
  value: EMenuItem;
}

export enum EMenuItem {
  TOTAL_ANALYSE = "TOTAL_ANALYSE",
  VIEW_SETUP = "VIEW_SETUP",
}

export interface IAppContext {
  menu: IDashboardMenuItem[];
}

export interface IMainContextProps {
  setupDone: boolean;
}

export interface IMainContext {
  customer: TMainContextCustomer; // CRM Customer that is logged in
  appContext: IAppContext; // App settings (nav etc)
  setupContext: ISetupContext; // Only used in SetupTax/ and ViewSetup (-> (Re-)upload clients process)
  datevConnector: DatevConnectClient | undefined; // DatevConnectClient for Datev connection
  clients?: TMainContextClient[]; // All clients the customer has in backend
  datevClients?: IDatevClient[]; // All clients from datev
}
export type TMainContextCustomer = Partial<Pick<Customer, "name" | "contact" | "customer_id">>;
export type TMainContextClient = Client;
export interface IMainContextAction {
  type: TMainContextActionType;
  payload: IMainContextActionPayload;
}
export enum TMainContextActionType {
  SET_CUSTOMER = "SET_CUSTOMER",
  SET_THRESHOLD = "SET_THRESHOLD",
  SET_SETUP = "SET_SETUP",
  SET_PENDING = "SET_PENDING",
  SET_CONNECTOR = "SET_CONNECTOR",
  SET_APP_DATA = "SET_APP_DATA",
}

export interface IMainContextActionPayload {
  app?: Partial<IAppContext>;
  setup?: Partial<ISetupContext>;
  connector?: DatevConnectClient;
  customer?: Partial<TMainContextCustomer>;
  clients?: TMainContextClient[];
  datev_clients?: IDatevClient[];
}
