import { createContext, Dispatch, FC, Reducer, useMemo, useReducer } from "react";

import { SetupBBMenu } from "./SetupBBMenu";
import { SetupLayout } from "../../../../_lib/Layout";
import { EProductEnums } from "../../../../_lib/Layout/types.layout";
import { SystemSetup } from "./SystemSetup";
import { TimeSpan } from "./TimeSpan";
import { ClientSetup } from "./CleintSetup";
import { SyncDone } from "./SyncDone";
import { Sync } from "./Sync";

export interface ISetupBBContext {
  step: ISetupBBStep;
  api_key?: string;
  api_token?: string;
  datev_url?: string;
  clients: IClientSetting[];
}

export interface IClientSetting {
  client_id: string;
  internal_id: string;
  from?: string;
  to?: string;
}
export interface ISetupBBAction {
  type: ISetupBBActionType;
  payload: Partial<ISetupBBContext>;
}
export enum ISetupBBActionType {
  SET_DATA = "SET_DATA",
}
export enum ISetupBBStep {
  SYSTEM_SETUP = "SYSTEM_SETUP",
  CLIENT_SETUP = "CLIENT_SETUP",
  TIME_SPAN = "TIME_SPAN",
  IN_SYNC = "IN_SYNC",
  SYNC_DONE = "SYNC_DONE",
}

const initialContext: ISetupBBContext = {
  step: ISetupBBStep.SYSTEM_SETUP,
  clients: [],
};
export const SetupBBContext = createContext<ISetupBBContext>(initialContext);
export const SetupBBDispatch = createContext<Dispatch<ISetupBBAction>>((state) => state);

const reducerFnSetupBBContext = (
  state: ISetupBBContext,
  action: ISetupBBAction
): ISetupBBContext => {
  switch (action.type) {
    case ISetupBBActionType.SET_DATA:
      return {
        ...state,
        ...action.payload,
      };
  }
};

export const SetupBB: FC = () => {
  const [context, dispatch] = useReducer<Reducer<ISetupBBContext, ISetupBBAction>>(
    reducerFnSetupBBContext,
    {
      ...initialContext,
      step: ISetupBBStep.IN_SYNC,
    }
  );
  const SetupBBContent = useMemo(() => {
    switch (context.step) {
      case ISetupBBStep.SYSTEM_SETUP:
        return SystemSetup;
      case ISetupBBStep.CLIENT_SETUP:
        return ClientSetup;
      case ISetupBBStep.TIME_SPAN:
        return TimeSpan;
      case ISetupBBStep.IN_SYNC:
        return Sync;
      case ISetupBBStep.SYNC_DONE:
        return SyncDone;
    }
  }, [context.step]);
  return (
    <SetupBBDispatch.Provider value={dispatch}>
      <SetupBBContext.Provider value={context}>
        <SetupLayout
          Content={SetupBBContent}
          Menu={SetupBBMenu}
          product={EProductEnums.BETTER_BUSINESS}
        />
      </SetupBBContext.Provider>
    </SetupBBDispatch.Provider>
  );
};
