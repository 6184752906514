import { gql, LazyQueryHookOptions, QueryTuple, useLazyQuery } from "@apollo/client";
import { IGetAlertsResults, IGetSelectedAlertsVars } from "./types.alerting";

export const GET_SELECTED_ALERTS = gql`
  query getClientAlerts($client_ids: [ID]!, $from: String, $to: String) {
    results: getAlertsOfClients(client_ids: $client_ids, from: $from, to: $to) {
      client_id
      months {
        date
        compare {
          alert_id
          prev_month
          prev_year_avg_month
          prev_year_cumulated
          critical
          not_applicable
          details {
            alert_detail_id
            value
            value_cumulated
            prev_month_value
            prev_year_avg_month_value
            prev_year_cumulated_value
          }
        }
      }
    }
  }
`;

export const useGetAlertsOfClients = (
  options?: LazyQueryHookOptions<IGetAlertsResults, IGetSelectedAlertsVars>
): QueryTuple<IGetAlertsResults, IGetSelectedAlertsVars> => {
  return useLazyQuery<IGetAlertsResults, IGetSelectedAlertsVars>(GET_SELECTED_ALERTS, options);
};
