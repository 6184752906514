export enum EPrivatePaths {
  HOME = "/",
  LOGOUT = "/logout",
  DASHBOARD = "/dashboard",
  TOTAL = "/#total",
  EDIT_VIEW = "/#edit-view",
  PAYMENT = "/payment",
  // DATA_SYNC = "/#data-sync",
  // UPGRADE = "/#upgrade",
}
export enum EPublicPaths {
  HOME = "/",
  RESET = "/reset",
  LOGIN = "/login",
  FORGOT = "/forgot",
  REGISTER = "/register",
  REGISTER_SUCCESS = "/register-success",
  CHANGE_PASSWORD = "/change-password",
  BETTER_BUSINESS = "/better-business",
}
