export interface IRegistrationFormFields {
  post: string;
  data: IRegistrationFormData;
  settings: IRegistrationFormSettings;
}
export interface IRegistrationFormData {
  _company?: string;
  _firstname?: string;
  _lastname?: string;
  _street?: string;
  _postalcode?: string;
  _city?: string;
  _email?: string;
  _telephone?: string;
  _category?: string;
  _mapping?: string;
  _legal?: string;
  _coupon?: string;
}
export interface IRegistrationFormSettings {
  _product: string;
  _pricing: string;
  _next: string;
  _type: "signup";
  _language: "DE";
  _covid: string;
  _utm_campaign: string;
  _utm_source: string;
  _utm_medium: string;
  _utm_content: string;
  _utm_term: string;
  _ad_group_id: string;
  _gcl_id: string;
  _lpage: string;
}

export const registrationForm = (formFields: IRegistrationFormFields): HTMLFormElement => {
  const form = document.createElement("form");
  form.action = formFields.post;
  form.method = "post";
  form.style.display = "none";

  Object.entries(formFields.data).forEach(([name, value]) => {
    const el = document.createElement("input");
    el.type = "text";
    el.name = name;
    el.value = value;
    form.appendChild(el);
  });

  Object.entries(formFields.settings).forEach(([name, value]) => {
    const el = document.createElement("input");
    el.type = "hidden";
    el.name = name;
    el.value = value;
    form.appendChild(el);
  });
  document.getElementsByTagName("body")[0].appendChild(form);
  return form;
};
