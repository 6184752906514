import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../@types";
import { EAppStatusActionType, IAppStatusAction } from "../../store/reducers/appStatus";
import { GET_CACHED_EVENT_STATE } from "./eventListener-queries";
import {
  EventListenerCodes,
  IEventListenerQueryResult,
  IStartEventListenerInput,
} from "./types.event-listener";
import { isEqual } from "lodash";

interface IUseEventListenerRetVal {
  startEventSync: (params: IStartEventListenerInput) => void;
}

export const useEventListener = (): IUseEventListenerRetVal => {
  const apolloClient = useApolloClient();
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const eventsState = useSelector(
    ({ appStatus: { eventsState } }: ILocalState) => eventsState,
    isEqual
  );

  // event listener function
  const startEventSync = useCallback(
    (params: IStartEventListenerInput) => {
      // if there are no params in the array stop
      if (!params) return;

      // if there is already an event listener running stop
      if (eventsState && eventsState[params.transactionId]) return;

      // if there is no event state yet create one
      if (!eventsState) {
        // dispatch ALERTS_LOADING to alertsInProgress redux state
        dispatchAppStatus({
          type: EAppStatusActionType.UPDATE_EVENTS_STATE,
          payload: {
            [params.transactionId]: {
              transactionId: params.transactionId,
              accountId: params.accountId,
              status: EventListenerCodes.UNKNOWN,
              timeOutTimer: 60,
            },
          },
        });
      }

      // if there is event state add the new event
      else {
        dispatchAppStatus({
          type: EAppStatusActionType.UPDATE_EVENTS_STATE,
          payload: {
            [params.transactionId]: {
              transactionId: params.transactionId,
              accountId: params.accountId,
              status: EventListenerCodes.UNKNOWN,
              timeOutTimer: 60,
            },
          },
        });
      }

      // initialize The interval for event sync in seconds
      const interval = 5;

      // initialize the timeout timer in seconds
      let timeOutTimer = 60; // in seconds

      // initialize the event sync interval using the interval and the getEventState function
      const eventSync = setInterval(getEventState, interval * 1000);

      // try getting the event state from cached events in backend
      function getEventState(): void {
        // if timeOutTimer is less or equal to 0 stop and dispatch as timeout
        if (timeOutTimer <= 0) {
          dispatchAppStatus({
            type: EAppStatusActionType.UPDATE_EVENTS_STATE,
            payload: {
              [params.transactionId]: {
                transactionId: params.transactionId,
                accountId: params.accountId,
                status: EventListenerCodes.TIME_OUT,
                timeOutTimer: 0,
              },
            },
          });
          clearInterval(eventSync);
          return;
        }
        // reduce the timeOutTimer by interval
        timeOutTimer -= interval;

        // call backend for event cache
        apolloClient
          .query<IEventListenerQueryResult>({
            query: GET_CACHED_EVENT_STATE,
            variables: {
              ...params,
            },
            fetchPolicy: "no-cache",
          })
          .then(({ data }) => {
            // if ther is a response save it to eventsState
            if (data?.event && data?.event?.status) {
              const { status } = data?.event;
              dispatchAppStatus({
                type: EAppStatusActionType.UPDATE_EVENTS_STATE,
                payload: {
                  [params.transactionId]: {
                    transactionId: params.transactionId,
                    accountId: params.accountId,
                    status: status,
                    timeOutTimer: timeOutTimer,
                  },
                },
              });

              // if the  status is "000" clear interval and dispatch as success
              if (status === EventListenerCodes.TRANSACTION_OK) {
                timeOutTimer = 0;
                clearInterval(eventSync);
              }

              // if the status is not initial or success make errored and clear interval
              if (
                status !== EventListenerCodes.TRANSACTION_OK &&
                status !== EventListenerCodes.UNKNOWN
              ) {
                dispatchAppStatus({
                  type: EAppStatusActionType.UPDATE_EVENTS_STATE,
                  payload: {
                    [params.transactionId]: {
                      transactionId: params.transactionId,
                      accountId: params.accountId,
                      status: EventListenerCodes.ERROR,
                      timeOutTimer: 0,
                    },
                  },
                });
                timeOutTimer = 0;
                clearInterval(eventSync);
              }
            }
          })
          .catch((err) => {
            dispatchAppStatus({
              type: EAppStatusActionType.UPDATE_EVENTS_STATE,
              payload: {
                [params.transactionId]: {
                  transactionId: params.transactionId,
                  accountId: params.accountId,
                  status: EventListenerCodes.ERROR,
                  timeOutTimer: 0,
                },
              },
            });
          });
      }
    },
    [apolloClient, dispatchAppStatus, eventsState]
  );

  return {
    startEventSync,
  };
};
