import styled, { css } from "styled-components/macro";

const Wrapper = styled.section(() => {
  return css`
    display: flex;
    height: 100vh;
  `;
});

const StepsMenu = styled.section(({ theme }) => {
  const { _100 } = theme.values.colors.global.secondary;
  return css`
    background-color: ${_100};
    padding: ${theme.rem(4)};
    flex-basis: min-content;
  `;
});

const StepContent = styled.section(() => {
  return css`
    flex: 1;
    overflow-y: auto;
  `;
});

export const StyledSetupLayout = { Wrapper, StepContent, StepsMenu };
