// THIS FILE MAY BE USEFUL FOR THE FUTURE ( COPIED FROM QUICK )

import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

// interface IImageWrapperProps {
//   imageUrl: string;
// }
// // const Wrapper = styled(StyledQTile.ContentWrapper)(({ theme, inverted = false }) => {
// //   const { _000: backgroundColor } = theme.values.colors.global.greys;
// //   const { _100: textColor } = theme.values.colors.global.secondary;
// //   return useResponsive({
// //     MINI: {
// //       style: css`
// //         grid-area: offer;
// //         display: flex;
// //         grid-column-gap: ${theme.rem(1)};
// //         background-color: ${inverted ? textColor : backgroundColor};
// //         color: ${inverted ? backgroundColor : textColor};
// //         flex-wrap: wrap;
// //       `,
// //     },
// //     XLARGE: {
// //       style: css`
// //         flex-wrap: nowrap;
// //       `,
// //     },
// //   });
// // });

// const Image = styled.div<IImageWrapperProps>(({ theme, imageUrl }) => {
//   return useResponsive({
//     MINI: {
//       style: css`
//         flex-basis: 100%;
//         height: ${theme.rem(24)};
//         background: url(${imageUrl}) no-repeat center center;
//         background-size: cover;
//       `,
//     },
//     XLARGE: {
//       style: css`
//         flex-basis: 50%;
//       `,
//     },
//   });
// });

// const Content = styled.div(({ theme }) => {
//   return useResponsive({
//     MINI: {
//       style: css`
//         align-self: center;
//         flex-basis: 100%;
//         justify-content: center;
//         align-items: center;
//         padding: ${theme.rem(2)};

//         > * {
//           text-align: center;
//         }
//       `,
//     },
//     XLARGE: {
//       style: css`
//         flex-basis: 50%;
//       `,
//     },
//   });
// });

// const ContentTitle = styled.h2(({ theme }) => {
//   return css`
//     font-size: ${theme.rem(1.4)};
//     line-height: ${theme.rem(1.8)};
//     font-weight: ${theme.values.fontWeight.bold};
//   `;
// });

// export const StyledOffer = { Image, Content, ContentTitle };
interface IImageWrapperProps {
  imageUrl: string;
}

const LogoContainer = styled.div(({ theme }) => {
  return css`
    width: ${theme.rem(24)};
    img {
      display: block;
      max-width: 60%;
      cursor: pointer;
    }
  `;
});

const Image = styled.div<IImageWrapperProps>(({ theme, imageUrl }) => {
  return useResponsive({
    MINI: {
      style: css`
        flex-basis: 100%;
        height: ${theme.rem(24)};
        background: url(${imageUrl}) no-repeat center center;
        background-size: cover;
      `,
    },
    XLARGE: {
      style: css`
        flex-basis: 50%;
      `,
    },
  });
});

const Content = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        align-self: center;
        flex-basis: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 ${theme.rem(2)} 0 ${theme.rem(2)};
        > :not(:last-child) {
          margin-bottom: ${theme.rem(2)};
        }
      `,
    },
    XLARGE: {
      style: css`
        flex-basis: 50%;
      `,
    },
  });
});

export const StyledOffer = {
  LogoContainer,
  Image,
  Content,
};
