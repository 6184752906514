import { IDialogKeyEnums, useDialogs } from "@canei/app-components";
import { FC, useEffect, useMemo } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../../@types";
import config from "../../../../config";
import { EClientSelection } from "../../../../_lib/hooks/useDatev";
import { EAppStatusActionType, IAppStatusAction } from "../../../../_lib/store/reducers/appStatus";
// import { ILocalState } from "../../../../@types";
import { StyledWarningDialog } from "./styled.warning-dialog";
import { isEqual } from "lodash";

export const Content: FC = () => {
  const { datev, warningDialog } = useSelector((state: ILocalState) => state.appStatus, isEqual);
  const alertsInProgress = useSelector(
    ({ appStatus: { alertsInProgress } }: ILocalState) => alertsInProgress,
    isEqual
  );
  const dialogs = useDialogs();
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();

  // memo for text data
  const status = useMemo(() => {
    const limit = config.warningLimiter;
    const total = datev.selected_clients.length;
    const done = Object.keys(alertsInProgress).filter(
      (alert) =>
        alertsInProgress[alert].progress === EClientSelection.ERRORED ||
        alertsInProgress[alert].progress === EClientSelection.TIME_OUT ||
        alertsInProgress[alert].progress === EClientSelection.LOADED ||
        alertsInProgress[alert].progress === EClientSelection.NO_SUSAS ||
        alertsInProgress[alert].progress === EClientSelection.INCOMPATIBLE ||
        alertsInProgress[alert].progress === EClientSelection.DATAPATH_INVALID
    ).length;

    return {
      limit,
      total,
      done,
    };
  }, [alertsInProgress, datev.selected_clients.length]);

  // change state for warningDialog to false when all clients are loaded
  useEffect(() => {
    if (status.total !== status.done) return;
    dispatchAppStatus({
      type: EAppStatusActionType.TOGGLE_WARNING_DIALOG,
      payload: {
        warningDialog: false,
      },
    });
  }, [dispatchAppStatus, status.done, status.total]);

  // close dialog when if warningDialog state changes
  useEffect(() => {
    if (warningDialog) return;
    dialogs.close(IDialogKeyEnums.BASIC);
  }, [dialogs, warningDialog]);

  return (
    <StyledWarningDialog.Wrapper>
      <StyledWarningDialog.TextWrapper>
        <Trans
          i18nKey="tax/common:warning_dialog.text_1"
          values={{
            limit: status.limit,
          }}
        >
          <StyledWarningDialog.Minutes />
        </Trans>
      </StyledWarningDialog.TextWrapper>
      <StyledWarningDialog.TextWrapper bold>
        <Trans
          i18nKey="tax/common:warning_dialog.text_2"
          values={{
            done: status.done,
            total: status.total,
          }}
        >
          <StyledWarningDialog.Minutes />
        </Trans>
      </StyledWarningDialog.TextWrapper>
      <StyledWarningDialog.TextWrapper>
        <Trans
          i18nKey="tax/common:warning_dialog.text_3"
          values={{
            total: status.total,
          }}
        >
          <StyledWarningDialog.Minutes />
        </Trans>
      </StyledWarningDialog.TextWrapper>
    </StyledWarningDialog.Wrapper>
  );
};

interface useNumberOfClientsWarningDialogVal {
  open: () => void;
}

export const useNumberOfClientsWarningDialog = (): useNumberOfClientsWarningDialogVal => {
  const dialogs = useDialogs();

  return {
    open: (): void => {
      dialogs.open({
        dialogKey: IDialogKeyEnums.BASIC,
        closable: false,
        content: <Content />,
        flexible: false,
      });
    },
  };
};
