import {
  Cell,
  EAlarmLevel,
  EFormatCurrencyDivision,
  H4,
  Icon,
  IconNameEnums,
  IconSizeEnums,
  LoaderTypeEnums,
  LoadingIndicator,
  Row,
  Table,
  TriStateAlarm,
  useFormatNumber,
} from "@canei/app-components";
import { FC } from "react";
import { StyledDetailsDialog } from "./styled.DetailsDialog";
import { Trans, useTranslation } from "react-i18next";
import { StyledTile } from "../../../../../StyledComponents";
import { IClientAlert } from "../../../../../_lib/hooks";
import { StyledTotalAnalyze } from "./styled.total-analyze";
import { IDatevClient } from "@canei/datev-connect-client/@types";
import { Offer } from "../Offer";
import { useSelector } from "react-redux";
import { ClientTax, ILocalState } from "../../../../../@types";
import { isEqual } from "lodash";

interface IProps {
  alerts: IClientAlert[] | undefined;
  idOfSelectedClient: string;
  alertsLoading: boolean;
  clients: ClientTax[] | undefined;
  datevClients: IDatevClient[] | undefined;
}

const ContactDialog: FC<IProps> = ({
  alerts,
  idOfSelectedClient,
  alertsLoading,
  clients,
  datevClients,
}) => {
  const { formatNumber } = useFormatNumber();
  const { t } = useTranslation(["tax/common"]);
  const alertsInProgress = useSelector(
    ({ appStatus: { alertsInProgress } }: ILocalState) => alertsInProgress,
    isEqual
  );
  const selectedClientsAlerts = alertsInProgress[idOfSelectedClient];

  return (
    <>
      <StyledDetailsDialog.Header>
        <StyledDetailsDialog.DialogHeadColumn>
          <StyledTile.HeadFirstItem>
            <H4>{selectedClientsAlerts?.name}</H4>
          </StyledTile.HeadFirstItem>
          <StyledDetailsDialog.DialogHeadItem>
            <StyledDetailsDialog.DialogAlertWrapper>
              <StyledDetailsDialog.DialogHoverText>
                <Trans
                  i18nKey={"tax/common:main_content.total_analyse.warnings.considerable_text"}
                />
              </StyledDetailsDialog.DialogHoverText>
              <TriStateAlarm level={EAlarmLevel.CONSIDERABLE} text={"erhebliche Auffälligkeit"} />
              <StyledTile.HelpWrapper>
                <Icon name={IconNameEnums.Q_HELP} size={IconSizeEnums.XSMALL} />
              </StyledTile.HelpWrapper>
            </StyledDetailsDialog.DialogAlertWrapper>
            <StyledDetailsDialog.DialogAlertWrapper>
              <StyledDetailsDialog.DialogHoverText>
                <Trans i18nKey={"tax/common:main_content.total_analyse.warnings.critical_text"} />
              </StyledDetailsDialog.DialogHoverText>
              <TriStateAlarm level={EAlarmLevel.CRITICAL} text={"kritische Auffälligkeit"} />
              <StyledTile.HelpWrapper>
                <Icon name={IconNameEnums.Q_HELP} size={IconSizeEnums.XSMALL} />
              </StyledTile.HelpWrapper>
            </StyledDetailsDialog.DialogAlertWrapper>
          </StyledDetailsDialog.DialogHeadItem>
        </StyledDetailsDialog.DialogHeadColumn>
        <StyledDetailsDialog.TableBody>
          <Table width={"100%"}>
            <thead>
              <Row>
                <Cell></Cell>
                <Cell></Cell>
                <Cell width={"13%"} level={"title"} align={"left"}>
                  {t("tax/common:detail_page_dialog.thead.aktueller_monat")}
                </Cell>
                <Cell width={"13%"} level={"title"} align={"left"}>
                  {t("tax/common:detail_page_dialog.thead.vorjahres_monat")}
                </Cell>
                <Cell width={"13%"} level={"title"} align={"left"}>
                  <Trans
                    i18nKey={"tax/common:detail_page_dialog.thead.vorjahres_durchschnitts_monat"}
                  />
                </Cell>
                <Cell width={"13%"} level={"title"} align={"left"}>
                  <Trans i18nKey={"tax/common:detail_page_dialog.thead.aktuelles_jahr_kumuliert"} />
                </Cell>
                <Cell width={"13%"} level={"title"} align={"left"}>
                  <Trans
                    i18nKey={"tax/common:detail_page_dialog.thead.stand_im_vorjahr_kumuliert"}
                  />
                </Cell>
              </Row>
            </thead>
          </Table>
        </StyledDetailsDialog.TableBody>
      </StyledDetailsDialog.Header>
      <StyledDetailsDialog.DetailPageWrapper>
        <StyledDetailsDialog.ContentWrapper>
          <StyledDetailsDialog.TableBody>
            {alertsLoading && <LoadingIndicator type={LoaderTypeEnums.COMPONENT} />}
            <Table width={"100%"}>
              <thead>
                <Row>
                  <Cell></Cell>
                  <Cell></Cell>
                  <Cell width={"13%"} level={"title"} align={"left"}>
                    {t("tax/common:detail_page_dialog.thead.aktueller_monat")}
                  </Cell>
                  <Cell width={"13%"} level={"title"} align={"left"}>
                    {t("tax/common:detail_page_dialog.thead.vorjahres_monat")}
                  </Cell>
                  <Cell width={"13%"} level={"title"} align={"left"}>
                    <Trans
                      i18nKey={"tax/common:detail_page_dialog.thead.vorjahres_durchschnitts_monat"}
                    />
                  </Cell>
                  <Cell width={"13%"} level={"title"} align={"left"}>
                    <Trans
                      i18nKey={"tax/common:detail_page_dialog.thead.aktuelles_jahr_kumuliert"}
                    />
                  </Cell>
                  <Cell width={"13%"} level={"title"} align={"left"}>
                    <Trans
                      i18nKey={"tax/common:detail_page_dialog.thead.stand_im_vorjahr_kumuliert"}
                    />
                  </Cell>
                </Row>
              </thead>
              <tbody>
                {selectedClientsAlerts?.months?.compare.map(
                  ({
                    alert_id,
                    prev_month,
                    prev_year_avg_month,
                    prev_year_cumulated,
                    critical,
                    not_applicable,
                    details,
                  }) => {
                    const icontype: string[] = [
                      prev_month,
                      prev_year_avg_month,
                      prev_year_cumulated,
                      critical,
                    ];

                    const highlight: boolean =
                      icontype.includes("critical") || icontype.includes("considerable");

                    return details.map(
                      (
                        {
                          alert_detail_id,
                          value,
                          value_cumulated,
                          prev_month_value,
                          prev_year_avg_month_value,
                          prev_year_cumulated_value,
                        },
                        index
                      ) => {
                        const digits = alert_detail_id === "ALD14" ? 1 : 0;
                        // function to return n/a for insolvency if number is zero or less
                        const formatReturnNumber = (
                          number: number,
                          not_applicable: boolean
                        ): string => {
                          // const id = alert_detail_id;
                          if (not_applicable) return "n/a";
                          if (!number) return "";
                          // if (id === "ALD14" && number === 0) return "n/a";
                          return formatNumber(number, {
                            divideTo: EFormatCurrencyDivision.NONE,
                            maximumFractionDigits: digits,
                          });
                        };

                        return (
                          <Row key={index}>
                            <Cell highlight={highlight}>
                              {not_applicable ? (
                                <StyledTotalAnalyze.AlertWrapper>
                                  <Icon
                                    name={IconNameEnums.NOT_APPLICABLE}
                                    size={IconSizeEnums.SMALL}
                                  />
                                </StyledTotalAnalyze.AlertWrapper>
                              ) : icontype.includes("critical") ? (
                                <TriStateAlarm level={EAlarmLevel.CRITICAL} />
                              ) : icontype.includes("considerable") ? (
                                <TriStateAlarm level={EAlarmLevel.CONSIDERABLE} />
                              ) : (
                                ""
                              )}
                            </Cell>
                            {alert_detail_id !== "ALD14" ? (
                              <Cell highlight={highlight} level={"title"}>
                                {t(
                                  `tax/common:main_content.total_analyse.alerts_details:${alert_detail_id}`
                                )}
                              </Cell>
                            ) : (
                              <StyledDetailsDialog.DialogHelpWrapper>
                                <StyledDetailsDialog.DialogHoverInsolvency>
                                  <Trans
                                    i18nKey={"tax/common:detail_page_dialog.insolvency_text"}
                                  />
                                </StyledDetailsDialog.DialogHoverInsolvency>
                                <span>
                                  {t(
                                    `tax/common:main_content.total_analyse.alerts_details:${alert_detail_id}`
                                  )}
                                </span>
                                <Icon name={IconNameEnums.Q_HELP} size={IconSizeEnums.XSMALL} />
                              </StyledDetailsDialog.DialogHelpWrapper>
                            )}
                            <Cell highlight={highlight} align="left">
                              <StyledTotalAnalyze.TextIconWrapper>
                                {formatReturnNumber(value, not_applicable)}
                              </StyledTotalAnalyze.TextIconWrapper>
                            </Cell>
                            <Cell highlight={highlight} align="left">
                              <StyledTotalAnalyze.TextIconWrapper>
                                {formatReturnNumber(prev_month_value, not_applicable)}
                              </StyledTotalAnalyze.TextIconWrapper>
                            </Cell>
                            <Cell highlight={highlight} align="left">
                              <StyledTotalAnalyze.TextIconWrapper>
                                {formatReturnNumber(prev_year_avg_month_value, not_applicable)}
                              </StyledTotalAnalyze.TextIconWrapper>
                            </Cell>
                            <Cell highlight={highlight} align="left">
                              <StyledTotalAnalyze.TextIconWrapper>
                                {formatReturnNumber(value_cumulated, not_applicable)}
                              </StyledTotalAnalyze.TextIconWrapper>
                            </Cell>
                            <Cell highlight={highlight} align="left">
                              <StyledTotalAnalyze.TextIconWrapper>
                                {formatReturnNumber(prev_year_cumulated_value, not_applicable)}
                              </StyledTotalAnalyze.TextIconWrapper>
                            </Cell>
                          </Row>
                        );
                      }
                    );
                  }
                )}
              </tbody>
            </Table>
            <Offer />
          </StyledDetailsDialog.TableBody>
        </StyledDetailsDialog.ContentWrapper>
      </StyledDetailsDialog.DetailPageWrapper>
    </>
  );
};

export default ContactDialog;
