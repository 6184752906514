import { FC } from "react";

import { StyledSetup } from "../../../../StyledComponents";
import { PercentCircle } from "@canei/app-components";
import { Trans, useTranslation } from "react-i18next";
export const Sync: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  return (
    <StyledSetup.SetupContent.Wrapper>
      <StyledSetup.SetupContent.Caption>
        {t("initial_setup.better_business.sync.caption")}
      </StyledSetup.SetupContent.Caption>
      <StyledSetup.SetupContent.Body>
        <StyledSetup.SetupContent.InformationText>
          <Trans i18nKey={"tax/common:initial_setup.better_business.process.text"} />
        </StyledSetup.SetupContent.InformationText>
        <PercentCircle t={t} value={24} />
        <StyledSetup.SetupContent.InformationText>
          {t("initial_setup.better_business.process.analyze_state", {
            client_name: "Test Client GmbH",
          })}
        </StyledSetup.SetupContent.InformationText>
      </StyledSetup.SetupContent.Body>
    </StyledSetup.SetupContent.Wrapper>
  );
};
