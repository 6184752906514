import { FC, useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  Icon,
  IconNameEnums,
  IconSizeEnums,
  LoaderTypeEnums,
  LoadingIndicator,
  FadeIn,
} from "@canei/app-components";

import { StyledUserMenu } from "./styled.user-menu";

import { useHistory } from "react-router-dom";
import { useInvoicesDialog } from "../DownloadInvoices/useInvoiceDialog";

import { EPrivatePaths, ILocalState } from "../../../../@types/index.d";
import { MainContext } from "../../../../views/local/Private/PrivateMainContext";
import { useEditSettingsDialog } from "../Settings";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useUserManagementDialog } from "../CustomerUserManagement";
import { isEqual } from "lodash";

export const UserMenu: FC = () => {
  const { customer } = useContext(MainContext);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { open: handleUserManagementDialog } = useUserManagementDialog();
  const { open: openEditSettingsDialog } = useEditSettingsDialog();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { t } = useTranslation(["tax/common"]);
  const { open: handleShowIncoicesDialog } = useInvoicesDialog();

  const customerName = useMemo(() => {
    if (!customer?.contact) return undefined;
    if (!currentUser.appUser.root) return currentUser.appUser.alias;
    return `${customer.contact.first_name} ${customer.contact.last_name}`;
  }, [currentUser.appUser.alias, currentUser.appUser.root, customer.contact]);

  useLayoutEffect(() => {
    const closeOnOuterClick = (e: MouseEvent): void => {
      if (userMenuRef === null) return;
      if (userMenuRef.current === null) return;
      if (e === null) return;
      if (e.target === null) return;
      const isOuter = !(
        userMenuRef.current === e.target || userMenuRef.current.contains(e.target as Node)
      );

      isOuter && isMenuVisible && setIsMenuVisible(false);
    };

    if (isMenuVisible) {
      window.document.querySelector("body")?.addEventListener("mouseup", closeOnOuterClick);
      return (): void => {
        window.document.querySelector("body")?.removeEventListener("mouseup", closeOnOuterClick);
      };
    }
  }, [isMenuVisible]);
  const handleToggleMenu = (): void => {
    setIsMenuVisible(!isMenuVisible);
  };
  const handleLogOut = (): void => {
    history.push(EPrivatePaths.LOGOUT);
  };
  const handleToProfileSettings = (): void => {
    setIsMenuVisible(false);
    openEditSettingsDialog();
  };
  const handleDownloadInvoices = (): void => {
    setIsMenuVisible(false);
    handleShowIncoicesDialog();
  };

  const handleUserManagement = (): void => {
    setIsMenuVisible(false);
    handleUserManagementDialog();
  };

  return (
    <>
      <StyledUserMenu.Wrapper>
        <StyledUserMenu.User ref={userMenuRef}>
          <StyledUserMenu.ClientName onClick={handleToggleMenu}>
            <span>
              {customerName ? (
                customerName
              ) : (
                <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
              )}
            </span>
            <Icon
              size={IconSizeEnums.SMALL}
              name={isMenuVisible ? IconNameEnums.Q_ARROW_DOWN : IconNameEnums.Q_ARROW_DOWN}
            />
          </StyledUserMenu.ClientName>
          <StyledUserMenu.CustomerName>
            {customer?.name ? (
              customer?.name
            ) : (
              <LoadingIndicator type={LoaderTypeEnums.TEXT} length={10} />
            )}
          </StyledUserMenu.CustomerName>

          {isMenuVisible && (
            <FadeIn>
              <StyledUserMenu.ListWrapper>
                <StyledUserMenu.List>
                  {currentUser.appUser.root === true && (
                    <>
                      <StyledUserMenu.ListItem onClick={handleToProfileSettings}>
                        {t("tax/common:main_content.user_menu.settings.user_settings")}
                      </StyledUserMenu.ListItem>
                      <StyledUserMenu.ListItem onClick={handleDownloadInvoices}>
                        {t("quick/common:main_content.top_bar.user_profile.invoices.invoices")}
                      </StyledUserMenu.ListItem>
                      <StyledUserMenu.ListItem onClick={handleUserManagement}>
                        {t("tax/common:main_content.user_menu.users")}
                      </StyledUserMenu.ListItem>
                    </>
                  )}
                  <StyledUserMenu.ListItem onClick={handleLogOut}>
                    {t("tax/common:main_content.user_menu.logout")}
                  </StyledUserMenu.ListItem>
                </StyledUserMenu.List>
              </StyledUserMenu.ListWrapper>
            </FadeIn>
          )}
        </StyledUserMenu.User>
      </StyledUserMenu.Wrapper>
    </>
  );
};
