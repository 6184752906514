import { ErrorInfo, MouseEvent, ReactElement, ReactNode } from "react";
import { IconNameEnums } from "../components/Icon";
import { EPrivatePathName, EQPrivatePaths } from "./route";

export enum ICardTypeEnums {
  FULL_WIDTH = "FULL_WIDTH",
  CENTERED = "CENTERED",
}

export enum AlertTypeEnums {
  BASIC = "BASIC",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
}
export interface ICardProps {
  /** Children */
  children: ReactNode;
  /** Type of ``Card Comp``*/
  type?: ICardTypeEnums;
  /** Shows isReady indicator, if it's set to true*/
  loading?: boolean;
}
export interface IAlertProps {
  children: ReactNode;
  type?: AlertTypeEnums;
  closable?: boolean;
}
export interface IErrorBoundaryState {
  hasError: boolean;
  errorInfo: ErrorInfo;
  error: Error;
}

export interface IMenuItem {
  icon: IconNameEnums | null;
  label: string | ReactElement;
  level: number;
}
export interface IRulerProps {
  showRuler: boolean;
}
export interface IMenuItemProps extends IMenuItem {
  href?: EPrivatePathName;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  expanded?: boolean;
  selected?: boolean;
}
export interface IExpandableMenuItemProps extends IMenuItem {
  showSubMenu?: boolean;
}

export interface IQMenuItemProps extends Omit<IMenuItemProps, "level"> {
  href: EQPrivatePaths;
  icon: IconNameEnums;
}

enum EMenuItem {
  DASHBOARD = "DASHBOARD",
  KPI = "KPI",
  PLAN = "PLAN",
  REPORTS = "REPORTS",
  REPORTS_CROSS_CLIENT = "REPORTS_CROSS_CLIENT",
  REPORTS_CURRENT = "REPORTS_CURRENT",
  COMPANY = "COMPANY",
  COMPANY_DATA = "COMPANY_DATA",
  COMPANY_USERS = "COMPANY_USERS",
  UPLOAD = "UPLOAD",
  ACCOUNT = "ACCOUNT",
  LOGOUT = "LOGOUT",
}

export enum EQMenuItem {
  OVERVIEW = "overview", // Übersicht
  SUCCESS = "success", // Erfolg
  ASSETS = "assets", // Vermögen
  DEBTS = "debts", // Schulden
  ABNORMALITIES = "abnormalities", // Auffälligkeiten
  //BALANCE = "BALANCE", // Wesentliche Bilanzpositionen
  BANKERS = "bankers", // Bankensicht
  KPIS = "kpis", // Kennzahlen
  GRADE = "grade", // Unternehmenzeugnis
  UPGRADE = "upgrade", // Upgrade
}
export type TMenuProps = Record<EMenuItem, IMenuItemProps | IExpandableMenuItemProps>;
export type TQMenuProps = Record<EQMenuItem, IMenuItemProps>;
