import styled, { css } from "styled-components/macro";

const Wrapper = styled.div(({ theme }) => {
  return css`
    width: 50vw;
    padding: ${theme.rem(1.5)};
    grid-column-gap: ${theme.rem(1)};
    & > * {
      max-width: max-content;
    }
    & label {
      margin-left: ${theme.rem(1)};
    }
    & button {
      width: 20%;
      margin-left: ${theme.rem(1)};
    }
  `;
});

export const StyledSettings = {
  Wrapper,
};
