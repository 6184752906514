import { FC } from "react";
import { IPageProps } from "./types.app";
import { LoadingIndicator, LoaderTypeEnums } from "@canei/app-components";
import { Private } from "../views/hosted/Private";
import { Public } from "../views/hosted/Public";

export const BrowserApp: FC<IPageProps> = ({ loading, authenticated }) => {
  if (loading) return <LoadingIndicator type={LoaderTypeEnums.PAGE} />;
  return authenticated ? <Private /> : <Public />;
};
