import {
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from "@apollo/client";
import {
  SET_META_DATA,
  GET_META_DATA,
  GET_THRESHOLD_META,
  SET_THRESHOLD_META,
  GET_DATEV_METADATA,
} from "./metaData-queries";
import {
  IGetDatevMetaVars,
  IDatevMetaInput,
  IDatevMetaData,
  IAlertingMetaData,
  IAlertingMetaInput,
  IGetAlertingMetaVars,
  IAllDatevMetaData,
  IGetAllDatevMetaVars,
} from "./types.meta-data";
import { QueryHookOptions, QueryResult, QueryTuple, useLazyQuery, useQuery } from "@apollo/client";

export const useGetDatevMetaData = (
  options: QueryHookOptions<IDatevMetaData, IGetDatevMetaVars>
): QueryResult<IDatevMetaData, IGetDatevMetaVars> => {
  return useQuery<IDatevMetaData, IGetDatevMetaVars>(GET_META_DATA, options);
};

export const useLazyGetDatevMetaData = (): QueryTuple<IDatevMetaData, IGetDatevMetaVars> => {
  return useLazyQuery<IDatevMetaData, IGetDatevMetaVars>(GET_META_DATA);
};

export const useSetDatevMetaData = (
  options?: MutationHookOptions<IDatevMetaData, IDatevMetaInput>
): MutationTuple<IDatevMetaData, IDatevMetaInput> => {
  return useMutation<IDatevMetaData, IDatevMetaInput>(SET_META_DATA, options);
};

export const useGetAlertingMetaData = (
  options: QueryHookOptions<IAlertingMetaData, IGetAlertingMetaVars>
): QueryResult<IAlertingMetaData, IGetAlertingMetaVars> => {
  return useQuery<IAlertingMetaData, IGetAlertingMetaVars>(GET_THRESHOLD_META, options);
};

export const useSetAlertingMetaData = (
  options?: MutationHookOptions<IAlertingMetaData, IAlertingMetaInput>
): MutationTuple<IAlertingMetaData, IAlertingMetaInput> => {
  return useMutation<IAlertingMetaData, IAlertingMetaInput>(SET_THRESHOLD_META, options);
};

export const useGetAllDatevMetaData = (
  options?: LazyQueryHookOptions<IAllDatevMetaData, IGetAllDatevMetaVars>
): QueryTuple<IAllDatevMetaData, IGetAllDatevMetaVars> => {
  return useLazyQuery<IAllDatevMetaData, IGetAllDatevMetaVars>(GET_DATEV_METADATA, options);
};
