export interface IUser {
  user_id: string;
  customer_id: string;
  email: string;
  alias: string;
}

export interface IGetUsers {
  getUsers: IUser[];
}

export interface IGetUsersVar {
  id: string;
}

export interface ICreateNewUser {
  createNewUser: IUser;
}

export interface ICreateNewUserVar {
  data: {
    customer_id: string;
    email: string;
  };
}

export interface IIsEmailValid {
  isEmailValid: boolean;
}

export interface IIsEMailValidVar {
  email: string;
}

export enum EActionType {
  ADD_CLIENT = "ADD_CLIENT",
  REMOVE_CLIENT = "REMOVE_CLIENT",
}
