import { Auth } from "aws-amplify";
import { AuthEnums, AuthError, CustomerState, IUserInfo } from "@canei/app-components";
import { UserStateActionTypes, ILocalState } from "../../@types/index.d";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../store/reducers/currentUser";
import { gql, useMutation } from "@apollo/client";
import isElectron from "is-electron";
import { isEqual } from "lodash";

const USER_LOGIN = gql`
  mutation appActivity($user_id: ID!, $app_version: String, $is_electron: String) {
    userLoginEvent(user_id: $user_id, app_version: $app_version, is_electron: $is_electron)
  }
`;
export interface ILoginParams {
  email: string;
  password: string;
  loading?: boolean;
}
const initialParams = {
  email: "__INITIAL__",
  password: "__INITIAL__",
};
type IUseLogin = () => [({ email, password }: ILoginParams) => void, boolean | undefined];
export const useLogin: IUseLogin = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  const [params, setParams] = useState<ILoginParams>(initialParams);
  const [userLoginEvent] = useMutation(USER_LOGIN);
  const version = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const pjson = require("../../../package.json");
    return pjson.version;
  }, []);

  const authChangeCallback = useCallback(
    (isMounted) => {
      const { email, password } = params;

      Auth.signIn(email.toLowerCase(), password)
        .then((user) => {
          const authenticated = user.challengeName !== AuthEnums.NEW_PASSWORD_REQUIRED;
          const forceChange = user.challengeName === AuthEnums.NEW_PASSWORD_REQUIRED;

          const loggedUser: IUserInfo = {
            ...currentUser,
            username: user.username,
            email: email,
            temporaryPassword: forceChange ? password : "",
            authenticated,
            forceChange,
            errors: [],
          };
          if (user.attributes) {
            loggedUser.attributes = {
              // __typename: TypeNameEnums.USER_ATTRIBUTES,
              customer_id: user.attributes["custom:customer-id"],
              user_id: user.attributes["custom:user-id"],
              name: user.attributes.name,
              sub: user.attributes.sub,
            };
          }
          setParams({
            email: "__INITIAL__",
            password: "__INITIAL__",
            loading: false,
          });

          isMounted &&
            dispatch({
              type: UserStateActionTypes.CURRENT_USER,
              payload: { ...loggedUser, state: CustomerState.UNKNOWN },
            });
          return loggedUser;
        })
        .then((loggedUser) => {
          if (loggedUser.authenticated) {
            userLoginEvent({
              variables: {
                user_id: loggedUser.attributes.user_id,
                app_version: version,
                is_electron: isElectron().toString(),
              },
            });
          }
        })
        .catch((e) => {
          const err: AuthError = {
            // __typename: TypeNameEnums.AUTH_ERROR,
            code: e.code,
            name: e.code,
          };

          switch (e.code) {
            case AuthEnums.RESET_REQUIRED:
              const userShouldReset = {
                email: email,
                temporaryPassword: password,
                authenticated: false,
                forceChange: true,
                errors: [],
              };
              setParams({
                email: "__INITIAL__",
                password: "__INITIAL__",
                loading: false,
              });
              isMounted &&
                dispatch({ type: UserStateActionTypes.CURRENT_USER, payload: userShouldReset });

              break;
            default:
              isMounted &&
                dispatch({ type: UserStateActionTypes.CURRENT_USER, payload: { errors: [err] } });
              setParams({
                email: "__INITIAL__",
                password: "__INITIAL__",
                loading: false,
              });
          }
        });
    },
    [currentUser, dispatch, params, userLoginEvent, version]
  );
  useEffect(() => {
    let mounted = true;

    if (params.email === "__INITIAL__" || params.loading) return;

    authChangeCallback(mounted);
    setParams({ ...params, loading: true });
    return (): void => {
      mounted = false;
    };
  }, [params, authChangeCallback]);

  /*** [useLogin hook returns a callback and a isReady state tuple] **/
  return [
    ({ email, password }: ILoginParams): void => {
      if (params.email === "__INITIAL__" && params.password === "__INITIAL__") {
        setParams({
          email,
          password,
          loading: false,
        });
      }
    },
    params?.loading,
  ];
};
