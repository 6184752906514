import { FC, useMemo } from "react";
import {
  AppComponents,
  CustomerState,
  Dialog,
  ErrorBoundary,
  EShortLocales,
  IAppComponentsContextProps,
  StripeJs,
} from "@canei/app-components";

import { getI18n, useTranslation } from "react-i18next";
import awsConfig from "../awsConfig";
import { EBuildType } from "./types.app";
import { Theme } from "../_lib/Theme";
import { ElectronApp } from "./ElectronApp";
import { BrowserApp } from "./BrowserApp";
import { useAuthState } from "../_lib/hooks";
import { Router } from "./Router";

const buildType = process.env.REACT_APP_BUILD_TYPE as EBuildType;

const stripePromise = StripeJs.loadStripe(awsConfig.payment.publicKey);
export const App: FC = () => {
  const { authenticating, authenticated, readyState } = useAuthState();

  const loading = authenticating || (authenticated && readyState !== CustomerState.READY);
  const { t } = useTranslation();
  const { language } = getI18n();
  const appComponentsValues = useMemo<IAppComponentsContextProps>(() => {
    return {
      longMonthNames: t("misc.months.long", { returnObjects: true }) as string[],
      shortMonthNames: t("misc.months.short", { returnObjects: true }) as string[],
      locale: language as EShortLocales,
      assetsPath: awsConfig.assets.host,
      stripePromise,
    };
  }, [language, t]);

  return (
    <ErrorBoundary>
      <Router>
        <Theme>
          <ErrorBoundary>
            <AppComponents {...appComponentsValues}>
              <Dialog t={t}>
                {buildType === EBuildType.ELECTRON ? (
                  <ElectronApp loading={loading} authenticated={authenticated} />
                ) : (
                  <BrowserApp loading={loading} authenticated={authenticated} />
                )}
              </Dialog>
            </AppComponents>
          </ErrorBoundary>
        </Theme>
      </Router>
    </ErrorBoundary>
  );
};
