import { Auth } from "aws-amplify";
import { ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { gql, useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../store/reducers/currentUser";
import { useClients } from "./useClients";
import { CustomerState } from "@canei/app-components";

import { useBillingInfo } from "./billing";
import { isEqual } from "lodash";
import { useAccountFrameTemplates } from "./useAccountFrameTemplates";
import { EDataStoreActionType, IDataStoreAction } from "../store/reducers/dataStore";

const GET_APP_USER_INFO = gql`
  query appUserInfo($userId: ID!) {
    getAppUserInfo(userId: $userId) {
      user_id
      customer_id
      alias
      email
      root
      links {
        href
        rel
      }
      clients {
        client_id
      }
      customer {
        api_access
        contact {
          first_name
          last_name
          telephone
        }
        address {
          country
        }
        customer_id
        links {
          href
          rel
        }
        name
        ou
        type
      }
    }
  }
`;

type TUseAuthState = () => IUseAuthStateReturnValue;
export interface IUseAuthStateReturnValue {
  authenticated: boolean;
  authenticating: boolean;
  readyState: CustomerState;
}
export const useAuthState: TUseAuthState = () => {
  const client = useApolloClient();
  const { update: updateClients } = useClients();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  const dispatchDataStore = useDispatch<Dispatch<IDataStoreAction>>();

  const { getCustomerStatus, getSubscriptions } = useBillingInfo();
  const { data: accFrameTemplates } = useAccountFrameTemplates();
  const [clientListLoading, setClientListLoading] = useState(false);
  const authenticated = useMemo(() => currentUser.authenticated, [currentUser.authenticated]);
  const authenticating = useMemo(() => currentUser.authenticating, [currentUser.authenticating]);
  const readyState = useMemo(() => currentUser.state, [currentUser.state]);

  useEffect(() => {
    if (currentUser.state === CustomerState.ERROR) {
      throw new Error("Error user validation...");
    }
  }, [currentUser.state]);

  const authCallback = useCallback(
    (user) => {
      dispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          username: user.username,
          temporaryPassword: "",
          authenticated: true,
          authenticating: false,
          forceChange: false,
          attributes: {
            // __typename: TypeNameEnums.USER_ATTRIBUTES,
            customer_id: user.attributes["custom:customer-id"],
            user_id: user.attributes["custom:user-id"],
            name: user.attributes.name,
            sub: user.attributes.sub,
          },
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentUser.authenticating) return;
    Auth.currentAuthenticatedUser()
      .then(authCallback)
      .catch(() => {
        return dispatch({
          type: UserStateActionTypes.CURRENT_USER,
          payload: {
            authenticating: false,
            // __typename: UserStateActionTypes.CURRENT_USER,
            username: "",
            authenticated: false,
            state: CustomerState.READY,
          },
        });
      });
  }, [dispatch, authCallback, currentUser.authenticating]);

  useEffect(() => {
    if (currentUser.authenticated && currentUser.state === CustomerState.UNKNOWN) {
      if (clientListLoading) return;
      setClientListLoading(true);
      client
        .query({
          query: GET_APP_USER_INFO,
          variables: {
            userId: currentUser.attributes.user_id,
          },
          fetchPolicy: "no-cache",
        })
        .then(({ data }) => {
          const appUser = { ...data.getAppUserInfo };

          updateClients({ variables: { customer_id: appUser.customer_id } });
          // to get the billing customer data and fill the redux
          getCustomerStatus({ variables: { customer_id: appUser.customer_id } });
          // to dispatch templates data
          dispatchDataStore({
            type: EDataStoreActionType.SET_TEMPLATES_DATA,
            payload: {
              accFrameTemplates: accFrameTemplates.result,
            },
          });
          return dispatch({
            type: UserStateActionTypes.CURRENT_USER,
            payload: {
              appUser,
            },
          });
        })
        .then(({ payload }) => {
          return getSubscriptions({ variables: { customer_id: payload.appUser.customer_id } });
        })
        .catch(() => {
          // user in cache has wrong credentials, should re login.
          return dispatch({
            type: UserStateActionTypes.CURRENT_USER,
            payload: {
              authenticating: false,
              // __typename: UserStateActionTypes.CURRENT_USER,
              username: "",
              authenticated: false,
              state: CustomerState.READY,
            },
          });
        });
    }
    return (): void => {
      if (clientListLoading) {
        setClientListLoading(false);
      }
    };
  }, [
    dispatch,
    client,
    currentUser,
    updateClients,
    clientListLoading,
    getSubscriptions,
    getCustomerStatus,
    dispatchDataStore,
    accFrameTemplates,
  ]);

  return {
    authenticating,
    authenticated,
    readyState,
  };
};
