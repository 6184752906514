import { FC } from "react";

import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  FormRow,
  P,
  windowOpen,
} from "@canei/app-components";

import { useTranslation } from "react-i18next";
import { StyledTotalAnalyze } from "../TotalAnalyze/styled.total-analyze";
import { StyledTile } from "../../../../../StyledComponents";
import { useTheme } from "styled-components/macro";
import { StyledOffer } from "./styled.offer";

export const Offer: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const url = t("tax/common:detail_page_dialog.footer_btn_link");
  const theme = useTheme();

  return (
    <StyledTile.Wrapper gridArea="offer">
      <StyledTotalAnalyze.Wrapper inverted={true}>
        <StyledTotalAnalyze.Image imageUrl={"./assets/detail_page_dialog_picture.png"} />
        <StyledOffer.Content>
          <StyledTotalAnalyze.ContentTitle>
            <StyledOffer.LogoContainer>
              <img src={theme.branded.logo.BETTER_BUSINESS.light} alt="CANEI.tax Logo" />
            </StyledOffer.LogoContainer>
          </StyledTotalAnalyze.ContentTitle>
          <P>{t("tax/common:detail_page_dialog.picture_text")}</P>
          <FormRow align={AlignmentEnums.CENTER}>
            <ButtonBranded
              variant={ButtonBrandedVariantEnums.DEFAULT}
              type={"button"}
              inline
              inverted
              onClick={(): void => windowOpen({ url })}
            >
              {t("tax/common:detail_page_dialog.picture_text_btn")}
            </ButtonBranded>
          </FormRow>
        </StyledOffer.Content>
      </StyledTotalAnalyze.Wrapper>
    </StyledTile.Wrapper>
  );
};
