import { FC, useEffect } from "react";
import {
  AlignmentEnums,
  FormRow,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  ErrorBoundary,
  StyledQWizardForm,
  IconNameEnums,
  ReactStripe,
  AlertTypeEnums,
  EQCardSectionTypes,
  QCard,
  QCardSection,
} from "@canei/app-components";

import { useTranslation } from "react-i18next";
import { StyledDownloadApp } from "./styled.download-app";
import DownloadTopic from "./downloadTopic";
import { SimpleLayout } from "../../../../_lib/Layout";
import { Logo } from "../../../shared/Logo";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UserMenuWebApp } from "../../../../_lib/Layout/TopBar/UserMenuWebApp";
import { useManagePaymentsDialog } from "../../../../_lib/Layout/TopBar/Payment";
import { useSearchQuery } from "../../../../_lib/hooks";
import { EPrivatePaths, ILocalState } from "../../../../@types/index.d";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

const GET_APP_DOWNLOAD_URL = gql`
  {
    getAppDownloadUrl
  }
`;

export interface IDownloadAppUrl {
  getAppDownloadUrl: string;
}

export interface IDownloadAppUrl {
  getAppDownloadUrl: string;
}

const GET_APP_VERSION = gql`
  {
    getAppVersion
  }
`;

export interface IAppVersion {
  getAppVersion: string;
}

const USER_DOWNLOAD = gql`
  mutation appActivity($user_id: ID!, $app_version: String, $is_electron: String) {
    userDownloadEvent(user_id: $user_id, app_version: $app_version, is_electron: $is_electron)
  }
`;

export const DownloadApp: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const { useStripe } = ReactStripe;
  const getQuery = useSearchQuery();
  const stripe = useStripe();
  const history = useHistory();
  const { open: openManagePaymentsDialog } = useManagePaymentsDialog();
  const [userDownloadEvent] = useMutation(USER_DOWNLOAD);

  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  const { data: newVersion } = useQuery<IAppVersion>(GET_APP_VERSION, {
    fetchPolicy: "no-cache",
  });

  const [downloadUrl] = useLazyQuery<IDownloadAppUrl>(GET_APP_DOWNLOAD_URL, {
    onCompleted: (data) => {
      const a = document.createElement("a");
      a.href = data.getAppDownloadUrl;
      a.click();
      userDownloadEvent({
        variables: {
          user_id: currentUser.appUser.user_id,
          app_version: newVersion?.getAppVersion,
          is_electron: "false",
        },
      });
    },
  });

  const renderedItems = [
    {
      name: IconNameEnums.APP_COMPLETE,
      title: "tax/common:wizards.download.items.first_icon.title",
      text: "tax/common:wizards.download.items.first_icon.text",
    },
    {
      name: IconNameEnums.APP_DOWNLOAD,
      title: "tax/common:wizards.download.items.second_icon.title",
      text: "tax/common:wizards.download.items.second_icon.text",
    },
    {
      name: IconNameEnums.APP_CLIENT_SELECT,
      title: "tax/common:wizards.download.items.third_icon.title",
      text: "tax/common:wizards.download.items.third_icon.text",
    },
  ];

  const handleAppDownload = (): void => {
    downloadUrl();
  };

  useEffect(() => {
    const setup_intent_client_secret = getQuery("setup_intent_client_secret");
    if (setup_intent_client_secret) {
      stripe?.retrieveSetupIntent(setup_intent_client_secret).then(function (result) {
        history.push(EPrivatePaths.HOME);
        if (result.error) {
          openManagePaymentsDialog(AlertTypeEnums.ERROR);
        } else {
          if (result.setupIntent.status === "succeeded") {
            openManagePaymentsDialog(AlertTypeEnums.SUCCESS);
          } else if (result.setupIntent.status === "requires_payment_method") {
            openManagePaymentsDialog(AlertTypeEnums.ERROR);
          }
        }
      });
    }
  }, [getQuery, stripe, history, openManagePaymentsDialog]);

  useEffect(() => {
    const payment_intent_client_secret = getQuery("payment_intent_client_secret");
    if (payment_intent_client_secret) {
      stripe?.retrievePaymentIntent(payment_intent_client_secret).then(function (result) {
        history.push(EPrivatePaths.HOME);
        if (result.error) {
          openManagePaymentsDialog(AlertTypeEnums.ERROR);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            openManagePaymentsDialog(AlertTypeEnums.SUCCESS);
          } else if (result.paymentIntent.status === "requires_payment_method") {
            openManagePaymentsDialog(AlertTypeEnums.ERROR);
          }
        }
      });
    }
  }, [getQuery, stripe, history, openManagePaymentsDialog]);

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <StyledDownloadApp.Wrapper>
          <StyledDownloadApp.TopHeaderWrapper>
            <StyledDownloadApp.LogoWrapper>
              <Logo />
            </StyledDownloadApp.LogoWrapper>
            <UserMenuWebApp />
          </StyledDownloadApp.TopHeaderWrapper>

          <QCard>
            <QCardSection type={EQCardSectionTypes.DEFAULT}>
              <StyledQWizardForm.Wrapper>
                <StyledQWizardForm.FormCaption>
                  {t("tax/common:wizards.download.headline")}
                </StyledQWizardForm.FormCaption>
                <StyledQWizardForm.Content>
                  <StyledDownloadApp.DownloadItemsWrapper>
                    {renderedItems.map(({ name, title, text }, i) => {
                      return <DownloadTopic key={i} name={name} title={title} text={text} />;
                    })}
                  </StyledDownloadApp.DownloadItemsWrapper>
                </StyledQWizardForm.Content>
                <StyledQWizardForm.Footer>
                  <FormRow align={AlignmentEnums.CENTER}>
                    <ButtonBranded
                      inverted={false}
                      type={"button"}
                      inline={true}
                      variant={ButtonBrandedVariantEnums.PRIMARY}
                      disabled={false}
                      onClick={handleAppDownload}
                    >
                      {t("tax/common:wizards.download.app_download")}
                    </ButtonBranded>
                  </FormRow>
                </StyledQWizardForm.Footer>
              </StyledQWizardForm.Wrapper>
            </QCardSection>
          </QCard>
        </StyledDownloadApp.Wrapper>
      </ErrorBoundary>
    </SimpleLayout>
  );
};

export default DownloadApp;
