import { DraftClient } from "./amplify";

import { FileUploadFormat } from "./uploads";
import { IUploadFileState } from "../_lib/store/reducers";
import {
  IUserInfo,
  ISystemMessage,
  AuthError,
  ISubscription,
  IBrandedValues,
} from "@canei/app-components";
import { LayoutEnums } from "../_lib/Layout/types.layout";
import {
  DatevConnectClient,
  IDatevClient,
  IDatevFiscalYearShort,
} from "@canei/datev-connect-client/@types";
import { IClientInProgress } from "../_lib/hooks/useDatev";
import { IClientAlert } from "../_lib/hooks";
import { ICustomerData } from "../_lib/Layout/TopBar/Payment/payment.types";
import { EventListenerCodes } from "../_lib/hooks/useEventListener/types.event-listener";

export interface ILocalState {
  //__typename: TypeNameEnums.INITIAL_DATA;
  appStatus: IAppStatusInfo;
  currentUser: IUserInfo;
  billing: IBillingInfo;
  errorState: {
    //__typename: TypeNameEnums.AUTH_ERROR;
    errors: AuthError[];
  };
  draftClient: DraftClient;
  themeOptions: IThemeOptions;
  dataSync: IDataSync;
  warmUp: IWarmUpState;
  downloads: TDownloadsState;
  uploads: IUploadFileState[];
  systemMessages: ISystemMessage[];
  dataStore: IDataStore;
}

export interface IDataStore {
  accFrameTemplates?: string[];
}

//------------------- APP STATUS -------------------//
export interface IAppStatusInfo {
  appSync?: IAppStatusAppSync;
  datev: IAppStatusDatev;
  metaData?: IAppStatusMetaData;
  caneiClients?: ClientTax[];
  alertsInProgress: Record<string, IClientInProgress>;
  alerts: IClientAlert[];
  filters?: IFilterOptions;
  eventsState: Record<string, IEventSync>;
  warningDialog?: boolean;
}

export interface ClientTax {
  client_id: string;
  name: string;
  fiscal_years: IDatevFiscalYearShort[];
  links: RelationLink[];
}
export interface DatevFiscalYear {
  begin_month: string;
  begin: string;
  end: string;
}

export interface IEventSync {
  transactionId: string;
  accountId: string;
  status: EventListenerCodes;
  timeOutTimer: number;
}

//--------- APP SYNC ---------//
export interface IAppStatusAppSync {
  ready: boolean; // is setup done
  appSyncState: ETaxAppSyncState; // app sync status
  setupSyncState: ETaxSetupSyncState; // setup sync state
}

export enum ETaxAppSyncState {
  SETUP = "SETUP",
  DASHBOARD = "DASHBOARD",
}

export enum ETaxSetupSyncState {
  INITIAL = "INITIAL",
  THRESHOLD = "THRESHOLD",
  IMPORTING = "IMPORTING",
  SELECTION = "SELECTION",
  ANALYSE = "ANALYSE",
  DONE = "DONE",
}

//--------- DATEV ---------//
export interface IAppStatusDatev {
  datev_clients: IDatevClient[]; // All clients from datev
  filtered_clients: IDatevClient[]; // filtered clients in client selection
  selected_clients: string[]; // selected clients in dashboard client selection
  selected_date?: string; // selected date in dashboard
  connector?: DatevConnectClient; // datev connector
}

//--------- META DATA ---------//
export interface IAppStatusMetaData {
  datev_url?: string;
  clients?: string[];
  datev_clients?: IDatevClient[];
  date?: string;
  thresholds?: IAppStatusThresholds;
}

export interface IAppStatusThresholds {
  considerable: string;
  critical: string;
}

//--------- FILTERS ---------//
export interface IFilterOptions {
  options?: TFilterOptions;
}

export type TFilterOptions = Record<EFilteredAlarms, IFilterState> &
  Record<EFilterGroup, Record<string, EFilterSelection>>;

export enum EFilteredAlarms {
  PREV_MONTH = "PREV_MONTH",
  AVERAGE = "AVERAGE",
  CUMULATIVE = "CUMULATIVE",
  CRITICAL_VALUE = "CRITICAL_VALUE",
}

export interface IFilterState {
  isCritical: EFilterSelection;
  isConsiderable: EFilterSelection;
}

export enum EFilterGroup {
  DESC = "DESC",
  CATEGORY = "CATEGORY",
}

export enum EFilterSelection {
  SHOW = "SHOW",
  HIDE = "HIDE",
}

export interface IToggleFilterItems {
  toggle_alerts?: [EFilteredAlarms, keyof IFilterState];
  toggle_group_id?: [EFilterGroup, string];
}

//------------------- APP STATUS -------------------//

export interface ISetupPending {
  clients: string[];
  tries: number;
  interval: number;
}

export interface IBillingInfo {
  subscription?: ISubscription;
  paymentType?: string;
  customerData?: ICustomerData;
  billingAddress?: IBillingDetailedAddress;
}
export type IDataSync = Record<EDataSyncLevel, Record<EDataSyncScope, IDataChangedState>>;

export interface IDataSyncOptions {
  level: EDataSyncLevel;
  scope: EDataSyncScope;
  refreshRate?: EDataSyncRefreshRate;
  retryUntil?: number;
}
export enum EDataSyncLevel {
  // ACCOUNT = "ACCOUNT",
  CUSTOMER = "CUSTOMER",
  CLIENT = "CLIENT",
  // USER = "USER",
}
export enum EDataSyncScope {
  CUSTOMER = "customer",
  SUBSCRIPTION = "subscription",
  WALLET = "wallet",
  CLIENT = "client",
  LOGO = "logo",
  FILE = "file",
  SUSA = "susa",
  KPI = "kpi",
  EVAL = "eval",
  ERROR = "error",
  ALERT = "alert",
}

export enum EDataSyncTypes {
  ADD = "add",
  UPSERT = "upsert",
  UPLOAD = "upload",
  DELETE = "delete",
  WARMUP = "warmup",
  DOWNLOAD = "download",
  LOGIN = "login",
  VISIT = "visit",
  LOGOUT = "logout",
  REMOVE = "remove",
  FLUSH = "flush",
  SYNC = "sync",
  BUY = "buy",
  CANCEL = "cancel",
}

export interface IThemeOptions {
  //__typename: TypeNameEnums.THEME_OPTIONS;
  layout: LayoutEnums;
  branded: IBrandedValues;
  sideNavExpanded: boolean;
}

export interface IDataChangedState {
  timeStamp: number;
  pending: boolean;
  timeout: boolean;
  refreshRate: EDataSyncRefreshRate;
  retryUntil?: number;
}
export enum EDataSyncRefreshRate {
  IDLE = 0,
  FAST = 3000,
  MEDIUM = 10000,
  SLOW = 30000,
}
export interface IDataSyncAction {
  type: DataSyncStateActionTypes;
  payload: IDataSyncActionPayload;
}
export interface IDataSyncActionPayload extends IDataSyncOptions {
  state?: Partial<Pick<IDataChangedState, "timeStamp" | "timeout">>;
}
export enum DataSyncStateActionTypes {
  SET_TIMESTAMP = "SET_TIMESTAMP",
  SET_PENDING = "SET_PENDING",
  SET_READY = "SET_READY",
  SET_TIMEOUT = "SET_TIMEOUT",
  SET_REFRESH_RATE = "SET_REFRESH_RATE",
  RESET = "RESET",
  RESET_ALL = "RESET_ALL",
}
export interface IWarmUpAction {
  type: IWarmUpTopics;
  scope: IWarmUpScopes;
  value: number;
}
export enum IWarmUpTopics {
  SUSA_TOPIC = "SUSA_TOPIC",
  FILE_TOPIC = "FILE_TOPIC",
}
export enum IWarmUpScopes {
  CUSTOMER = "CUSTOMER",
  CLIENT = "CLIENT",
  USER = "USER",
  LOGO = "LOGO",
  FILE = "FILE",
  SUSA = "SUSA",
  KPI = "KPI",
  EVAL = "EVAL",
  ERROR = "ERROR",
}

export type IWarmUpState = Record<IWarmUpTopics, IWarmUpScopeState>;
export type IWarmUpScopeState = Record<IWarmUpScopes, number>;
export interface IDownloadsAction {
  type: IDownloadsActionType;
  payload: IDownloadFile;
}

export enum IDownloadsActionType {
  ADD_DOWNLOAD = "ADD_DOWNLOAD",
  REMOVE_DOWNLOAD = "REMOVE_DOWNLOAD",
  EDIT_DOWNLOAD = "EDIT_DOWNLOAD",
  ADD_ERROR = "ADD_ERROR",
}
export interface IDownloadFile {
  identifier: string;
  name: string;
  url: string;
  format: FileUploadFormat;
  error?: string;
  isDownloading: boolean;
  downloadUrl?: string;
}
export type TDownloadsState = IDownloadFile[];
