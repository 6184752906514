import isEmail from "validator/lib/isEmail";
import { Auth } from "aws-amplify";
import { useLogin } from "./";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../store/reducers/currentUser";
import { AuthEnums, UserStateActionTypes } from "../../@types/index.d";
import { AuthError } from "@canei/app-components";

export interface IResetPasswordParams {
  code: string;
  email: string;
  password: string;
  passwordRepeat: string;
  loading?: boolean;
}

type IUseResetPassword = () => [
  ({ code, email, password, passwordRepeat }: IResetPasswordParams) => void,
  boolean | undefined
];
const initialParams: IResetPasswordParams = {
  code: "__INITIAL__",
  email: "__INITIAL__",
  password: "__INITIAL__",
  passwordRepeat: "__INITIAL__",
  loading: false,
};
export const useResetPassword: IUseResetPassword = () => {
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  const [params, setParams] = useState<IResetPasswordParams>(initialParams);
  const [login, loginLoading] = useLogin();

  const authResetCallback = useCallback(
    (isMounted) => {
      const { email, code, password } = params;
      Auth.forgotPasswordSubmit(email.toLowerCase(), code, password)
        .then(() => {
          isMounted &&
            dispatch({
              type: UserStateActionTypes.CURRENT_USER,
              payload: { authenticated: false, forceChange: false, errors: [] },
            });
          // Re-Login with new password
          login({ email, password });
        })
        .catch((e) => {
          //FIXME: Do not produce know error from AuthError
          const error: AuthError = {
            // __typename: TypeNameEnums.AUTH_ERROR,
            ...e,
          };

          dispatch({ type: UserStateActionTypes.CURRENT_USER, payload: { errors: [error] } });
          setParams(initialParams);
        });
    },
    [dispatch, login, params]
  );
  if (loginLoading === false && params.loading) {
    setParams({ ...params, loading: false });
  }

  useEffect(() => {
    let mounted = true;

    if (params.email === "__INITIAL__" || params.loading) return;
    authResetCallback(mounted);
    setParams({ ...params, loading: true });
    return (): void => {
      mounted = false;
    };
  }, [setParams, authResetCallback, params]);

  /*** [useLogin hook returns a callback and a isReady state tuple] **/
  return [
    ({ email, code, password, passwordRepeat }): void => {
      const errors: AuthError[] = [];
      if (code.length === 0) {
        errors.push({
          // __typename: TypeNameEnums.AUTH_ERROR,
          code: AuthEnums.NO_EMPTY,
          name: "empty_code",
        });
      }
      if (!isEmail(email)) {
        errors.push({
          // __typename: TypeNameEnums.AUTH_ERROR,
          code: AuthEnums.EMAIL_REQUIRED,
          name: "invalid_email",
        });
      }
      if (password !== passwordRepeat) {
        errors.push({
          // __typename: TypeNameEnums.AUTH_ERROR,
          code: AuthEnums.PASSWORD_CONFIRM_MISMATCH,
          name: "invalid_password_repeat",
        });
      }
      if (errors.length > 0) {
        dispatch({ type: UserStateActionTypes.CURRENT_USER, payload: { errors } });
        setParams({ ...params, loading: false });
      }

      errors.length === 0 &&
        setParams({
          code,
          email,
          password,
          passwordRepeat,
          loading: false,
        });
    },
    params.loading,
  ];
};
