import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";
import { EPrivatePaths } from "../../../../@types";
import { IMenuProps } from "./index";

export const StyledMenu = styled.ul(
  ({ theme }) => css`
    list-style: none;
    padding: ${theme.rem(4)} 0;
    width: 100%;
    max-width: 100%;
    margin: 0;
  `
);

export const StyledQMenuItem = styled.li<Partial<IMenuProps<EPrivatePaths>>>(
  ({ theme, selected, expanded }) => {
    return css`
      list-style: none;

      position: relative;
      transition: background-color 300ms;
      font-size: ${theme.rem(1.22)};
      line-height: 1.2;
      ${selected &&
      css`
        border-radius: ${theme.rem(1)};
        background-color: ${theme.values.colors.global.greys._000};
        transform: translateX(${theme.rem(2)});
        transition: transform 300ms, background 300ms;
        transition-delay: 0s, 300ms;
      `}

      > a {
        color: ${selected
          ? theme.values.colors.global.secondary._100
          : theme.values.colors.global.greys._000};
        padding: ${theme.rem(0.75)} ${theme.rem(1)} ${theme.rem(0.7)} ${theme.rem(2)};

        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;

        &:hover {
          color: ${selected
            ? theme.values.colors.global.primary._085
            : theme.values.colors.global.secondary._040};
        }
        &:hover,
        &:active,
        &:visited {
          text-decoration: none;
        }
        & svg * {
          fill: currentColor;
        }
      }
    `;
  }
);
export const StyledQMenuIcon = styled.span(({ theme }) => {
  return css`
    padding: ${theme.rem(0.1)};
    padding-right: ${theme.rem(5 / 3)};
    align-self: flex-start;
  `;
});
export const StyledQMenuLabel = styled.span<Partial<IMenuProps<EPrivatePaths>>>(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: inline-block;
        flex-grow: 1;
        text-transform: capitalize;
      `,
    },
    LARGE: {
      style: css`
        display: inline-block;
        padding: 0;
        flex-grow: 1;
        text-transform: capitalize;
        text-align: left;
      `,
    },
  });
});
