import stripUrlAuth from "strip-url-auth";
import { DatevConnectClient } from "@canei/datev-connect-client";
import { useDispatch, useSelector } from "react-redux";
import { IAppStatusMetaData, ILocalState } from "../../../@types/index.d";
import { useRef, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { EAppStatusActionType, IAppStatusAction } from "../../store/reducers/appStatus";
import { useSetDatevMetaData } from "../useMetaData";
import { isEqual } from "lodash";

export interface IUseGetDatevClientsRetVal {
  listClients: (connector: DatevConnectClient, datevUrlInput: string) => void;
  setConnector: (datevUrlInput: string) => void;
  datevListLoading: boolean;
  datevListLoaded: boolean;
  setDatevListLoaded: (datevListLoaded: boolean) => void;
  error: string | undefined;
}

export const useDatevClientList = (): IUseGetDatevClientsRetVal => {
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const datev = useSelector(({ appStatus: { datev } }: ILocalState) => datev, isEqual);
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const appStatus = useSelector(({ appStatus }: ILocalState) => appStatus, isEqual);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const [datevListLoading, setDatevListLoading] = useState<boolean>(false);
  const [datevListLoaded, setDatevListLoaded] = useState<boolean>(false);
  const [setMeta] = useSetDatevMetaData();
  const [error, setError] = useState<string | undefined>(undefined);
  const metaDataRef = useRef<IAppStatusMetaData | undefined>(metaData);
  metaDataRef.current = metaData;
  // to fill redux  with features data
  const listClients = (connector: DatevConnectClient, datevUrlInput: string): void => {
    if (!connector) return;
    if (datevListLoaded) setDatevListLoaded(false);
    setDatevListLoading(true);
    connector
      .loadClients(false, [], true)
      .then((clients) => {
        if (clients.length > 0) {
          // dispatch datev connector to datev redux state
          dispatchAppStatus({
            type: EAppStatusActionType.SET_APP_DATEV,
            payload: {
              datev: { ...datev, connector: connector },
            },
          });
        }

        // sort the result by name
        let datevClients = [...clients].sort((a, b) => a.name.localeCompare(b.name));

        // if the user is not root filter with clients in user metadata
        if (!currentUser.appUser.root) {
          datevClients =
            metaDataRef.current && metaDataRef.current.datev_clients
              ? datevClients.filter((datevClient) =>
                  metaDataRef.current?.datev_clients?.some((client) => client.id === datevClient.id)
                )
              : [];
        }

        // if the user is root
        if (currentUser.appUser.root) {
          // refresh the client list in user meta and redux
          setMeta({
            variables: {
              data: {
                account_id: currentUser.appUser.user_id,
                datev_clients: datevClients,
              },
            },
          });

          // and if the url is different from the current customer metadata
          if (metaData && (!metaData.datev_url || metaData.datev_url !== datevUrlInput)) {
            //dispatch datev url to accout metadata
            setMeta({
              variables: {
                data: {
                  account_id: currentUser.appUser.customer_id,
                  datev_url: datevUrlInput,
                },
              },
            });
          }
        }

        // dispatch the datev client list to redux
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_STATUS,
          payload: {
            ...appStatus,
            datev: {
              ...datev,
              datev_clients: datevClients,
              connector: connector,
              filtered_clients: datevClients,
            },
            metaData: {
              ...metaDataRef.current,
              datev_clients: datevClients,
              datev_url: datevUrlInput,
            },
          },
        });
      })
      .then(() => {
        setDatevListLoaded(true);
        setDatevListLoading(false);
      })
      .catch((err: string) => {
        setError(err);
      });
  };

  const setConnector = (datevUrlInput: string): void => {
    // check for username/password override in url and create datev connector
    const datevUrl = new URL(datevUrlInput);
    let strippedUrl = stripUrlAuth(datevUrl.href);
    if (strippedUrl.slice(-1) === "/") strippedUrl = strippedUrl.slice(0, -1);
    const connector = new DatevConnectClient(
      strippedUrl,
      datevUrl.username === "" ? null : datevUrl.username.replace(/\+/g, " ").trim(),
      datevUrl.password === "" ? null : datevUrl.password
    );

    // if there is no valid connector return
    if (!connector) return;

    // start the list clients function
    listClients(connector, datevUrlInput);
  };

  return {
    listClients,
    setConnector,
    datevListLoading,
    datevListLoaded,
    setDatevListLoaded,
    error,
  };
};
