import { FC } from "react";

import { StyledSetup } from "../../../../StyledComponents";
import { useTranslation } from "react-i18next";
import { StyledSetupBB } from "./styled.setup-bb";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Form,
  FormRow,
  Input,
} from "@canei/app-components";
export const TimeSpan: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  return (
    <StyledSetup.SetupContent.Wrapper>
      <StyledSetup.SetupContent.Caption>
        {t("initial_setup.better_business.sync.caption")}
      </StyledSetup.SetupContent.Caption>
      <StyledSetup.SetupContent.Body>
        <Form>
          <StyledSetupBB.ListWrapper>
            <StyledSetupBB.ListRow isTitle={true}>
              <StyledSetupBB.ClientName>
                {t("initial_setup.better_business.sync.client_name_row_caption")}
              </StyledSetupBB.ClientName>
              <StyledSetupBB.TimeSpan>
                {t("initial_setup.better_business.sync.month_select_caption")}
              </StyledSetupBB.TimeSpan>
            </StyledSetupBB.ListRow>
            <StyledSetupBB.ListRow isTitle={true}>
              <StyledSetupBB.ClientName> </StyledSetupBB.ClientName>
              <StyledSetupBB.TimeSpan>
                <div>{t("initial_setup.better_business.sync.from_row_caption")}</div>
                <div>{t("initial_setup.better_business.sync.to_row_caption")}</div>
              </StyledSetupBB.TimeSpan>
            </StyledSetupBB.ListRow>
            <StyledSetupBB.ListRow>
              <StyledSetupBB.ClientName>Company Name</StyledSetupBB.ClientName>
              <StyledSetupBB.TimeSpan>
                <div>
                  <Input name={"month-from"} value={""} branded />
                </div>
                <div>
                  <Input name={"month-to"} value={""} branded />
                </div>
              </StyledSetupBB.TimeSpan>
            </StyledSetupBB.ListRow>
          </StyledSetupBB.ListWrapper>
          <FormRow align={AlignmentEnums.CENTER}>
            <ButtonBranded
              variant={ButtonBrandedVariantEnums.PRIMARY}
              type={"submit"}
              inverted={false}
              inline
            >
              {t("initial_setup.better_business.sync.button_text")}
            </ButtonBranded>
          </FormRow>
        </Form>
      </StyledSetup.SetupContent.Body>
    </StyledSetup.SetupContent.Wrapper>
  );
};
