import { FC } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  // ButtonVariantEnums,
  // CheckBox,
  // ECheckboxState,
  ErrorBoundary,
  Form,
  FormRow,
  ICardTypeEnums,
  IFormState,
  Input,
  InputTypes,
  P,
  EQCardSectionTypes,
  QCard,
  QCardSection,
  Card,
  CardBody,
  CardTitle,
} from "@canei/app-components";

import { EPrivatePaths, EPublicPaths, ILocalState } from "../../../@types/index.d";
import { useSelector } from "react-redux";

import { useLogin } from "../../../_lib/hooks";
import { SimpleLayout } from "../../../_lib/Layout";
import { LoginError } from "../LoginError";

import { ChangePassword } from "../ChangePassword";
import { StyledLogin } from "./styled.login";
import { Logo } from "../Logo";
import { EBuildType } from "../../../App/types.app";
import { EProductEnums } from "../../../_lib/Layout/types.layout";
import { isEqual } from "lodash";

const buildType = process.env.REACT_APP_BUILD_TYPE as EBuildType;

export const LoginFormTestID = "LoginForm";
/**
 * Login Comp:
 */
export const Login: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const { push } = useHistory();
  const [login, loading] = useLogin();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  if (currentUser.authenticated) return <Redirect to={EPrivatePaths.DASHBOARD} />;

  const handleLogin = (form: IFormState): void => {
    if (form.email.length === 0 || form.password.length === 0) return;
    login({ email: form.email, password: form.password });
  };
  const handleRedirectToRegister = (): void => {
    push(EPublicPaths.REGISTER);
  };
  const SharedLoginFormContent: FC = () => {
    return (
      <StyledLogin.FormWrapper>
        <Form onSubmitHandler={handleLogin} testID={LoginFormTestID}>
          <FormRow align={AlignmentEnums.STRETCH}>
            <Input
              name="email"
              label={t("login.email.label")}
              type={InputTypes.EMAIL}
              value=""
              tabIndex={0}
              required={true}
              disabled={loading}
              branded={true}
            />
          </FormRow>
          <FormRow align={AlignmentEnums.STRETCH}>
            <Input
              name="password"
              label={t("login.password.label")}
              type={InputTypes.PASSWORD}
              value=""
              required={true}
              disabled={loading}
              branded={true}
            />
          </FormRow>
          {currentUser.errors.length > 0 && <LoginError />}
          {/*<FormRow align={AlignmentEnums.LEFT}>
            <CheckBox
              inverted={false}
              variant={ButtonVariantEnums.DEFAULT}
              name={"remember"}
              checked={ECheckboxState.ON}
            >
              {t("login.remember")}
            </CheckBox>
          </FormRow>*/}
          <FormRow align={AlignmentEnums.CENTER}>
            <ButtonBranded
              inverted={false}
              variant={ButtonBrandedVariantEnums.PRIMARY}
              type="submit"
              disabled={loading}
              inline={true}
            >
              {t("login.submit")}
            </ButtonBranded>
          </FormRow>
          <FormRow align={AlignmentEnums.CENTER}>
            <Link to="/forgot">{t("login.forgot_password")}</Link>
          </FormRow>
        </Form>
      </StyledLogin.FormWrapper>
    );
  };
  const BrowserLoginForm: FC = () => {
    return (
      <StyledLogin.Wrapper>
        <QCard>
          <QCardSection type={EQCardSectionTypes.DEFAULT}>
            <Logo />
            <SharedLoginFormContent />
          </QCardSection>
          <QCardSection type={EQCardSectionTypes.DARK}>
            <StyledLogin.CTAWrapper>
              <StyledLogin.CTAHeadline>
                {t("tax/common:CTA.not_yet_customer.title")}
              </StyledLogin.CTAHeadline>
              <P>{t("tax/common:CTA.not_yet_customer.text")}</P>
              <ButtonBranded
                variant={ButtonBrandedVariantEnums.DEFAULT}
                inverted={true}
                type={"button"}
                inline={true}
                onClick={handleRedirectToRegister}
              >
                {t("tax/common:CTA.not_yet_customer.register")}
              </ButtonBranded>
            </StyledLogin.CTAWrapper>
          </QCardSection>
        </QCard>
      </StyledLogin.Wrapper>
    );
  };

  const LocalLoginForm: FC = () => {
    return (
      <Card type={ICardTypeEnums.CENTERED} loading={loading}>
        <CardTitle>
          <Logo product={EProductEnums.TAX} />
        </CardTitle>

        <CardBody>
          <SharedLoginFormContent />
        </CardBody>
      </Card>
    );
  };
  const LoginForm = buildType === EBuildType.ELECTRON ? LocalLoginForm : BrowserLoginForm;
  return (
    <SimpleLayout>
      <ErrorBoundary>
        <>{currentUser.forceChange ? <ChangePassword /> : <LoginForm />}</>
      </ErrorBoundary>
    </SimpleLayout>
  );
};
