import { createRef, FC, RefObject, useCallback, useContext, useEffect } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { CommonLayout } from "../../../../_lib/Layout";
import { TopBar } from "../../../../_lib/Layout/TopBar";
import { SideBar } from "../../../../_lib/Layout/SideBar";
import { Content } from "../../../../_lib/Layout/Content";

import { TotalAnalyze } from "./TotalAnalyze";
import { Watermark } from "../../../../_lib/Layout/WaterMark";
import { DataSync } from "./DataSync";
import { Upgrade } from "./Upgrade";
import { Welcome } from "./Welcome";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../../@types/index.d";
import { MainContext, MainDispatch, TMainContextActionType } from "../PrivateMainContext";
import { ViewSetup } from "./ViewSetup";
import { EPrivatePaths } from "../../../../@types/index.d";
import { useHistory } from "react-router";
import { Offer } from "./Offer";
import { useClients } from "../../../../_lib/hooks";
import { EAppStatusActionType, IAppStatusAction } from "../../../../_lib/store/reducers/appStatus";
import { isEqual } from "lodash";
export interface IOverviewSectionProps<T> {
  forwardRef: RefObject<T>;
}

export const Dashboard: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const caneiClients = useSelector(
    ({ appStatus: { caneiClients } }: ILocalState) => caneiClients,
    isEqual
  );
  const { listTax: listCRMClients } = useClients();
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const history = useHistory();

  // get canei client list if there is none
  useEffect(() => {
    if (metaData && metaData.clients && metaData.clients.length && !caneiClients) {
      listCRMClients({
        variables: { customer_id: currentUser.appUser.customer_id },
        fetchPolicy: "no-cache",
      }).then((clients) => {
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_CLIENTS,
          payload: {
            caneiClients: [...clients.data.getClientsTax],
          },
        });
        return clients.data.getClientsTax;
      });
    }
  }, [caneiClients, currentUser.appUser.customer_id, dispatchAppStatus, listCRMClients, metaData]);

  const contentRef = createRef<HTMLDivElement>();
  const welcomeRef = createRef<HTMLDivElement>();
  const totalAnalyzeRef = createRef<HTMLDivElement>();
  const viewSetupRef = createRef<HTMLDivElement>();
  const dataSyncRef = createRef<HTMLDivElement>();
  const upgradeRef = createRef<HTMLDivElement>();

  const scrollPosCallback = useCallback(
    (section: EPrivatePaths): number | undefined => {
      switch (section) {
        case EPrivatePaths.HOME:
          return welcomeRef.current?.offsetTop;
        case EPrivatePaths.DASHBOARD:
        case EPrivatePaths.TOTAL:
          return totalAnalyzeRef.current?.offsetTop;
        case EPrivatePaths.EDIT_VIEW:
          return viewSetupRef.current?.offsetTop;
      }
    },
    [totalAnalyzeRef, viewSetupRef, welcomeRef]
  );

  useEffect(() => {
    return history.listen((location) => {
      const top = scrollPosCallback(`${location.pathname}${location.hash}` as EPrivatePaths);
      const SCROLL_THRESHOLD = 50;
      const contentTop = contentRef.current?.scrollTop;
      if (contentTop !== undefined && top !== undefined) {
        if (contentTop + SCROLL_THRESHOLD === top) {
          contentRef.current?.scrollBy({ top: -1 * SCROLL_THRESHOLD, behavior: "smooth" });
          setTimeout(() => {
            contentRef.current?.scrollTo({ top: top - SCROLL_THRESHOLD, behavior: "smooth" });
            return;
          }, 100);
        }
      }
      if (top !== undefined) {
        contentRef.current &&
          contentRef.current.scrollTo({ top: top - SCROLL_THRESHOLD, behavior: "smooth" });
      }
    });
  }, [scrollPosCallback, contentRef, history]);

  const { customer } = useContext(MainContext);
  const mainDispatch = useContext(MainDispatch);
  useEffect(() => {
    if (!customer) {
      mainDispatch({
        type: TMainContextActionType.SET_CUSTOMER,
        payload: { customer: currentUser.appUser.customer },
      });
    }
  }, [customer, currentUser.appUser.customer, mainDispatch]);
  return (
    <CommonLayout>
      <TopBar />
      <SideBar />
      <Content forwardRef={contentRef}>
        <Watermark type={"primary"} />
        <Welcome forwardRef={welcomeRef} />
        <TotalAnalyze forwardRef={totalAnalyzeRef} />
        <ViewSetup forwardRef={viewSetupRef} />
        <Offer />
        <DataSync forwardRef={dataSyncRef} />
        <Upgrade forwardRef={upgradeRef} />
      </Content>
    </CommonLayout>
  );
};
