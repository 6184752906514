import {
  ApolloQueryResult,
  FetchResult,
  gql,
  MutationOptions,
  QueryOptions,
  useApolloClient,
} from "@apollo/client";
import { ClientInput, ClientTax, ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../store/reducers";
// import { useSwitchClient } from "./useSwitchClient";
import { Client, LocalCacheEnums, localCache, CustomerState } from "@canei/app-components";
import { isEqual } from "lodash";

export const clientDataQuery = `
    client_id
    name
    fiscal_year
    legal_form
    category
    mapping
    address {
      city
      country
      line_1
      line_2
      postal
      telephone
    }
    customization {
      report_color
      dashboard_kpis
      upload_settings {
        format
        delimiter
        sheet_name
        variant_type
        account_code
        description
        balance
        balance_s
        balance_h
        balance_sh
        starting_balance
        starting_balance_s
        starting_balance_h
        starting_balance_sh
      }
      plan_settings {
        revenue_curve
        revenue_growth
        revenue_ratios {
          jan
          feb
          mar
          apr
          may
          jun
          jul
          aug
          sep
          oct
          nov
          dec
        }
      }
    }
    links {
      href
      rel
    }
  `;

const CLIENTS_LIST = gql`
  query clientsList($customer_id: ID!) {
    getClients(id: $customer_id) {
      ${clientDataQuery}
    }
  }
`;

const CLIENTS_LIST_TAX = gql`
  query clientsListTax($customer_id: ID!) {
    getClientsTax(id: $customer_id) {
      client_id
      name
      links {
        href
        rel
      }
      fiscal_years {
        id
        begin_month
        begin
        end
      }
    }
  }
`;

const CREATE_NEW_CLIENT = gql`
  mutation newClient($data: ClientInput!) {
    createNewClient(data: $data) {
      ${clientDataQuery}
    }
  }
`;
const CREATE_MULTI_NEW_CLIENT = gql`
  mutation multiNewClient($data: [ClientInput]!) {
    createMultiNewClient(data: $data) {
      ${clientDataQuery}
    }
  }
`;
const UPSERT_CLIENT = gql`
  mutation upsertClient($data: ClientInput!) {
    upsertClient(data: $data) {
        ${clientDataQuery}
    }
  }
`;
const DELETE_CLIENT = gql`
  mutation delete($customer_id: ID!, $client_id: ID!) {
    deleteClient(customer_id: $customer_id, client_id: $client_id)
  }
`;
const DEFAULT_KPI_VALUES = gql`
  {
    getDefaultKpiList
  }
`;
type TUseClients = () => IUseClientsResult;
type ClientListDataResult = { getClients: Client[] };
type ClientListTaxDataResult = { getClientsTax: ClientTax[] };
type TUseClientList = (
  options?: PartialQueryOptions
) => Promise<ApolloQueryResult<ClientListDataResult>>;
type TUseClientListTax = (
  options?: PartialQueryOptions
) => Promise<ApolloQueryResult<ClientListTaxDataResult>>;
type TUseClientCreate = (
  data: Omit<ClientInput, "client_id">,
  options?: PartialMutationOptions
) => Promise<FetchResult>;
type TUseClientMultiCreate = (
  data: Omit<ClientInput, "client_id">[],
  options?: PartialMutationOptions
) => Promise<FetchResult>;
type TUseClientUpsert = (
  data: ClientInput,
  options?: PartialMutationOptions
) => Promise<FetchResult>;
type TUseClientRemove = (
  client_id: string,
  options?: PartialMutationOptions
) => Promise<FetchResult>;
type TUseClientUpdate = (options?: PartialQueryOptions) => void;

interface IUseClientsResult {
  /**
   * Get updates list of available clients of the customer
   */
  list: TUseClientList;
  /**
   * Get updates list of available clients of the customer
   */
  listTax: TUseClientListTax;
  /**
   * Creates a new Client
   */
  create: TUseClientCreate;
  /**
   * Creates a new Client
   */
  createMulti: TUseClientMultiCreate;
  /**
   * Adds/removes/edits data of a client defined by client_id
   */
  upsert: TUseClientUpsert;
  /**
   * Removes a client defined with client_id
   */
  remove: TUseClientRemove;
  /**
   * Updates Clients list from server
   * */
  update: TUseClientUpdate;
}
type PartialQueryOptions = Partial<QueryOptions>;
type PartialMutationOptions = Partial<MutationOptions>;

export const useClients: TUseClients = () => {
  const apolloClient = useApolloClient();
  // const { switchClient } = useSwitchClient();
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  const list: TUseClientList = (options = {}) =>
    apolloClient.query({
      ...options,
      query: CLIENTS_LIST,
      variables: {
        ...options.variables,
        customer_id: options.variables?.customer_id || currentUser.appUser.customer_id,
      },
    });

  const listTax: TUseClientListTax = (options = {}) =>
    apolloClient.query({
      ...options,
      query: CLIENTS_LIST_TAX,
      variables: {
        ...options.variables,
        customer_id: options.variables?.customer_id || currentUser.appUser.customer_id,
      },
    });

  const create: TUseClientCreate = (data: Omit<ClientInput, "client_id">, options = {}) => {
    return apolloClient.query({ query: DEFAULT_KPI_VALUES }).then(({ data: defaultData }) =>
      apolloClient
        .mutate({
          ...options,
          mutation: CREATE_NEW_CLIENT,
          variables: {
            data: {
              ...data,
              customization: {
                ...data.customization,
                dashboard_kpis: defaultData.getDefaultKpiList,
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((fetchResult) => {
          const newClient = fetchResult.data?.createNewClient;

          newClient !== undefined &&
            dispatchCurrentUser({
              type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
              payload: {
                clients: [...currentUser.clients, newClient],

                appUser: {
                  ...currentUser.appUser,
                  clients: [...currentUser.appUser.clients, newClient.client_id],
                },
              },
            });
          return fetchResult;
        })
    );
  };

  const createMulti: TUseClientMultiCreate = (
    data: Omit<ClientInput, "client_id">[],
    options = {}
  ) => {
    return apolloClient.query({ query: DEFAULT_KPI_VALUES }).then(({ data: defaultData }) =>
      apolloClient
        .mutate({
          ...options,
          mutation: CREATE_MULTI_NEW_CLIENT,
          variables: {
            data: {
              ...data,
            },
          },
        })
        .then((fetchResult) => {
          const newClientArr = fetchResult.data?.createMultiNewClient;
          for (const newClient of newClientArr) {
            newClient !== undefined &&
              dispatchCurrentUser({
                type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
                payload: {
                  clients: [...currentUser.clients, newClient],

                  appUser: {
                    ...currentUser.appUser,
                    clients: [...currentUser.appUser.clients, newClient.client_id],
                  },
                },
              });
          }
          return fetchResult;
        })
    );
  };

  const upsert: TUseClientUpsert = (data: ClientInput, options = {}) => {
    return apolloClient
      .mutate<{ upsertClient?: Client }, { data: ClientInput }>({
        ...options,
        mutation: UPSERT_CLIENT,
        variables: { data: data },
      })
      .then((clientData) => {
        const { data } = clientData;
        const newClient = data?.upsertClient;
        if (newClient === undefined) return clientData;

        dispatchCurrentUser({
          type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
          payload: {
            clients: currentUser.clients.map((client) =>
              client.client_id === newClient?.client_id ? newClient : client
            ),
          },
        });
        return clientData;
      });
  };
  const remove: TUseClientRemove = (client_id: string, options = {}) => {
    dispatchCurrentUser({
      type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
      payload: { clients: currentUser.clients.filter((client) => client.client_id !== client_id) },
    });
    apolloClient.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        getClients: (cachedClients: Client[]): Client[] =>
          cachedClients.filter((client) => client.client_id !== client_id),
      },
    });
    return apolloClient
      .mutate({
        ...options,
        mutation: DELETE_CLIENT,
        variables: {
          client_id,
          customer_id: currentUser.appUser.customer_id,
        },
      })
      .then((result) => {
        if (result.data?.deleteClient) {
          localCache.removeItem(LocalCacheEnums.CLIENT_LOGOS, {
            logo_hash: result.data?.deleteClient,
          });
        }
        return result;
      });
  };
  const update: TUseClientUpdate = (options = {}): void => {
    if (currentUser.state === CustomerState.PENDING) return;

    list(options)
      .then(({ data }) => {
        const clients = data?.getClients as Client[];
        if (!clients) return;

        // if (client_id !== null) {
        //   // If selected client is not available in newly updated client list,
        //   // reset client selection state
        //   const currentClientSelection = clients.find((c) => c.client_id === client_id);
        //   if (currentClientSelection === undefined) {
        //     switchClient(null, SelectClientState.UNKNOWN);
        //   }
        // }

        dispatchCurrentUser({
          type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
          payload: { clients },
        });
      })
      .catch(() => {
        dispatchCurrentUser({
          type: UserStateActionTypes.CURRENT_USER_STATE,
          payload: {
            state: CustomerState.ERROR,
          },
        });
      });
  };
  return {
    list,
    listTax,
    create,
    createMulti,
    upsert,
    remove,
    update,
  } as IUseClientsResult;
};
