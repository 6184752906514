import {
  createContext,
  Dispatch,
  FC,
  Reducer,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { initialContext, reducerFnMainContext } from "./mainContext";
import { useDispatch, useSelector } from "react-redux";
import {
  EDataSyncLevel,
  EDataSyncScope,
  ETaxAppSyncState,
  ETaxSetupSyncState,
  ILocalState,
} from "../../../../@types/index.d";
import { IMainContext, IMainContextAction, IMainContextProps } from "./types.private-main-context";
import { Dashboard } from "../Dashboard";
import { SetupTax } from "../SetupTax";
import { EClientSelection } from "../../../../_lib/hooks/useDatev";
import { EAppStatusActionType, IAppStatusAction } from "../../../../_lib/store/reducers/appStatus";
import { useGetAlertsOfClient } from "../../../../_lib/hooks";
import { useEventListener } from "../../../../_lib/hooks/useEventListener";
import { EventListenerCodes } from "../../../../_lib/hooks/useEventListener/types.event-listener";
import { EAppSyncLocal, useAppSync } from "../../../../_lib/hooks/useAppSync";
import * as async from "async";
import { useBulkEventListener } from "../../../../_lib/hooks/useEventListener/useBulkEventListener";
import { IconNameEnums } from "@canei/app-components";
import { isEqual } from "lodash";

export * from "./types.private-main-context";
export const MainContext = createContext<IMainContext>(initialContext);
export const MainDispatch = createContext<Dispatch<IMainContextAction>>((s) => s);

export const PrivateMainContext: FC<IMainContextProps> = ({ setupDone }) => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { appSync, metaData } = useSelector((state: ILocalState) => state.appStatus, isEqual);
  const alertsInProgress = useSelector(
    ({ appStatus: { alertsInProgress } }: ILocalState) => alertsInProgress,
    isEqual
  );
  const eventsState = useSelector(
    ({ appStatus: { eventsState } }: ILocalState) => eventsState,
    isEqual
  );
  const appSyncRef = useRef(appSync);
  appSyncRef.current = appSync;
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const { getAlertsOfClient } = useGetAlertsOfClient();
  const { startEventSync /*, eventsState */ } = useEventListener();
  const { startBulkEventSync } = useBulkEventListener();
  const { changeAppSync } = useAppSync();
  const [context, dispatch] = useReducer<Reducer<IMainContext, IMainContextAction>>(
    reducerFnMainContext,
    {
      ...initialContext,
      customer: currentUser.appUser.customer,
      appContext: { ...initialContext.appContext },
    }
  );

  // if the user is not root and doesn't have clients assign move to client selection
  useEffect(() => {
    if (currentUser.appUser.root) return;
    if (appSync && appSync.setupSyncState === ETaxSetupSyncState.SELECTION) return;
    if (appSync && appSync.appSyncState === ETaxAppSyncState.DASHBOARD) return;
    if (metaData && metaData.clients && (metaData.clients.length || metaData?.date)) return;

    changeAppSync({
      ready: false,
      appSyncState: ETaxAppSyncState.SETUP,
      setupSyncState: ETaxSetupSyncState.SELECTION,
      local: EAppSyncLocal.UPDATE,
    });
  }, [appSync, changeAppSync, currentUser, metaData]);

  // defines is customer has all data and if it should move to dashboard or setup
  const ready = useMemo(() => {
    if (appSync) {
      return appSync.ready;
    } else return setupDone;
  }, [appSync, setupDone]);

  // if setupDone, use changeAppSync
  useEffect(() => {
    if (!ready) return;
    if (ready && appSync && appSync.appSyncState === ETaxAppSyncState.SETUP) {
      changeAppSync({
        ready: true,
        appSyncState: ETaxAppSyncState.DASHBOARD,
        setupSyncState: ETaxSetupSyncState.DONE,
        local: EAppSyncLocal.REMOVE,
      });
    }
  }, [appSync, changeAppSync, ready]);

  // start the get alerts hook
  useEffect(() => {
    // if alertsInProgress object is empty stop the hook
    if (!Object.keys(alertsInProgress)) return;

    // run a loop through the alertsInProgress object
    async.each(Object.keys(alertsInProgress), (alert, callback) => {
      // if the alert is not in ALERTS_SHOULD_START progress state stop the loop iteration
      const shouldStart = alertsInProgress[alert].progress === EClientSelection.ALERTS_SHOULD_START;
      if (!shouldStart) return;

      //define params for event listener
      const params = {
        transactionId: alertsInProgress[alert].datev_id,
        accountId: alertsInProgress[alert].client_id as string,
        level: EDataSyncLevel.CLIENT,
        scope: EDataSyncScope.ALERT,
      };

      // change the progress state to ALERTS_LOADING
      dispatchAppStatus({
        type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
        payload: {
          [alertsInProgress[alert].datev_id]: {
            datev_id: alertsInProgress[alert].datev_id,
            client_id: alertsInProgress[alert].client_id,
            progress: EClientSelection.ALERTS_EVENT_SYNC,
            icon: IconNameEnums.LOADING_75,
          },
        },
      });

      // start the event listener
      // startEventSync({ ...params });
      startBulkEventSync({ ...params });
    });
  }, [alertsInProgress, dispatchAppStatus, eventsState, startEventSync, startBulkEventSync]);

  useEffect(() => {
    // if there is no error state stop the hook
    if (!eventsState || !Object.keys(alertsInProgress).length || !Object.keys(eventsState).length)
      return;

    // go through eayh key of event state (transaction ID)
    async.each(Object.keys(eventsState), (event, callback) => {
      // if the event state is not TRANSACTION_OK stop the loop iteration
      if (
        eventsState[event].status === EventListenerCodes.TIME_OUT &&
        alertsInProgress[event].progress !== EClientSelection.TIME_OUT
      ) {
        dispatchAppStatus({
          type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
          payload: {
            [alertsInProgress[event].datev_id]: {
              datev_id: alertsInProgress[event].datev_id,
              client_id: alertsInProgress[event].client_id,
              progress: EClientSelection.TIME_OUT,
              icon: IconNameEnums.LATER,
            },
          },
        });
        return;
      }

      if (eventsState[event].status !== EventListenerCodes.TRANSACTION_OK) return;

      // if the progress state of alertIn Progress is not ALERTS_EVENT_SYNC stop the loop iteration
      if (alertsInProgress[event].progress !== EClientSelection.ALERTS_EVENT_SYNC) return;

      // change the progress state to ALERTS_LOADING
      dispatchAppStatus({
        type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
        payload: {
          [alertsInProgress[event].datev_id]: {
            datev_id: alertsInProgress[event].datev_id,
            client_id: alertsInProgress[event].client_id,
            progress: EClientSelection.ALERTS_LOADING,
            icon: IconNameEnums.LOADING_87,
          },
        },
      });

      // get the alerts of the client
      getAlertsOfClient(alertsInProgress[event]);
      return;
    });
  }, [alertsInProgress, dispatchAppStatus, eventsState, getAlertsOfClient]);

  return (
    <MainDispatch.Provider value={dispatch}>
      <MainContext.Provider value={context}>
        {ready ? <Dashboard /> : <SetupTax />}
      </MainContext.Provider>
    </MainDispatch.Provider>
  );
};
