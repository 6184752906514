import { gql } from "graphql.macro";

export const GET_META_DATA = gql`
  query getMetaData($customer_id: ID, $user_id: ID) {
    result: getDatevMetaData(customer_id: $customer_id, user_id: $user_id) {
      datev_url
      clients
      date
      datev_clients {
        id
        name
        number
      }
    }
  }
`;

export const GET_DATEV_METADATA = gql`
  query getDatevMetadata($customer_id: ID, $user_id: ID, $account_id: ID!) {
    customer: getDatevMetaData(customer_id: $customer_id) {
      datev_url
    }

    user: getDatevMetaData(user_id: $user_id) {
      clients
      date
      datev_clients {
        id
        name
        number
      }
    }

    alerting: getAlertingMetaData(account_id: $account_id) {
      considerable
      critical
    }
  }
`;

export const SET_META_DATA = gql`
  mutation setMeta($data: DatevMetaInput!) {
    result: setDatevMetaData(data: $data) {
      datev_url
      clients
      date
      datev_clients {
        id
        name
        number
      }
    }
  }
`;

export const GET_THRESHOLD_META = gql`
  query getAlertingMetaData($account_id: ID!) {
    result: getAlertingMetaData(account_id: $account_id) {
      considerable
      critical
    }
  }
`;
export const SET_THRESHOLD_META = gql`
  mutation setThreshold($data: AlertingMetaInput) {
    result: setAlertingMetaData(data: $data) {
      considerable
      critical
    }
  }
`;
