import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { StyledTile } from "../../../../../StyledComponents";
import { Trans, useTranslation } from "react-i18next";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  ButtonVariantEnums,
  CheckBox,
  ECheckboxState,
  Form,
  FormRow,
  H3,
  Icon,
  IconNameEnums,
  IconSizeEnums,
  IFormState,
  IInputVariantEnums,
  Input,
  InputTypes,
  toSuSaDate,
} from "@canei/app-components";

import { getMontPickerData, MonthPicker } from "../../../../../components/MonthPicker/MonthPicker";
import { StyledViewSetup } from "./styled.view-setup";
import { MainContext } from "../../PrivateMainContext";
import { StyledSelection } from "../../SetupTax/styled.setup-tax";
import { IOverviewSectionProps } from "..";
import { gql, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../../../@types/index.d";
import {
  EAppStatusActionType,
  IAppStatusAction,
} from "../../../../../_lib/store/reducers/appStatus";
import { EClientSelection, useDatevCreateUpload } from "../../../../../_lib/hooks/useDatev";
import { useSetDatevMetaData } from "../../../../../_lib/hooks";
import { isEqual } from "lodash";

const DATEV_ERROR = gql`
  mutation appErrors($customer_id: ID!) {
    noDatevErrorEvent(customer_id: $customer_id)
  }
`;

export const ViewSetup: FC<IOverviewSectionProps<HTMLDivElement>> = ({ forwardRef }) => {
  const { t } = useTranslation(["tax/common"]);
  const { customer } = useContext(MainContext);
  const { processDatevClients } = useDatevCreateUpload();
  const [errored, setErrored] = useState(false);
  const [noDatevErrorEvent] = useMutation(DATEV_ERROR);
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const appStatus = useSelector(({ appStatus }: ILocalState) => appStatus, isEqual);
  const { datev, metaData } = useSelector((state: ILocalState) => state.appStatus, isEqual);
  const alertsInProgress = useSelector(
    ({ appStatus: { alertsInProgress } }: ILocalState) => alertsInProgress,
    isEqual
  );
  const [allChecked, setAllChecked] = useState<ECheckboxState | undefined>(undefined);
  const [setMeta] = useSetDatevMetaData();

  // disables selection and buttons if loading, enables on error and loading done
  const disableSelection = useMemo(() => {
    if (!Object.keys(alertsInProgress)) return false;
    const allDone = Object.keys(alertsInProgress).every(
      (key) =>
        alertsInProgress[key].progress === EClientSelection.LOADED ||
        alertsInProgress[key].progress === EClientSelection.NO_SUSAS ||
        alertsInProgress[key].progress === EClientSelection.INCOMPATIBLE ||
        alertsInProgress[key].progress === EClientSelection.DATAPATH_INVALID ||
        alertsInProgress[key].progress === EClientSelection.TIME_OUT
    );
    if (errored) return false;
    if (allDone) return false;
    return true;
  }, [alertsInProgress, errored]);

  const makeErrored = (): void => {
    if (errored) return;
    setErrored(true);
  };

  // dispatches Datev error event
  useEffect(() => {
    if (!errored) return;
    noDatevErrorEvent({ variables: { customer_id: customer.customer_id } });
  }, [customer.customer_id, errored, noDatevErrorEvent]);

  // --------------------- CLIENT SELECTION HANDLERS START --------------------- //
  // governs the state of the select all checkbox
  useEffect(() => {
    const allSelected = datev.selected_clients.length === datev.datev_clients.length;
    setAllChecked(allSelected ? ECheckboxState.ON : ECheckboxState.OFF);
  }, [datev.selected_clients, datev.datev_clients]);

  // if there are clients in metadata set selected clients
  useEffect(() => {
    if (
      metaData &&
      metaData.clients &&
      metaData.clients.length &&
      datev &&
      datev.selected_clients &&
      !datev.selected_clients.length &&
      allChecked !== ECheckboxState.OFF
    ) {
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: {
            ...datev,
            selected_clients: metaData.clients,
          },
        },
      });
    }
  }, [allChecked, datev, dispatchAppStatus, metaData]);

  // handle month selection change
  const handleSelectionChange = (form: IFormState): void => {
    const monthPickerData = getMontPickerData("taxSuSaDate", form);
    const { value } = monthPickerData;
    if (datev.selected_date === undefined || value !== datev.selected_date) {
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: { ...datev, selected_date: value },
        },
      });
    }
  };

  // handle client selection via checkboxes
  const handleSelection = (name: string | undefined): void => {
    // if name is missing stop
    if (!name) return;

    // if select all is checked dispatch all clients to appStatus.selected_clients and change state of allChecked to true
    if (name === "selectAll" && allChecked === ECheckboxState.OFF) {
      const selectedClients = datev.datev_clients && datev.datev_clients.map((client) => client.id);
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: { ...datev, selected_clients: selectedClients },
        },
      });
      setAllChecked(ECheckboxState.ON);
      return;
    }

    // if select all is checked and allChecked is true, dispatch empty array to appStatus.selected_clients and change state of allChecked to false
    else if (name === "selectAll" && allChecked === ECheckboxState.ON) {
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: { ...datev, selected_clients: [] },
        },
      });
      setAllChecked(ECheckboxState.OFF);
      return;
    }

    // single client selection
    else {
      const alreadySelected = datev.selected_clients.includes(name);
      if (alreadySelected) {
        const selectedClients = datev.selected_clients.filter((client) => client !== name);
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_DATEV,
          payload: {
            datev: { ...datev, selected_clients: [...selectedClients] },
          },
        });
        return;
      } else {
        const selectedClients = [...datev.selected_clients, name];
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_DATEV,
          payload: {
            datev: { ...datev, selected_clients: [...selectedClients] },
          },
        });
        return;
      }
    }
  };
  // --------------------- CLIENT SELECTION HANDLERS END --------------------- //

  // starts the proccess of client creation, susa upload and alerts fetching
  const handleStartAnalyse = (form: IFormState): void => {
    if (!currentUser.appUser.customer_id) return;
    if (errored) setErrored(false);

    // dispatch clients in process to redux and reset alerts and clients
    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_STATUS,
      payload: {
        ...appStatus,
        alerts: [],
        metaData: {
          ...metaData,
          date: datev?.selected_date,
          clients: [...datev.selected_clients],
        },
        alertsInProgress: {},
      },
    });

    // reset Evens State
    dispatchAppStatus({
      type: EAppStatusActionType.RESET_EVENTS_STATE,
      payload: {},
    });

    // dispatch clients to account metadata
    setMeta({
      variables: {
        data: {
          account_id: currentUser.appUser.user_id,
          date: datev.selected_date,
          clients: [...datev.selected_clients],
        },
      },
    });

    // start client processing
    processDatevClients(datev.selected_clients);
  };

  // searchbar handler, save filtered clients to appStatus.filtered_clients
  const handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const filteredClients = datev.datev_clients.filter((client) => {
      return client?.name.toLowerCase().includes(value.toLowerCase());
    });

    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_DATEV,
      payload: {
        datev: {
          ...datev,
          filtered_clients: filteredClients,
        },
      },
    });
  };

  // reset filtered_clients to appStatus.datev_clients if there is no search value
  useEffect(() => {
    if (!datev.filtered_clients || datev.filtered_clients.length === 0) {
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_DATEV,
        payload: {
          datev: {
            ...datev,
            filtered_clients: datev.datev_clients,
          },
        },
      });
    }
  }, [datev, dispatchAppStatus]);

  return (
    <StyledTile.Wrapper ref={forwardRef} gridArea="view-setup">
      <StyledTile.ContentWrapper>
        <Form onSubmitHandler={handleStartAnalyse} subscribe={handleSelectionChange}>
          <StyledTile.Head>
            <StyledTile.HeadFirstItem>
              <H3>{t("tax/common:main_content.view_setup.caption")}</H3>
            </StyledTile.HeadFirstItem>{" "}
            <StyledViewSetup.SearchBar>
              <FormRow align={AlignmentEnums.CENTER}>
                <Icon size={IconSizeEnums.SMALL} name={IconNameEnums.Q_SEARCH} />
                <Input
                  type={InputTypes.TEXT}
                  variant={IInputVariantEnums.DEFAULT}
                  name={"Suche"}
                  value={""}
                  placeholder="Suche"
                  branded
                  onChange={handleSearchBarChange}
                />
              </FormRow>
            </StyledViewSetup.SearchBar>
          </StyledTile.Head>
          <StyledViewSetup.ErrorLegendWrapper>
            <StyledViewSetup.ErrorLegend>
              <StyledViewSetup.ErrorLegendIcon state={EClientSelection.LOADED}>
                <Icon name={IconNameEnums.Q_CHECKMARK_ON} size={IconSizeEnums.SMALL} />{" "}
              </StyledViewSetup.ErrorLegendIcon>
              <StyledViewSetup.ClientName>{t("legend.all_good")}</StyledViewSetup.ClientName>
            </StyledViewSetup.ErrorLegend>
            <StyledViewSetup.ErrorLegend>
              <StyledViewSetup.ErrorLegendIcon state={EClientSelection.ERRORED}>
                <Icon name={IconNameEnums.Q_CLOSE_CIRCLE} size={IconSizeEnums.SMALL} />
              </StyledViewSetup.ErrorLegendIcon>
              <StyledViewSetup.ClientName>{t("legend.datev_error")}</StyledViewSetup.ClientName>
            </StyledViewSetup.ErrorLegend>
            <StyledViewSetup.ErrorLegend>
              <StyledViewSetup.ErrorLegendIcon state={EClientSelection.NO_SUSAS}>
                <Icon name={IconNameEnums.ATTENTION_1} size={IconSizeEnums.SMALL} />
              </StyledViewSetup.ErrorLegendIcon>
              <StyledViewSetup.ClientName>{t("legend.no_susas")}</StyledViewSetup.ClientName>
            </StyledViewSetup.ErrorLegend>
            <StyledViewSetup.ErrorLegend>
              <StyledViewSetup.ErrorLegendIcon state={EClientSelection.TIME_OUT}>
                <Icon name={IconNameEnums.LATER} size={IconSizeEnums.SMALL} />
              </StyledViewSetup.ErrorLegendIcon>
              <StyledViewSetup.ClientName>{t("legend.time_out")}</StyledViewSetup.ClientName>
            </StyledViewSetup.ErrorLegend>
            <StyledViewSetup.ErrorLegend>
              <StyledViewSetup.ErrorLegendIcon state={EClientSelection.INCOMPATIBLE}>
                <Icon name={IconNameEnums.QUESTION_MARK} size={IconSizeEnums.SMALL} />
              </StyledViewSetup.ErrorLegendIcon>
              <StyledViewSetup.ClientName>{t("legend.account_system")}</StyledViewSetup.ClientName>
            </StyledViewSetup.ErrorLegend>
            <StyledViewSetup.ErrorLegend>
              <StyledViewSetup.ErrorLegendIcon state={EClientSelection.DATAPATH_INVALID}>
                <Icon name={IconNameEnums.ATTENTION_2} size={IconSizeEnums.SMALL} />
              </StyledViewSetup.ErrorLegendIcon>
              <StyledViewSetup.ClientName>{t("legend.data_path")}</StyledViewSetup.ClientName>
            </StyledViewSetup.ErrorLegend>
          </StyledViewSetup.ErrorLegendWrapper>
          <StyledTile.Body>
            <StyledViewSetup.SelectAll>
              <CheckBox
                inverted={false}
                variant={ButtonVariantEnums.DEFAULT}
                name={"selectAll"}
                disabled={disableSelection}
                checked={allChecked}
                // setting key allows the component to reset on state change
                key={Math.random()}
                onMouseClick={handleSelection}
              >
                {t("initial_setup.tax.clients_selection.select_all")}
              </CheckBox>
            </StyledViewSetup.SelectAll>
            <StyledViewSetup.Wrapper>
              {datev.filtered_clients &&
                datev.filtered_clients.map((client) => {
                  const state =
                    alertsInProgress &&
                    alertsInProgress[client.id] &&
                    alertsInProgress[client.id].progress;

                  if (state === "ERRORED") makeErrored();

                  // get icon from state if there is any
                  const icon = alertsInProgress[client.id]?.icon;

                  // if client is in appStatus.selected_clients we show it as selected
                  const checked = datev.selected_clients.some((item) => {
                    return item === client?.id;
                  });

                  return (
                    <FormRow align={AlignmentEnums.SPACE_BETWEEN} key={Math.random()}>
                      <CheckBox
                        inverted={false}
                        variant={ButtonVariantEnums.DEFAULT}
                        name={client?.id}
                        disabled={disableSelection}
                        checked={checked ? ECheckboxState.ON : ECheckboxState.OFF}
                        onMouseClick={handleSelection}
                      >
                        <StyledViewSetup.ClientName>
                          {client?.name}
                          <StyledViewSetup.ClientCode>{client?.number}</StyledViewSetup.ClientCode>
                        </StyledViewSetup.ClientName>
                      </CheckBox>
                      {icon && (
                        <StyledSelection.ClientUploadState state={state}>
                          <Icon name={icon} size={IconSizeEnums.SMALL} />
                        </StyledSelection.ClientUploadState>
                      )}
                    </FormRow>
                  );
                })}
            </StyledViewSetup.Wrapper>
          </StyledTile.Body>
          <FormRow align={AlignmentEnums.CENTER}>
            <StyledViewSetup.Month>
              <MonthPicker
                data={[]}
                name={"taxSuSaDate"}
                disabled={false}
                initialValue={undefined}
                to={toSuSaDate()}
              />
            </StyledViewSetup.Month>

            <ButtonBranded
              type={"submit"}
              variant={ButtonBrandedVariantEnums.PRIMARY}
              inverted={false}
              inline
              disabled={
                datev.selected_clients.length === 0 ||
                datev.selected_date === "" ||
                disableSelection
              }
            >
              {t("initial_setup.tax.clients_selection.button_text")}
            </ButtonBranded>
          </FormRow>
          {errored && (
            <StyledViewSetup.ErrorText>
              <Trans i18nKey={"tax/common:initial_setup.tax.clients_selection.internet_problems"} />
            </StyledViewSetup.ErrorText>
          )}
        </Form>
      </StyledTile.ContentWrapper>
    </StyledTile.Wrapper>
  );
};
