import { FC } from "react";
import { StyledWizardForm } from "../../../../../StyledComponents/styled.wizard-form";
import { ButtonBranded, ButtonBrandedVariantEnums, P } from "@canei/app-components";
import { useHistory } from "react-router-dom";
import { EPublicPaths } from "../../../../../@types/index.d";
import { useTranslation, Trans } from "react-i18next";

export const Success: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(["tax/common"]);
  const handleGoToLogin = (): void => {
    history.push(EPublicPaths.LOGIN);
  };
  return (
    <>
      <StyledWizardForm.FormWrapper columnCount={1}>
        <P>{t("tax/common:wizards.registration.success.info")}</P>
      </StyledWizardForm.FormWrapper>
      <StyledWizardForm.Footer>
        <ButtonBranded
          inverted={false}
          type={"button"}
          inline={true}
          variant={ButtonBrandedVariantEnums.PRIMARY}
          onClick={handleGoToLogin}
        >
          <Trans i18nKey={"tax/common:wizards.registration.success.to_login_page"} />
        </ButtonBranded>
      </StyledWizardForm.Footer>
    </>
  );
};
