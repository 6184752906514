import { RefObject } from "react";
import styled, { css } from "styled-components/macro";

export interface IStyledTileProps {
  gridArea?: string;
  inverted?: boolean;
  ref?: RefObject<HTMLDivElement>;
}

export const DetailPageWrapper = styled.div(({ theme }) => {
  return css`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70vw;
    tr {
      height: ${theme.rem(2.73)};
    }
    padding: ${theme.rem(1.5)} 0;
  `;
});

const ContentWrapper = styled.section<IStyledTileProps>(({ theme, inverted }) => {
  const { _000: backgroundColor } = theme.values.colors.global.greys;
  const { _100: textColor } = theme.values.colors.global.secondary;
  return css`
    background-color: ${inverted ? textColor : backgroundColor};
    color: ${inverted ? backgroundColor : textColor};
    overflow: hidden;
    flex-grow: 1;
  `;
});

const TableBody = styled.div(({ theme }) => {
  return css`
    padding: 0 ${theme.rem(1.75)};
  `;
});

const Header = styled.div(({ theme }) => {
  return css`
    height: auto;
    position: absolute;
    top: ${theme.rem(0.4)};
    z-index: 1;
    width: 70vw;
  `;
});

export const DetailsHeadItem = styled.div(({ theme }) => {
  return css`
    justify-self: auto;
    align-self: center;
    flex-grow: 1;
    display: flex;
    justify-items: left;
    gap: ${theme.rem(1)};
    padding: ${theme.rem(2.75)} ${theme.rem(1.75)} ${theme.rem(0.75)} ${theme.rem(1.75)};
  `;
});

export const DialogHeadColumn = styled.div(({ theme }) => {
  return css`
    margin: 0 ${theme.rem(1.75)};
    background-color: white;
  `;
});

export const DialogHeadItem = styled.div(({ theme }) => {
  return css`
    flex-grow: 1;
    display: flex;
    justify-content: end;
    margin-top: ${theme.rem(-1)};
    gap: ${theme.rem(1)};
    position: relative;
    background-color: white;
  `;
});

export const DialogAlertWrapper = styled.div(({ theme }) => {
  return css`
    justify-self: auto;
    align-self: center;
    display: flex;
    justify-items: left;
    gap: ${theme.rem(1)};
    &:hover ${DialogHoverText} {
      display: block;
    }
  `;
});

const DialogHoverText = styled.section<IStyledTileProps>(({ theme, inverted }) => {
  const { _000: backgroundColor } = theme.values.colors.global.greys;
  const { _100: textColor } = theme.values.colors.global.secondary;
  return css`
    display: none;
    position: absolute;
    top: ${theme.rem(2)};
    box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
      ${theme.values.colors.global.greys._025};
    border-radius: ${theme.rem(1.75)};
    background-color: ${theme.values.colors.global.greys._010};
    color: ${inverted ? backgroundColor : textColor};
    overflow: hidden;
    flex-grow: 1;
    padding: ${theme.rem(2)};
    z-index: 1;
    white-space: pre-line;
    width: ${theme.rem(25)};
  `;
});

export const DialogHelpWrapper = styled.td(({ theme }) => {
  return css`
    position: relative;
    & > span:not(:last-child) {
      font-weight: bold;
      padding: 7.5px 10px 0px 12px;
    }
    border-bottom: 1px solid ${theme.values.colors.global.greys._025};
    & > span:not(:first-child) {
      position: absolute;
      top: ${theme.rem(0.3)};
      left: ${theme.rem(-0.3)};
      line-height: 1;
      margin: 0 !important;
    }
    &:hover ${DialogHoverInsolvency} {
      display: block;
    }
  `;
});

const DialogHoverInsolvency = styled.section<IStyledTileProps>(({ theme, inverted }) => {
  const { _000: backgroundColor } = theme.values.colors.global.greys;
  const { _100: textColor } = theme.values.colors.global.secondary;
  return css`
    display: none;
    position: absolute;
    top: ${theme.rem(2)};
    left: ${theme.rem(-2)};
    box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
      ${theme.values.colors.global.greys._025};
    border-radius: ${theme.rem(1.75)};
    background-color: ${theme.values.colors.global.greys._010};
    color: ${inverted ? backgroundColor : textColor};
    overflow: hidden;
    flex-grow: 1;
    padding: ${theme.rem(2)};
    z-index: 100;
    white-space: pre-line;
    font-size: ${theme.rem(0.8)};
    width: 65vw;
  `;
});

export const StyledDetailsDialog = {
  DetailPageWrapper,
  ContentWrapper,
  TableBody,
  Header,
  DialogHeadColumn,
  DialogHeadItem,
  DialogHoverText,
  DialogHelpWrapper,
  DialogHoverInsolvency,
  DialogAlertWrapper,
};
