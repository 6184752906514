import { FC, useContext, useMemo } from "react";
import { StyledRegisterCTA } from "../styled.register-cta";
import { Logo } from "../../../../shared/Logo";
import { useTranslation, Trans } from "react-i18next";
import { gql, useQuery } from "@apollo/client";
import config from "../../../../../config";
import { RegisterContext } from "../";
import { LoadingIndicator, LoaderTypeEnums } from "@canei/app-components";

const GET_DEFAULT_PRICING = gql`
  query getDefaultPricing($product_key: String!) {
    getDefaultPricing(product_key: $product_key) {
      trial_days
      price
    }
  }
`;

const GET_COUPONS_LIST = gql`
  query getCouponList($product_id: ID!) {
    getCouponList(product_id: $product_id) {
      campaigns {
        coupon_id
        trial_days
        price
      }
    }
  }
`;

export const CTA: FC = () => {
  const tRegisterContext = useContext(RegisterContext);
  const { t } = useTranslation(["tax/common"]);
  const offers: string[] = t("tax/common:CTA.campaign.offers", { returnObjects: true });
  const { data, loading } = useQuery(GET_DEFAULT_PRICING, {
    variables: {
      product_key: config.product_key,
    },
  });
  const { data: couponsList, loading: couponsLoading } = useQuery(GET_COUPONS_LIST, {
    variables: {
      product_id: config.product_id,
    },
  });

  const pricing = useMemo(() => {
    if (tRegisterContext.coupon && couponsList && !couponsLoading) {
      const price = couponsList.getCouponList.campaigns.find(
        (c: { coupon_id: string | undefined }) =>
          c.coupon_id?.toLowerCase() === tRegisterContext.coupon?.toLowerCase()
      );
      if (price) {
        return price;
      }
    }
    if (data && !loading) return data.getDefaultPricing;
  }, [couponsList, couponsLoading, data, loading, tRegisterContext.coupon]);

  return (
    <StyledRegisterCTA.Wrapper>
      <StyledRegisterCTA.Item>
        <Logo isDark={false} />
      </StyledRegisterCTA.Item>
      <StyledRegisterCTA.Headline>
        <Trans i18nKey="tax/common:CTA.campaign.headline" />
      </StyledRegisterCTA.Headline>

      <StyledRegisterCTA.EntryGroup>
        {offers.map((offer, index) => (
          <StyledRegisterCTA.Entry key={index}>{offer}</StyledRegisterCTA.Entry>
        ))}
      </StyledRegisterCTA.EntryGroup>

      <StyledRegisterCTA.Price>
        {loading && !pricing ? (
          <LoadingIndicator type={LoaderTypeEnums.COMPONENT} />
        ) : (
          <>
            <Trans
              i18nKey="tax/common:CTA.campaign.price"
              values={{
                price: pricing?.price.toFixed(2) || "",
                days: pricing?.trial_days || "",
              }}
            />
            <StyledRegisterCTA.TaxNote>
              <Trans i18nKey="tax/common:CTA.campaign.tax_note" />
            </StyledRegisterCTA.TaxNote>
          </>
        )}
      </StyledRegisterCTA.Price>
      <StyledRegisterCTA.Info>{t("tax/common:CTA.campaign.note")}</StyledRegisterCTA.Info>
    </StyledRegisterCTA.Wrapper>
  );
};
