import styled, { css } from "styled-components/macro";

const Wrapper = styled.section(({ theme }) => {
  const { secondary } = theme.values.colors.global;
  return css`
    grid-area: topbar;
    display: flex;
    justify-content: stretch;
    box-shadow: ${theme.rem(18)} 10px 20px ${secondary._025};
    z-index: 1;
  `;
});

const LogoContainer = styled.div(({ theme }) => {
  return css`
    width: ${theme.rem(24)};
    padding: ${theme.rem(2)} ${theme.rem(2)} ${theme.rem(2)} ${theme.rem(4)};
    img {
      display: block;
      max-width: 100%;
      cursor: pointer;
    }
  `;
});

const SuSaSelection = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    width: ${theme.rem(14)};
    padding: 0 0 0 ${theme.rem(3.33)};
  `;
});

const MonthPickerWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    width: ${theme.rem(16)};
    padding: 0 0 0 ${theme.rem(3.33)};
  `;
});

const AddNewSuSa = styled.div(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
  `;
});

export const StyledQTopBar = {
  Wrapper,
  LogoContainer,
  SuSaSelection,
  AddNewSuSa,
  MonthPickerWrapper,
};
