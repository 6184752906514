import {
  ClientTax,
  EFilteredAlarms,
  EFilterGroup,
  EFilterSelection,
  IAppStatusAppSync,
  IAppStatusDatev,
  IAppStatusInfo,
  IAppStatusMetaData,
  IEventSync,
  IFilterOptions,
  IFilterState,
} from "../../../@types/index.d";
import { IClientAlert, IClientAlertTest } from "../../hooks";
import { IClientInProgress } from "../../hooks/useDatev";
// import { EventListenerCodes } from "../../hooks/useEventListener/types.event-listener";
import initialData from "../initialState";
import initialState from "../initialState";

export interface IAppStatusAction {
  type: EAppStatusActionType;
  payload: IAppStatusPayload;
}
export interface IAppStatusPayload {
  appSync?: IAppStatusAppSync;
  datev?: IAppStatusDatev;
  metaData?: IAppStatusMetaData;
  caneiClients?: ClientTax[];
  alertsInProgress?: Record<string, IClientInProgress>;
  alerts?: IClientAlert[];
  alertsTest?: Record<string, IClientAlertTest>;
  filters?: IFilterOptions;
  toggle_alerts?: [EFilteredAlarms, keyof IFilterState];
  toggle_group_id?: [EFilterGroup, string];
  eventsState?: Record<string, IEventSync> | undefined;
  warningDialog?: boolean;
}

export enum EAppStatusActionType {
  CLEAR_STORE = "CLEAR_STORE",
  SET_APP_STATUS = "SET_APP_STATUS",
  SET_APP_SYNC = "SET_APP_SYNC",
  SET_APP_DATEV = "SET_APP_DATEV",
  SET_APP_META = "SET_APP_META",
  SET_APP_CLIENTS = "SET_APP_CLIENTS",
  UPDATE_APP_ALERTS_IN_PROGRESS = "UPDATE_APP_ALERTS_IN_PROGRESS",
  UPDATE_EVENTS_STATE = "UPDATE_EVENTS_STATE",
  RESET_EVENTS_STATE = "RESET_EVENTS_STATE",
  CLEAR_APP_ALERTS_IN_PROGRESS = "CLEAR_APP_ALERTS_IN_PROGRESS",
  SET_APP_ALERTS = "SET_APP_ALERTS",
  UPDATE_APP_ALERTS = "UPDATE_APP_ALERTS",
  CLEAR_APP_ALERTS = "CLEAR_APP_ALERTS",
  SET_APP_FILTERS = "SET_APP_FILTERS",
  TOGGLE_APP_ALERT = "TOGGLE_APP_ALERT",
  TOGGLE_APP_GROUP = "TOGGLE_APP_GROUP",
  TOGGLE_WARNING_DIALOG = "TOGGLE_WARNING_DIALOG",
}

const appStatus = (state = initialState.appStatus, action: IAppStatusAction): IAppStatusInfo => {
  switch (action.type) {
    case EAppStatusActionType.CLEAR_STORE: {
      return initialData.appStatus;
    }
    case EAppStatusActionType.SET_APP_STATUS: {
      if (action.payload === undefined) return state;
      return {
        ...state,
        ...action.payload,
      };
    }
    case EAppStatusActionType.SET_APP_SYNC: {
      if (action.payload.appSync === undefined) return state;
      return {
        ...state,
        appSync: {
          ...state.appSync,
          ...action.payload.appSync,
        },
      };
    }
    case EAppStatusActionType.SET_APP_DATEV: {
      if (action.payload.datev === undefined) return state;
      return {
        ...state,
        datev: {
          ...state.datev,
          ...action.payload.datev,
        },
      };
    }
    case EAppStatusActionType.SET_APP_META: {
      if (action.payload.metaData === undefined) return state;
      return {
        ...state,
        metaData: {
          ...state.metaData,
          ...action.payload.metaData,
        },
      };
    }
    case EAppStatusActionType.SET_APP_CLIENTS: {
      if (action.payload.caneiClients === undefined) return state;
      return {
        ...state,
        caneiClients: [...action.payload.caneiClients],
      };
    }
    case EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS: {
      if (action.payload === undefined) return state;
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      return {
        ...state,
        alertsInProgress: {
          ...state.alertsInProgress,
          [key]: { ...value },
        },
      };
    }
    case EAppStatusActionType.CLEAR_APP_ALERTS_IN_PROGRESS: {
      return {
        ...state,
        alertsInProgress: initialData.appStatus.alertsInProgress,
      };
    }
    case EAppStatusActionType.UPDATE_EVENTS_STATE: {
      if (action.payload === undefined) return state;
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      if (!key && !value) return state;
      return {
        ...state,
        eventsState: {
          ...state.eventsState,
          [key]: value,
        },
      };
    }
    case EAppStatusActionType.RESET_EVENTS_STATE: {
      return {
        ...state,
        eventsState: {},
      };
    }
    case EAppStatusActionType.SET_APP_ALERTS: {
      if (action.payload.alerts === undefined) return state;
      return {
        ...state,
        alerts: [...action.payload.alerts],
      };
    }
    case EAppStatusActionType.SET_APP_FILTERS: {
      if (!action.payload.filters) return state;
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters },
      };
    }
    case EAppStatusActionType.TOGGLE_APP_ALERT: {
      if (!action.payload.toggle_alerts) return state;
      const [filterName, type] = action.payload.toggle_alerts;
      const options = state.filters?.options;
      if (!options) return state;

      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            ...options,
            [filterName]: {
              ...options[filterName],
              [type]:
                options[filterName][type] === EFilterSelection.SHOW
                  ? EFilterSelection.HIDE
                  : EFilterSelection.SHOW,
            },
          },
        },
      };
    }
    case EAppStatusActionType.TOGGLE_APP_GROUP: {
      if (!action.payload.toggle_group_id) return state;
      const options = state.filters?.options;
      if (!options) return state;

      const [group, code] = action.payload.toggle_group_id;
      return {
        ...state,
        filters: {
          options: {
            ...options,
            [group]: {
              ...options[group],
              [code]:
                options[group][code] === EFilterSelection.SHOW
                  ? EFilterSelection.HIDE
                  : EFilterSelection.SHOW,
            },
          },
        },
      };
    }
    case EAppStatusActionType.TOGGLE_WARNING_DIALOG: {
      return {
        ...state,
        warningDialog: action.payload.warningDialog,
      };
    }
    default:
      return state;
  }
};

export default appStatus;
