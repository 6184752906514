import { gql } from "@apollo/client";
import { FileUploadFormat, IconNameEnums, RelationLink } from "@canei/app-components";
import { IAlertMonthData } from "../alerting";

export enum EClientSelection {
  LOADING = "LOADING", // icon LOADING_25
  CLIENT_CREATION = "CLIENT_CREATION",
  SUSA_UPLOADING = "SUSA_UPLOADING", // icon LOADING_50
  ALERTS_SHOULD_START = "ALERTS_SHOULD_START", // icon LOADING_50
  ALERTS_EVENT_SYNC = "ALERTS_EVENT_SYNC", // icon LOADING_75
  ALERTS_LOADING = "ALERTS_LOADING", // icon LOADING_87
  NO_SUSAS = "NO_SUSAS", // icon ATTENTION_1
  TIME_OUT = "TIME_OUT", // icon LATER
  INCOMPATIBLE = "INCOMPATIBLE", // icon QUESTION_MARK
  DATAPATH_INVALID = "DATAPATH_INVALID", // icon
  LOADED = "LOADED", // icon Q_CHECKMARK_ON
  ERRORED = "ERRORED", // icon Q_CLOSE_CIRCLE
}

export interface IClientInProgress {
  datev_id: string;
  client_id?: string;
  progress: EClientSelection;
  months?: IAlertMonthData;
  number?: number;
  name?: string;
  show?: boolean;
  icon?: IconNameEnums;
}

export interface IClientToProcess {
  datevId: string;
  toCreate: boolean;
  toProcess: boolean;
}

export interface IAlertToProcess {
  clientId: string;
}

// -------------------------- upload susas ---------------------------- //

export const UPLOAD_DATEV_JSON = gql`
  mutation uploadDatevJson($file: FileInput!, $covid: ID) {
    result: uploadSuSaAsFile(file: $file, covid: $covid) {
      guid
      covid
    }
  }
`;

export const UPLOAD_MULTIPLE_DATEV_JSON = gql`
  mutation uploadMultipleSuSasAsFile($data: [MultipleUploadInput!]) {
    result: uploadMultipleSuSasAsFile(data: $data) {
      guid
      covid
    }
  }
`;

export interface IDatevJsonFile {
  client_id: string;
  links: RelationLink[];
  content: string;
  lastModified: string;
  name: string;
  size: number;
  type: string;
  date: string;
  format: FileUploadFormat.JSON_DATEV_CONNECT;
  covid?: string;
}

export interface IDatevJsonInput {
  file: IDatevJsonFile;
  covid?: string;
}

export interface IDatevJsonMultipleInput {
  data: IDatevJsonInput[];
}

export interface IDatevJsonResult {
  covid: string;
  guid: string;
}

export interface IUploadSingleVar {
  client_id: string;
  content: string;
  date: string;
  links: RelationLink[];
  covid?: string;
}

// -------------------------- upload susas ---------------------------- //
