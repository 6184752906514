import styled, { css } from "styled-components/macro";

const Wrapper = styled.div(() => {
  return css`
    display: flex;
    position: relative;
  `;
});
const Label = styled.span(() => {
  return css``;
});
const Button = styled.span(() => {
  return css`
    flex-grow: 0;
    align-self: center;
  `;
});

export const StyledFilterable = { Wrapper, Label, Button };
