import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import {
  IDialogKeyEnums,
  useDialogs,
  useDialogContext,
  Button,
  ButtonVariantEnums,
  LoadingIndicator,
  LoaderTypeEnums,
  Input,
  InputTypes,
  IInputVariantEnums,
  P,
  IconNameEnums,
  IconSizeEnums,
  Icon,
  CheckBox,
  ECheckboxState,
} from "@canei/app-components";
import { StyledCustomerUser } from "./styled.customer-user";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ILocalState } from "../../../../@types";
import { StyledSelection } from "../../../../views/local/Private/SetupTax/styled.setup-tax";
import { IDatevClient } from "@canei/datev-connect-client/@types";
import { EActionType } from "./userManagementTypes.d";
import { useLazyQuery } from "@apollo/client";
import { useSetDatevMetaData } from "../../../hooks/useMetaData";
import { GET_META_DATA } from "../../../hooks/useMetaData/metaData-queries";
import { isEqual } from "lodash";

export const Content: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const dialogs = useDialogs();
  const datev = useSelector(({ appStatus: { datev } }: ILocalState) => datev, isEqual);
  const [search, setSearch] = useState<string>("");
  const [searchResponsible, setSearchResponsible] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [allClients, setAllClients] = useState<IDatevClient[]>(
    datev?.datev_clients ? [...datev?.datev_clients] : []
  );
  const [selectedClients, setSelectedClients] = useState<IDatevClient[]>([]);
  const dialogContext = useDialogContext<IUserClientsDialogProps | undefined>(
    IDialogKeyEnums.BASIC,
    undefined
  );
  const [setMeta] = useSetDatevMetaData();
  const [getMeta, { data, loading: metaLoading }] = useLazyQuery(GET_META_DATA, {
    onCompleted: (data) => {
      !data && setSelectedClients([]);
      data &&
        data.result &&
        data.result.datev_clients &&
        setSelectedClients(data.result.datev_clients);
    },
    fetchPolicy: "no-cache",
  });

  // filters the list of all clients to only those that are not selected
  const filteredClients = useMemo(() => {
    if (!datev.datev_clients) return [];

    // filter by responsible person and company name
    return allClients.filter((client) => {
      if (searchResponsible.length)
        return (
          !selectedClients.some((selectedClient) => selectedClient.id === client.id) &&
          client.responsibility &&
          client.responsibility.toLowerCase().includes(searchResponsible.toLowerCase()) &&
          client.name.toLowerCase().includes(search.toLowerCase())
        );
      return (
        !selectedClients.some((selectedClient) => selectedClient.id === client.id) &&
        client.name.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [allClients, datev.datev_clients, search, searchResponsible, selectedClients]);

  // gets the clients_list for user from metadata
  useEffect(() => {
    if (!dialogContext) return;
    if (!dialogContext.user_id) return;
    getMeta({ variables: { user_id: dialogContext?.user_id } });
  }, [dialogContext, dialogContext?.user_id, getMeta]);

  // adds or removes clients from users metadata and selected clients
  const addOrRemoveClients = (client: IDatevClient, type: EActionType): void => {
    if (type === EActionType.ADD_CLIENT) {
      setSelectedClients([...selectedClients, client].sort((a, b) => a.name.localeCompare(b.name)));
      const filteredAllClients = filteredClients.filter((item) => item.id !== client.id);
      setAllClients(filteredAllClients);
      if (search) {
        setSearch("");
        setAllClients(allClients.filter((item) => !selectedClients.includes(item)));
      }
    }
    if (type === EActionType.REMOVE_CLIENT) {
      setAllClients([...filteredClients, client].sort((a, b) => a.name.localeCompare(b.name)));
      const filteredSelectedClients = selectedClients.filter((item) => item.id !== client.id);
      setSelectedClients(filteredSelectedClients);
    }
  };

  // determines if the button for save can be pressed
  const canSave = useMemo(() => {
    if (data && !data.result) return false;
    if (data && data.result && data.result.datev_clients.length !== selectedClients.length)
      return false;
    return true;
  }, [data, selectedClients.length]);

  // saves the selected clients to the user
  const saveMetaClientList = (): void => {
    setLoading(true);
    setMeta({
      variables: {
        data: {
          account_id: dialogContext?.user_id as string,
          datev_clients: selectedClients,
          clients: selectedClients.length === 0 ? [] : data.result ? data.result.clients : [],
          date: selectedClients.length === 0 ? null : data.result ? data.result.date : null,
        },
      },
    })
      .then(({ data }) => {
        data &&
          data.result &&
          data.result.datev_clients &&
          setSelectedClients(data.result.datev_clients);
        setLoading(false);
      })
      .then(() => {
        dialogs.close(IDialogKeyEnums.BASIC);
      });
  };

  // saves the clients filtered by responsible person to the user
  const selectResponsible = (): void => {
    const selected = [...selectedClients, ...filteredClients];

    // set selected clients and remove duplicates to avoid duplicate selection
    setSelectedClients(
      [...selectedClients, ...selected].filter((client, index, self) => {
        return self.findIndex((t) => t.id === client.id) === index;
      })
    );
  };

  // save the search term
  const handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setSearch(value);
  };

  // save the search term
  const handleSearchResponsibility = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setSearchResponsible(value);
  };

  // boolean for if all clients displayed are selected
  const selectAll = useMemo(() => {
    if (!filteredClients.length) return true;
    return false;
  }, [filteredClients.length]);

  // handle select all and deselect all
  const handleSelection = (name: string | undefined): void => {
    if (!selectAll) {
      setSelectedClients(
        [...selectedClients, ...filteredClients].sort((a, b) => a.name.localeCompare(b.name))
      );
    }
    if (selectAll) {
      setSelectedClients([]);
      setAllClients(datev.datev_clients);
    }
  };

  return (
    <StyledCustomerUser.Wrapper margin_bottom>
      {loading && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}

      <h2>{t("tax/common:user_client_dialog.title")}</h2>
      <h3>
        {t("tax/common:user_client_dialog.user")} {dialogContext?.email}
      </h3>
      <P>
        <Trans i18nKey={"tax/common:user_client_dialog.description"} />
      </P>
      <StyledCustomerUser.Footer>
        <Input
          type={InputTypes.TEXT}
          variant={IInputVariantEnums.DEFAULT}
          name={"SuchePerson"}
          value={searchResponsible}
          placeholder="Filter nach verantwortlicher Person (Mandantsverantwortliche*r)"
          branded
          onChange={handleSearchResponsibility}
          disabled={false}
        />
        <Button
          variant={ButtonVariantEnums.PRIMARY}
          onClick={selectResponsible}
          inverted={false}
          type={"button"}
          disabled={!searchResponsible.length}
          inline
        >
          {t("misc.select")}
        </Button>
      </StyledCustomerUser.Footer>

      <StyledCustomerUser.ClientsTitleWrapper>
        <StyledCustomerUser.ClientsTitles>
          <StyledCustomerUser.SelectAll>
            <h3>{t("tax/common:user_client_dialog.all_clients")}</h3>
            <CheckBox
              // inverted={!!searchResponsible.length}
              inverted={false}
              variant={ButtonVariantEnums.DEFAULT}
              name={"selectAll"}
              disabled={false}
              checked={!filteredClients.length ? ECheckboxState.ON : ECheckboxState.OFF}
              // setting key allows the component to reset on state change
              key={Math.random()}
              onMouseClick={handleSelection}
            ></CheckBox>{" "}
          </StyledCustomerUser.SelectAll>

          <h3>{t("tax/common:user_client_dialog.selected_clients")}</h3>
        </StyledCustomerUser.ClientsTitles>
      </StyledCustomerUser.ClientsTitleWrapper>
      <StyledCustomerUser.SelectionWrapper>
        <StyledCustomerUser.ClientListWrapper>
          <StyledCustomerUser.ClientList>
            {filteredClients.map((client) => {
              if (!client) return null;
              return (
                <StyledCustomerUser.ClientWrapper
                  key={Math.random()}
                  onClick={(): void => addOrRemoveClients(client, EActionType.ADD_CLIENT)}
                >
                  <StyledSelection.ClientName>
                    {client?.name}
                    <StyledSelection.ClientCode>{client?.number}</StyledSelection.ClientCode>
                  </StyledSelection.ClientName>
                </StyledCustomerUser.ClientWrapper>
              );
            })}
          </StyledCustomerUser.ClientList>
        </StyledCustomerUser.ClientListWrapper>
        <Icon name={IconNameEnums.TWO_ARROWS_SPIN} size={IconSizeEnums.SMALL} />
        <StyledCustomerUser.ClientListWrapper>
          <StyledCustomerUser.ClientList>
            {metaLoading ? (
              <StyledCustomerUser.ClientWrapper>
                {t("misc.loading")}
              </StyledCustomerUser.ClientWrapper>
            ) : (
              selectedClients.map((client) => {
                if (!client) return null;
                return (
                  <StyledCustomerUser.ClientWrapper
                    key={Math.random()}
                    onClick={(): void => addOrRemoveClients(client, EActionType.REMOVE_CLIENT)}
                  >
                    <StyledSelection.ClientName>
                      {client?.name}
                      <StyledSelection.ClientCode>{client?.number}</StyledSelection.ClientCode>
                    </StyledSelection.ClientName>
                  </StyledCustomerUser.ClientWrapper>
                );
              })
            )}
          </StyledCustomerUser.ClientList>
        </StyledCustomerUser.ClientListWrapper>
      </StyledCustomerUser.SelectionWrapper>
      <StyledCustomerUser.Footer>
        <Input
          type={InputTypes.TEXT}
          variant={IInputVariantEnums.DEFAULT}
          name={"Suche"}
          value={search}
          placeholder="Suche"
          branded
          onChange={handleSearchBarChange}
        />
        <Button
          variant={ButtonVariantEnums.PRIMARY}
          onClick={saveMetaClientList}
          inverted={false}
          type={"button"}
          disabled={canSave}
          inline
        >
          {t("misc.save")}
        </Button>
      </StyledCustomerUser.Footer>
    </StyledCustomerUser.Wrapper>
  );
};

export interface IUserClientsDialogProps {
  user_id?: string;
  email?: string;
}

interface IUserClientsDialogVal {
  open: (props: IUserClientsDialogProps) => void;
}

export const useUserClientsDialog = (): IUserClientsDialogVal => {
  const dialogs = useDialogs();

  return {
    open: (props): void => {
      dialogs.open({
        dialogKey: IDialogKeyEnums.BASIC,
        closable: true,
        content: <Content />,
        flexible: false,
        data: { ...props },
      });
    },
  };
};
