import { FC } from "react";

import { StyledSetup } from "../../../../StyledComponents";
import { useTranslation } from "react-i18next";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Form,
  FormRow,
  Input,
  P,
} from "@canei/app-components";
import { StyledSetupBB } from "./styled.setup-bb";
export const ClientSetup: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  return (
    <StyledSetup.SetupContent.Wrapper>
      <StyledSetup.SetupContent.Caption>
        {t("initial_setup.better_business.client_settings.caption")}
      </StyledSetup.SetupContent.Caption>
      <StyledSetup.SetupContent.Body>
        <P>{t("initial_setup.better_business.client_settings.text")}</P>
        <Form>
          <StyledSetupBB.ListWrapper>
            <StyledSetupBB.ListRow isTitle={true}>
              <StyledSetupBB.ClientName>
                {t("initial_setup.better_business.client_settings.client_name_row_caption")}
              </StyledSetupBB.ClientName>
              <StyledSetupBB.ClientId>
                {t("initial_setup.better_business.client_settings.clients_number_row_caption")}
              </StyledSetupBB.ClientId>
            </StyledSetupBB.ListRow>
            <StyledSetupBB.ListRow>
              <StyledSetupBB.ClientName>Company Name</StyledSetupBB.ClientName>
              <StyledSetupBB.ClientId>
                <Input name={"client-x"} value={""} branded />
              </StyledSetupBB.ClientId>
            </StyledSetupBB.ListRow>
          </StyledSetupBB.ListWrapper>

          <FormRow align={AlignmentEnums.CENTER}>
            <ButtonBranded
              variant={ButtonBrandedVariantEnums.PRIMARY}
              type={"submit"}
              inverted={false}
              inline
            >
              {t("initial_setup.better_business.client_settings.button_text")}
            </ButtonBranded>
          </FormRow>
        </Form>
      </StyledSetup.SetupContent.Body>
    </StyledSetup.SetupContent.Wrapper>
  );
};
