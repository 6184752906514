import { gql } from "@apollo/client";

export const GET_CUSTOMER_PRICING = gql`
  query getCustomerPricing($pricing_id: String!) {
    getCustomerPricing(pricing_id: $pricing_id) {
      trial_days
      price
    }
  }
`;

export const GET_CUSTOMER_STATUS = gql`
  query getCustomerStatus($customer_id: ID!) {
    getCustomerStatus(customer_id: $customer_id) {
      customer_id
      status
      product_id
      pricing_id
      trial_end
      start_date
      canceled_at
      billing_address {
        line_1
        # line_2
        city
        postal
        # country
        email
        telephone
        name
      }
      payment_method {
        type
        last_four
        brand
        bank_code
        exp_month
        exp_year
      }
    }
  }
`;

export const UPDATE_BILLING_ADDRESS = gql`
  mutation updateBillingAddress($params: BillingAddressInput, $customer_id: ID!) {
    updateBillingAddress(params: $params, customer_id: $customer_id) {
      line_1
      # line_2
      city
      postal
      # country
      email
      telephone
      name
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query getSubs($customer_id: ID!) {
    result: getSubscriptions(customer_id: $customer_id) {
      subscription_id
      customer_id
      product_id
      pricing_id
      status
      start_date
      trial_end
      trial_start
    }
  }
`;

export const GET_STRIPE_INTENT = gql`
  query getIntent($customer_id: ID!, $type: PaymentMethodTypes, $covId: ID!) {
    result: getIntent(customer_id: $customer_id, type: $type, covId: $covId) {
      client_secret
      type
    }
  }
`;
