import { FC } from "react";
import { StyledWelcome } from "./styled.welcome";
import { H1 } from "@canei/app-components";
import { Trans, useTranslation } from "react-i18next";
import { IOverviewSectionProps } from "..";

export const Welcome: FC<IOverviewSectionProps<HTMLDivElement>> = ({ forwardRef }) => {
  const { t } = useTranslation(["tax/common"]);

  return (
    <StyledWelcome.Wrapper ref={forwardRef}>
      <H1>{t("tax/common:main_content.welcome.caption")}</H1>
      <Trans i18nKey={"tax/common:main_content.welcome.text"} />
    </StyledWelcome.Wrapper>
  );
};
