import {
  IMainContext,
  IMainContextAction,
  TMainContextActionType,
  EMenuItem,
} from "./types.private-main-context";

export const initialContext: IMainContext = {
  customer: {},
  appContext: {
    menu: [{ value: EMenuItem.TOTAL_ANALYSE }, { value: EMenuItem.VIEW_SETUP }],
  },
  setupContext: {
    pending: undefined,
  },
  datevConnector: undefined,
  datevClients: undefined,
};

export const reducerFnMainContext = (
  state: IMainContext,
  action: IMainContextAction
): IMainContext => {
  switch (action.type) {
    case TMainContextActionType.SET_CONNECTOR:
      if (!action.payload.connector) return state;
      return { ...state, datevConnector: action.payload.connector };
    case TMainContextActionType.SET_SETUP:
      if (!action.payload.setup) return state;
      return { ...state, setupContext: { ...state.setupContext, ...action.payload.setup } };
    case TMainContextActionType.SET_APP_DATA:
      if (!action.payload.app) return state;
      return { ...state, appContext: { ...state.appContext, ...action.payload.app } };
    case TMainContextActionType.SET_CUSTOMER:
      if (!action.payload.customer) return state;

      return {
        ...state,

        customer: { ...state.customer, ...action.payload.customer },
      };
    default:
      return state;
  }
};
