import { brandedValues } from "../Theme/defaultThemeValues";
import {
  DraftClientSetup,
  EDataSyncLevel,
  EDataSyncRefreshRate,
  EDataSyncScope,
  IDataChangedState,
  IDataStore,
  ILocalState,
} from "../../@types/index.d";

import {
  Country,
  CustomerState,
  CustomerType,
  ERevenueCurves,
  SelectClientState,
} from "@canei/app-components";
import { LayoutEnums } from "../Layout/types.layout";
import { IClientInProgress } from "../hooks/useDatev";

const initialEventSync: IDataChangedState = {
  timeStamp: -1,
  pending: false,
  timeout: false,
  refreshRate: EDataSyncRefreshRate.IDLE,
};
const data: ILocalState = {
  appStatus: {
    appSync: undefined,
    datev: {
      datev_clients: [],
      filtered_clients: [],
      selected_clients: [],
      selected_date: undefined,
      connector: undefined,
    },
    metaData: undefined,
    caneiClients: undefined,
    alertsInProgress: {} as Record<string, IClientInProgress>,
    alerts: [],
    filters: undefined,
    eventsState: {},
    warningDialog: false,
  },
  //__typename: TypeNameEnums.INITIAL_DATA,
  currentUser: {
    //__typename: TypeNameEnums.CURRENT_USER,
    authenticating: false,
    username: "",
    email: "",
    temporaryPassword: "",
    authenticated: false,
    forceChange: false,
    errors: [],
    attributes: {
      //__typename: TypeNameEnums.USER_ATTRIBUTES,
      customer_id: "",
      user_id: "",
      name: "",
      sub: "",
    },
    clients: [],
    state: CustomerState.UNKNOWN,
    appUser: {
      //__typename: TypeNameEnums.APP_USER,
      user_id: "",
      customer_id: "",
      alias: "",
      email: "",
      root: false,
      links: [],
      clients: [],
      customer: {
        //__typename: TypeNameEnums.CUSTOMER,
        name: "",
        customer_id: "",
        ou: "",
        links: [],
        contact: {
          first_name: "",
          last_name: "",
          telephone: "",
        },
        address: {
          line_1: null,
          line_2: null,
          postal: null,
          telephone: null,
          city: null,
          country: Country.DEU,
        },
        api_access: false,
        type: CustomerType.direct,
      },
    },
    selectedClient: {
      client_id: null,
      state: SelectClientState.UNKNOWN,
      kpis: [],
    },
  },
  billing: {},
  draftClient: {
    customer_id: "",
    client_id: null,
    name: "",
    fiscal_year: "",
    category: "",
    category_type: "",
    errors: [],
    state: DraftClientSetup.DATA,
    address: {
      line_1: "",
      line_2: "",
      city: "",
      postal: "",
      telephone: "",
      country: Country.DEU,
    },
    customization: {
      report_color: "#003f71",
      dashboard_kpis: [],
      plan_settings: {
        revenue_curve: ERevenueCurves.OF007,
        revenue_growth: 0,
        revenue_ratios: {
          jan: 8.33,
          feb: 8.33,
          mar: 8.34,
          apr: 8.33,
          may: 8.33,
          jun: 8.34,
          jul: 8.33,
          aug: 8.33,
          sep: 8.34,
          oct: 8.33,
          nov: 8.33,
          dec: 8.34,
        },
      },
    },
  },
  errorState: {
    //__typename: TypeNameEnums.AUTH_ERROR,
    errors: [],
  },
  themeOptions: {
    //__typename: TypeNameEnums.THEME_OPTIONS,
    layout: LayoutEnums.SIMPLE,
    branded: {
      //__typename: TypeNameEnums.THEME_OPTIONS_BRANDED,
      logo: {
        ...brandedValues.logo,
      },
      assets: {
        ...brandedValues.assets,
      },
      buttons: {
        ...brandedValues.buttons,
      },
      inputs: {
        ...brandedValues.inputs,
      },
    },
    sideNavExpanded: true,
  },
  dataSync: {
    [EDataSyncLevel.CLIENT]: {
      [EDataSyncScope.CUSTOMER]: initialEventSync,
      [EDataSyncScope.CLIENT]: initialEventSync,
      [EDataSyncScope.SUSA]: initialEventSync,
      [EDataSyncScope.ERROR]: initialEventSync,
      [EDataSyncScope.EVAL]: initialEventSync,
      [EDataSyncScope.FILE]: initialEventSync,
      [EDataSyncScope.KPI]: initialEventSync,
      [EDataSyncScope.LOGO]: initialEventSync,
      [EDataSyncScope.EVAL]: initialEventSync,
      [EDataSyncScope.SUBSCRIPTION]: initialEventSync,
      [EDataSyncScope.WALLET]: initialEventSync,
      [EDataSyncScope.ALERT]: initialEventSync,
    },
    [EDataSyncLevel.CUSTOMER]: {
      [EDataSyncScope.CUSTOMER]: initialEventSync,
      [EDataSyncScope.CLIENT]: initialEventSync,
      [EDataSyncScope.SUSA]: initialEventSync,
      [EDataSyncScope.ERROR]: initialEventSync,
      [EDataSyncScope.EVAL]: initialEventSync,
      [EDataSyncScope.FILE]: initialEventSync,
      [EDataSyncScope.KPI]: initialEventSync,
      [EDataSyncScope.LOGO]: initialEventSync,
      [EDataSyncScope.EVAL]: initialEventSync,
      [EDataSyncScope.SUBSCRIPTION]: initialEventSync,
      [EDataSyncScope.WALLET]: initialEventSync,
      [EDataSyncScope.ALERT]: initialEventSync,
    },
  },
  warmUp: {
    SUSA_TOPIC: {
      CLIENT: -1,
      CUSTOMER: -1,
      ERROR: -1,
      LOGO: -1,
      FILE: -1,
      SUSA: -1,
      USER: -1,
      KPI: -1,
      EVAL: -1,
    },
    FILE_TOPIC: {
      CLIENT: -1,
      CUSTOMER: -1,
      ERROR: -1,
      LOGO: -1,
      FILE: -1,
      SUSA: -1,
      USER: -1,
      KPI: -1,
      EVAL: -1,
    },
  },
  downloads: [],
  uploads: [],
  systemMessages: [],
  dataStore: {} as IDataStore,
};
const initialData = {
  ...data,
};
export default initialData;
