import { Children, FC } from "react";

import { StyledQMenuIcon, StyledQMenuItem, StyledQMenuLabel } from "./styled.menu";
import { Link } from "react-router-dom";
import { Icon, IconNameEnums, IconSizeEnums } from "@canei/app-components";

import { EPrivatePaths } from "../../../../@types/index.d";
import { IMenuProps } from "./index";

export const MenuItem: FC<IMenuProps<EPrivatePaths>> = ({
  children,
  icon,
  label,
  href,
  onClick,
  expanded,
  selected,
}) => {
  const to = { pathname: href || "#", state: { selection: href } };
  const linkProps = { onClick, to };

  return (
    <>
      <StyledQMenuItem selected={selected}>
        <Link {...linkProps}>
          <StyledQMenuIcon>
            <Icon name={icon} size={IconSizeEnums.SMALL} />
          </StyledQMenuIcon>

          <StyledQMenuLabel>{label}</StyledQMenuLabel>
          {Children.count(children) > 0 && (
            <Icon
              name={expanded ? IconNameEnums.CHEVRON_UP : IconNameEnums.CHEVRON_DOWN}
              size={IconSizeEnums.XSMALL}
            />
          )}
        </Link>
        {children}
      </StyledQMenuItem>
    </>
  );
};
