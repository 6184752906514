export enum EProductEnums {
  TAX = "TAX",
  BETTER_BUSINESS = "BETTER_BUSINESS",
  HAUFE_BETTER_BUSINESS = "HAUFE_BETTER_BUSINESS",
}

export enum LayoutEnums {
  SIMPLE, // ex: login page
  COMMON, // ex: dashboard
  SETUP, // ex: client management pages
}
