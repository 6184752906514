import { FC, useEffect, useState, ChangeEvent, useMemo, useContext } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Trans, useTranslation } from "react-i18next";
import {
  Cell,
  Row,
  Table,
  ButtonVariantEnums,
  IconButton,
  Icon,
  IDialogKeyEnums,
  useDialogs,
  LoadingIndicator,
  LoaderTypeEnums,
  IconNameEnums,
  IconSizeEnums,
  LinkButton,
  FormRow,
  Input,
  AlignmentEnums,
  FadeIn,
  Button,
  P,
  IDialogOptions,
  Alert,
  AlertTypeEnums,
  DialogDispatch,
} from "@canei/app-components";
import { useDispatch, useSelector } from "react-redux";
import { ILocalState, UserStateActionTypes } from "../../../../@types/index.d";
import validator from "validator";
import { StyledCustomerUser } from "./styled.customer-user";
import { ICurrentUserAction } from "../../../store/reducers";
import {
  CHANGE_EMAIL,
  CREATE_NEW_USER,
  DELETE_USER,
  GET_USERS,
  IS_MAIL_VALID,
} from "./userManagement-queries";
import { useUserClientsDialog } from "./useUserClientsDialog";
import {
  ICreateNewUser,
  ICreateNewUserVar,
  IGetUsers,
  IGetUsersVar,
  IIsEmailValid,
  IIsEMailValidVar,
  IUser,
} from "./userManagementTypes";
import { isEqual } from "lodash";

export const UserManagement: FC = () => {
  const dialogs = useDialogs();
  const dispatch = useContext(DialogDispatch);
  const { open: handleUserClientSelectionDialog } = useUserClientsDialog();
  const { t } = useTranslation(["quick/common"]);
  const [users, setUsers] = useState<IUser[] | undefined>([]);
  const [deletedUser, setDeletedUser] = useState("");
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [existiert, setExistiert] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [editEmail, setEditEmail] = useState({ showEditEmail: false, newEmail: "" });
  const [state, setState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { isEmail } = validator;
  const Ldispatch = useDispatch<Dispatch<ICurrentUserAction>>();

  // getUser Query
  const [getUsersData, { loading: queryLoading }] = useLazyQuery<IGetUsers, IGetUsersVar>(
    GET_USERS,
    {
      onCompleted: (data) => {
        setUsers(data.getUsers.slice(1));
      },
      fetchPolicy: "no-cache",
    }
  );

  // createUser Mutation
  const [createUserEvent, { loading: mutationLoading }] = useMutation<
    ICreateNewUser,
    ICreateNewUserVar
  >(CREATE_NEW_USER, {
    variables: {
      data: {
        customer_id: currentUser?.appUser?.customer_id as string,
        email: userEmail,
      },
    },
    onCompleted: (data) => {
      const newUser: IUser = data.createNewUser;
      handleUserClientSelection(data.createNewUser.user_id, data.createNewUser.email);
      setUsers((prevState) => [...(prevState as IUser[]), newUser]);
    },
    fetchPolicy: "no-cache",
  });

  // deleteUser Mutation
  const [deleteUserEvent, { loading: deleteLoading }] = useMutation(DELETE_USER, {
    onCompleted: () => {
      setUsers((prevState) =>
        (prevState as IUser[]).filter((item) => item.user_id !== deletedUser)
      );
      setState({
        ...state,
        show: true,
        type: AlertTypeEnums.SUCCESS,
        message: t(
          "quick/common:main_content.top_bar.user_profile.user_management.delete_user_success"
        ),
      });
      setDeletedUser("");
    },
    fetchPolicy: "no-cache",
  });

  // check email does not exist in DB
  const [getIsEMailValid] = useLazyQuery<IIsEmailValid, IIsEMailValidVar>(IS_MAIL_VALID, {
    onCompleted: (data) => {
      if (!data.isEmailValid) {
        setState({
          ...state,
          show: true,
          type: AlertTypeEnums.ERROR,
          message: t("quick/common:main_content.top_bar.user_profile.user_management.mail_exist"),
        });
        setExistiert(true);
        setTimeout(() => {
          setState({ ...state, show: false });
        }, 5000);
      }
      if (userEmail && isValid(userEmail) && data.isEmailValid) {
        createUserEvent();
        setState({
          ...state,
          show: true,
          type: AlertTypeEnums.SUCCESS,
          message: t(
            "quick/common:main_content.top_bar.user_profile.user_management.change_success"
          ),
        });
        setExistiert(false);
        setUserEmail("");
        setShowAddUserForm(false);
      }
    },
    fetchPolicy: "no-cache",
  });

  // change user email
  const [updateCustomerEmail, { loading: editEmailLoading }] = useMutation(CHANGE_EMAIL, {
    onCompleted: () => {
      handleCloseDialog();
      Ldispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          authenticated: false,
        },
      });
    },
    fetchPolicy: "no-cache",
  });

  // get user email entry
  const handleDataChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (existiert) {
      setExistiert(false);
    }
    setUserEmail(e.target.value);
  };

  // email validation
  const isValid = (email: string): boolean => {
    return isEmail(email);
  };

  // handler to toggle the Form
  const handleShowForm = (): void => {
    setShowAddUserForm(!showAddUserForm);
  };

  // handler to add/create user after checking email
  const handleAddUser = (): void => {
    getIsEMailValid({ variables: { email: userEmail } });
  };

  // handler to close main Dialog
  const handleCloseDialog = (): void => {
    dialogs.close(IDialogKeyEnums.USER_MANAGEMENT);
  };

  // handler to delete user and close warning Dialog
  const handleCloseWarningDialog = (user_id: string): void => {
    dispatch({ type: "close", dialogKey: IDialogKeyEnums.WARNING });
    setDeletedUser(user_id);
    deleteUserEvent({ variables: { user_id } });
  };

  // handler to user client selection dialog
  const handleUserClientSelection = (user_id: string, email: string): void => {
    handleUserClientSelectionDialog({ user_id, email });
  };

  // handler to create and open Warning Dialog
  const handleDeleteUserDialog = (user_id: string): void => {
    const title = t(
      "quick/common:main_content.top_bar.user_profile.user_management.warning_dialog_title"
    );
    const msg = t(
      "quick/common:main_content.top_bar.user_profile.user_management.delete_user_message"
    );
    dialogs.open({
      closable: true,
      dialogKey: IDialogKeyEnums.WARNING,
      title,
      footer: (
        <FormRow align={AlignmentEnums.RIGHT}>
          <>
            <Button
              variant={ButtonVariantEnums.DEFAULT}
              inverted={false}
              type={"button"}
              inline={true}
              onClick={(): void => {
                dispatch({ type: "close", dialogKey: IDialogKeyEnums.WARNING });
              }}
            >
              {t("misc.cancel")}
            </Button>
            <Button
              variant={ButtonVariantEnums.WARNING}
              inverted={false}
              type={"button"}
              inline={true}
              onClick={(): void => {
                handleCloseWarningDialog(user_id);
              }}
            >
              {t("misc.delete")}
            </Button>
          </>
        </FormRow>
      ),
      content: <P>{msg}</P>,
    });
  };

  const [isNewEmailValid] = useLazyQuery<IIsEmailValid, IIsEMailValidVar>(IS_MAIL_VALID, {
    onCompleted: (data) => {
      if (!data.isEmailValid) return;
      updateCustomerEmail({
        variables: {
          customer_id: currentUser.appUser.customer_id,
          email: editEmail.newEmail,
        },
      });
    },
    fetchPolicy: "no-cache",
  });

  const handleNewEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditEmail({ ...editEmail, newEmail: e.target.value });
  };
  const handleEditEmail = (): void => {
    if (!isValid(editEmail.newEmail)) return;
    isNewEmailValid({ variables: { email: editEmail.newEmail } });
  };

  useEffect(() => {
    if (currentUser.appUser.customer_id === null) return;
    getUsersData({ variables: { id: currentUser.appUser.customer_id } });
  }, [getUsersData, currentUser.appUser.customer_id]);

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>;
    if (state.show) {
      timeOut = setTimeout(() => {
        setState({ ...state, show: false, type: "", message: "" });
      }, 5000);
    }

    return (): void => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [state]);

  return (
    <StyledCustomerUser.Wrapper>
      <Table width="100%">
        <thead>
          <Row>
            <Cell level="title" width="50%">
              {t("quick/common:main_content.top_bar.user_profile.user_management.administrator")}
            </Cell>
            <Cell level="title">&nbsp;</Cell>
          </Row>
        </thead>
        <tbody>
          {currentUser && (
            <Row>
              <Cell hasBorder={false}>
                {currentUser.appUser.customer.contact.first_name}{" "}
                {currentUser.appUser.customer.contact.last_name}
              </Cell>
              <Cell align="right" hasBorder={false}>
                {editEmail.showEditEmail ? (
                  <StyledCustomerUser.EmailInputWrapper>
                    <input
                      type="text"
                      name="email"
                      placeholder={currentUser.appUser.email}
                      onChange={handleNewEmailChange}
                    />
                    <IconButton
                      icon={<Icon size={IconSizeEnums.XSMALL} name={IconNameEnums.EDIT} />}
                      inverted={false}
                      variant={ButtonVariantEnums.DEFAULT}
                      type={"button"}
                      onClick={(): void => {
                        setEditEmail((editEmail) => ({ ...editEmail, showEditEmail: false }));
                      }}
                    />
                  </StyledCustomerUser.EmailInputWrapper>
                ) : (
                  <>
                    {currentUser.appUser.email}
                    <IconButton
                      icon={<Icon size={IconSizeEnums.XSMALL} name={IconNameEnums.EDIT} />}
                      inverted={false}
                      variant={ButtonVariantEnums.DEFAULT}
                      type={"button"}
                      onClick={(): void => {
                        setEditEmail((editEmail) => ({ ...editEmail, showEditEmail: true }));
                      }}
                    />
                  </>
                )}
              </Cell>
            </Row>
          )}
        </tbody>
      </Table>

      {(mutationLoading || deleteLoading || editEmailLoading) && (
        <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />
      )}

      <Table width="100%">
        <thead>
          <Row>
            <Cell level="title" width="50%">
              {t("quick/common:main_content.top_bar.user_profile.user_management.title")}
            </Cell>
            <Cell align="right">
              <Trans>
                <LinkButton
                  variant={ButtonVariantEnums.PRIMARY}
                  type={"button"}
                  inverted={false}
                  onClick={(): void => {
                    handleShowForm();
                  }}
                >
                  {t("quick/common:main_content.top_bar.user_profile.user_management.add_user")}
                </LinkButton>
              </Trans>
            </Cell>
          </Row>
        </thead>
      </Table>
      <StyledCustomerUser.TableOverFlow>
        <Table width="100%">
          <tbody>
            {users && users.length === 0 ? (
              <Row>
                <Cell width="50%">
                  {queryLoading
                    ? t("misc.loading")
                    : t("quick/common:main_content.top_bar.user_profile.user_management.no_user")}
                </Cell>
                <Cell></Cell>
              </Row>
            ) : (
              users?.map(({ alias, user_id, email }, index) => {
                return (
                  <Row key={index}>
                    <Cell width="50%">{alias}</Cell>
                    <Cell align="right">
                      <StyledCustomerUser.CellContent>{email}</StyledCustomerUser.CellContent>
                      <IconButton
                        icon={<Icon size={IconSizeEnums.XSMALL} name={IconNameEnums.TRASH} />}
                        inverted={false}
                        variant={ButtonVariantEnums.DEFAULT}
                        type={"button"}
                        onClick={(): void => {
                          handleDeleteUserDialog(user_id);
                        }}
                      />
                      <IconButton
                        icon={<Icon size={IconSizeEnums.XSMALL} name={IconNameEnums.EDIT} />}
                        inverted={false}
                        variant={ButtonVariantEnums.DEFAULT}
                        type={"button"}
                        onClick={(): void => {
                          handleUserClientSelection(user_id, email);
                        }}
                      />
                    </Cell>
                  </Row>
                );
              })
            )}
          </tbody>
        </Table>
      </StyledCustomerUser.TableOverFlow>

      {showAddUserForm && (
        <FadeIn>
          <Table width="100%">
            <tbody>
              <Row>
                <Cell>
                  <FormRow align={AlignmentEnums.CENTER}>
                    <Input
                      name={"email"}
                      value={userEmail}
                      branded={true}
                      label={t(
                        "quick/common:main_content.top_bar.user_profile.user_management.email"
                      )}
                      valid={existiert ? false : userEmail ? isValid(userEmail) : true}
                      invalidWarning={
                        !userEmail
                          ? t("misc.required_field")
                          : isValid(userEmail) && !existiert
                          ? ""
                          : t("error.invalid_email")
                      }
                      onChange={handleDataChange}
                      autoFocus
                    ></Input>
                  </FormRow>
                </Cell>
              </Row>
            </tbody>
          </Table>
        </FadeIn>
      )}
      <Table width="100%">
        <tbody>
          <Row>
            <Cell width="50%" hasBorder={false}>
              <FadeIn>
                <FormRow align={AlignmentEnums.LEFT}>
                  {!deleteLoading && !mutationLoading && state.show && (
                    <Alert type={state.type as AlertTypeEnums} closable={false}>
                      {state.message}
                    </Alert>
                  )}
                </FormRow>
              </FadeIn>
            </Cell>
            <Cell width="50%" hasBorder={false}>
              <FormRow align={AlignmentEnums.RIGHT}>
                <Button
                  variant={ButtonVariantEnums.SECONDARY}
                  onClick={handleCloseDialog}
                  inverted={true}
                  type={"button"}
                  inline
                >
                  {t("misc.cancel")}
                </Button>
                {editEmail.showEditEmail ? (
                  <Button
                    variant={ButtonVariantEnums.PRIMARY}
                    onClick={handleEditEmail}
                    inverted={false}
                    type={"button"}
                    inline
                  >
                    {t("misc.change_email")}
                  </Button>
                ) : (
                  <Button
                    variant={ButtonVariantEnums.PRIMARY}
                    onClick={handleAddUser}
                    inverted={false}
                    type={"button"}
                    disabled={!isValid(userEmail) || existiert}
                    inline
                  >
                    {t("misc.save")}
                  </Button>
                )}
              </FormRow>
            </Cell>
          </Row>
        </tbody>
      </Table>
    </StyledCustomerUser.Wrapper>
  );
};

export interface ICustomerUserDialogState {
  key: number;
}
interface ICustomerUserDialogVal {
  open: () => void;
}
export const useUserManagementDialog = (): ICustomerUserDialogVal => {
  const dialogs = useDialogs();

  const options = useMemo((): IDialogOptions<Partial<ICustomerUserDialogState>> => {
    return {
      dialogKey: IDialogKeyEnums.USER_MANAGEMENT,
      title: "Benutzer",
      closable: true,
      content: <UserManagement />,
      flexible: false,
    };
  }, []);
  return {
    open: (): void => {
      dialogs.open(options);
    },
  };
};
