import { ChangeEvent, FC, FocusEvent, useContext, useEffect, useState } from "react";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";
import {
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Input,
  InputTypes,
  LoaderTypeEnums,
  LoadingIndicator,
  StyledQWizardForm,
  isValidLengthString,
} from "@canei/app-components";
import {
  EProgressStep,
  EQRegisterActionType,
  IRegisterData,
  RegisterContext,
  RegisterDispatch,
} from "../";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { useSearchQuery, useValidateEmailAndCoupon } from "../../../../../_lib/hooks";
import config from "../../../../../config";
import { useValidateCoupon } from "../../../../../_lib/hooks/useValidateCoupon";

const { isEmail, isPostalCode } = validator;
export const CustomerData: FC = () => {
  const [phoneInput, setPhoneInput] = useState<boolean>(true);
  const tRegisterContext = useContext(RegisterContext);
  const tRegisterDispatch = useContext(RegisterDispatch);
  const { t } = useTranslation(["datastore", "tax/common"]);
  const [isValidating, setIsValidating] = useState(false);
  const validateEmailAndCoupon = useValidateEmailAndCoupon();
  const validateCoupon = useValidateCoupon();
  const getQuery = useSearchQuery();
  const couponCode = getQuery("coupon");
  const utmCampaignCode = getQuery("utm_campaign") || "";
  const utmSourceCode = getQuery("utm_source") || "";
  const utmMediumCode = getQuery("utm_medium") || "";
  const utmContentCode = getQuery("utm_content") || "";
  const utmTermCode = getQuery("utm_term") || "";
  const adGroupIdCode = getQuery("adgroupid") || "";
  const gclIdCode = getQuery("gclid") || "";
  const lPageCode = getQuery("lpage") || "";

  const isValid = (name: keyof IRegisterData, initial = true): boolean => {
    if (initial && tRegisterContext.form_state.initial) return true;
    switch (name) {
      case "company_name":
      case "first_name":
      case "last_name":
      case "street":
      case "city":
        return isValidLengthString(tRegisterContext[name], 1);
      case "postal_code":
        return (
          tRegisterContext.postal_code !== undefined &&
          isValidLengthString(tRegisterContext.postal_code, 5) &&
          isPostalCode(tRegisterContext.postal_code, "DE")
        );
      case "email":
        return tRegisterContext.email !== undefined && isEmail(tRegisterContext.email);
      case "phone":
        if (isValidLengthString(tRegisterContext.phone, 4) && phoneInput) return true;
    }
    return false;
  };

  const handleDataChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const field = e.target.name as unknown as keyof IRegisterData;
    let value = e.target.value;
    if (tRegisterContext[field] === value) return;

    if (field === "phone") {
      setPhoneInput(isValidPhoneNumber(value, "DE"));
      if (value.startsWith("+490")) {
        value = value.replace("+490", "+49");
      }
    }

    tRegisterDispatch({
      type: EQRegisterActionType.UPDATE_DATA,
      payload: {
        [field]: value,
      },
    });
  };

  const handlePhoneFocus = (e: FocusEvent<HTMLInputElement>): void => {
    if (e.target.value === "+49") return;
    tRegisterDispatch({
      type: EQRegisterActionType.UPDATE_DATA,
      payload: {
        phone: "+49",
      },
    });
  };

  const handleToStepConfirm = (): void => {
    if (tRegisterContext.form_state.initial) {
      tRegisterDispatch({
        type: EQRegisterActionType.UPDATE_DATA,
        payload: {
          form_state: {
            initial: false,
            valid: tRegisterContext.form_state.valid,
          },
        },
      });
    }
    const allFieldsAreValid =
      isValid("company_name", false) &&
      isValid("first_name", false) &&
      isValid("last_name", false) &&
      isValid("street", false) &&
      isValid("postal_code", false) &&
      isValid("city", false) &&
      isValid("email", false) &&
      isValid("phone", false);

    if (!allFieldsAreValid) return;

    if (allFieldsAreValid !== tRegisterContext.form_state.valid) {
      tRegisterDispatch({
        type: EQRegisterActionType.UPDATE_DATA,
        payload: {
          form_state: {
            valid: allFieldsAreValid,
            initial: false,
          },
        },
      });
    }
    tRegisterDispatch({
      type: EQRegisterActionType.UPDATE_DATA,
      payload: {
        phone: parsePhoneNumber(tRegisterContext.phone || "", "DE")?.formatInternational(),
      },
    });
    if (
      allFieldsAreValid ===
      (tRegisterContext.progress.currentStep === EProgressStep.CUSTOMER_DATA)
    ) {
      setIsValidating(true);

      tRegisterContext.email &&
        validateEmailAndCoupon(
          tRegisterContext.email,
          tRegisterContext.coupon || "",
          config.product_id
        )
          .then(({ data }) => {
            if (data) {
              tRegisterDispatch({
                type: EQRegisterActionType.UPDATE_DATA,
                payload: {
                  ...data,
                  progress: {
                    currentStep:
                      data.isEmailValid && data.isCouponValid
                        ? EProgressStep.CONFIRM
                        : EProgressStep.CUSTOMER_DATA,
                    steps: tRegisterContext.progress.steps,
                  },
                },
              });
            }
          })
          .finally(() => {
            setIsValidating(false);
          });
    }
  };

  useEffect(() => {
    if (
      couponCode !== null &&
      couponCode !== "undefined" &&
      couponCode !== "" &&
      couponCode !== tRegisterContext.coupon &&
      tRegisterContext.isCouponValid
    ) {
      validateCoupon(couponCode, config.product_id)
        .then(({ data }) => {
          if (data) {
            tRegisterDispatch({
              type: EQRegisterActionType.UPDATE_DATA,
              payload: {
                ...data,
                coupon: couponCode,
                isCouponValid: data.isValid,
              },
            });
          }
        })
        .catch((err) => {
          return err;
        });
    }
  }, [
    couponCode,
    tRegisterContext.coupon,
    tRegisterContext.isCouponValid,
    tRegisterDispatch,
    validateCoupon,
  ]);

  useEffect(() => {
    if (
      utmCampaignCode !== tRegisterContext.utm_campaign ||
      utmSourceCode !== tRegisterContext.utm_source ||
      utmMediumCode !== tRegisterContext.utm_medium ||
      utmContentCode !== tRegisterContext.utm_content ||
      utmTermCode !== tRegisterContext.utm_term ||
      adGroupIdCode !== tRegisterContext.ad_group_id ||
      gclIdCode !== tRegisterContext.gcl_id ||
      lPageCode !== tRegisterContext.l_page
    ) {
      tRegisterDispatch({
        type: EQRegisterActionType.UPDATE_DATA,
        payload: {
          utm_campaign: utmCampaignCode,
          utm_source: utmSourceCode,
          utm_medium: utmMediumCode,
          utm_content: utmContentCode,
          utm_term: utmTermCode,
          ad_group_id: adGroupIdCode,
          gcl_id: gclIdCode,
          l_page: lPageCode,
        },
      });
    }
  }, [
    tRegisterDispatch,
    tRegisterContext,
    utmCampaignCode,
    utmSourceCode,
    utmMediumCode,
    utmContentCode,
    utmTermCode,
    adGroupIdCode,
    gclIdCode,
    lPageCode,
  ]);

  return (
    <>
      <StyledQWizardForm.Content>
        <StyledQWizardForm.FormWrapper columnCount={1}>
          <StyledQWizardForm.Entry>
            <Input
              name={"company_name"}
              value={tRegisterContext.company_name || ""}
              branded={true}
              label={t("tax/common:wizards.registration.customer_data.labels.company_name")}
              valid={isValid("company_name")}
              invalidWarning={t("misc.required_field")}
              onChange={handleDataChange}
            />
          </StyledQWizardForm.Entry>
        </StyledQWizardForm.FormWrapper>
        <StyledQWizardForm.FormWrapper columnCount={2}>
          <StyledQWizardForm.Entry>
            <Input
              name={"first_name"}
              value={tRegisterContext.first_name || ""}
              branded={true}
              label={t("tax/common:wizards.registration.customer_data.labels.first_name")}
              valid={isValid("first_name")}
              invalidWarning={t("misc.required_field")}
              onChange={handleDataChange}
            />
          </StyledQWizardForm.Entry>
          <StyledQWizardForm.Entry>
            <Input
              name={"last_name"}
              label={t("tax/common:wizards.registration.customer_data.labels.last_name")}
              value={tRegisterContext.last_name || ""}
              branded={true}
              valid={isValid("last_name")}
              invalidWarning={t("misc.required_field")}
              onChange={handleDataChange}
            />
          </StyledQWizardForm.Entry>
        </StyledQWizardForm.FormWrapper>
        <StyledQWizardForm.FormWrapper columnCount={2}>
          <StyledQWizardForm.Entry>
            <Input
              name={"street"}
              label={t("tax/common:wizards.registration.customer_data.labels.street")}
              value={tRegisterContext.street || ""}
              branded={true}
              type={InputTypes.EMAIL}
              valid={isValid("street")}
              invalidWarning={t("misc.required_field")}
              onChange={handleDataChange}
            />
          </StyledQWizardForm.Entry>
          <StyledQWizardForm.FormWrapper columnCount={2}>
            <StyledQWizardForm.Entry>
              <Input
                name={"postal_code"}
                label={t("tax/common:wizards.registration.customer_data.labels.postal_code")}
                value={tRegisterContext.postal_code || ""}
                branded={true}
                type={InputTypes.EMAIL}
                valid={isValid("postal_code")}
                invalidWarning={t("misc.required_field")}
                onChange={handleDataChange}
              />
            </StyledQWizardForm.Entry>
            <StyledQWizardForm.Entry>
              <Input
                name={"city"}
                label={t("tax/common:wizards.registration.customer_data.labels.city")}
                value={tRegisterContext.city || ""}
                branded={true}
                valid={isValid("city")}
                onChange={handleDataChange}
                invalidWarning={t("misc.required_field")}
              />
            </StyledQWizardForm.Entry>
          </StyledQWizardForm.FormWrapper>
        </StyledQWizardForm.FormWrapper>
        <StyledQWizardForm.FormWrapper columnCount={2}>
          <StyledQWizardForm.Entry>
            <Input
              name={"email"}
              label={t("tax/common:wizards.registration.customer_data.labels.email")}
              value={tRegisterContext.email || ""}
              branded={true}
              type={InputTypes.EMAIL}
              valid={isValid("email") && tRegisterContext.isEmailValid}
              invalidWarning={
                tRegisterContext.isEmailValid ? t("misc.required_field") : t("error.invalid_email")
              }
              onChange={handleDataChange}
            />
          </StyledQWizardForm.Entry>
          <StyledQWizardForm.Entry>
            <Input
              name={"phone"}
              label={t("tax/common:wizards.registration.customer_data.labels.phone")}
              value={tRegisterContext.phone || ""}
              branded={true}
              valid={isValid("phone")}
              onChange={handleDataChange}
              onFocus={handlePhoneFocus}
              invalidWarning={
                !phoneInput ? t("error.invalid_phoneNumber") : t("misc.required_field")
              }
              placeholder="+49 xxx xxxxxxx"
            />
          </StyledQWizardForm.Entry>
        </StyledQWizardForm.FormWrapper>
        <StyledQWizardForm.FormWrapper columnCount={2}>
          <StyledQWizardForm.Entry>
            <Input
              name={"coupon"}
              label={t("tax/common:wizards.registration.customer_data.labels.coupon")}
              value={tRegisterContext.coupon || ""}
              placeholder={"Wenn vorhanden"}
              branded={true}
              onChange={handleDataChange}
              disabled={couponCode !== null && couponCode !== "" && tRegisterContext.isCouponValid}
              valid={couponCode === "" || tRegisterContext.isCouponValid}
              invalidWarning={t("error.invalid_coupon")}
            />
          </StyledQWizardForm.Entry>
        </StyledQWizardForm.FormWrapper>
      </StyledQWizardForm.Content>
      <StyledQWizardForm.Footer>
        <ButtonBranded
          inverted={false}
          type={"button"}
          inline={true}
          variant={ButtonBrandedVariantEnums.PRIMARY}
          onClick={handleToStepConfirm}
        >
          {t("misc.next")}
        </ButtonBranded>
      </StyledQWizardForm.Footer>
      {isValidating && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}
    </>
  );
};
