import { FC, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ILocalState, IThemeOptions, ThemeStateActionTypes } from "../../../@types/index.d";
import { Dispatch } from "@reduxjs/toolkit";
import { IThemeOptionsAction } from "../../store/reducers";
import { EProductEnums, LayoutEnums } from "../types.layout";

import { Logo } from "../../../views/shared";

import { StyledSetupLayout } from "./styled.setup-layout";
import { isEqual } from "lodash";

export interface ISetupLayoutProps<M, C> {
  Menu: FC<M>;
  Content: FC<C>;
  product: EProductEnums;
}

export const SetupLayout: FC<ISetupLayoutProps<unknown, unknown>> = ({
  Menu,
  Content,
  product,
}) => {
  const themeOptions = useSelector(({ themeOptions }: ILocalState) => themeOptions, isEqual);
  const dispatch = useDispatch<Dispatch<IThemeOptionsAction>>();
  useEffect(() => {
    if (themeOptions.layout !== LayoutEnums.SETUP) {
      dispatch({
        type: ThemeStateActionTypes.SET_LAYOUT,
        payload: { layout: LayoutEnums.SETUP } as Partial<IThemeOptions>,
      });
    }
  });
  return (
    <StyledSetupLayout.Wrapper>
      <StyledSetupLayout.StepsMenu>
        <Logo isDark={false} product={product} />
        <Menu />
      </StyledSetupLayout.StepsMenu>
      <StyledSetupLayout.StepContent>
        <Content />
      </StyledSetupLayout.StepContent>
    </StyledSetupLayout.Wrapper>
  );
};
