import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  ButtonVariantEnums,
  FormRow,
  IDialogKeyEnums,
  LinkButton,
  useDialogs,
  EQCardSectionTypes,
  QCardSection,
} from "@canei/app-components";
import { FC, useMemo, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import ContactDialog from "./ContactDialog";
import { StyledContact } from "./styled.contact";
import { Trans, useTranslation } from "react-i18next";
import { gql } from "graphql.macro";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { UserStateActionTypes } from "../../../../@types/amplify.d";
import { ICurrentUserAction } from "../../../store/reducers";
import { ILocalState } from "../../../../@types/store";
import { isEqual } from "lodash";

const GET_APP_DOWNLOAD_URL = gql`
  {
    getAppDownloadUrl
  }
`;

export interface IDownloadAppUrl {
  getAppDownloadUrl: string;
}

const GET_APP_VERSION = gql`
  {
    getAppVersion
  }
`;

export interface IAppVersion {
  getAppVersion: string;
}

const USER_DOWNLOAD = gql`
  mutation appActivity($user_id: ID!, $app_version: String, $is_electron: String) {
    userDownloadEvent(user_id: $user_id, app_version: $app_version, is_electron: $is_electron)
  }
`;

export const Contact: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const dialogs = useDialogs();
  const version = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const pjson = require("../../../../../package.json");
    return pjson.version;
  }, []);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const [newVersion, setNewVersion] = useState("");
  const [userDownloadEvent] = useMutation(USER_DOWNLOAD);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();

  const openContactDialog = (): void => {
    return dialogs.open({
      dialogKey: IDialogKeyEnums.SIMPLE,
      content: <ContactDialog />,
      footer: <Footer />,
      closable: true,
      flexible: false,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useQuery<IAppVersion>(GET_APP_VERSION, {
    onCompleted: (data) => {
      // 0=equal, -1=less, 1=greater
      const check = version.localeCompare(data.getAppVersion, undefined, {
        numeric: true,
        sensitivity: "base",
      });
      if (check === -1) {
        setDownloadAvailable(true);
        setNewVersion(data.getAppVersion);
      }
    },
  });

  const [downloadUrl] = useLazyQuery<IDownloadAppUrl>(GET_APP_DOWNLOAD_URL, {
    onCompleted: (data) => {
      const a = document.createElement("a");
      a.href = data.getAppDownloadUrl;
      a.click();
      userDownloadEvent({
        variables: {
          user_id: currentUser.appUser.user_id,
          app_version: newVersion,
          is_electron: "true",
        },
      });
      dispatch({
        type: UserStateActionTypes.CURRENT_USER,
        payload: {
          authenticated: false,
        },
      });
    },
    fetchPolicy: "no-cache",
  });

  const handleAppDownload = (): void => {
    downloadUrl();
  };

  return (
    <StyledContact.Wrapper>
      {downloadAvailable ? (
        <StyledContact.WrapperDownload>
          <QCardSection type={EQCardSectionTypes.DEFAULT}>
            <StyledContact.DownloadText>
              <Trans
                i18nKey="tax/common:wizards.download.new_version.text"
                values={{
                  version: newVersion,
                }}
              />
            </StyledContact.DownloadText>
            <ButtonBranded
              type={"submit"}
              variant={ButtonBrandedVariantEnums.PRIMARY}
              inverted={false}
              onClick={(): void => handleAppDownload()}
              disabled={false}
            >
              {t("tax/common:wizards.download.new_version.button")}
            </ButtonBranded>
          </QCardSection>
        </StyledContact.WrapperDownload>
      ) : (
        ""
      )}
      <StyledContact.WrapperContact>
        <QCardSection type={EQCardSectionTypes.DARK}>
          <StyledContact.ContactText>
            {t("tax/common:main_content.contact.card_text")}
          </StyledContact.ContactText>
          <ButtonBranded
            type={"submit"}
            variant={ButtonBrandedVariantEnums.DEFAULT}
            inverted={false}
            onClick={(): void => openContactDialog()}
            disabled={false}
          >
            {t("tax/common:main_content.contact.button")}
          </ButtonBranded>
        </QCardSection>
      </StyledContact.WrapperContact>
      <StyledContact.Version>{`version: ${version}`}</StyledContact.Version>
    </StyledContact.Wrapper>
  );
};

const Footer: FC = () => {
  const { close } = useDialogs();
  const closeDetailDialog = (): void => {
    close(IDialogKeyEnums.SIMPLE);
  };

  return (
    <FormRow align={AlignmentEnums.CENTER}>
      <LinkButton
        variant={ButtonVariantEnums.PRIMARY}
        type={"button"}
        inverted
        onClick={closeDetailDialog}
      ></LinkButton>
    </FormRow>
  );
};
