import { RefObject } from "react";
import styled, { css } from "styled-components/macro";

export interface IStyledTileProps {
  gridArea?: string;
  inverted?: boolean;
  ref?: RefObject<HTMLDivElement>;
}

export const Wrapper = styled.div<IStyledTileProps>(({ theme, gridArea }) => {
  return css`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    ${gridArea &&
    css`
      grid-area: ${gridArea};
    `}
  `;
});
const ContentWrapper = styled.section<IStyledTileProps>(({ theme, inverted }) => {
  const { _000: backgroundColor } = theme.values.colors.global.greys;
  const { _100: textColor } = theme.values.colors.global.secondary;
  return css`
    box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
      ${theme.values.colors.global.greys._025};
    border-radius: ${theme.rem(1.75)};
    background-color: ${inverted ? textColor : backgroundColor};
    color: ${inverted ? backgroundColor : textColor};
    overflow: hidden;
    flex-grow: 1;
  `;
});

export const Head = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: space-between;
    padding: 0 ${theme.rem(1)};
    width: 100%;
  `;
});
export const HeadItem = styled.div(({ theme }) => {
  return css`
    justify-self: auto;
    align-self: center;
    flex-grow: 1;
    display: flex;
    justify-items: left;
    gap: ${theme.rem(1)};
    padding: ${theme.rem(1.75)};
  `;
});

export const AlertWrapper = styled.div(({ theme }) => {
  return css`
    justify-self: auto;
    align-self: center;
    display: flex;
    justify-items: left;
    gap: ${theme.rem(1)};
    &:hover ${HoverText} {
      display: block;
    }
  `;
});

const HoverText = styled.section<IStyledTileProps>(({ theme, inverted }) => {
  const { _000: backgroundColor } = theme.values.colors.global.greys;
  const { _100: textColor } = theme.values.colors.global.secondary;
  return css`
    display: none;
    position: absolute;
    top: ${theme.rem(5)};
    box-shadow: ${theme.rem(0.25)} ${theme.rem(0.5)} ${theme.rem(1)}
      ${theme.values.colors.global.greys._025};
    border-radius: ${theme.rem(1.75)};
    background-color: ${theme.values.colors.global.greys._010};
    color: ${inverted ? backgroundColor : textColor};
    overflow: hidden;
    flex-grow: 1;
    padding: ${theme.rem(2)};
    z-index: 1;
    white-space: pre-line;
    width: ${theme.rem(25)};
  `;
});

export const HeadFirstItem = styled(HeadItem)(({ theme }) => {
  return css`
    justify-self: flex-start;
    flex-grow: 1;
    font-size: ${theme.rem(1.4)};
    line-height: ${theme.rem(1.8)};
    text-transform: uppercase;
    text-align: left;
    hyphens: auto;
  `;
});

export const HelpWrapper = styled.div(({ theme }) => {
  return css`
    position: relative;
    & > span {
      position: absolute;
      left: ${theme.rem(-1)};
      top: ${theme.rem(0)};
    }
  `;
});

const SearchBar = styled.div(({ theme }) => {
  return css`
    position: relative;
    width: 75%;
    margin-right: ${theme.rem(1)};
    margin-top: ${theme.rem(1.5)};
    & span {
      position: absolute;
      z-index: 1;
      right: ${theme.rem(1)};
      top: ${theme.rem(0.5)};
    }
  `;
});

export const HeadLastItem = styled(HeadItem)(() => {
  return css`
    justify-self: flex-end;
    flex-grow: 2;

    & > div.report {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      & > span {
        text-align: right;
        color: red;
      }
    }
  `;
});

export const ImageWrapper = styled.div(({ theme }) => {
  return css`
    & > img {
      width: 100%;
      max-width: 100%;
    }
  `;
});

export const Body = styled.div(({ theme }) => {
  return css`
    padding: ${theme.rem(1.75)};
    width: 100%;
  `;
});

export const StyledTile = {
  Wrapper,
  Head,
  HeadFirstItem,
  HeadItem,
  HeadLastItem,
  ContentWrapper,
  ImageWrapper,
  Body,
  HoverText,
  HelpWrapper,
  AlertWrapper,
  SearchBar,
};
