const getDates = (): string[] => {
  const date = new Date();
  const dates = [];
  for (let i = 0; i < 36; i++) {
    date.setMonth(date.getMonth() - 1);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    dates.push(month + "/" + year);
  }  
  return dates;
};

const dateList = getDates();
export { dateList };
