import { EAlarmLevel } from "@canei/app-components";
import { EFilterSelection, TFilterOptions } from "../../../../../../@types/index.d";
import { IAlertComparison, IAlertDetail } from "../../../../../../_lib/hooks";

export interface IAlertVisibility {
  alert_id: string;
  prev_month: IAlert;
  prev_year_avg_month: IAlert;
  prev_year_cumulated: IAlert;
  critical: IAlert;
  details: [IAlertDetail];
  not_applicable: boolean;
  entryVisible: boolean;
}

export interface IAlert {
  alarm: EAlarmLevel;
  show: boolean;
}

export const isVisible = (
  entry: IAlertComparison,
  options: TFilterOptions | undefined,
  visible = true
): IAlertVisibility => {
  const modEntry = {
    alert_id: entry.alert_id,
    prev_month: { alarm: entry.prev_month, show: false },
    prev_year_avg_month: { alarm: entry.prev_year_avg_month, show: false },
    prev_year_cumulated: { alarm: entry.prev_year_cumulated, show: false },
    critical: { alarm: entry.critical, show: false },
    details: entry.details,
    not_applicable: entry.not_applicable,
    entryVisible: true,
  };

  if (modEntry.prev_month.alarm && modEntry.prev_month.alarm === EAlarmLevel.INVALID)
    modEntry.entryVisible = false;
  if (
    modEntry.prev_year_avg_month.alarm &&
    modEntry.prev_year_avg_month.alarm === EAlarmLevel.INVALID
  )
    modEntry.entryVisible = false;
  if (
    modEntry.prev_year_cumulated.alarm &&
    modEntry.prev_year_cumulated.alarm === EAlarmLevel.INVALID
  )
    modEntry.entryVisible = false;
  if (modEntry.critical.alarm && modEntry.critical.alarm === EAlarmLevel.INVALID)
    modEntry.entryVisible = false;

  if (
    modEntry.prev_month.alarm === null &&
    modEntry.prev_year_avg_month.alarm === null &&
    modEntry.prev_year_cumulated.alarm === null &&
    modEntry.critical.alarm === null
  )
    modEntry.entryVisible = false;
  if (!options) return modEntry;
  const { PREV_MONTH, AVERAGE, CUMULATIVE, CRITICAL_VALUE, DESC } = options;

  if (!(DESC[entry.alert_id] === EFilterSelection.SHOW)) modEntry.entryVisible = false;

  if (
    PREV_MONTH?.isConsiderable === EFilterSelection.SHOW &&
    modEntry.prev_month.alarm === EAlarmLevel.CONSIDERABLE
  )
    modEntry.prev_month.show = true;
  if (
    PREV_MONTH.isCritical === EFilterSelection.SHOW &&
    modEntry.prev_month.alarm === EAlarmLevel.CRITICAL
  )
    modEntry.prev_month.show = true;

  if (
    AVERAGE?.isConsiderable === EFilterSelection.SHOW &&
    modEntry.prev_year_avg_month.alarm === EAlarmLevel.CONSIDERABLE
  )
    modEntry.prev_year_avg_month.show = true;
  if (
    AVERAGE.isCritical === EFilterSelection.SHOW &&
    modEntry.prev_year_avg_month.alarm === EAlarmLevel.CRITICAL
  )
    modEntry.prev_year_avg_month.show = true;

  if (
    CUMULATIVE?.isConsiderable === EFilterSelection.SHOW &&
    modEntry.prev_year_cumulated.alarm === EAlarmLevel.CONSIDERABLE
  )
    modEntry.prev_year_cumulated.show = true;
  if (
    CUMULATIVE.isCritical === EFilterSelection.SHOW &&
    modEntry.prev_year_cumulated.alarm === EAlarmLevel.CRITICAL
  )
    modEntry.prev_year_cumulated.show = true;

  if (
    CRITICAL_VALUE?.isConsiderable === EFilterSelection.SHOW &&
    modEntry.critical.alarm === EAlarmLevel.CONSIDERABLE
  )
    modEntry.critical.show = true;
  if (
    CRITICAL_VALUE.isCritical === EFilterSelection.SHOW &&
    modEntry.critical.alarm === EAlarmLevel.CRITICAL
  )
    modEntry.critical.show = true;

  if (
    !modEntry.prev_month.show &&
    !modEntry.prev_year_avg_month.show &&
    !modEntry.prev_year_cumulated.show &&
    !modEntry.critical.show
  )
    modEntry.entryVisible = false;
  if (!visible) modEntry.entryVisible = false;

  return modEntry;
};
