import {
  Client,
  ClientCustomizations,
  CustomerState,
  ISelectedClient,
  IUserInfo,
  SelectClientState,
} from "@canei/app-components";
import initialData from "../initialState";
import { UserStateActionTypes } from "../../../@types/index.d";

export interface ICurrentUserActionPayload extends Omit<IUserInfo, "selectedClient"> {
  selectedClient: Partial<ISelectedClient>;
  customization: Partial<ClientCustomizations>;
}

export interface ICurrentUserAction {
  type: UserStateActionTypes;
  payload: Partial<ICurrentUserActionPayload>;
}
const getSelectedClientData = (state: IUserInfo): Client | undefined => {
  const { clients, selectedClient } = state;
  return clients.find((client) => selectedClient.client_id === client.client_id);
};
const setSelectedClientData = (state: IUserInfo, payload: Client): Client[] => {
  const { clients, selectedClient } = state;
  return clients.map((client) => {
    if (selectedClient.client_id === client.client_id) {
      return {
        ...client,
        ...payload,
      };
    }
    return client;
  });
};
const currentUser = (
  state: IUserInfo = initialData.currentUser,
  action: ICurrentUserAction
): IUserInfo => {
  const client = getSelectedClientData(state);
  switch (action.type) {
    case UserStateActionTypes.CURRENT_USER:
      return {
        ...state,
        ...action.payload,
        selectedClient: {
          ...state.selectedClient,
          ...action.payload.selectedClient,
        },
      };
    case UserStateActionTypes.CURRENT_USER_STATE:
      return { ...state, state: action.payload?.state || CustomerState.UNKNOWN };
    case UserStateActionTypes.UPDATE_CLIENTS_LIST:
      const clients = action.payload.clients?.map(({ client_id }) => client_id) || [];

      return action.payload?.clients
        ? {
            ...state,
            state: CustomerState.READY,
            clients: action.payload?.clients || [],
            appUser: {
              ...state.appUser,
              clients,
            },
          }
        : state;

    case UserStateActionTypes.SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          ...action.payload.selectedClient,
        },
      };
    case UserStateActionTypes.SET_SELECTED_CLIENT_UNKNOWN:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          ...initialData.currentUser.selectedClient,
          state: SelectClientState.UNKNOWN,
        },
      };
    case UserStateActionTypes.SET_SELECTED_CLIENT_DRAFT:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: SelectClientState.DRAFT,
        },
      };
    case UserStateActionTypes.SET_SELECTED_CLIENT_PENDING:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          ...action.payload.selectedClient,
          state: SelectClientState.PENDING,
        },
      };
    case UserStateActionTypes.SET_SELECTED_CLIENT_INITIAL:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: SelectClientState.INITIAL,
        },
      };
    case UserStateActionTypes.SET_SELECTED_CLIENT_READY:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: SelectClientState.READY,
        },
      };
    case UserStateActionTypes.UPDATE_SELECTED_CLIENT_DATA:
      const newClient = (action.payload?.clients || [])[0];
      if (newClient === undefined) {
        return state;
      }

      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          kpis: newClient.customization.dashboard_kpis || [],
        },
        clients: state.clients.map((client) =>
          client.client_id !== newClient.client_id ? client : newClient
        ),
      };
    case UserStateActionTypes.UPDATE_KPI_LIST:
      if (client === undefined) return state;
      return {
        ...state,
        clients: setSelectedClientData(state, {
          ...client,
          customization: {
            ...client.customization,
            dashboard_kpis: action.payload.customization?.dashboard_kpis || [],
          },
        }),
      };

    case UserStateActionTypes.UPDATE_UPLOAD_SETTINGS:
      if (client === undefined) return state;

      return {
        ...state,
        clients: setSelectedClientData(state, {
          ...client,
          customization: {
            ...client.customization,
            upload_settings: action.payload.customization?.upload_settings,
          },
        }),
      };
    case UserStateActionTypes.UPDATE_EVALUATION_DATA:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          evaluation: {
            ...state.selectedClient.evaluation,
            ...action.payload.selectedClient?.evaluation,
          },
        },
      };
    case UserStateActionTypes.UPDATE_UPLOADS_LIST:
      const clientState =
        action.payload.selectedClient?.uploads?.list?.length === 0
          ? SelectClientState.DRAFT
          : state.selectedClient.state;

      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          state: clientState,
          uploads: {
            ...action.payload.selectedClient?.uploads,
          },
        },
      };
    case UserStateActionTypes.CLEAR_STORE:
      return initialData.currentUser;

    default:
      return state;
  }
};
export default currentUser;
