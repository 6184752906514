import styled, { css } from "styled-components/macro";

interface ITextWrapper {
  bold?: boolean;
}

const Wrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: justify;
    justify-content: space-between;
    width: ${theme.rem(45)};
    height: ${theme.rem(20)};
    padding: ${theme.rem(0)} ${theme.rem(3)} ${theme.rem(3)} ${theme.rem(3)};
  `;
});

const TextWrapper = styled.div<ITextWrapper>(({ theme, bold }) => {
  return css`
    text-align: center;
    font-weight: ${bold ? "bold" : "normal"};
  `;
});

const Minutes = styled.span(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.primary._085};
  `;
});

const BtnWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    width: 100%;
    justify-content: space-around;
  `;
});

export const StyledWarningDialog = {
  Wrapper,
  TextWrapper,
  Minutes,
  BtnWrapper,
};
