import styled, { css } from "styled-components/macro";
import { rgba } from "polished";
import { IFilterItem } from "./FilterByAlertType";

const Wrapper = styled.div(({ theme }) => {
  return css`
    display: block;
    position: relative;
  `;
});

const List = styled.div(({ theme }) => {
  return css`
    background-color: ${theme.values.colors.global.greys._010};
    border-radius: ${theme.rem(2 / 3)};
    position: relative;
    padding: ${theme.rem(1)};
    box-shadow: ${theme.rem(1 / 3)} ${theme.rem(2 / 3)} ${theme.rem(4 / 3)}
      ${rgba(theme.values.colors.global.greys._100, 0.29)};
  `;
});
const ListItem = styled.div(({ theme }) => {
  return css`
    display: flex;
    gap: ${theme.rem(2 / 3)};
    align-items: center;
    padding: 0 ${theme.rem(1)};
    transition: background-color 300ms;
    background-color: transparent;
    :not(:last-child) {
      margin-bottom: ${theme.rem(1 / 3)};
    }
    border-radius: ${theme.rem(2 / 3)};
    cursor: pointer;
    :hover {
      background-color: ${rgba(theme.values.colors.global.greys._000, 0.5)};
    }
  `;
});
const Selection = styled.div<Pick<IFilterItem, "selected">>(({ theme, selected }) => {
  const { success } = theme.values.colors.global.validation;

  return css`
    color: ${theme.values.colors.global.greys._055};
    svg,
    svg * {
      fill: ${selected ? success : "currentColor"};
    }
  `;
});
const Text = styled.div(({ theme }) => {
  return css``;
});

export const StyledFilterSelector = { Wrapper, List, ListItem, Selection, Text };
