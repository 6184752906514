import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";
export interface IFormTemplateProps {
  area: string;
  maxWidth?: string;
}

const Wrapper = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: grid;
        grid-template-areas:
          "invoice invoice "
          "company company "
          "street street "
          "zip city"
          "email email"
          "payment payment"
          "payment_option payment_option"
          "name name"
          "card_number card_number"
          "iban iban"
          "valid_until cvc"
          "error error"
          "iban_text iban_text";
        grid-column-gap: ${theme.rem(2)};
        grid-row-gap: ${theme.rem(2)};
        grid-template-columns: repeat(2, 1fr);
      `,
    },
    LARGE: {
      style: css`
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          "invoice invoice payment payment"
          "company company payment_option payment_option"
          "street street name name"
          "zip city card_number card_number"
          "email email cvc valid_until"
          "error error . ."
          "iban_text iban_text iban_text iban_text";
      `,
    },
  });
});
const Entry = styled.div<IFormTemplateProps>(({ theme, area, maxWidth }) => {
  return css`
    grid-area: ${area};
    max-width: ${maxWidth ? "fit-content" : ""};
  `;
});

const SectionTitle = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(1.2)};
    font-weight: ${theme.values.fontWeight.bold};
  `;
});

const IbanText = styled.div(({ theme }) => {
  return css`
    margin-top: ${theme.rem(-2)};
    text-align: justify;
    font-size: 12px;
  `;
});

const StateText = styled.div(({ theme }) => {
  return useResponsive({
    LARGE: {
      style: css`
        margin-top: ${theme.rem(0)};
      `,
    },
  });
});

export const StyledPaymentForm = { Wrapper, SectionTitle, Entry, IbanText, StateText };
