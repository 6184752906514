import { FC } from "react";
import { SimpleLayout } from "../../../../_lib/Layout";

import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  ButtonBranded,
  ButtonBrandedVariantEnums,
} from "@canei/app-components";
import { StyledSelectSoftware } from "./styled.select-software";
import { Logo } from "../../../shared";
import { EPublicPaths } from "../../../../@types/index.d";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EProductEnums } from "../../../../_lib/Layout/types.layout";

export const SelectSoftware: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(["tax/common"]);

  const handleGoToTax = (): (() => void) => {
    return (): void => {
      history.push(EPublicPaths.LOGIN);
    };
  };
  const handleGoToBB = (): (() => void) => {
    return (): void => {
      history.push(EPublicPaths.BETTER_BUSINESS);
    };
  };

  return (
    <SimpleLayout>
      <StyledSelectSoftware.Wrapper>
        <Card>
          <CardTitle>
            <Logo product={EProductEnums.BETTER_BUSINESS} />
          </CardTitle>
          <CardBody>{t("tax/common:product_selection.h_b_b.text")}</CardBody>
          <CardFooter>
            <ButtonBranded
              type="button"
              variant={ButtonBrandedVariantEnums.PRIMARY}
              inverted={false}
              onClick={handleGoToBB()}
              inline
              disabled
            >
              {t("tax/common:product_selection.h_b_b.button_text")}
            </ButtonBranded>
          </CardFooter>
        </Card>
        <Card>
          <CardTitle>
            <Logo />
          </CardTitle>
          <CardBody>{t("tax/common:product_selection.tax.text")}</CardBody>
          <CardFooter>
            <ButtonBranded
              type="button"
              variant={ButtonBrandedVariantEnums.PRIMARY}
              inverted={false}
              onClick={handleGoToTax()}
              inline
            >
              {t("tax/common:product_selection.tax.button_text")}
            </ButtonBranded>
          </CardFooter>
        </Card>
      </StyledSelectSoftware.Wrapper>
    </SimpleLayout>
  );
};
