import styled, { css } from "styled-components/macro";
import { useResponsive } from "@canei/app-components";

const Wrapper = styled.div(({ theme }) => {
  return useResponsive({
    MINI: {
      style: css`
        display: flex;
        flex-wrap: wrap;
      `,
    },
    MEDIUM: {
      style: css`
        width: min(${theme.rem(70)}, 90%);
      `,
    },
  });
});
const FormWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
});
const CTAWrapper = styled.div(({ theme }) => {
  return css`
    text-align: center;
    align-self: center;
    > :last-child {
      margin-top: ${theme.rem(1.3)};
    }
  `;
});
const CTAHeadline = styled.div(({ theme }) => {
  return css`
    font-size: ${theme.rem(1.4)};
    margin-bottom: ${theme.rem(2)};
  `;
});

export const StyledLogin = { Wrapper, FormWrapper, CTAWrapper, CTAHeadline };
