import styled, { css } from "styled-components/macro";

const Wrapper = styled.div(({ theme }) => css``);

const Version = styled.p(
  ({ theme }) => css`
    color: ${theme.values.colors.global.greys._000};
    font-size: ${theme.rem(1)};
    text-align: center;
    margin-top: ${theme.rem(-1)};
  `
);

const WrapperContact = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    border-radius: ${theme.rem(1)};
    overflow: hidden;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    max-height: 200px;
    margin-bottom: ${theme.rem(2)};
  `
);

const WrapperDownload = styled.div(
  ({ theme }) => css`
    & button {
      margin: 0 auto;
      width: 75%;
    }
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    border-radius: ${theme.rem(1)};
    overflow: hidden;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    max-height: 200px;
    padding: ${theme.rem(2)};
    margin-bottom: ${theme.rem(2)};
  `
);

const ContactText = styled.span(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.greys._000};
    font-size: ${theme.rem(1.22)};
    padding: ${theme.rem(0.5)};
    margin-bottom: ${theme.rem(0.5)};
  `;
});

const DownloadText = styled.span(({ theme }) => {
  return css`
    color: ${theme.values.colors.global.greys._020};
    font-size: ${theme.rem(1.22)};
    padding-left: ${theme.rem(2.5)};
    padding-right: ${theme.rem(2.5)};
    padding-bottom: ${theme.rem(0.5)};
    margin-bottom: ${theme.rem(0.5)};
  `;
});

export const StyledContact = {
  Wrapper,
  Version,
  WrapperContact,
  WrapperDownload,
  ContactText,
  DownloadText,
};
