import { FC } from "react";
import styled, { css } from "styled-components/macro";
import { ReactComponent as WaterMarkSvg } from "./wasserzeichen.svg";
import { IColors } from "@canei/app-components";

export interface IWaterMarkProps {
  type: keyof IColors;
}

const StyledWaterMark = styled.div<IWaterMarkProps>(({ theme, type }) => {
  const { _085 } = theme.values.colors.global[type];
  return css`
    position: fixed;
    height: 100%;
    max-height: 75vh;
    color: ${_085};
    right: -10%;
    bottom: 10%;

    z-index: -1;
    > svg {
      height: 100%;
      max-height: 100%;
    }
  `;
});
export const Watermark: FC<IWaterMarkProps> = ({ children, ...props }) => {
  return (
    <StyledWaterMark {...props}>
      <WaterMarkSvg />
    </StyledWaterMark>
  );
};
