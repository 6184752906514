import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { StyledTile } from "../../../../../StyledComponents";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  ButtonVariantEnums,
  Cell,
  CellProps,
  EAlarmLevel,
  FormRow,
  H3,
  Icon,
  IconButton,
  IconNameEnums,
  IconSizeEnums,
  IDialogKeyEnums,
  IInputVariantEnums,
  Input,
  InputTypes,
  LinkButton,
  LoaderTypeEnums,
  LoadingIndicator,
  Row,
  Table,
  TriStateAlarm,
  useDialogs,
} from "@canei/app-components";

import { IAlertComparison, IClientAlert, useGetAlertsOfClient } from "../../../../../_lib/hooks";

import { useSetDatevMetaData } from "../../../../../_lib/hooks/useMetaData";

import { Filterable } from "./Filterable";
import { IOverviewSectionProps } from "..";
import DetailsDialog from "./DetailsDialog";
import * as visLib from "./Filterable/dashboardFilterLib";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  ClientTax,
  EFilteredAlarms,
  EFilterGroup,
  EFilterSelection,
  ILocalState,
} from "../../../../../@types/index.d";
import {
  EAppStatusActionType,
  IAppStatusAction,
} from "../../../../../_lib/store/reducers/appStatus";
import {
  IAbnormalityClient,
  IAbnormalityGeneral,
  IAbnormalityReport,
  IDataForFunc,
  IFilterTypeTwo,
  IParamsVar,
} from "../../../../../@types/reports.d";
import { StyledTotalAnalyze } from "./styled.total-analyze";
import { ReactElement } from "react";
import { isEqual } from "lodash";

export const CLIENT_DETAIL_PAGE_VISIT = gql`
  mutation userVisitPageEvent($user_id: ID!, $page_id: String) {
    userVisitPageEvent(user_id: $user_id, page_id: $page_id)
  }
`;

const GET_ABNORMALITY_REPORT = gql`
  query getAbnormalityReport($params: AbnormalityReportInput!) {
    getAbnormalityReport(params: $params)
  }
`;

export const TotalAnalyze: FC<IOverviewSectionProps<HTMLDivElement>> = ({ forwardRef }) => {
  const { t } = useTranslation(["tax/common"]);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const appStatus = useSelector(({ appStatus }: ILocalState) => appStatus, isEqual);
  const { datev, metaData, filters, alerts, caneiClients, warningDialog } = useSelector(
    (state: ILocalState) => state.appStatus,
    isEqual
  );
  const alertsInProgress = useSelector(
    ({ appStatus: { alertsInProgress } }: ILocalState) => alertsInProgress,
    isEqual
  );
  const dialogs = useDialogs();
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const [setMeta] = useSetDatevMetaData();
  const [filteredAlerts, setFilteredAlerts] = useState<IClientAlert[] | undefined>(alerts);
  const { startAlertsDashboard } = useGetAlertsOfClient();

  const [getAbnormalityReport, { loading }] = useLazyQuery<IAbnormalityReport>(
    GET_ABNORMALITY_REPORT,
    {
      onCompleted: (data) => {
        const a = document.createElement("a");
        a.href = data.getAbnormalityReport;
        a.download = "abnormalityReport";
        a.click();
      },
      fetchPolicy: "no-cache",
    }
  );

  const handleResetAllFilters = (): void => {
    const desc = filters?.options?.DESC || {};
    const ctg = filters?.options?.CATEGORY || {};

    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_FILTERS,
      payload: {
        filters: {
          options: {
            [EFilteredAlarms.PREV_MONTH]: {
              isCritical: EFilterSelection.SHOW,
              isConsiderable: EFilterSelection.SHOW,
            },
            [EFilteredAlarms.AVERAGE]: {
              isCritical: EFilterSelection.SHOW,
              isConsiderable: EFilterSelection.SHOW,
            },
            [EFilteredAlarms.CUMULATIVE]: {
              isCritical: EFilterSelection.SHOW,
              isConsiderable: EFilterSelection.SHOW,
            },
            [EFilteredAlarms.CRITICAL_VALUE]: {
              isCritical: EFilterSelection.SHOW,
              isConsiderable: EFilterSelection.SHOW,
            },
            DESC: Object.entries(desc).reduce(
              (p, [key]) => ({ ...p, [key]: EFilterSelection.SHOW }),
              {}
            ),
            CATEGORY: Object.entries(ctg).reduce(
              (p, [key]) => ({ ...p, [key]: EFilterSelection.SHOW }),
              {}
            ),
          },
        },
      },
    });
  };

  const [userVisitPageEvent] = useMutation(CLIENT_DETAIL_PAGE_VISIT, {
    fetchPolicy: "no-cache",
  });

  // grab alerts on dashboard load
  useEffect(() => {
    if (!caneiClients || !caneiClients.length) return;
    if (
      datev &&
      datev.selected_clients &&
      datev.selected_clients.length &&
      !Object.keys(alertsInProgress).length
    ) {
      if (!currentUser.appUser.customer_id) return;

      const filteredSelected = datev.selected_clients.filter((client) =>
        caneiClients.some((c) => c.name === client)
      );

      // check for discrepancies between selected clients and canei clients (possible due to incompatible account system)
      if (filteredSelected.length !== datev.selected_clients.length) {
        // dispatch clients to account metadata and redux metadata
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_STATUS,
          payload: {
            ...appStatus,
            metaData: {
              ...metaData,
              clients: [...filteredSelected],
            },
            datev: {
              ...datev,
              selected_clients: [...filteredSelected],
            },
          },
        });
        setMeta({
          variables: {
            data: {
              account_id: currentUser.appUser.user_id,
              clients: [...filteredSelected],
            },
          },
        });
      }

      // start client processing
      startAlertsDashboard(filteredSelected);
    }
  }, [
    alerts,
    alertsInProgress,
    appStatus,
    caneiClients,
    currentUser.appUser.customer_id,
    currentUser.appUser.user_id,
    datev,
    dispatchAppStatus,
    metaData,
    metaData?.clients,
    setMeta,
    startAlertsDashboard,
  ]);

  // fill filtered alerts
  useEffect(() => {
    if (alerts && filteredAlerts && alerts.length === filteredAlerts.length) return;
    if (!alerts) setFilteredAlerts(undefined);
    setFilteredAlerts(alerts);
  }, [alerts, filteredAlerts]);

  const isVisible = useCallback(
    (entry: IAlertComparison) => {
      // if (alertsLoading || !autoRefreshFinished)
      //   return visLib.isVisible(entry, filters?.options, false);
      if (filters?.options === undefined) return visLib.isVisible(entry, filters?.options, false);
      return visLib.isVisible(entry, filters?.options);
    },
    [filters?.options]
  );

  const hasActiveFilter = useMemo(() => {
    if (filters?.options === undefined) return false;
    return (
      Object.values(filters?.options.CATEGORY).includes(EFilterSelection.HIDE) ||
      Object.values(filters?.options.DESC).includes(EFilterSelection.HIDE) ||
      Object.values(filters?.options.PREV_MONTH).includes(EFilterSelection.HIDE) ||
      Object.values(filters?.options.CRITICAL_VALUE).includes(EFilterSelection.HIDE) ||
      Object.values(filters?.options.CUMULATIVE).includes(EFilterSelection.HIDE) ||
      Object.values(filters?.options.AVERAGE).includes(EFilterSelection.HIDE)
    );
  }, [filters?.options]);

  const Footer: FC = () => {
    const { close } = useDialogs();
    const closeDetailDialog = (): void => {
      close(IDialogKeyEnums.SIMPLE);
    };

    return (
      <FormRow align={AlignmentEnums.CENTER}>
        <LinkButton
          variant={ButtonVariantEnums.PRIMARY}
          type={"button"}
          inverted
          onClick={closeDetailDialog}
        >
          {t("tax/common:detail_page_dialog.footer_btn_text")}
        </LinkButton>
      </FormRow>
    );
  };

  const openDetailDialog = (idOfSelectedClient: string | undefined): void => {
    dialogs.open({
      dialogKey: IDialogKeyEnums.SIMPLE,
      content: (
        <DetailsDialog
          alerts={alerts}
          idOfSelectedClient={idOfSelectedClient as string}
          // alertsLoading={alertsLoading}
          alertsLoading={false}
          clients={caneiClients}
          datevClients={datev.datev_clients}
        />
      ),
      footer: <Footer />,
      closable: true,
      flexible: true,
    });
  };

  let invisibleClientsCount = 0; // Count the number of client, which has no entry after filtering to decide which rows should be highlighted

  const handleDelete = (id: string | undefined): void => {
    if (!caneiClients || caneiClients?.length === 0) return;
    if (metaData?.date === undefined && datev.selected_date === undefined) return;

    const metaClients = caneiClients.filter(
      (client) => metaData?.clients?.includes(client?.name as string) && client?.client_id !== id
    ) as ClientTax[];

    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_CLIENTS,
      payload: {
        caneiClients: metaClients,
      },
    });

    const metaClientIds: string[] = metaClients.map((client) => (client && client.name) as string);
    setMeta({
      variables: {
        data: {
          datev_url: metaData?.datev_url,
          account_id: currentUser.appUser.customer_id as string,
        },
      },
    });

    setMeta({
      variables: {
        data: {
          account_id: currentUser.appUser.user_id as string,
          clients: metaClientIds,
          date: metaData?.date || datev.selected_date,
        },
      },
    });
  };

  // initialize the filter as undefined
  const [filter, setFilter] = useState<string | undefined>(undefined);

  // set the value to filter if value length is greater than 0, else make it undefined
  const handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value.length === 0) setFilter(undefined);
    else setFilter(value);
  };

  const dataForFunc: IDataForFunc | undefined = useMemo(() => {
    if (!datev.datev_clients) return undefined;
    if (!caneiClients) return undefined;
    if (!metaData?.date && !datev.selected_date) return undefined;
    if (!filters?.options) return undefined;
    if (!filters?.options.DESC) return undefined;
    const data: IAbnormalityGeneral = {
      date: (metaData?.date || datev.selected_date) as string,
      filters: {
        description: filters?.options.DESC as unknown as IFilterTypeTwo,
        month_prev_year: filters?.options?.PREV_MONTH,
        aver_prev_year: filters?.options?.AVERAGE,
        cumulative_prev_year: filters?.options?.CUMULATIVE,
        critical_value: filters?.options?.CRITICAL_VALUE,
      },
    };

    const clientsForFunc: IAbnormalityClient[] = datev.datev_clients
      .slice()
      .filter((item) => {
        return (
          datev?.selected_clients &&
          datev.selected_clients.some((subItem) => {
            return subItem === item.id;
          })
        );
      })
      .map((item) => {
        const client = {
          name: item.name,
          number: item.number,
          client_id: "",
        };
        if (!caneiClients) return client;

        for (const i of caneiClients) {
          if (i.name === item.id) {
            client.client_id = i.client_id;
            break;
          }
        }
        return client;
      });

    const objForFunction: IDataForFunc = {
      general: data,
      clients: clientsForFunc,
      user_email: currentUser.appUser.email,
    };
    return objForFunction;
  }, [
    datev.datev_clients,
    datev.selected_date,
    datev.selected_clients,
    caneiClients,
    metaData?.date,
    filters?.options,
    currentUser,
  ]);

  const handleGetAbnormalityReport = (clientId: string | undefined): void => {
    const data: IDataForFunc = JSON.parse(JSON.stringify(dataForFunc));

    if (clientId) {
      data.clients = data.clients.filter((client) => client.client_id === clientId);
    }

    const params: IParamsVar = {
      data,
      account_id: currentUser.appUser.customer_id as string,
    };
    if (params.data) {
      getAbnormalityReport({
        variables: {
          params,
        },
      });
    }
  };

  return (
    <StyledTile.Wrapper ref={forwardRef} gridArea="total-analyze">
      <StyledTile.ContentWrapper>
        <StyledTile.Head>
          <StyledTile.HeadFirstItem>
            <H3>{t("tax/common:main_content.total_analyse.caption")}</H3>
          </StyledTile.HeadFirstItem>

          <StyledTile.HeadItem>
            <StyledTile.AlertWrapper>
              <StyledTile.HoverText>
                <Trans
                  i18nKey={"tax/common:main_content.total_analyse.warnings.considerable_text"}
                />
              </StyledTile.HoverText>
              <TriStateAlarm
                level={EAlarmLevel.CONSIDERABLE}
                text={t("tax/common:main_content.total_analyse.warnings.considerable")}
              />
              <StyledTile.HelpWrapper>
                <Icon name={IconNameEnums.Q_HELP} size={IconSizeEnums.XSMALL} />
              </StyledTile.HelpWrapper>
            </StyledTile.AlertWrapper>
            <StyledTile.AlertWrapper>
              <StyledTile.HoverText>
                <Trans i18nKey={"tax/common:main_content.total_analyse.warnings.critical_text"} />
              </StyledTile.HoverText>
              <TriStateAlarm
                level={EAlarmLevel.CRITICAL}
                text={t("tax/common:main_content.total_analyse.warnings.critical")}
              />
              <StyledTile.HelpWrapper>
                <Icon name={IconNameEnums.Q_HELP} size={IconSizeEnums.XSMALL} />
              </StyledTile.HelpWrapper>
            </StyledTile.AlertWrapper>
            <StyledTile.AlertWrapper>
              <StyledTile.HoverText>
                <Trans
                  i18nKey={"tax/common:main_content.total_analyse.warnings.not_applicable_text"}
                />
              </StyledTile.HoverText>
              <StyledTotalAnalyze.LegendWrapper>
                <Icon name={IconNameEnums.NOT_APPLICABLE} size={IconSizeEnums.SMALL} />
                {t("tax/common:main_content.total_analyse.warnings.not_applicable")}
              </StyledTotalAnalyze.LegendWrapper>

              <StyledTile.HelpWrapper>
                <Icon name={IconNameEnums.Q_HELP} size={IconSizeEnums.XSMALL} />
              </StyledTile.HelpWrapper>
            </StyledTile.AlertWrapper>
          </StyledTile.HeadItem>

          <StyledTile.HeadLastItem>
            <div className="report">
              <span>{t("tax/common:main_content.total_analyse.download_report")}</span>
              <IconButton
                icon={<Icon name={IconNameEnums.PDF} size={IconSizeEnums.SMALL} />}
                variant={ButtonVariantEnums.SECONDARY}
                inverted={false}
                disabled={loading || !dataForFunc || dataForFunc.clients.length === 0}
                onClick={(): void => handleGetAbnormalityReport(undefined)}
              />
            </div>
            <ButtonBranded
              variant={ButtonBrandedVariantEnums.PRIMARY}
              type={"button"}
              inverted={false}
              // disabled={alertsLoading || !autoRefreshFinished || !hasActiveFilter}
              disabled={!hasActiveFilter}
              onClick={handleResetAllFilters}
            >
              {t("tax/common:main_content.total_analyse.remove_all_filters")}
            </ButtonBranded>
          </StyledTile.HeadLastItem>
        </StyledTile.Head>
        <StyledTile.Body>
          {/* {(alertsLoading || !autoRefreshFinished) && (
            <LoadingIndicator type={LoaderTypeEnums.COMPONENT} />
          )} */}
          {/* {(loading || !autoRefreshFinished) && ( */}
          {loading && <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />}
          <Table width={"100%"}>
            <thead>
              <Row>
                <Cell colSpan={3}>
                  <StyledTile.SearchBar>
                    <FormRow align={AlignmentEnums.CENTER}>
                      <Icon size={IconSizeEnums.SMALL} name={IconNameEnums.Q_SEARCH} />
                      <Input
                        type={InputTypes.TEXT}
                        variant={IInputVariantEnums.DEFAULT}
                        name={"Suche"}
                        value={""}
                        placeholder={t("tax/common:main_content.view_setup.search_clients")}
                        branded
                        onChange={handleSearchBarChange}
                      />
                    </FormRow>
                  </StyledTile.SearchBar>
                </Cell>
                <Cell align={"center"}>⎡</Cell>
                <Cell align={"center"} colSpan={2} level={"title"}>
                  <Trans i18nKey={"tax/common:main_content.total_analyse.rows.group"} />
                </Cell>
                <Cell align={"center"}>⎤</Cell>
                <Cell colSpan={3}> </Cell>
              </Row>
              <Row>
                <Cell level={"title"} align={"left"}>
                  <Trans i18nKey={"tax/common:main_content.total_analyse.rows.clients"} />
                </Cell>
                <Cell level={"title"} align={"left"}>
                  <Trans i18nKey={"tax/common:main_content.total_analyse.rows.client_number"} />
                </Cell>
                <Filterable i18nKey={"description"} align={"left"} name={EFilterGroup.DESC} />
                <Filterable i18nKey={"month_previous_year"} name={EFilteredAlarms.PREV_MONTH} />
                <Filterable i18nKey={"average_previous_year"} name={EFilteredAlarms.AVERAGE} />
                <Filterable
                  i18nKey={"cumulative_previous_year"}
                  name={EFilteredAlarms.CUMULATIVE}
                />
                <Filterable i18nKey={"critical_value"} name={EFilteredAlarms.CRITICAL_VALUE} />
                <Cell level={"title"}> </Cell>
                <Cell level={"title"}> </Cell>
                <Cell level={"title"}> </Cell>
              </Row>
            </thead>
            <tbody>
              {/* {filteredAlerts */}
              {!warningDialog &&
                Object.keys(alertsInProgress).map((alertKey, rowIndex) => {
                  // extract name and number from alertsInProgress
                  const name = alertsInProgress[alertKey].name as string;
                  const number = alertsInProgress[alertKey].number;

                  // check for number or key match, if there is no filter return true
                  const nameMatch = filter ? name && name.toLowerCase().includes(filter) : true;
                  const numberMatch = filter ? number && number.toString().includes(filter) : true;

                  // filter out the row if there is no match
                  const entryVisible =
                    nameMatch || numberMatch
                      ? alertsInProgress[alertKey].months?.compare
                          .map((entry) => isVisible(entry))
                          .filter((item) => item.entryVisible)
                      : [];

                  entryVisible && entryVisible.length === 0 && invisibleClientsCount++;

                  return entryVisible?.map((entry, index, array) => {
                    const sharedCellProps: Partial<CellProps> = {
                      highlight: (rowIndex - invisibleClientsCount) % 2 === 1,
                      hasBorder: index === array.length - 1,
                    };

                    const getAlertIcon = (
                      entry: visLib.IAlertVisibility,
                      type: string
                    ): ReactElement => {
                      if (entry.not_applicable) {
                        return (
                          <StyledTotalAnalyze.AlertWrapper>
                            <Icon name={IconNameEnums.NOT_APPLICABLE} size={IconSizeEnums.SMALL} />
                          </StyledTotalAnalyze.AlertWrapper>
                        );
                      }
                      switch (type) {
                        case "prev_month":
                          return <TriStateAlarm justify="center" level={entry.prev_month.alarm} />;
                        case "prev_year_avg_month":
                          return (
                            <TriStateAlarm
                              justify="center"
                              level={entry.prev_year_avg_month.alarm}
                            />
                          );
                        case "prev_year_cumulated":
                          return (
                            <TriStateAlarm
                              justify="center"
                              level={entry.prev_year_cumulated.alarm}
                            />
                          );
                        case "critical":
                          return <TriStateAlarm justify="center" level={entry.critical.alarm} />;
                        default:
                          return <></>;
                      }
                    };

                    return (
                      <Row key={index}>
                        <Cell level={"title"} {...sharedCellProps}>
                          {index === 0 ? alertsInProgress[alertKey].name : " "}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {index === 0 ? alertsInProgress[alertKey].number : " "}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {t(`tax/common:main_content.total_analyse.alerts:${entry.alert_id}`)}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {getAlertIcon(entry, "prev_month")}
                          {/* {entry.prev_month.show && (
                            <TriStateAlarm justify="center" level={entry.prev_month.alarm} />
                          )} */}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {getAlertIcon(entry, "prev_year_avg_month")}
                          {/* {entry.prev_year_avg_month.show && (
                            <TriStateAlarm
                              justify="center"
                              level={entry.prev_year_avg_month.alarm}
                            />
                          )} */}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {getAlertIcon(entry, "prev_year_cumulated")}
                          {/* {entry.prev_year_cumulated.show && (
                            <TriStateAlarm
                              justify="center"
                              level={entry.prev_year_cumulated.alarm}
                            />
                          )} */}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {getAlertIcon(entry, "critical")}
                          {/* {entry.critical.show && (
                            <TriStateAlarm justify="center" level={entry.critical.alarm} />
                          )} */}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {index === 0 && (
                            <LinkButton
                              variant={ButtonVariantEnums.PRIMARY}
                              type={"button"}
                              inverted
                              onClick={(): void => {
                                userVisitPageEvent({
                                  variables: {
                                    user_id: currentUser.appUser.user_id,
                                    page_id: "client-detail",
                                  },
                                });
                                openDetailDialog(alertsInProgress[alertKey].datev_id);
                              }}
                            >
                              {t("tax/common:main_content.total_analyse.detailed_view")}
                            </LinkButton>
                          )}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {index === 0 && (
                            <IconButton
                              icon={<Icon name={IconNameEnums.PDF} size={IconSizeEnums.SMALL} />}
                              variant={ButtonVariantEnums.SECONDARY}
                              inverted={false}
                              disabled={loading || !dataForFunc || dataForFunc.clients.length === 0}
                              onClick={
                                (): void =>
                                  handleGetAbnormalityReport(alertsInProgress[alertKey].client_id)
                                // handleGetAbnormalityReport(client_id)
                              }
                            />
                          )}
                        </Cell>
                        <Cell {...sharedCellProps}>
                          {index === 0 && (
                            <IconButton
                              icon={<Icon name={IconNameEnums.DELETE} size={IconSizeEnums.SMALL} />}
                              variant={ButtonVariantEnums.SECONDARY}
                              inverted={false}
                              onClick={(): void =>
                                handleDelete(alertsInProgress[alertKey].client_id)
                              }
                            />
                          )}
                        </Cell>
                      </Row>
                    );
                  });
                })}
            </tbody>
          </Table>
        </StyledTile.Body>
      </StyledTile.ContentWrapper>
    </StyledTile.Wrapper>
  );
};
