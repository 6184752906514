import { FC, useEffect, useState } from "react";
import { LoaderTypeEnums, LoadingIndicator } from "@canei/app-components";
import { Auth } from "aws-amplify";

import { ILocalState, UserStateActionTypes } from "../../../../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../../../../../_lib/store/reducers/currentUser";
import {
  EAppStatusActionType,
  IAppStatusAction,
} from "../../../../../_lib/store/reducers/appStatus";
import { isEqual } from "lodash";

const Logout: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatch = useDispatch<Dispatch<ICurrentUserAction>>();
  const dispatchAppStore = useDispatch<Dispatch<IAppStatusAction>>();

  const [loggingOut, setLoggingOut] = useState(true);
  const client = useApolloClient();
  useEffect(() => {
    Auth.signOut().then(() => {
      setLoggingOut(false);
      dispatch({ type: UserStateActionTypes.CLEAR_STORE, payload: {} });
      dispatchAppStore({ type: EAppStatusActionType.CLEAR_STORE, payload: {} });
      localStorage.clear();
      window.location.reload();
    });
  }, [currentUser, client, dispatch, dispatchAppStore]);

  return loggingOut ? <LoadingIndicator type={LoaderTypeEnums.PAGE} /> : null;
};
export default Logout;
