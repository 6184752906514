import styled, { css } from "styled-components/macro";

const Wrapper = styled.div(({ theme }) => {
  return css`
    width: max(70vw, ${theme.rem(20)});
  `;
});
const Content = styled.div(({ theme }) => {
  return css``;
});
const TitleWrapper = styled.div(({ theme }) => {
  return css`
    text-align: center;
    font-size: ${theme.rem(2.5)};
    font-weight: ${theme.values.fontWeight.bolder};
    color: ${theme.values.colors.global.secondary._100};
    margin-bottom: ${theme.rem(1)};
  `;
});
const SuperWrapper = styled.div(({ theme }) => {
  return css`
    width: max(90%, ${theme.rem(20)});
    display: flex;
    flex-direction: column;
  `;
});

const SuperAllItemsWrapper = styled.div(({ theme }) => {
  return css`
    width: max(95%, ${theme.rem(20)});
    margin: 0 auto;
    display: flex;
    margin-top: ${theme.rem(4)};
  `;
});
const ItemTitleWrapper = styled.div(({ theme }) => {
  return css`
    text-align: center;
    font-size: ${theme.rem(1.5)};
    font-weight: ${theme.values.fontWeight.bolder};
    color: ${theme.values.colors.global.secondary._100};
    margin-bottom: ${theme.rem(1)};
  `;
});
const ItemTextWrapper = styled.div(({ theme }) => {
  return css`
    text-align: center;
    font-size: ${theme.rem(1.2)};
    color: ${theme.values.colors.global.secondary._085};
    font-weight: ${theme.values.fontWeight.normal};
    width: max(85%, ${theme.rem(20)});
    margin: 0 auto;
  `;
});
const IconWrapper = styled.div(({ theme }) => {
  return css`
    span {
      margin: 0 auto;
      margin-bottom: ${theme.rem(2)};
    }
  `;
});

export const StyledContactDialog = {
  Wrapper,
  Content,
  TitleWrapper,
  SuperWrapper,
  SuperAllItemsWrapper,
  ItemTitleWrapper,
  ItemTextWrapper,
  IconWrapper,
};
