import { IDatevClient, IDatevData } from "@canei/datev-connect-client";
import { useDispatch, useSelector } from "react-redux";
import { ClientTax, ILocalState } from "../../../@types";
import { useRef } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { EAppStatusActionType, IAppStatusAction } from "../../store/reducers/appStatus";
import { useClients } from "../useClients";
import { Client, IconNameEnums, Relation, RelationLink } from "@canei/app-components";
import * as async from "async";
import { EClientSelection, IClientToProcess } from "./types.useDatev";
import { useSetDatevMetaData } from "../useMetaData";
import config from "../../../config";
import { gql, useApolloClient } from "@apollo/client";
import { useUploadDatevJson } from "./useUploadDatevJSON";
import { useNumberOfClientsWarningDialog } from "../../../views/local/Private/PrivateMainContext/useNumberOfClientsWarningDialog";
import { useDatevHelper } from "./useDatevHelper";
import { isEqual } from "lodash";

const TAX_WARM_UP = gql`
  query taxWarmUp($crm: Int!, $conversion: Int!) {
    taxWarmUp(crm: $crm, conversion: $conversion)
  }
`;

interface IUseCreateDatevClientsRetVal {
  processDatevClients: (clients: string[]) => void;
}

export const useDatevCreateUpload = (): IUseCreateDatevClientsRetVal => {
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const datev = useSelector(({ appStatus: { datev } }: ILocalState) => datev, isEqual);
  const caneiClients = useSelector(
    ({ appStatus: { caneiClients } }: ILocalState) => caneiClients,
    isEqual
  );
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const warningDialog = useSelector(
    ({ appStatus: { warningDialog } }: ILocalState) => warningDialog,
    isEqual
  );
  const accFrameTemplates = useSelector(
    ({ dataStore: { accFrameTemplates } }: ILocalState) => accFrameTemplates,
    isEqual
  );
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const { listTax: listCRMClients, create: createCRMClient } = useClients();
  const { /* uploadSingle,*/ uploadMultiple } = useUploadDatevJson();
  const [setMeta] = useSetDatevMetaData();
  const { getClientObject, getRequiredData } = useDatevHelper();
  const apolloClient = useApolloClient();
  const { open: handleWarningDialog } = useNumberOfClientsWarningDialog();

  const caneiClientsRef = useRef<ClientTax[] | undefined>(caneiClients);
  caneiClientsRef.current = caneiClients;

  // function to process selected Datev clients
  const processDatevClients = async (clients: string[]): Promise<void> => {
    dispatchAppStatus({
      type: EAppStatusActionType.UPDATE_EVENTS_STATE,
      payload: {},
    });

    // if warning limiter has been reached, show warning dialog
    if (clients.length >= config.warningLimiter && !warningDialog) {
      // console.log("useEffect: PrivateMainContext", datev.selected_clients);
      dispatchAppStatus({
        type: EAppStatusActionType.TOGGLE_WARNING_DIALOG,
        payload: {
          warningDialog: true,
        },
      });
      handleWarningDialog();
    }

    // init clients to process
    const clientsToProcess = {
      toProcess: {} as Record<string, IClientToProcess>,
      processing: {} as Record<string, IClientToProcess>,
      processed: {} as Record<string, IClientToProcess>,
    };

    // make all alerts loading in redux state
    async.each(clients, (client, callback) => {
      dispatchAppStatus({
        type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
        payload: {
          [client]: {
            datev_id: client,
            progress: EClientSelection.LOADING,
            icon: IconNameEnums.LOADING_25,
          },
        },
      });
    });

    // get existing clients and fill the fill the master object with the clients to process
    const clientsInCRM = await listCRMClients({
      variables: { customer_id: currentUser.appUser.customer_id },
      fetchPolicy: "no-cache",
    })
      .then((clientsInCRM) => {
        clientsInCRM &&
          clientsInCRM.data &&
          clientsInCRM.data.getClientsTax &&
          dispatchAppStatus({
            type: EAppStatusActionType.SET_APP_CLIENTS,
            payload: {
              caneiClients: [...clientsInCRM.data.getClientsTax],
            },
          });
        return clientsInCRM.data.getClientsTax;
      })
      .then((clientsInCRM) => {
        // fill the master object with the clients to process
        async.each(clients, (client, callback) => {
          const clientExists = clientsInCRM.some((c) => c.name === client);
          if (clientExists) {
            clientsToProcess.toProcess[client] = {
              datevId: client,
              toCreate: false,
              toProcess: true,
            };
          } else {
            clientsToProcess.toProcess[client] = {
              datevId: client,
              toCreate: true,
              toProcess: true,
            };
          }
        });
        return clientsInCRM;
      });

    // warm up conversion lambdas (10% for CRM and 20% for conversion)
    warmUpServices(clients.length - clientsInCRM.length, clients.length);

    // function that will process n clients at the time (n = config.limiter)
    const processClients = async (): Promise<string | undefined> => {
      // get the first client to process (number comes from config file)
      const toProcess = Object.keys(clientsToProcess.toProcess).filter(
        (_, index) => index < config.limiter
      );

      // start processing the clients
      const processing = await Promise.all(
        toProcess.map(async (datevClientId) => {
          // move the limiter amount of clients from toProcess to processing
          clientsToProcess.processing[datevClientId] = clientsToProcess.toProcess[datevClientId];
          delete clientsToProcess.toProcess[datevClientId];

          // does the client need to be created in CRM?
          const toCreate = clientsToProcess.processing[datevClientId].toCreate;

          // if needed create the client in CRM, if not find the data in clientsInCRM
          let clientInCRM: ClientTax | undefined = undefined;
          let susas = [];
          try {
            clientInCRM = toCreate
              ? await loadAndCreateClient(datevClientId)
              : (clientsInCRM.find((c) => c.name === datevClientId) as ClientTax);

            // if client was not found in CRM, return error
            if (!clientInCRM) return "Client not found in CRM";

            // try to get susas for the client from Datev
            susas = await getSusas(datevClientId, clientInCRM);
          } catch (e) {
            // Check if we have datapath error
            if (e instanceof Error && e.message === "REW11034") {
              dispatchAppStatus({
                type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
                payload: {
                  [datevClientId]: {
                    datev_id: datevClientId,
                    progress: EClientSelection.DATAPATH_INVALID,
                    icon: IconNameEnums.ATTENTION_2,
                  },
                },
              });
              return datevClientId;
            }

            // if we have another uncatched error we will X it
            dispatchAppStatus({
              type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
              payload: {
                [datevClientId]: {
                  datev_id: datevClientId,
                  progress: EClientSelection.ERRORED,
                  icon: IconNameEnums.Q_CLOSE_CIRCLE,
                },
              },
            });

            return datevClientId;
          }

          // if there are less than 5 susas, add NO_SUSAS error and skip the client
          if (susas.length < 5) {
            dispatchAppStatus({
              type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
              payload: {
                [datevClientId]: {
                  client_id: clientInCRM.client_id,
                  datev_id: datevClientId,
                  progress: EClientSelection.NO_SUSAS,
                  icon: IconNameEnums.ATTENTION_1,
                },
              },
            });
            return datevClientId;
          } else {
            // if there are 5 susas upload them
            await uploadSusas(clientInCRM as ClientTax, datevClientId, susas)
              .then(() => {
                // at this point the bulk event listener starts (governed from maincontext index.js) and we remove the client from processing
                clientsToProcess.processed[datevClientId] =
                  clientsToProcess.processing[datevClientId];
                delete clientsToProcess.processing[datevClientId];
              })
              .catch(() => {
                dispatchAppStatus({
                  type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
                  payload: {
                    [datevClientId]: {
                      client_id: clientInCRM?.client_id,
                      datev_id: datevClientId,
                      progress: EClientSelection.ERRORED,
                      icon: IconNameEnums.Q_CLOSE_CIRCLE,
                    },
                  },
                });
              });
            return datevClientId;
          }
        })
      ).then(() => {
        // if toProcess is empty we are done
        if (Object.keys(clientsToProcess.toProcess).length === 0) {
          return "done";
        }
        // if toProcess is not empty we need to process the next batch
        processClients();
      });
      return processing;
    };

    // first start of processing function
    processClients();
  };

  // create Datev clients in CRM
  const createClient = (client: IDatevClient): Promise<Client> => {
    // create client in CRM
    const clientCRM = createCRMClient(
      getClientObject(
        client.id,
        client.category,
        client.fiscal_year,
        client.account_system,
        currentUser.appUser.customer_id
      )
    )
      .then(({ data }) => {
        if (!data) return;

        if (!caneiClientsRef.current) {
          dispatchAppStatus({
            type: EAppStatusActionType.SET_APP_CLIENTS,
            payload: {
              caneiClients: [data.createNewClient],
            },
          });
        } else {
          dispatchAppStatus({
            type: EAppStatusActionType.SET_APP_CLIENTS,
            payload: {
              caneiClients: [...caneiClientsRef.current, data.createNewClient],
            },
          });
        }
        return data?.createNewClient;
      })
      .catch(() => {
        dispatchAppStatus({
          type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
          payload: {
            [client.id]: {
              datev_id: client.id,
              progress: EClientSelection.INCOMPATIBLE,
              icon: IconNameEnums.QUESTION_MARK,
            },
          },
        });
        // dispatch datev date and clients to accout metadata
        setMeta({
          variables: {
            data: {
              account_id: currentUser.appUser.user_id,
              date: datev.selected_date,
              clients: datev.selected_clients.filter((c) => c !== client.id),
            },
          },
        });

        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_META,
          payload: {
            metaData: {
              ...metaData,
              date: datev?.selected_date,
              clients: datev.selected_clients.filter((c) => c !== client.id),
            },
          },
        });
      });

    // return CRM client
    return clientCRM;
  };

  // get client from datev and create in CRM
  const loadAndCreateClient = async (datevClientId: string): Promise<ClientTax> => {
    const clientCRM = await datev.connector?.loadClient(datevClientId).then((datevClientLoaded) => {
      const datevClient = datevClientLoaded as IDatevClient;

      // check if acc frame is ok
      const accFrameOk = accFrameTemplates?.some(
        (accFrame) => accFrame === datevClient.account_system
      );

      // if incompatible account frame error the client
      if (!accFrameOk) {
        dispatchAppStatus({
          type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
          payload: {
            [datevClient.id]: {
              datev_id: datevClient.id,
              progress: EClientSelection.INCOMPATIBLE,
              icon: IconNameEnums.QUESTION_MARK,
            },
          },
        });
        return undefined;
      }

      return createClient(datevClient as IDatevClient).then((client) => {
        // exit if client is not created
        if (!client) return;
        setMeta({
          variables: {
            data: {
              account_id: client.client_id,
              fiscal_years: datevClient.fiscal_years,
            },
          },
        });
        const clientTax = {
          client_id: client.client_id,
          name: client.name,
          fiscal_years: datevClient.fiscal_years,
          links: client.links,
        } as ClientTax;
        return clientTax;
      });
    });
    return clientCRM as ClientTax;
  };

  // get susas from datev
  const getSusas = async (
    datevClientId: string,
    clientInCRM: ClientTax
    // fiscal_years: IDatevFiscalYearShort[]
  ): Promise<IDatevData[]> => {
    const requiredData = await getRequiredData(
      datevClientId,
      datev.selected_date as string,
      clientInCRM
    );
    if (!requiredData) return [];
    const susas = await datev.connector
      ?.loadSuSasOfClientByMonths(requiredData)
      .then((data) => data.datev_data);
    if (!susas) return [];
    return susas;
  };

  // upload client susas in parallel
  const uploadSusas = (
    client: ClientTax,
    datevClientId: string,
    clientSusas: IDatevData[]
  ): Promise<IDatevData[]> => {
    const clientLinks = client?.links.map((link: RelationLink) => {
      return {
        href: link.href,
        rel: link.rel === Relation.self ? Relation.client : link.rel,
      };
    });

    const multiUploadVars = clientSusas.map((susa: IDatevData) => {
      return {
        client_id: client.client_id,
        content: JSON.stringify({
          data: susa.sums_and_balances,
        }),
        date: susa.month,
        links: clientLinks as RelationLink[],
        covid: datevClientId,
      };
    });

    // upload susa in batch and then start the transaction listeners
    return uploadMultiple(multiUploadVars).then(() => {
      dispatchAppStatus({
        type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
        payload: {
          [datevClientId]: {
            datev_id: datevClientId,
            client_id: client.client_id,
            progress: EClientSelection.ALERTS_SHOULD_START,
            icon: IconNameEnums.LOADING_50,
          },
        },
      });
    }) as Promise<IDatevData[]>;
  };

  const warmUpServices = (toCreate: number, toUpload: number): void => {
    apolloClient.query({
      query: TAX_WARM_UP,
      variables: { crm: toCreate, conversion: toUpload },
      fetchPolicy: "no-cache",
    });
  };

  return {
    processDatevClients,
  };
};
