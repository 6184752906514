import { FC } from "react";

import { StyledResponsiveImage } from "../../../StyledComponents";
import styled, { css, useTheme } from "styled-components/macro";
import { EProductEnums } from "../../../_lib/Layout/types.layout";

const StyledLogo = styled.div(({ theme }) => {
  return css`
    width: ${theme.rem(20)};
    display: inline-block;
    margin-bottom: ${theme.rem(2)};
  `;
});
interface ILogoProps {
  isDark?: boolean;
  product?: EProductEnums;
}

export const Logo: FC<ILogoProps> = ({ isDark = true, product = EProductEnums.TAX }) => {
  const themeOptions = useTheme();
  const { light, dark } = themeOptions.branded.logo[product];

  return (
    <StyledLogo>
      <StyledResponsiveImage src={isDark ? dark : light} alt="logo" />
    </StyledLogo>
  );
};
