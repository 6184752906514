import { FC, ReactElement } from "react";
import { EPublicPaths, ILocalState } from "../../@types/index.d";
import { Trans } from "react-i18next";
import {
  AlignmentEnums,
  FormRow,
  Alert,
  AlertTypeEnums,
  AuthEnums,
  IUserInfo,
} from "@canei/app-components";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

/**
 * Using error.code & error.name, returns proper and translated content for the
 * error.
 */
const TranslatedErrorMessage = ({ errors, email }: IUserInfo): ReactElement[] => {
  return errors.map((error) => {
    switch (error.code) {
      case AuthEnums.CODE_EXPIRED:
      case AuthEnums.RESET_REQUIRED:
        return (
          <Trans
            i18nKey={`error.${error.name}`}
            values={{ ...error, message: { email: email || "" } }}
          >
            <Link to={EPublicPaths.FORGOT}>get reset code</Link>
          </Trans>
        );
      case AuthEnums.CODE_MISMATCH:
        error.message = { email: email || "" };
        return (
          <Trans
            i18nKey={`error.${error.name}`}
            values={{ ...error, message: { email: email || "" } }}
          >
            check your inbox and spam folder
          </Trans>
        );
      case AuthEnums.INVALID_PASSWORD:
        return <Trans i18nKey={`change.validation_rule`} />;
      default:
        return (
          <>
            <Trans i18nKey={`error.${error.name}`} />
          </>
        );
    }
  });
};

/**
 * <LoginError /> offers a unified and translated error messages in:
 * login, reset, create, forgot password views.
 * It needs to be rendered conditionally to avoid async unmounted component error.
 * Ex: on the parent  component use it like this: { isThereAnyError && <LoginError />}
 */
export const LoginError: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  if (currentUser?.errors.length === 0) return <></>;
  const errors = TranslatedErrorMessage(currentUser);

  return (
    <FormRow align={AlignmentEnums.CENTER}>
      <Alert type={AlertTypeEnums.ERROR}>
        {errors.map((CustomError, key) => (
          <div key={key}>{CustomError}</div>
        ))}
      </Alert>
    </FormRow>
  );
};
