import styled, { css } from "styled-components/macro";

const Wrapper = styled.div(({ theme }) => {
  return css`
    position: unset;
    > :last-child {
      margin-top: ${theme.rem(7.3)};
    }
  `;
});

const ItemTitleWrapper = styled.div(({ theme }) => {
  return css`
    text-align: center;
    font-size: ${theme.rem(1)};
    font-weight: ${theme.values.fontWeight.bolder};
    color: ${theme.values.colors.global.secondary._100};
  `;
});
const ItemTextWrapper = styled.div(({ theme }) => {
  return css`
    text-align: center;
    color: ${theme.values.colors.global.secondary._085};
    font-weight: ${theme.values.fontWeight.normal};
  `;
});
const IconWrapper = styled.div(({ theme }) => {
  return css`
    span {
      margin: 0 auto;
      margin-bottom: ${theme.rem(2)};
    }
  `;
});
const DownloadItemsWrapper = styled.div(({ theme }) => {
  return css`
    display: flex;
    gap: ${theme.rem(1.4)};
    margin: ${theme.rem(2)} 0;
    > div:nth-child(2) {
      margin-left: ${theme.rem(1.33)};
    }
  `;
});
const TopHeaderWrapper = styled.div(({ theme }) => {
  return css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    padding-top: ${theme.rem(1.33)};
    padding-left: ${theme.rem(2.66)};
    padding-bottom: ${theme.rem(1.14)};
  `;
});
const LogoWrapper = styled.div(({ theme }) => {
  return css`
    > :first-child {
      margin-bottom: 0;
    }
  `;
});

export const StyledDownloadApp = {
  Wrapper,
  LogoWrapper,
  ItemTitleWrapper,
  ItemTextWrapper,
  IconWrapper,
  DownloadItemsWrapper,
  TopHeaderWrapper,
};
