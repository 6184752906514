import { FC, Suspense, useEffect, useMemo, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { Route, Switch } from "react-router-dom";
import { EPrivatePaths, IAppStatusMetaData, ILocalState } from "../../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllDatevMetaData } from "../../../_lib/hooks/useMetaData";
import {
  ESubscriptionStatus,
  LoaderTypeEnums,
  LoadingIndicator,
  useDialogs,
} from "@canei/app-components";
import { PrivateMainContext } from "./PrivateMainContext";
import Logout from "./Dashboard/Logout";
import PaymentWarning from "./PaymentWarning";
import { EAppStatusActionType, IAppStatusAction } from "../../../_lib/store/reducers/appStatus";
import { useDatevClientList } from "../../../_lib/hooks/useDatev";
import { isEqual } from "lodash";

interface IUseSetupDoneRetVal {
  setupDone: boolean;
  loading: boolean;
  meta: IAppStatusMetaData | undefined;
}

const useIsSetupDone = (): IUseSetupDoneRetVal => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const [loading, setLoading] = useState<boolean>(true);

  // get user, customer and alerting metadata
  const [getAllMetaData, { data: allMetaData }] = useGetAllDatevMetaData({
    variables: {
      customer_id: currentUser.appUser.customer_id,
      user_id: currentUser.appUser.user_id,
      account_id: currentUser.appUser.customer_id,
    },
    fetchPolicy: "no-cache",
    onError: () => {
      if (!window.storage) return;
    },
    onCompleted: (data) => {
      setLoading(false);
      return data;
    },
  });

  useEffect(() => {
    if (!allMetaData) {
      setLoading(true);

      getAllMetaData();
    }
  }, [allMetaData, getAllMetaData]);

  // construct metadata object from queries and dispatch to appStatus.metaData
  const meta = useMemo<IAppStatusMetaData | undefined>(() => {
    // initiate the metaData object
    let metaData;

    // if there is datev metaData add it to the object
    if (allMetaData) {
      metaData = {} as IAppStatusMetaData;
      if (allMetaData.customer) {
        metaData.datev_url = allMetaData.customer.datev_url;
      }
      if (allMetaData.user) {
        metaData.clients = allMetaData.user.clients;
        metaData.datev_clients = allMetaData.user.datev_clients;
        metaData.date = allMetaData.user.date;
      }
      if (allMetaData.alerting) {
        metaData.thresholds = {
          considerable: allMetaData.alerting.considerable,
          critical: allMetaData.alerting.critical,
        };
      }
    }

    // return metadata object
    return metaData;
  }, [allMetaData]);

  // check if setup is done by checking if all data is present in customer and user metadata
  const setupDone = useMemo(() => {
    // if queries failed clear configurationStorage and return false

    if (!allMetaData) {
      return false;
    }

    // check if all elements are present in query results and if they are return true
    if (
      meta &&
      meta.datev_url &&
      meta.clients &&
      meta.clients.length > 0 &&
      meta.date &&
      meta.thresholds &&
      meta.thresholds.considerable &&
      meta.thresholds.critical
    )
      return true;

    // return false as default
    return false;
  }, [allMetaData, meta]);

  return { setupDone, loading, meta };
};

export const Private: FC = () => {
  const { loading, meta, setupDone } = useIsSetupDone();
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const customerData = useSelector(
    ({ billing: { customerData } }: ILocalState) => customerData,
    isEqual
  );
  const datev = useSelector(({ appStatus: { datev } }: ILocalState) => datev, isEqual);
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const { setConnector, datevListLoading } = useDatevClientList();
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();

  const dialogs = useDialogs();

  // reload datev_clients on first load
  useEffect(() => {
    if (meta && meta.datev_url && datev && !datev.connector && !datevListLoading) {
      setConnector(meta.datev_url);
      return;
    }
  }, [datev, datevListLoading, meta, metaData, setConnector]);

  // check subscription status and show Payment page if needed
  const paymentPage = useMemo(() => {
    if (!customerData) return false;
    const now = Date.now() / 1000;

    if (
      customerData?.status !== ESubscriptionStatus.ACTIVE &&
      customerData?.status !== ESubscriptionStatus.TRIALING
    ) {
      if (
        customerData?.status === ESubscriptionStatus.CANCELED &&
        customerData?.canceled_at &&
        customerData?.canceled_at > now
      ) {
        return false;
      }
      return true;
    }

    return false;
  }, [customerData]);

  localStorage.clear();
  // if meta and !metaData dispatch metaData
  useEffect(() => {
    if (!meta) return;
    // console.log("index private meta", meta);
    // console.log("index private metaData", metaData);

    if (meta && !metaData) {
      // dispatch the object to the main redux state
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_META,
        payload: {
          metaData: meta,
        },
      });
      return;
    }
    return;
  }, [dispatchAppStatus, meta, metaData]);

  useEffect(() => {
    if (!currentUser.authenticated) dialogs.closeAll();
  }, [currentUser.authenticated, dialogs]);

  // read local appSync status
  const local = localStorage.getItem("app_status");

  // if there is a local appSync state dispatch it to redux state
  useEffect(() => {
    if (local)
      dispatchAppStatus({
        type: EAppStatusActionType.SET_APP_SYNC,
        payload: {
          appSync: {
            ready: JSON.parse(local).ready,
            appSyncState: JSON.parse(local).appSyncState,
            setupSyncState: JSON.parse(local).setupSyncState,
          },
        },
      });
    return;
  }, [dispatchAppStatus, local]);

  // show loading page if any of the loading states is true
  if (loading || datevListLoading) return <LoadingIndicator type={LoaderTypeEnums.PAGE} />;
  return (
    <Switch>
      <Route path={EPrivatePaths.LOGOUT}>
        <Suspense fallback={<LoadingIndicator type={LoaderTypeEnums.PAGE} />}>
          <Logout />
        </Suspense>
      </Route>
      <Route exact path={"*"}>
        {paymentPage ? <PaymentWarning /> : <PrivateMainContext setupDone={setupDone} />}
      </Route>
    </Switch>
  );
};
