import { FC } from "react";

import { StyledSetup } from "../../../../StyledComponents";
export const SyncDone: FC = () => {
  return (
    <StyledSetup.SetupContent.Wrapper>
      <StyledSetup.SetupContent.Caption>Title</StyledSetup.SetupContent.Caption>
      <StyledSetup.SetupContent.Body>
        <StyledSetup.SetupContent.InformationText>bla bla</StyledSetup.SetupContent.InformationText>
        hey --
      </StyledSetup.SetupContent.Body>
    </StyledSetup.SetupContent.Wrapper>
  );
};
