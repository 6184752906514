import styled, { css } from "styled-components/macro";

const Wrapper = styled.section(({ theme }) => {
  return css`
    padding: ${theme.rem(4)};
    max-width: ${theme.rem(44)};
    margin: 0 auto;
  `;
});

const Caption = styled.section(({ theme }) => {
  return css`
    font-size: ${theme.rem(1.25)};
    font-size: ${theme.rem(2.2)};
    line-height: ${theme.rem(2.8)};
    margin-bottom: ${theme.rem(4)};
    text-align: center;
    font-weight: ${theme.values.fontWeight.bold};
  `;
});
const ErrorText = styled.section(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(1)};
    color: red;
    text-align: center;
  `;
});
const InformationText = styled.section(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(1)};
  `;
});
const InformationTextBold = styled.section(({ theme }) => {
  return css`
    margin-bottom: ${theme.rem(1)};
    font-weight: ${theme.values.fontWeight.bold};
  `;
});
const Body = styled.section(() => {
  return css``;
});
const SearchBar = styled.div(({ theme }) => {
  return css`
    position: relative;
    & span:not(:last-child) {
      position: absolute;
      z-index: 1;
      right: ${theme.rem(1)};
      top: ${theme.rem(0.5)};
    }
    & button {
      margin-top: ${theme.rem(0.5)};
    }
  `;
});
const SetupContent = {
  Wrapper,
  Caption,
  Body,
  InformationText,
  InformationTextBold,
  SearchBar,
  ErrorText,
};

const List = styled.ul(({ theme }) => {
  const { _000 } = theme.values.colors.global.greys;
  return css`
    list-style: none;
    color: ${_000};
    padding: ${theme.rem(2)} 0;
    margin: 0;
  `;
});
const ListItem = styled.li(({ theme }) => {
  const { _040 } = theme.values.colors.global.greys;
  return css`
    display: flex;
    column-gap: ${theme.rem(1)};
    padding: ${theme.rem(1)} 0;
    & svg * {
      fill: currentColor;
    }
    align-items: center;
    cursor: pointer;
    &:hover {
      color: ${_040};
    }
  `;
});

export const SetupMenu = {
  List,
  ListItem,
};

export const StyledSetup = {
  SetupContent,
  SetupMenu,
};
