import { gql, useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { EventErrorsCodes, RelationLink } from "@canei/app-components";

import { EDataSyncLevel, EDataSyncScope, EDataSyncTypes } from "../../@types/index.d";

const GET_CACHE_STATE = gql`
  query getTransaction(
    $transactionId: ID!
    $accountId: ID!
    $level: CacheLevel!
    $scope: EventScopes
    $type: EventTypes
  ) {
    getCachedTransaction(
      transactionId: $transactionId
      accountId: $accountId
      level: $level
      scope: $scope
      type: $type
    ) {
      status
      action {
        type
        scope
        guid
        covid
        links {
          rel
          href
        }
      }
    }
  }
`;
interface ITransactionQueryResult {
  getCachedTransaction?: ICachedTransactionData;
}
interface ICachedTransactionData {
  action: ICachedTransactionAction;
  status: EventErrorsCodes;
}

interface ICachedTransactionAction {
  covid: string;
  guid: string;
  links: RelationLink;
  type: string; //TODO: define type enums as in app_backend (POST/PUT/UPSERT)
  scope: EDataSyncScope;
}
export interface ITransactionStateInput {
  transactionId: string;
  accountId: string;
  level: EDataSyncLevel;
  scope?: EDataSyncScope;
  type?: EDataSyncTypes;
}

type IUseTransactionEventReturnValue = [
  (params: ITransactionStateInput) => void,
  Record<string, EventErrorsCodes> | undefined,
  ITransactionStateInput | undefined,
  () => void
];
type TUseTransactionEvent = () => IUseTransactionEventReturnValue;
export const useTransactionEventListener: TUseTransactionEvent = () => {
  const apolloClient = useApolloClient();
  const [errorState, setErrorState] = useState<Record<string, EventErrorsCodes> | undefined>(
    undefined
  );

  const [params, setParams] = useState<ITransactionStateInput | undefined>(undefined);

  useEffect(() => {
    if (params === undefined) return;
    const initializedAt = Date.now();
    let i = -1;
    const getErrorState = (): void => {
      apolloClient
        .query<ITransactionQueryResult>({
          query: GET_CACHE_STATE,
          variables: {
            ...params,
          },
          fetchPolicy: "no-cache",
        })
        .then(({ data }) => {
          if (data?.getCachedTransaction && data?.getCachedTransaction?.status) {
            const { status } = data?.getCachedTransaction;

            setErrorState({ [params.transactionId]: status });
          } else {
            if ((Date.now() - initializedAt) / 1000 > /*1 Minute*/ 1 * 60) {
              setErrorState({ [params.transactionId]: EventErrorsCodes.TIME_OUT });
            } else {
              i = window.setTimeout(getErrorState, 3000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    getErrorState();
    return (): void => {
      i !== -1 && window.clearTimeout(i);
    };
  }, [apolloClient, params]);

  return [
    (p: ITransactionStateInput): void => {
      setErrorState({ [p.transactionId]: EventErrorsCodes.UNKNOWN });
      setParams(p);
    },
    errorState,
    params,
    (): void => {
      setParams(undefined);
      setErrorState(undefined);
    },
  ];
};
