import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Alert,
  AlertTypeEnums,
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Form,
  FormRow,
  IInputVariantEnums,
  Input,
  InputTypes,
  LoaderTypeEnums,
  LoadingIndicator,
  P,
  SlideIn,
  SlideInDirections,
} from "@canei/app-components";

import { StyledSetup } from "../../../../StyledComponents";

import { useTheme } from "styled-components/macro";
import config from "../../../../config";
import { useSelector } from "react-redux";
import { ETaxAppSyncState, ETaxSetupSyncState, ILocalState } from "../../../../@types/index.d";
import { useDatevClientList } from "../../../../_lib/hooks/useDatev";
import { EAppSyncLocal, useAppSync } from "../../../../_lib/hooks/useAppSync";
import { isEqual } from "lodash";

export const PrepareSettings: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  const theme = useTheme();
  const appSync = useSelector(({ appStatus: { appSync } }: ILocalState) => appSync, isEqual);
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const [datevUrlInput, setDatevUrlInput] = useState(metaData?.datev_url || config.datev_url);
  const { setConnector, datevListLoading, datevListLoaded, setDatevListLoaded, error } =
    useDatevClientList();
  const { changeAppSync } = useAppSync();

  const canSubmit = useMemo(
    (): boolean => datevListLoading || datevUrlInput.length === 0,
    [datevUrlInput.length, datevListLoading]
  );

  const handleSubmitUrl = (): void => {
    // set connector and load client list
    setConnector(datevUrlInput);
  };

  const handleEditDatevUrl = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) setDatevUrlInput(e.target.value);
  };

  // move user to selection if datev client list is loaded
  useEffect(() => {
    if (!datevListLoaded) return;

    // if datev list is loaded from useDatevClientList() update appSync
    changeAppSync({
      ready: appSync && appSync.ready ? appSync.ready : false,
      appSyncState:
        appSync && appSync.appSyncState === ETaxAppSyncState.DASHBOARD
          ? ETaxAppSyncState.DASHBOARD
          : ETaxAppSyncState.SETUP,
      setupSyncState: ETaxSetupSyncState.THRESHOLD,
      local: EAppSyncLocal.UPDATE,
    });

    // reset datev list loaded and clear useEffect
    return (): void => {
      setDatevListLoaded(false);
    };
  }, [appSync, changeAppSync, datevListLoaded, setDatevListLoaded]);

  return (
    <StyledSetup.SetupContent.Wrapper>
      <StyledSetup.SetupContent.Caption>
        {t("initial_setup.tax.settings.caption")}
      </StyledSetup.SetupContent.Caption>
      <SlideIn direction={SlideInDirections.TO_LEFT}>
        <StyledSetup.SetupContent.Body>
          <Form onSubmitHandler={handleSubmitUrl} customStyle={{ display: "unset" }}>
            <P>{t("initial_setup.tax.settings.text")}</P>
            <FormRow align={AlignmentEnums.CENTER}>
              <Input
                type={InputTypes.TEXT}
                variant={IInputVariantEnums.DEFAULT}
                name={"DatevConnectUrl"}
                value={datevUrlInput || config.datev_url}
                branded
                label={t("initial_setup.tax.settings.datev_url.label")}
                onChange={handleEditDatevUrl}
                disabled={datevListLoading}
              />
            </FormRow>
            <P>{t("initial_setup.tax.settings.hinweis")}</P>
            <FormRow align={AlignmentEnums.CENTER}>
              {error && <Alert type={AlertTypeEnums.ERROR}>{`${error}`}</Alert>}
            </FormRow>

            <FormRow align={AlignmentEnums.CENTER}>
              <ButtonBranded
                type={"submit"}
                variant={ButtonBrandedVariantEnums.PRIMARY}
                inverted={false}
                disabled={canSubmit}
                inline
              >
                {t("initial_setup.tax.settings.button_text")}
              </ButtonBranded>
            </FormRow>
          </Form>
          {datevListLoading && (
            <>
              <SlideIn>
                <P>
                  <Trans i18nKey={"tax/common:initial_setup.tax.settings.process.text"} />
                </P>
              </SlideIn>
              <LoadingIndicator
                type={LoaderTypeEnums.PAGE}
                dotColor={theme.values.colors.global.primary._100}
              />
            </>
          )}
        </StyledSetup.SetupContent.Body>
      </SlideIn>
    </StyledSetup.SetupContent.Wrapper>
  );
};
