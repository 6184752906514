import { ChangeEvent, FC, useRef, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import {
  AlignmentEnums,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  Form,
  FormRow,
  IInputVariantEnums,
  Input,
  InputTypes,
  P,
  SlideIn,
  SlideInDirections,
  StyledQWizardForm,
} from "@canei/app-components";

import { StyledSetup } from "../../../../StyledComponents";

import config from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  ETaxSetupSyncState,
  ETaxAppSyncState,
  ILocalState,
  IAppStatusThresholds,
  IAppStatusInfo,
} from "../../../../@types/store.d";
import { EAppStatusActionType, IAppStatusAction } from "../../../../_lib/store/reducers/appStatus";
import { useSetAlertingMetaData } from "../../../../_lib/hooks/useMetaData";
import { EAppSyncLocal, useAppSync } from "../../../../_lib/hooks/useAppSync";
import { isEqual } from "lodash";

export const ThresholdSettings: FC = () => {
  const [setThreshold] = useSetAlertingMetaData();
  const { t } = useTranslation(["tax/common"]);
  const [thresholdChanged, setThresholdChanged] = useState(false);
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const appStatus = useSelector(({ appStatus }: ILocalState) => appStatus, isEqual);
  const appStatusRef = useRef<IAppStatusInfo>(appStatus);
  appStatusRef.current = appStatus;
  const appSync = useSelector(({ appStatus: { appSync } }: ILocalState) => appSync, isEqual);
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const [considerable, setConsiderable] = useState(
    metaData?.thresholds?.considerable || config.considerable
  );
  const [critical, setCritical] = useState(metaData?.thresholds?.critical || config.critical);
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const { changeAppSync } = useAppSync();

  // localStorage.clear();
  const handlechangeThreshold = (): void => {
    if (!considerable || !critical) return;

    // if thresholds are not changed update appSync
    if (!thresholdChanged) {
      changeAppSync({
        //if user came from dashboard bring him back to dashboard, if not continue with setup
        ready: appSync && appSync.appSyncState === ETaxAppSyncState.DASHBOARD ? true : false,
        appSyncState:
          appSync && appSync.appSyncState === ETaxAppSyncState.DASHBOARD
            ? ETaxAppSyncState.DASHBOARD
            : ETaxAppSyncState.SETUP,
        setupSyncState:
          appSync && appSync.appSyncState === ETaxAppSyncState.DASHBOARD
            ? ETaxSetupSyncState.DONE
            : ETaxSetupSyncState.SELECTION,
        local:
          appSync && appSync.appSyncState === ETaxAppSyncState.DASHBOARD
            ? EAppSyncLocal.REMOVE
            : EAppSyncLocal.UPDATE,
      });
      return;
    }

    // if thresholds are changed update metadata and appSync
    setThreshold({
      variables: {
        data: {
          account_id: currentUser.appUser.customer_id,
          considerable: considerable,
          critical: critical,
        },
      },
    })
      .then(({ data }) => {
        data &&
          data.result &&
          dispatchAppStatus({
            type: EAppStatusActionType.SET_APP_META,
            payload: {
              metaData: {
                ...metaData,
                thresholds: {
                  ...(data.result as IAppStatusThresholds),
                },
              },
            },
          });
      })
      .then(() => {
        dispatchAppStatus({
          type: EAppStatusActionType.SET_APP_STATUS,
          payload: {
            ...appStatusRef.current,
            alerts: [],
          },
        });
        changeAppSync({
          ready: false,
          appSyncState:
            appSync && appSync.appSyncState === ETaxAppSyncState.DASHBOARD
              ? ETaxAppSyncState.DASHBOARD
              : ETaxAppSyncState.SETUP,
          setupSyncState: ETaxSetupSyncState.SELECTION,
          local: EAppSyncLocal.UPDATE,
        });
      });
  };

  const handleEditThresholds = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== "") {
      setThresholdChanged(true);
      if (e.target.name === "significant") setConsiderable(e.target.value);
      if (e.target.name === "critical") setCritical(e.target.value);
    }
    if (e.target.value === "") {
      setThresholdChanged(false);
      setConsiderable(metaData?.thresholds?.considerable || config.considerable);
      setCritical(metaData?.thresholds?.critical || config.critical);
    }
  };

  return (
    <StyledSetup.SetupContent.Wrapper>
      <StyledSetup.SetupContent.Caption>
        {t("initial_setup.tax.threshold.caption")}
      </StyledSetup.SetupContent.Caption>
      <SlideIn direction={SlideInDirections.TO_LEFT}>
        <StyledSetup.SetupContent.Body>
          <Form onSubmitHandler={handlechangeThreshold} customStyle={{ display: "unset" }}>
            <P>{t("initial_setup.tax.threshold.sub_caption")}</P>
            <StyledQWizardForm.FormWrapper columnCount={2}>
              <Input
                type={InputTypes.TEXT}
                variant={IInputVariantEnums.DEFAULT}
                name={"significant"}
                value=""
                placeholder={considerable || config.considerable}
                branded
                label={t("initial_setup.tax.threshold.considerable")}
                onChange={handleEditThresholds}
                disabled={false}
              />
              <Input
                type={InputTypes.TEXT}
                variant={IInputVariantEnums.DEFAULT}
                name={"critical"}
                value=""
                placeholder={critical || config.critical}
                branded
                label={t("initial_setup.tax.threshold.critical")}
                onChange={handleEditThresholds}
                disabled={false}
              />
            </StyledQWizardForm.FormWrapper>
            <P>{t("initial_setup.tax.threshold.text.one")}</P>
            <P>{t("initial_setup.tax.threshold.text.two")}</P>
            <P>{t("initial_setup.tax.threshold.text.three")}</P>
            <FormRow align={AlignmentEnums.CENTER}>
              <ButtonBranded
                type={"submit"}
                variant={ButtonBrandedVariantEnums.PRIMARY}
                inverted={false}
                disabled={false}
                inline
              >
                {t("initial_setup.tax.threshold.button_text")}
              </ButtonBranded>
            </FormRow>
          </Form>
        </StyledSetup.SetupContent.Body>
      </SlideIn>
    </StyledSetup.SetupContent.Wrapper>
  );
};
