import { FC } from "react";
import { StyledQTopBar } from "./styled.q-top-bar";

import { useTheme } from "styled-components/macro";

import { UserMenu } from "./UserMenu";

import { useHistory } from "react-router-dom";
import { EPrivatePaths, ILocalState } from "../../../@types/index.d";
import { MonthPicker } from "@canei/app-components";
import { dateList } from "../../../components/MonthPicker/dateList";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

export const TopBar: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const handleLogoClick = (): void => {
    history.push(EPrivatePaths.HOME);
  };

  return (
    <StyledQTopBar.Wrapper>
      <StyledQTopBar.LogoContainer>
        <img src={theme.branded.logo.TAX.dark} alt="CANEI.tax Logo" onClick={handleLogoClick} />
      </StyledQTopBar.LogoContainer>
      <StyledQTopBar.MonthPickerWrapper>
        <MonthPicker
          data={dateList}
          name={"taxSuSaDate"}
          disabled={true}
          initialValue={metaData?.date}
        />
      </StyledQTopBar.MonthPickerWrapper>

      <UserMenu />
    </StyledQTopBar.Wrapper>
  );
};
