import { gql } from "@apollo/client/core";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";

const IS_EMAIL_AND_COUPON_VALID = gql`
  query validate($email: String!, $coupon: String!, $product: String!) {
    isEmailValid(email: $email)
    isCouponValid(product: $product, coupon: $coupon)
  }
`;
interface ValidateEMailVars {
  email: string;
  coupon: string;
  product: string;
}

interface ValidateResult {
  isEmailValid: boolean;
  isCouponValid: boolean;
}

type TUseValidateEmail = () => TUseValidateEmailRetVal;

type TUseValidateEmailRetVal = (
  email: string,
  coupon: string,
  product: string
) => Promise<ApolloQueryResult<ValidateResult>>;

export const useValidateEmailAndCoupon: TUseValidateEmail = () => {
  const apolloClient = useApolloClient();

  return (
    email: string,
    coupon: string,
    product: string
  ): Promise<ApolloQueryResult<ValidateResult>> => {
    return apolloClient.query<ValidateResult, ValidateEMailVars>({
      query: IS_EMAIL_AND_COUPON_VALID,
      variables: { email, coupon, product },
      fetchPolicy: "no-cache",
    });
  };
};
