import { FC } from "react";

import { ICardTypeEnums, ILocalState } from "../../@types/index.d";
import {
  Form,
  FormRow,
  Input,
  InputTypes,
  ButtonBranded,
  ButtonBrandedVariantEnums,
  AlignmentEnums,
  P,
  ErrorBoundary,
  FadeIn,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
} from "@canei/app-components";

import { Link } from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";

import { LoginError } from "./LoginError";

import { SimpleLayout } from "../../_lib/Layout";

import { useResetPassword, IResetPasswordParams } from "../../_lib/hooks";
import { useSelector } from "react-redux";
import { Logo } from "./Logo";
import { isEqual } from "lodash";

export const Reset: FC = () => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);

  const { t } = useTranslation();
  const [resetPassword, loading] = useResetPassword();

  function handleReset(form: { [x: string]: string }): void {
    const params: IResetPasswordParams = {
      password: "",
      passwordRepeat: "",
      email: "",
      code: "",
      ...form,
    };

    resetPassword(params);
  }

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <FadeIn>
          <Card type={ICardTypeEnums.CENTERED} loading={loading}>
            <CardTitle>
              <Logo />
            </CardTitle>
            <CardBody>
              <Form onSubmitHandler={handleReset}>
                <FormRow align={AlignmentEnums.STRETCH}>
                  <P>
                    <Trans i18nKey={"reset.info_text"} />
                  </P>
                </FormRow>
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Input
                    name="code"
                    label={t("reset.code.label")}
                    type={InputTypes.TEXT}
                    value=""
                    branded={true}
                    required={true}
                  />
                </FormRow>
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Input
                    name="email"
                    label={t("reset.email.label")}
                    type={InputTypes.EMAIL}
                    value=""
                    branded={true}
                    required={true}
                  />
                </FormRow>
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Input
                    name="password"
                    label={t("reset.password.label")}
                    type={InputTypes.PASSWORD}
                    value=""
                    branded={true}
                    required={true}
                  />
                </FormRow>
                <FormRow align={AlignmentEnums.STRETCH}>
                  <Input
                    name="passwordRepeat"
                    label={t("reset.repeat_password.label")}
                    type={InputTypes.PASSWORD}
                    value=""
                    branded={true}
                    required={true}
                  />
                </FormRow>
                {currentUser.errors.length > 0 && <LoginError />}
                <FormRow align={AlignmentEnums.STRETCH}>
                  <ButtonBranded
                    inverted={false}
                    variant={ButtonBrandedVariantEnums.PRIMARY}
                    type="submit"
                    disabled={loading}
                  >
                    {t("reset.submit")}
                  </ButtonBranded>
                </FormRow>
              </Form>
            </CardBody>
            <CardFooter>
              <div>
                <Link to="/">{t("misc.back")}</Link>
              </div>
            </CardFooter>
          </Card>
        </FadeIn>
      </ErrorBoundary>
    </SimpleLayout>
  );
};
