import { gql, QueryLazyOptions, useLazyQuery } from "@apollo/client";

import { useSelectedClientData } from "./useSelectedClientData";
import { useEffect, useState } from "react";
import { useTransactionEventListener } from "./useTransactionEventListener";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ICurrentUserAction } from "../store/reducers";
import { EDataSyncLevel, ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { IUploadedSuSa, EventErrorsCodes } from "@canei/app-components";
import { isEqual } from "lodash";

interface IGetUploadsListVariables {
  client_id: string;
}
const GET_UPLOADS_LIST = gql`
  query getUploads($client_id: ID!) {
    getAllSuSasOfClient(client_id: $client_id, withPlans: true) {
      date
      format
      plans_count
      uploaded_at
      susa_id
    }
  }
`;
const DELETE_SUSA = gql`
  query deleteSuSa($susa_id: ID!, $links: [RelationLinkInput]!) {
    deleteSuSa(susa_id: $susa_id, links: $links)
  }
`;
export interface IUseEditUploadListReturnValue {
  deleteInProgress: string[];
  deleteUploadBySuSaId: TDeleteUploadsBySuSaId;
}
type TDeleteUploadsBySuSaId = (susa_id: string) => void;
export const useEditUploadList = (): IUseEditUploadListReturnValue => {
  const { getUploads } = useUpdateUploadsList();
  const [deleteSuSa, { data: deletedCovid }] = useLazyQuery(DELETE_SUSA);
  const [transactionEvent, errorState, params] = useTransactionEventListener();
  const [susaIdTobeDeleted, setSusaIdTobeDeleted] = useState<string | undefined>(undefined);
  const [deleteInProgress, setDeleteInProgress] = useState<string[]>([]);
  const [updateListAfterDelete, setUpdateListAfterDelete] = useState(false);
  const selectedClientData = useSelectedClientData();

  useEffect(() => {
    if (selectedClientData === false) return;
    if (susaIdTobeDeleted) {
      setDeleteInProgress([...deleteInProgress, susaIdTobeDeleted]);
      deleteSuSa({
        variables: {
          susa_id: susaIdTobeDeleted,
          links: selectedClientData.links,
        },
      });
      setSusaIdTobeDeleted(undefined);
    }
  }, [selectedClientData, susaIdTobeDeleted, deleteSuSa, deleteInProgress]);

  useEffect(() => {
    if (selectedClientData === false) return;
    const transactionId = deletedCovid?.deleteSuSa as string;
    if (!transactionId) return;
    if (transactionId === params?.transactionId) return;
    setUpdateListAfterDelete(true);
    transactionEvent({
      transactionId,
      accountId: selectedClientData.client_id,
      level: EDataSyncLevel.CLIENT,
    });
  }, [deletedCovid, selectedClientData, transactionEvent, params]);

  useEffect(() => {
    const transactionId = deletedCovid?.deleteSuSa as string;
    if (!transactionId || !errorState) return;

    if (
      (errorState[transactionId] === EventErrorsCodes.TRANSACTION_OK ||
        errorState[transactionId] === EventErrorsCodes.TIME_OUT) &&
      selectedClientData !== false
    ) {
      if (updateListAfterDelete) {
        setUpdateListAfterDelete(false);
        getUploads({ variables: { client_id: selectedClientData.client_id } });
      }
    }
  }, [errorState, getUploads, updateListAfterDelete, selectedClientData, deletedCovid?.deleteSuSa]);

  const deleteUploadBySuSaId: TDeleteUploadsBySuSaId = (susa_id: string) => {
    if (selectedClientData === false) return;
    setSusaIdTobeDeleted(susa_id);
  };

  return {
    deleteInProgress,
    deleteUploadBySuSaId,
  };
};

export interface IUseUpdateUploadsListReturnValue {
  getUploads: (options?: QueryLazyOptions<IGetUploadsListVariables>) => void;
}

export const useUpdateUploadsList = (): IUseUpdateUploadsListReturnValue => {
  const currentUser = useSelector(({ currentUser }: ILocalState) => currentUser, isEqual);
  const dispatchUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const [getUploads, { data, loading: listLoading, called: listCalled }] = useLazyQuery(
    GET_UPLOADS_LIST,
    {
      fetchPolicy: "no-cache",
    }
  );

  const uploadsList: IUploadedSuSa[] = data?.getAllSuSasOfClient;
  useEffect(() => {
    if (currentUser.selectedClient.uploads?.loading !== listLoading && listCalled) {
      dispatchUser({
        type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
        payload: {
          selectedClient: {
            uploads: {
              loading: listLoading,
            },
          },
        },
      });
    }
  }, [currentUser.selectedClient.uploads, dispatchUser, listLoading, listCalled]);
  useEffect(() => {
    if (!uploadsList) return;

    dispatchUser({
      type: UserStateActionTypes.UPDATE_UPLOADS_LIST,
      payload: {
        selectedClient: {
          uploads: {
            loading: false,
            list: uploadsList,
          },
        },
      },
    });
  }, [dispatchUser, uploadsList, listLoading]);
  return {
    getUploads,
  };
};
