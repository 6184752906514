import { Dispatch } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ETaxAppSyncState, ETaxSetupSyncState, ILocalState } from "../../../@types";
import { EAppStatusActionType, IAppStatusAction } from "../../store/reducers/appStatus";

export enum EAppSyncLocal {
  UPDATE = "UPDATE",
  REMOVE = "REMOVE",
}

interface IChangeAppSyncParams {
  ready: boolean;
  appSyncState: ETaxAppSyncState;
  setupSyncState: ETaxSetupSyncState;
  local: EAppSyncLocal;
}

interface IUseAppSyncRetVal {
  changeAppSync: (p: IChangeAppSyncParams) => void;
}

export const useAppSync = (): IUseAppSyncRetVal => {
  const appSync = useSelector(({ appStatus: { appSync } }: ILocalState) => appSync, isEqual);
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();

  const changeAppSync = ({
    ready,
    appSyncState,
    setupSyncState,
    local,
  }: IChangeAppSyncParams): void => {
    dispatchAppStatus({
      type: EAppStatusActionType.SET_APP_SYNC,
      payload: {
        appSync: {
          ...appSync,
          ready: ready,
          appSyncState: appSyncState,
          setupSyncState: setupSyncState,
        },
      },
    });
    if (local === EAppSyncLocal.UPDATE) {
      localStorage.setItem(
        "app_status",
        JSON.stringify({
          ready: ready,
          appSyncState: appSyncState,
          setupSyncState: setupSyncState,
        })
      );
    }
    if (local === EAppSyncLocal.REMOVE) {
      localStorage.removeItem("app_status");
    }
  };
  return { changeAppSync };
};
