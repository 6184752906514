import { useDispatch, useSelector } from "react-redux";
import { ILocalState } from "../../../@types/index.d";
import { EClientSelection, IClientInProgress } from "../useDatev";
import { useCallback, useRef, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { GET_SELECTED_ALERTS } from "./useCallGetAlertsOfClients";
import { useApolloClient } from "@apollo/client";
import { IClientAlert, IGetAlertsResults, IGetSelectedAlertsVars } from "./types.alerting";
import { EAppStatusActionType, IAppStatusAction } from "../../store/reducers/appStatus";
import { useAlertingHelper } from "./useAlertingHelper";
import { IconNameEnums } from "@canei/app-components";
import { useNumberOfClientsWarningDialog } from "../../../views/local/Private/PrivateMainContext/useNumberOfClientsWarningDialog";
import config from "../../../config";
import { isEqual } from "lodash";

export interface IseGetAlertsOfClient {
  startAlertsDashboard: (alertsToLoad: string[]) => void;
  getAlertsOfClient: (alert: IClientInProgress) => void;
}

export const useGetAlertsOfClient = (): IseGetAlertsOfClient => {
  const datev = useSelector(({ appStatus: { datev } }: ILocalState) => datev, isEqual);
  const alerts = useSelector(({ appStatus: { alerts } }: ILocalState) => alerts, isEqual);
  const metaData = useSelector(({ appStatus: { metaData } }: ILocalState) => metaData, isEqual);
  const caneiClients = useSelector(
    ({ appStatus: { caneiClients } }: ILocalState) => caneiClients,
    isEqual
  );
  const warningDialog = useSelector(
    ({ appStatus: { warningDialog } }: ILocalState) => warningDialog,
    isEqual
  );

  // const [alertsToProcess, setAlertsToProcess] = useState<IClientInProgress[]>([]);
  const [dashAlertsStarted, setDashAlertsStarted] = useState<boolean>(false);
  const apolloClient = useApolloClient();
  const dispatchAppStatus = useDispatch<Dispatch<IAppStatusAction>>();
  const { getAlertsCompleted } = useAlertingHelper();
  const alertsRef = useRef<IClientAlert[]>(alerts);
  alertsRef.current = alerts;
  const { open: handleWarningDialog } = useNumberOfClientsWarningDialog();

  // start alert processing from client selection
  const startAlertsDashboard = (alertsToLoad: string[]): void => {
    if (dashAlertsStarted) return;
    setDashAlertsStarted(true);

    // if warning limiter has been reached, show warning dialog
    if (alertsToLoad.length >= config.warningLimiter && !warningDialog) {
      // console.log("useEffect: PrivateMainContext", datev.selected_clients);
      dispatchAppStatus({
        type: EAppStatusActionType.TOGGLE_WARNING_DIALOG,
        payload: {
          warningDialog: true,
        },
      });
      handleWarningDialog();
    }

    // get all clientIds and split in to chunks (n = config.alertLimiter)
    const client_ids = alertsToLoad
      .map((alertId) => {
        return caneiClients?.find((client) => client.name === alertId)?.client_id;
      })
      .map((_, i, all) =>
        all.slice(config.alertsLimiter * i, config.alertsLimiter * i + config.alertsLimiter)
      )
      .filter((x) => x.length) as string[][];

    Promise.all(
      client_ids.map((client_ids) => {
        // init params
        const params = {
          client_ids: client_ids,
          from: metaData?.date,
          to: metaData?.date,
        };
        return apolloClient
          .query<IGetAlertsResults, IGetSelectedAlertsVars>({
            query: GET_SELECTED_ALERTS,
            variables: params,
            fetchPolicy: "no-cache",
          })
          .then(({ data }) => {
            getAlertsCompleted(data);
            return data.results;
          })
          .then((data) => {
            return Promise.all(
              data.map((alert) => {
                const datevId = caneiClients?.find((client) => client.client_id === alert.client_id)
                  ?.name as string;
                if (alert.months.length === 0) {
                  dispatchAppStatus({
                    type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
                    payload: {
                      [datevId]: {
                        client_id: alert.client_id,
                        datev_id: datevId,
                        progress: EClientSelection.NO_SUSAS,
                        icon: IconNameEnums.ATTENTION_1,
                      },
                    },
                  });
                } else {
                  dispatchAppStatus({
                    type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
                    payload: {
                      [datevId]: {
                        datev_id: datevId,
                        client_id: alert.client_id,
                        progress: EClientSelection.LOADED,
                        months: alert.months[0],
                        number: datev.datev_clients.find((c) => c.id === datevId)?.number,
                        name: datev.datev_clients.find((c) => c.id === datevId)?.name,
                        show: true,
                        icon: IconNameEnums.Q_CHECKMARK_ON,
                      },
                    },
                  });
                }
                return "done";
              })
            );
          });
      })
    ).then(() => {
      setDashAlertsStarted(false);
    });
  };

  // get alerts of processed client
  const getAlertsOfClient = useCallback(
    (alert: IClientInProgress): void => {
      const params = {
        client_ids: [alert.client_id as string],
        from: datev.selected_date,
        to: datev.selected_date,
      };

      // in case the request is coming from dashboard
      if (!datev.selected_date) {
        params.from = metaData?.date;
        params.to = metaData?.date;
      }

      // not to send an empty array, then backend returns empty array, related to backlog issues 356
      if (!alert.client_id || params.client_ids.length === 0) return;

      // get alerts from backend
      apolloClient
        .query<IGetAlertsResults, IGetSelectedAlertsVars>({
          query: GET_SELECTED_ALERTS,
          variables: params,
          fetchPolicy: "no-cache",
        })
        .then(({ data }) => {
          getAlertsCompleted(data);
          return data.results;
        })
        .then(([data]) => {
          // finish processing by changing state to LOADED
          dispatchAppStatus({
            type: EAppStatusActionType.UPDATE_APP_ALERTS_IN_PROGRESS,
            payload: {
              [alert.datev_id]: {
                datev_id: alert.datev_id,
                client_id: alert.client_id,
                progress: EClientSelection.LOADED,
                months: data.months[0],
                number: datev.datev_clients.find((c) => c.id === alert.datev_id)?.number,
                name: datev.datev_clients.find((c) => c.id === alert.datev_id)?.name,
                show: true,
                icon: IconNameEnums.Q_CHECKMARK_ON,
              },
            },
          });
        });
    },
    [
      apolloClient,
      datev.datev_clients,
      datev.selected_date,
      dispatchAppStatus,
      getAlertsCompleted,
      metaData?.date,
    ]
  );

  return {
    startAlertsDashboard,
    getAlertsOfClient,
  };
};
