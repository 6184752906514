import { FC } from "react";

import { StyledSetup } from "../../../../StyledComponents";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormRow,
  AlignmentEnums,
  Input,
  ButtonBranded,
  ButtonBrandedVariantEnums,
} from "@canei/app-components";
export const SystemSetup: FC = () => {
  const { t } = useTranslation(["tax/common"]);
  return (
    <StyledSetup.SetupContent.Wrapper>
      <StyledSetup.SetupContent.Caption>
        {t("initial_setup.better_business.system_settings.caption")}
      </StyledSetup.SetupContent.Caption>
      <StyledSetup.SetupContent.Body>
        <StyledSetup.SetupContent.InformationText>
          {t("initial_setup.better_business.system_settings.text")}
        </StyledSetup.SetupContent.InformationText>
        <Form>
          <FormRow align={AlignmentEnums.CENTER}>
            <Input
              name={"api_key"}
              value={""}
              label={t("initial_setup.better_business.system_settings.api_key.label")}
              branded
            />
          </FormRow>
          <FormRow align={AlignmentEnums.CENTER}>
            <Input
              name={"api_token"}
              value={""}
              label={t("initial_setup.better_business.system_settings.api_token.label")}
              branded
            />
          </FormRow>
          <FormRow align={AlignmentEnums.CENTER}>
            {t("initial_setup.better_business.system_settings.datev_url.text")}
          </FormRow>
          <FormRow align={AlignmentEnums.CENTER}>
            <Input
              name={"api_token"}
              value={""}
              label={t("initial_setup.better_business.system_settings.datev_url.label")}
              branded
            />
          </FormRow>

          <FormRow align={AlignmentEnums.CENTER}>
            <ButtonBranded
              variant={ButtonBrandedVariantEnums.PRIMARY}
              type={"submit"}
              inverted={false}
              inline
            >
              {t("initial_setup.better_business.system_settings.button_text")}
            </ButtonBranded>
          </FormRow>
        </Form>
      </StyledSetup.SetupContent.Body>
    </StyledSetup.SetupContent.Wrapper>
  );
};
