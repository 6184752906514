import { createContext, Dispatch, FC, Reducer, useEffect, useReducer } from "react";
import { SimpleLayout } from "../../../../_lib/Layout";
import {
  ErrorBoundary,
  IQProgressProps,
  QProgress,
  StyledQWizardForm,
  EQCardSectionTypes,
  QCard,
  QCardSection,
} from "@canei/app-components";

import { CTA } from "./CTA";

import { EPublicPaths } from "../../../../@types/index.d";
import { RegisterSteps } from "./RegisterSteps";

import { useLocation } from "react-router-dom";

export interface IRegisterData {
  progress: IQProgressProps<EProgressStep>;
  company_name: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  street: string | undefined;
  postal_code: string | undefined;
  city: string | undefined;
  email: string | undefined;
  isEmailValid: boolean;
  phone: string | undefined;
  coupon: string | undefined;
  utm_campaign: string | undefined;
  utm_source: string | undefined;
  utm_medium: string | undefined;
  utm_content: string | undefined;
  utm_term: string | undefined;
  ad_group_id: string | undefined;
  gcl_id: string | undefined;
  l_page: string | undefined;
  isCouponValid: boolean;
  form_state: IQRegisterFormState;
  terms_accepted: boolean;
  privacy_accepted: boolean;
  data_processing_accepted: boolean;
}
export interface IRegisterAction {
  type: EQRegisterActionType;
  payload: Partial<IRegisterData>;
}

export enum EProgressStep {
  CUSTOMER_DATA = "CUSTOMER_DATA",
  CONFIRM = "CONFIRM",
  SUCCESS = "SUCCESS",
}

export enum EQRegisterActionType {
  UPDATE_DATA,
}
const initialRegisterData: IRegisterData = {
  company_name: undefined,
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  isEmailValid: true,
  phone: undefined,
  street: undefined,
  postal_code: undefined,
  city: undefined,
  coupon: undefined,
  utm_campaign: undefined,
  utm_source: undefined,
  utm_medium: undefined,
  utm_content: undefined,
  utm_term: undefined,
  ad_group_id: undefined,
  gcl_id: undefined,
  l_page: undefined,
  isCouponValid: true,
  progress: {
    steps: [
      { label: "Kanzleidaten", stepId: EProgressStep.CUSTOMER_DATA },
      { label: "Datenschutz", stepId: EProgressStep.CONFIRM },
      { label: "Bestätigung", stepId: EProgressStep.SUCCESS },
    ],
    currentStep: EProgressStep.CUSTOMER_DATA,
  },
  form_state: {
    initial: true,
    valid: false,
  },
  terms_accepted: false,
  privacy_accepted: false,
  data_processing_accepted: false,
};
export interface IQRegisterFormState {
  initial: boolean;
  valid: boolean;
}
export const RegisterContext = createContext<IRegisterData>(initialRegisterData);
export const RegisterDispatch = createContext<Dispatch<IRegisterAction>>((state) => state);

const reducerQRegister = (state: IRegisterData, action: IRegisterAction): IRegisterData => {
  return { ...state, ...action.payload };
};

export const Register: FC = () => {
  const [context, dispatch] = useReducer<Reducer<IRegisterData, IRegisterAction>>(
    reducerQRegister,
    initialRegisterData
  );

  const location = useLocation();
  const isSuccessPage = location.pathname === EPublicPaths.REGISTER_SUCCESS;

  useEffect(() => {
    if (isSuccessPage && context.progress.currentStep !== EProgressStep.SUCCESS) {
      dispatch({
        type: EQRegisterActionType.UPDATE_DATA,
        payload: {
          progress: {
            ...context.progress,
            currentStep: EProgressStep.SUCCESS,
          },
        },
      });
    }
  }, [context.progress, isSuccessPage]);

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <RegisterDispatch.Provider value={dispatch}>
          <RegisterContext.Provider value={context}>
            <QCard>
              <QCardSection type={EQCardSectionTypes.DARK}>
                <CTA />
              </QCardSection>
              <QCardSection type={EQCardSectionTypes.DEFAULT}>
                <StyledQWizardForm.Wrapper>
                  <QProgress {...context.progress} />
                  <RegisterSteps />
                </StyledQWizardForm.Wrapper>
              </QCardSection>
            </QCard>
          </RegisterContext.Provider>
        </RegisterDispatch.Provider>
      </ErrorBoundary>
    </SimpleLayout>
  );
};
